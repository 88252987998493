import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

class Menu extends Component {
  render() {
    const { classes, anchor, isOpen, onClose, routes, children } = this.props;
    return (
      <Popover
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isOpen}
        onClose={onClose}
      >
        {routes.map(({ icon: Icon, ...route }, index) => (
          <Link
            key={index}
            onClick={onClose}
            to={`/${route.path.split('/')[1]}`}
            className={classes.link}
          >
            <MenuItem>
              {Icon && <Icon color="primary" />}
              <Typography className={classes.text} variant="body1">
                {route.title}
              </Typography>
            </MenuItem>
          </Link>
        ))}

        {children}
      </Popover>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.func,
  close: PropTypes.func,
  routes: PropTypes.array
};

const styles = theme => ({
  link: {
    textDecoration: 'none'
  },
  text: {
    marginLeft: theme.spacing.unit
  }
});

export default withStyles(styles)(Menu);
