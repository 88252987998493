import { Trans } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import CNPJField from 'components/form/fields/cnpj';
import EmailField from 'components/form/fields/email';
import PhoneNumberField from 'components/form/fields/phone-number';
import TextField from 'components/form/fields/text';
import { maxLength, required } from 'components/form/fields/validation';

const propTypes = {
  disableCNPJ: PropTypes.bool
};

const defaultProps = {
  disableCNPJ: false
};

function Information({ disableCNPJ, ...props }) {
  return (
    <>
      <Grid container spacing={24}>
        <Grid item xs={12} md={4}>
          <CNPJField
            disabled={disableCNPJ}
            fullWidth
            label={<Trans>CNPJ*</Trans>}
            name="cnpj"
            validate={[required]}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            label={<Trans>Razão Social*</Trans>}
            name="socialName"
            validate={[required]}
          />
        </Grid>
      </Grid>

      <Grid container spacing={24}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="tradingName"
            label={<Trans>Nome Fantasia*</Trans>}
            validate={[required, tradingNameMaxLength]}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <PhoneNumberField fullWidth name="phoneNumber" label={<Trans>Telefone</Trans>} />
        </Grid>

        <Grid item xs={12} md={4}>
          <EmailField fullWidth name="email" label={<Trans>Email*</Trans>} validate={[required]} />
        </Grid>
      </Grid>
    </>
  );
}

Information.propTypes = propTypes;
Information.defaultProps = defaultProps;

const tradingNameMaxLength = maxLength(255);

export default Information;
