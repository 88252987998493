import React, { PureComponent } from 'react';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { connect } from 'react-redux';
import { getCurrentLocale } from 'store/selectors/i18nSelectors';
import MomentUtils from '@date-io/moment';
// import "moment/locale/pt";
// Moment.globalFormat = "ll";

export class PickerWrapper extends PureComponent {
  static propTypes = {};

  render() {
    const { locale } = this.props;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
        {this.props.children}
      </MuiPickersUtilsProvider>
    );
  }
}

const mapStateToProps = state => ({
  currentLocale: getCurrentLocale(state)
});

export default connect(mapStateToProps)(PickerWrapper);
