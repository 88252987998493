import { Trans } from '@lingui/macro';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Button from 'components/buttons/button';
import SelectField from 'components/form/fields/select';
import TextField from 'components/form/fields/text';
import EmailField from 'components/form/fields/email';
import CPFField from 'components/form/fields/cpf/';
import PhoneNumberField from 'components/form/fields/phone-number';
import { required } from 'components/form/fields/validation';
import memberType from 'utils/types/memberType';

export default function AddEditMembers({ onSubmit, handleSubmit }) {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SelectField
        fullWidth
        name="type"
        validate={[required]}
        label={<Trans>Tipo do membro*</Trans>}
      >
        {memberType &&
          memberType.map(({ value, text }) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
      </SelectField>
      <TextField
        fullWidth
        id="name"
        label={<Trans>Nome*</Trans>}
        margin="dense"
        name="name"
        validate={[required]}
      />
      <CPFField fullWidth label={<Trans>CPF</Trans>} name="identity" />
      <EmailField fullWidth label={<Trans>Email</Trans>} margin="dense" name="email" type="email" />
      <PhoneNumberField fullWidth name="phoneNumber" label={<Trans>Telefone</Trans>} />
      <Button type="submit">
        <Trans>Salvar</Trans>
      </Button>
    </form>
  );
}
