export default function styles(theme) {
  return {
    logo: {
      maxWidth: 250,
      minHeight: 50,
      marginBottom: theme.spacing.unit * 2,
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block'
    },
    wrapperLogin: {
      background: theme.palette.primary.light,
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100vw',
      height: '100vh',
      zIndex: '2',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        overflowY: 'auto'
      }
    },
    container: {
      bottom: 0,
      display: 'block',
      left: 0,
      margin: 'auto',
      width: '100vw',
      height: '100vh',
      background: 'rgba(255,255,255,.6)',
      perspective: '1000px',
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translateZ(0)',
      zIndex: '2',
      [theme.breakpoints.up('md')]: {
        height: 'fit-content',
        width: 'auto',
        maxHeight: 580,
        maxWidth: 450
      }
    },
    loginCard: {
      transition: 'transform 0.6s ease, -webkit-transform 0.6s ease'
    },
    bgBubbles: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      zIndex: '1',
      '& li': {
        position: 'absolute',
        listStyle: 'none',
        display: 'block',
        width: '40px',
        height: '40px',
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
        bottom: '-160px',
        animation: 'square 25s infinite',
        'transition-timing-function': 'linear',
        '&:nth-child(1)': {
          left: '10%'
        },
        '&:nth-child(2)': {
          left: '20%',
          width: '80px',
          height: '80px',
          'animation-delay': '2s',
          'animation-duration': '17s'
        },
        '&:nth-child(3)': {
          left: '25%',
          'animation-delay': '4s'
        },
        '&:nth-child(4)': {
          left: '40%',
          width: '60px',
          height: '60px',
          'animation-duration': '22s',
          backgroundColor: 'rgba(255, 255, 255, 0.25)'
        },
        '&:nth-child(5)': {
          left: '70%'
        },
        '&:nth-child(6)': {
          left: '80%',
          width: '120px',
          height: '120px',
          'animation-delay': '3s',
          backgroundColor: 'rgba(255, 255, 255, 0.2)'
        },
        '&:nth-child(7)': {
          left: '32%',
          width: '160px',
          height: '160px',
          'animation-delay': '7s'
        },
        '&:nth-child(8)': {
          left: '55%',
          width: '20px',
          height: '20px',
          'animation-delay': '15s',
          'animation-duration': '40s'
        },
        '&:nth-child(9)': {
          left: '25%',
          width: '10px',
          height: '10px',
          'animation-delay': '2s',
          'animation-duration': '40s',
          backgroundColor: 'rgba(255, 255, 255, 0.3)'
        },
        '&:nth-child(10)': {
          left: '90%',
          width: '160px',
          height: '160px',
          'animation-delay': '11s'
        }
      }
    },
    '@keyframes square': {
      '0%': {
        transform: 'translateY(0)'
      },
      '100%': {
        transform: 'translateY(-1000px) rotate(600deg)'
      }
    },
    hide: {
      display: 'none'
    }
  };
}
