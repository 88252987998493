import React from 'react';
import Button from 'components/buttons/button';
import ReactDOMServer from 'react-dom/server';
import BackupIcon from '@material-ui/icons/Backup';
import Input from '@material-ui/core/Input';

function FileInput({ complete, input: { accept, onChange, name, value }, label }) {
  function handleChange(event) {
    const file = event.target.files[0];
    onChange(file);
  }

  return (
    <>
      <input
        style={{ display: 'none' }}
        id={name}
        accept={accept}
        onChange={handleChange}
        type="file"
      />
      <label htmlFor={name}>
        {!complete && (
          <Button component="span" variant="text">
            {label}
          </Button>
        )}

        {complete && (
          <>
            <Input
              style={{ flexGrow: 1 }}
              type="text"
              disabled
              value={value ? value.name : ReactDOMServer.renderToStaticMarkup(label)}
            />
            <Button component="span" variant="text">
              <BackupIcon />
            </Button>
          </>
        )}
      </label>
    </>
  );
}

export default FileInput;
