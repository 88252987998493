import { withStyles } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';
import { Creators as SuppliersCreators } from 'store/ducks/suppliers';
import withWidth from '@material-ui/core/withWidth';
import { formatPrescriptionValues, formatFrameValues } from 'utils/formatters/formatters';
import ConectOrdersProvider from './EditOrderDetail';
import styles from './styles';

const findItemBySequence = order => sequence =>
  order.items.find(item => item.itemSequence === sequence);

function mapStateToProps(state, ownProps) {
  const formValues = getFormValues('editOrder')(state) || {};
  const order = ownProps.getOrderById(ownProps.orderId);

  return {
    initialValues: {
      ...order,
      store: ownProps.storeInfo,
      items: order.items.map(item => ({
        ...item,
        selector: {
          label: item.description,
          value: item.description,
          id: item.sku
        }
      })),
      prescription: {
        ...order.prescription,
        eyesPrescription: orderBy(
          formatPrescriptionValues(order.prescription.eyesPrescription),
          'side',
          'desc'
        ).map(eye => {
          const item = findItemBySequence(order)(eye.itemSequence);
          return {
            ...eye,
            selector: {
              label: item && item.description,
              value: item && item.description,
              id: item && item.sku
            }
          };
        }),
        frame: formatFrameValues(order.prescription.frame),
        anamnese: order.prescription.anamnese
      },
      promotionId: order.promotionId,
      promotionOrder: order.promotionOrder,
      promotionVoucher: order.promotionVoucher,
      sentToSupplier: order.sentToSupplier,
      sgoOriginId: order.sgoOriginId
    },
    suppliersList: state.suppliers,
    formValues
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchList: _ => dispatch(SuppliersCreators.getSuppliers())
  };
}

export default compose(
  withWidth(),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'editOrder',
    enableReinitialize: true
  }),
  withStyles(styles)
)(ConectOrdersProvider);
