export default [
  { value: 'occupation', label: 'Ocupação', type: 'text' },
  { value: 'hobby', label: 'Hobby', type: 'text' },
  { value: 'visualNeed', label: 'Necessidade Visual', type: 'select' },
  { value: 'reading', label: 'Leitura', type: 'select' },
  { value: 'computer', label: 'Computador', type: 'select' },
  { value: 'direction', label: 'Direção', type: 'select' },
  { value: 'tv', label: 'TV', type: 'select' },
  { value: 'pantoscopicTilt', label: 'Inclinação Pantoscópica', type: 'number' },
  { value: 'vertexCornea', label: 'Córnea Vértice', type: 'number' }
];
