import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import { Trans } from '@lingui/macro';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import StepperValidation from './stepper/steppervalidation/StepperValidation';
import BaseOrderDialog from './BaseOrderDialog';

class SendOrderDialog extends Component {
  render() {
    const { closeDialog, classes, order, fetchOrders, getOrderById, getStoreById } = this.props;
    const Title = () => (
      <>
        <Trans>Enviando pedido </Trans> <b>{order && order.orderNumber}</b>
      </>
    );

    return (
      <BaseOrderDialog
        closeCallback={this.close}
        maxWidth="lg"
        fullScreen
        fullWidth
        name="sendOrder"
        title={<Title />}
      >
        <DialogContent className={classes.root}>
          <StepperValidation
            storeId={order && order.storeId}
            order={order}
            getOrderById={getOrderById}
            getStoreById={getStoreById}
            fetchOrders={fetchOrders}
            closeDialog={closeDialog}
          />
        </DialogContent>
      </BaseOrderDialog>
    );
  }

  close = event => {
    const { destroy } = this.props;
    destroy();
  };
}

const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: 350,
    flexDirection: 'column',
    padding: theme.spacing.unit * 3
  }
});

export default compose(
  withStyles(styles),

  reduxForm({
    form: 'wizard'
  })
)(SendOrderDialog);
