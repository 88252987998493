import PropTypes from 'prop-types';
import React from 'react';
import OrdersPerSupplierChart from './partials/OrdersPerSupplierChart';
import ReceivedOrdersChart from './partials/ReceivedOrdersChart';
import SentOrdersChart from './partials/SentOrdersChart';

const propTypes = {
  chart: PropTypes.oneOf('receivedOrders', 'sentOrders', 'ordersPerSupplier'),
  filters: PropTypes.shape({
    date: PropTypes.string,
    store: PropTypes.arrayOf(PropTypes.string),
    period: PropTypes.string
  })
};

export default function Charts(props) {
  const { chart, filters } = props;

  return (
    <>
      {chart === 'receivedOrders' && <ReceivedOrdersChart filters={filters} />}
      {chart === 'sentOrders' && <SentOrdersChart filters={filters} />}
      {chart === 'ordersPerSupplier' && <OrdersPerSupplierChart filters={filters} />}
    </>
  );
}

Charts.propTypes = propTypes;
