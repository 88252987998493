import { Trans } from '@lingui/macro';
import React from 'react';
import SelectField from 'components/form/fields/select';
import MenuItem from '@material-ui/core/MenuItem';
import types from 'utils/types';
import lensType from 'utils/types/lensType';
import colorType from 'utils/types/colorType';
import { Link } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';

export default function IntegrationFiltersForm({ stores, formValues, suppliers, query }) {
  const { storeId } = formValues;
  const { resourceType, supplierId } = query.params;
  const areStoresSet = Boolean(stores && stores.length);
  const isStoreSet = Boolean(storeId);

  const selectedStore = stores.find(item => item.store.id === +storeId);
  const isTypeLens = resourceType === 'LENS';
  const isColor = resourceType === 'COLOR';

  const storeSuppliers = areStoresSet && isStoreSet ? selectedStore.suppliers : [];

  const removeProperty = property => query.remove({ property });

  const handleChange = property => event => {
    const { value } = event.target;
    query.set({ property, value });

    if (resourceType !== 'COLOR') removeProperty('colorType');
    if (property === 'resourceType') removeProperty('lensType');
  };

  const suppliersList = suppliers.filter(supplier =>
    storeSuppliers
      ? storeSuppliers.find(storeSupplier => storeSupplier.supplierId === supplier.id)
      : supplierId && removeProperty('supplierId')
  );

  return (
    <>
      <SelectField
        name="resourceType"
        fullWidth
        onChangeCallback={handleChange('resourceType')}
        label={<Trans>Tipo</Trans>}
        options={types.map(({ text, ...type }) => ({ ...type, label: text }))}
      />

      <Collapse in={isTypeLens}>
        <SelectField
          displayEmpty
          name="lensType"
          onChangeCallback={handleChange('lensType')}
          fullWidth
          label={<Trans>Tipo da lente</Trans>}
        >
          {lensType.map(type => (
            <MenuItem value={type.value}>{type.text}</MenuItem>
          ))}
        </SelectField>
      </Collapse>

      <Collapse in={isColor}>
        <SelectField
          displayEmpty
          name="colorType"
          onChangeCallback={handleChange('colorType')}
          fullWidth
          label={<Trans>Tipo de cor</Trans>}
        >
          {colorType.map(type => (
            <MenuItem value={type.value}>{type.text}</MenuItem>
          ))}
        </SelectField>
      </Collapse>

      <SelectField
        name="storeId"
        fullWidth
        label={<Trans>Loja</Trans>}
        onChangeCallback={handleChange('storeId')}
      >
        {stores.map(store => (
          <MenuItem value={store.store.id}>{store.store.tradingName}</MenuItem>
        ))}
      </SelectField>

      <SelectField
        disabled={!isStoreSet}
        name="supplierId"
        fullWidth
        onChangeCallback={handleChange('supplierId')}
        label={<Trans>Fornecedor associado</Trans>}
      >
        {suppliersList.map(supplier => (
          <MenuItem value={supplier.id}>{supplier.companyName}</MenuItem>
        ))}
        {!suppliersList.length && (
          <MenuItem>
            <Link to={`store/${storeId}/settings/suppliers`}>
              <Trans>Associe um fornecedor a Loja</Trans>
            </Link>
          </MenuItem>
        )}
      </SelectField>
    </>
  );
}
