import { Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import Button from 'components/buttons/button';
import PasswordField from 'components/form/fields/password';
import { minLength, required } from 'components/form/fields/validation';
import { changeUserPassword } from 'services/users';
import withNotification from 'store/providers/withNotification';
import withOverlay from 'store/providers/withOverlay';

class ChangePassword extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <PasswordField
          label={<Trans>Senha atual*</Trans>}
          validate={[required]}
          fullWidth
          name="oldPassword"
        />
        <PasswordField
          label={<Trans>Nova senha*</Trans>}
          fullWidth
          validate={[required, minLengthPassword]}
          name="newPassword"
        />
        <PasswordField
          label={<Trans>Confirme a senha*</Trans>}
          fullWidth
          validate={[required, minLengthPassword]}
          name="confirmPassword"
        />

        <Button type="submit">
          <Trans>Confirmar</Trans>
        </Button>
      </form>
    );
  }

  submit = values => {
    const { oldPassword, newPassword, confirmPassword } = values;
    const {
      userID: { userId }
    } = this.props;
    if (newPassword !== confirmPassword) {
      this.props.notify({
        variant: 'error',
        message: <Trans>Senhas não coincidem!</Trans>
      });
      return;
    }
    this.props.overlay.show(<Trans>Aguarde, processando...</Trans>);
    changeUserPassword(userId, newPassword, oldPassword).then(response => {
      if (response.status === 204) {
        this.props.notify({
          variant: 'success',
          message: <Trans>Sua senha foi atualizada com sucesso!</Trans>
        });
        this.props.submitCallback();
      } else if (response.status === 401) {
        this.props.notify({
          variant: 'error',
          message: <Trans>Senha atual incorreta!</Trans>
        });
      } else {
        this.props.notify({
          variant: 'error',
          message: (
            <Trans>
              Ocorreu uma falha de comunicação com o servidor. Tente novamente mais tarde!
            </Trans>
          )
        });
      }
      this.props.overlay.hide();
    });
  };
}

const minLengthPassword = minLength(6);

const styles = theme => ({});

const mapStateToProps = state => ({
  values: getFormValues('changePassword')(state)
});

export default compose(
  withOverlay,
  withNotification,
  withStyles(styles),
  connect(mapStateToProps),
  reduxForm({
    form: 'changePassword'
  })
)(ChangePassword);
