import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { type } from './TypeFrame';

class Frame extends Component {
  render() {
    const { classes, frame } = this.props;
    const filteredType = type.filter(({ id }) => id === frame.type);
    return (
      <>
        <Card>
          <CardHeader subheader="Dados da armação" />
          <CardContent className={classes.root}>
            <Grid container spacing={24}>
              <Grid item xs={6} sm={2}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>M.V.A:</Trans>{' '}
                  </b>
                  {frame && frame.vbox}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>M.H.A:</Trans>{' '}
                  </b>
                  {frame && frame.hbox}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>D.M.A:</Trans>{' '}
                  </b>
                  {frame && frame.largestDiameter}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>Ponte:</Trans>{' '}
                  </b>
                  {frame && frame.distanceBetweenLenses}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                {filteredType.length > 0 ? (
                  <Typography className={classes.classTypography} variant="body1" gutterBottom>
                    <b>
                      <Trans>Tipo:</Trans>{' '}
                    </b>
                    {filteredType[0].text}
                  </Typography>
                ) : (
                  <Typography className={classes.classTypography} variant="body1" gutterBottom>
                    <b>
                      <Trans>Tipo:</Trans>{' '}
                    </b>
                    <Trans>Nenhum encontrado</Trans>
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={24}>
              <Grid item xs={6} sm={4}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>Diâmetro:</Trans>{' '}
                  </b>
                  {frame && frame.largestDiameter}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>Envia montagem:</Trans>{' '}
                  </b>
                  {frame && frame.sendFrame === true ? 'Sim' : 'Não'}
                </Typography>
              </Grid>
              {frame.sendFrame && (
                <>
                  <Grid item xs={6} sm={4}>
                    <Typography className={classes.classTypography} variant="body1" gutterBottom>
                      <b>
                        <Trans>Cor:</Trans>{' '}
                      </b>
                      {frame && frame.color}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography className={classes.classTypography} variant="body1" gutterBottom>
                      <b>
                        <Trans>Grife:</Trans>{' '}
                      </b>
                      {frame && frame.designer}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography className={classes.classTypography} variant="body1" gutterBottom>
                      <b>
                        <Trans>Referência:</Trans>{' '}
                      </b>
                      {frame && frame.reference}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 2
  },
  classTypography: {
    fontSize: '0.8em'
  }
});

export default withStyles(styles)(Frame);
