import { Trans } from '@lingui/macro';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AddEditProduct from 'forms/orders/add-edit-product';
import { connect } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/orders';
import { bindActionCreators } from 'redux';
import BaseOrderDialog from './BaseOrderDialog';

class AddEditProductDialog extends Component {
  render() {
    const { payload } = this.props;
    if (!payload) return null;

    const { order, productKey, addItem } = payload;
    const title =
      addItem !== 'new' ? (
        <>
          <Trans>Editar item do pedido</Trans> <b>{order.orderNumber}</b>
        </>
      ) : (
        <>
          <Trans>Adicionar itens ao pedido </Trans> <b>{order.orderNumber}</b>{' '}
        </>
      );

    return (
      <BaseOrderDialog name="addEditProduct" title={title}>
        <DialogContent>
          <AddEditProduct onSubmit={this.submitCallback} order={order} productKey={productKey} />
        </DialogContent>
      </BaseOrderDialog>
    );
  }

  submitCallback = res => {
    const {
      receiveOrder,
      payload: { productKey, order }
    } = this.props;

    delete res.selector;

    const isNew = productKey >= order.items.length;

    const items = !isNew
      ? order.items.map((item, index) => {
          if (index === productKey) return res;
          return item;
        })
      : [...order.items, res];

    receiveOrder({ ...order, items });

    this.props.closeDialog('addEditProduct');
  };

  static propTypes = {
    order: PropTypes.object
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(OrderCreators, dispatch);
}

export default connect(
  null,
  mapDispatchToProps
)(AddEditProductDialog);
