import { DateTimePicker as MuiDateTimePicker } from 'material-ui-pickers';
import React from 'react';
import withHelperText from 'components/form/inputs/helper-text/withHelperText';
import PickerWrapper from '../../wrappers/PickerWrapper';

function DateTimePicker({ input, ...props }) {
  async function handleChange(moment) {
    const { onChangeCallback } = props;
    const { onChange } = input;

    const value = moment.format();

    await onChange(value);
    onChangeCallback && onChangeCallback(moment);
  }

  return (
    <PickerWrapper>
      <MuiDateTimePicker
        {...input}
        {...props}
        autoOk
        cancelLabel={null}
        okLabel={null}
        style={{
          backgroundColor: '#eee',
          width: '-webkit-fill-available',
          marginRight: 28
        }}
        onChange={handleChange}
      />
    </PickerWrapper>
  );
}

export default withHelperText(DateTimePicker);
