export default function styles(theme) {
  return {
    avatar: {
      maxWidth: '100%',
      margin: `0 auto`,
      minHeight: 200,
      minWidth: 200,
      boxShadow: theme.shadows[2],
      marginBottom: theme.spacing.unit * 2
    },
    avatarIcon: {
      minWidth: 150,
      minHeight: 150,
      opacity: 0.75
    },
    card: {
      padding: theme.spacing.unit * 2
    },
    email: {
      margin: theme.spacing.unit
    }
  };
}
