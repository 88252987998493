import React from 'react';
import { Field } from 'redux-form-normalize-on-blur';
import PrescriptionInput from 'components/form/inputs/prescription-input';
import { decimalFormat } from 'utils/formatters/formatters';

export const PrismValueField = ({ step = '0.25', ...props }) => (
  <Field
    {...props}
    step={step}
    component={PrescriptionInput}
    type="number"
    normalizeOnBlur={value => decimalFormat(value)}
  />
);

export default PrismValueField;
