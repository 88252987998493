import React, { Component } from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import RemoveAttachFromOrder from 'forms/orders/RemoveAttachFromOrder';
import { Trans } from '@lingui/macro';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import BaseOrderDialog from './BaseOrderDialog';

class RemoveAttachOrderDialog extends Component {
  render() {
    const { payload } = this.props;

    if (!payload) return null;

    const { attachedFile, order } = payload;

    return (
      <BaseOrderDialog name="removeAttachFromOrder" title={this.renderTitle()}>
        <DialogContent>
          <Typography variant="h6">
            <Trans>O anexo será deletado!</Trans>
          </Typography>

          <Typography variant="body1">
            <Trans>
              Você realmente confirma esta operação? Digite <b>SIM</b> para confirmar.
            </Trans>
          </Typography>

          <RemoveAttachFromOrder
            submitCallback={this.submitCallback}
            attachedFile={attachedFile}
            order={order}
          />
        </DialogContent>
      </BaseOrderDialog>
    );
  }

  renderTitle = () => {
    const {
      payload: { attachedFile, order }
    } = this.props;

    return (
      <>
        <Trans> Removendo o anexo </Trans> <b>{attachedFile.description}</b>{' '}
        <Trans> do pedido </Trans> <b>{order.orderNumber}</b>
      </>
    );
  };

  submitCallback = () => {
    const {
      closeDialog,
      payload: { getAttachedFiles }
    } = this.props;

    closeDialog();
    getAttachedFiles();
  };
}

const styles = theme => ({});

export default withStyles(styles)(RemoveAttachOrderDialog);
