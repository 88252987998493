import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

function Option({ children, selectProps, innerRef, isFocused, innerProps, props }) {
  const { classes } = selectProps;

  return (
    <MenuItem
      className={classes.option}
      style={{ fontSize: '1em' }}
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

export default Option;
