import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import IconButton from '@material-ui/core/IconButton';
import GenericIconFileField from 'components/form/fields/GenericIconFileField';
import Paper from '@material-ui/core/Paper';
import { Send as SendIcon, Upload as UploadIcon } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import { required } from 'components/form/validation';
import FormHelperText from '@material-ui/core/FormHelperText';
import GenericDragAndDropInput from 'components/form/fields/GenericDragAndDropFileField';

export default function UploadCSV(props) {
  const { classes, onSubmit, handleSubmit, submitSucceeded, reset, formValues } = props;
  const { file } = formValues;
  const [extension, setExtension] = useState(false);

  if (submitSucceeded) reset();

  if (file) {
    const ext = /[^.]+$/.exec(file.name);
    if (ext[0] === 'csv' && extension !== true) {
      setExtension(true);
    } else if (ext[0] !== 'csv' && extension === true) setExtension(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.field}>
        <span className={classes.button}>
          <GenericIconFileField
            hideHelpers
            icon={<UploadIcon />}
            name="file"
            validate={[required]}
          />
        </span>

        <GenericDragAndDropInput hideHelpers name="file" validate={[required]}>
          {file && file.name ? (
            <span>{file.name}</span>
          ) : (
            <span style={{ color: '#999' }}>Nenhum arquivo carregado</span>
          )}
        </GenericDragAndDropInput>

        <IconButton
          disabled={!extension || !file}
          className={classes.button}
          color="primary"
          type="submit"
        >
          <SendIcon />
        </IconButton>
      </Paper>
      <FormHelperText className={classes.helper}>
        {!file && (
          <Trans>
            Clique no botão a esquerda ou arraste um arquivo para a área pontilhada acima
          </Trans>
        )}
        {file && !extension && (
          <Trans>
            <span style={{ color: '#f00' }}>
              A extensão do arquivo é inválida, carregue um arquivo com a extensão .csv
            </span>
          </Trans>
        )}
        {file && extension && <Trans>Pronto! Agora é só enviar</Trans>}
      </FormHelperText>
    </form>
  );
}

UploadCSV.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  classes: PropTypes.shape({}),
  reset: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    file: PropTypes.shape({}).isRequired
  }).isRequired
};

UploadCSV.defaultProps = {
  classes: {}
};
