import React from 'react';
import { Field } from 'redux-form';
import DateTimePickerInput from 'components/form/inputs/date-time-picker';

export default function DateTimePickerField({ ...props }) {
  return <Field {...props} component={DateTimePickerInput} />;
}

DateTimePickerField.defaultProps = {
  variant: 'outlined',
  margin: 'dense'
};
