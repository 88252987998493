import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import lightBlue from '@material-ui/core/colors/lightBlue';

function InputSearch({ classes, className, placeholder, ...restArgs }) {
  return (
    <div className={classes.root}>
      <InputBase
        className={classes.item}
        placeholder={placeholder || 'Pesquisar…'}
        classes={{
          root: [classes.input]
        }}
        {...restArgs}
      />
      <IconButton className={[classes.searchIcon, classes.item]} type="submit">
        <SearchIcon />
      </IconButton>
    </div>
  );
}

function styles(theme) {
  return {
    root: {
      alignItems: 'center',
      backgroundColor: fade(lightBlue[500], 0.5),
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.common.white,
      display: 'flex',
      paddingLeft: theme.spacing.unit,
      transition: theme.transitions.create('background-color'),
      '&:focus, &:active': {
        backgroundColor: fade(lightBlue[500], 1)
      },
      '&:hover': {
        backgroundColor: fade(lightBlue[500], 0.75)
      }
    },
    input: {
      flex: 1
    },
    item: {
      color: 'inherit'
    }
  };
}

export default withStyles(styles)(InputSearch);
