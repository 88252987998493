import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'components/utils/Loading';
import { getSupplierStatus, getWebPedidos } from 'services/orders';
import { Creators as SuppliersCreators } from 'store/ducks/suppliers';
import { getOrderById } from 'store/selectors/orders';
import SupplierStatusList from './SupplierStatusList';

class SupplierStatusListContainer extends Component {
  render() {
    const { loading, supplierStatus, checkIframe, iframe } = this.state;
    const { suppliersList } = this.props;

    if (loading) {
      return <Loading />;
    }
    return (
      <SupplierStatusList
        supplierStatus={supplierStatus}
        checkIframe={checkIframe}
        iframe={iframe}
        suppliersList={suppliersList}
      />
    );
  }

  componentWillMount = async _ => {
    try {
      const { fetchList, orderId, storeId, suppliersList, order } = this.props;
      this.setState({ loading: true });
      fetchList();

      if (order && order.sentToSupplier) {
        const checkSupplier = suppliersList.filter(item => item.id === order.sentToSupplier);
        if (
          checkSupplier &&
          checkSupplier.length > 0 &&
          checkSupplier[0].integrationType === 'REST_ACERT'
        ) {
          const iframe = await getWebPedidos(orderId, storeId, order.sentToSupplier);
          this.setState({
            supplierStatus: [],
            checkIframe: true,
            iframe,
            loading: false
          });
        } else {
          const supplierStatusData = await getSupplierStatus(orderId, storeId);
          this.setState({
            supplierStatus: supplierStatusData,
            checkIframe: false,
            iframe: '',
            loading: false
          });
        }
      } else {
        const supplierStatusData = await getSupplierStatus(orderId, storeId);
        this.setState({
          supplierStatus: supplierStatusData,
          checkIframe: false,
          iframe: '',
          loading: false
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
}

function mapStateToProps(state, ownProps) {
  return { suppliersList: state.suppliers, order: getOrderById(state, ownProps.orderId) };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchList: _ => dispatch(SuppliersCreators.getSuppliers())
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SupplierStatusListContainer);
