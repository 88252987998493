import React, { Component } from 'react';

import ChangeState from 'forms/store/add-edit/partials/ChangeState';
import Dialog from 'components/dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Trans } from '@lingui/macro';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

class InactivateStore extends Component {
  render = () => {
    const { open, onClose, tradingName, storeId, formCallback } = this.props;
    return (
      <Dialog
        title={
          <>
            <Trans> Inativação da loja</Trans>: <b>{tradingName}</b>
          </>
        }
        open={open}
        onClose={onClose}
        aria-labelledby="user-edit"
      >
        <DialogContent>
          <Typography component="h2" gutterBottom variant="h5">
            <Trans>Atenção!</Trans>
          </Typography>
          <Typography component="p" gutterBottom variant="body2">
            <b>{tradingName}</b> será desativada! Você realmente confirma esta operação? Digite{' '}
            <b>"{tradingName}"</b> para confirmar.
          </Typography>

          <ChangeState
            storeId={storeId}
            tradingName={tradingName}
            activate={false}
            cancelCallback={() => {
              onClose();
            }}
            submitCallback={formCallback}
          />
        </DialogContent>
      </Dialog>
    );
  };
}

const styles = theme => ({
  text: {
    textAlign: 'center'
  }
});

export default withStyles(styles)(InactivateStore);
