export default function styles(theme) {
  return {
    root: {
      minWidth: 150,
      overflow: 'hidden',
      textOverflow: 'ellipis',
      backgroundColor: theme.palette.grey[200]
    },
    label: {
      backgroundColor: theme.palette.grey[200],
      height: `50%`
    }
  };
}
