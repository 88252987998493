import React from 'react';
import { FieldArray } from 'redux-form';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';

import ProductFields from './ProductFields';

function Products({ ...props }) {
  return (
    <List>
      <Grid container spacing={24}>
        <FieldArray name="items" {...props} component={ProductFields} />
      </Grid>
    </List>
  );
}

export default Products;
