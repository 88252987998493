export default function styles(theme) {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap',
        flexDirection: 'row'
      }
    },
    button: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing.unit * 3,
      width: '100%'
    }
  };
}
