import { USERS_ENDPOINT, PROFILE_IMAGE_URL } from '../endpoints';
import { defaultHeaders, fetcher, flatFetcher } from '../fetcher';

export const getProfilePicture = id => `${PROFILE_IMAGE_URL}/pp${id}.jpg`;

export const resendConfirmation = (email, password, uriConfirmationToken) =>
  fetcher(`${USERS_ENDPOINT}/resend-confirmation`, {
    method: 'POST',
    body: JSON.stringify({ email, password, uriConfirmationToken })
  });

export const addUser = (email, name, password, uriConfirmationToken) =>
  fetcher(`${USERS_ENDPOINT}`, {
    method: 'POST',
    body: JSON.stringify({ email, name, password, uriConfirmationToken })
  });

export const userConfirmation = (userId, userToken) =>
  fetcher(`${USERS_ENDPOINT}/${userId}/confirmation/${userToken}`, {
    method: 'GET'
  });

export const getUser = email =>
  flatFetcher(`${USERS_ENDPOINT}?email=${email}`, {
    method: 'GET'
  });

export const getUserByID = id =>
  flatFetcher(`${USERS_ENDPOINT}/${id}/profile`, {
    method: 'GET'
  });

export const changeUserPassword = (id, newPassword, oldPassword) =>
  fetcher(`${USERS_ENDPOINT}/${id}/change-password`, {
    method: 'POST',
    body: JSON.stringify({ newPassword, oldPassword })
  });

export const changeUserProfile = (id, name, userDetail) =>
  fetcher(`${USERS_ENDPOINT}/${id}/profile`, {
    method: 'PUT',
    body: JSON.stringify({ name, userDetail })
  });

export function changeProfileImage(userId, newProfilePicture) {
  const profilePictureFormData = new FormData();
  profilePictureFormData.append('file', newProfilePicture);

  const profilePictureUrl = `${USERS_ENDPOINT}/${userId}/profile-picture`;
  const profilePictureHeaders = { ...defaultHeaders() };

  delete profilePictureHeaders['Content-Type'];

  const profilePictureOptions = {
    method: 'POST',
    body: profilePictureFormData,
    headers: profilePictureHeaders
  };

  return fetcher(profilePictureUrl, profilePictureOptions);
}

export function resetPassword({ email }) {
  const encodedEmail = encodeURIComponent(`${email}`);
  const url = `${USERS_ENDPOINT}/reset-password?email=${encodedEmail}`;

  const options = {
    method: 'GET'
  };

  return fetcher(url, options);
}
