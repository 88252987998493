export default function styles(theme) {
  const sideNavBaseWidth = 50;
  const sideNavWidth = sideNavBaseWidth + theme.spacing.unit * 2;

  return {
    buttonIcon: {
      color: theme.palette.common.white
    },
    content: {
      marginLeft: 40,
      width: `calc(100vw - ${sideNavWidth + 17}px)`, // don`t know why
      paddingLeft: theme.spacing.unit * 3 // same as DialogContent
    },
    sideNav: {
      alignItems: 'center',
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,
      bottom: 0,
      boxShadow: `0px 0px 0px #000 !important`, // overwrite uses important
      flexDirection: 'column',
      left: 0,
      padding: `
        ${theme.spacing.unit * 2}px 
        0px
        ${theme.spacing.unit * 2}px 
        ${theme.spacing.unit * 2}px
        `,
      position: 'absolute',
      top: 0,
      width: sideNavWidth
    },
    removeStep: {
      '& input[type=number]::-webkit-inner-spin-button': {
        display: 'none'
      }
    },
    negativeNumber: {
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    controlLabel: {
      marginBottom: theme.spacing.unit * 2
    },
    submitFab: {
      right: theme.spacing.unit * 5
    },
    attachFab: {
      right: theme.spacing.unit * 14
    },
    warningFab: {
      right: theme.spacing.unit * 5,
      bottom: theme.spacing.unit * 10
    },
    secondaryButton: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row'
      }
    }
  };
}
