import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import React from 'react';
import { Trans } from '@lingui/macro';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import Tooltip from '@material-ui/core/Tooltip';
import { EyeGlassesIcon } from 'assets/Icons';

const mui = createMuiTheme({
  overrides: {
    MuiChip: {
      root: { height: 25, width: 25, margin: 0 },
      outlinedPrimary: { color: amber[700], borderColor: amber[700] },
      outlinedSecondary: { color: green[700], borderColor: green[700] },
      avatarColorPrimary: { backgroundColor: amber[700] },
      avatarColorSecondary: { backgroundColor: green[700] },
      label: {
        paddingLeft: 0,
        paddingRight: 0
      },
      avatar: {
        borderWidth: 0,
        height: 25,
        margin: 0,
        width: 25
      }
    },
    MuiAvatar: {
      root: {
        width: 25,
        margin: '0!important',
        borderWidth: '!important',
        height: 25
      }
    }
  }
});

export const Waiting = () => (
  <MuiThemeProvider theme={mui}>
    <Tooltip title={<Trans>Aguardando armação</Trans>}>
      <Chip
        avatar={
          <Avatar>
            <EyeGlassesIcon />
          </Avatar>
        }
        color="primary"
        variant="outlined"
      />
    </Tooltip>
  </MuiThemeProvider>
);

export const Received = () => (
  <MuiThemeProvider theme={mui}>
    <Tooltip title={<Trans>Armação Recebida</Trans>}>
      <Chip
        avatar={
          <Avatar>
            <EyeGlassesIcon />
          </Avatar>
        }
        color="secondary"
        variant="outlined"
      />
    </Tooltip>
  </MuiThemeProvider>
);
