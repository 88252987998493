import React, { Component } from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import { Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core/styles';
import HistoricalTable from './HistoricalTable';
import BaseOrderDialog from './BaseOrderDialog';

class OrderHistoryDialog extends Component {
  render() {
    const { order } = this.props;

    const Title = () => (
      <>
        <Trans>Histórico do pedido</Trans> <b>{order.orderNumber}</b>
      </>
    );

    return order ? (
      <BaseOrderDialog fullWidth maxWidth="lg" name="orderHistory" title={<Title />}>
        <DialogContent>
          <HistoricalTable orderId={order.orderId} storeId={order.storeId} />
        </DialogContent>
      </BaseOrderDialog>
    ) : null;
  }

  static defaultProps = {
    order: {}
  };
}

const styles = theme => ({});

export default withStyles(styles)(OrderHistoryDialog);
