import { Trans } from '@lingui/macro';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import Chart from 'react-google-charts';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from 'components/utils/Loading';
import { getOrderReceived } from 'services/historical';
import { getSupplierRequestChart } from 'services/orders';
import { getUserStores } from 'services/stores';
import { createNotification, Creators as NotificationCreators } from 'store/ducks/notifications';

const dateFormat = require('dateformat');

class ChartOrdersSendSuppliers extends Component {
  render() {
    const { classes } = this.props;
    const isOrdersSendSuppliers = this.state.chartData.length > 1;
    if (this.state.loading) {
      return <Loading />;
    }
    return this.state.loading ? (
      <Loading />
    ) : isOrdersSendSuppliers ? (
      <Paper className={classes.chartContainer}>
        <Chart
          className={classes.chart}
          chartType="Bar"
          loader={<Loading />}
          data={this.state.chartData}
          options={{
            chart: {
              title: ReactDOMServer.renderToStaticMarkup(
                <Trans>Pedidos enviados aos fornecedores</Trans>
              ),
              subtitle: ReactDOMServer.renderToStaticMarkup(
                <Trans>Relação de pedidos recebidos e pedidos enviados aos fornecedores</Trans>
              )
            }
          }}
          rootProps={{ 'data-testid': '2' }}
        />
      </Paper>
    ) : (
      <Typography component="h1" variant="h6" color="textSecondary">
        <Trans>Pedidos enviados aos fornecedores não encontrados nesse período</Trans>
      </Typography>
    );
  }

  state = {
    store: ['all'],
    periodMonth: 1,
    dateTime: null,
    chartData: [],
    checkAllStore: false,
    loading: false,
    storeID: [],
    GetstoreID: [],
    storeData: []
  };

  async update() {
    this.setState({ loading: true });
    // Recebendo storeId
    const receivingStoreId = [];
    // Recebendo dados da API
    const receivingStoreAPI = [];
    // Populando o select
    const receivingStoreAPIData = [];
    // Recebendo todas as lojas relacionadas ao usuario
    const receivingDataStore = await getUserStores(this.props.user.email);
    let now;

    if (this.props.filters.date === null) {
      now = new Date();
    } else {
      now = new Date(this.props.filters.date);
    }

    // Variavel data anterior.
    const before = new Date(
      now.getFullYear(),
      now.getMonth() - this.props.filters.period,
      now.getDay()
    );
    // Data atual
    const nowDate = `${dateFormat(now, 'yyyy-mm-dd')}T23:59:59`;
    // Data antes
    const beforeDate = `${dateFormat(before, 'yyyy-mm-dd')}T23:59:59`;

    for (let j = 0; j < receivingDataStore.length; j += 1) {
      receivingStoreId.push(receivingDataStore[j].store.id);
    }

    if (this.props.filters.store[0] === 'all') {
      this.setState({ storeID: receivingStoreId });
    } else {
      this.setState({ storeID: this.props.filters.store });
    }

    const purchasesData = await getSupplierRequestChart(this.state.storeID, beforeDate, nowDate);

    const historicalData = await getOrderReceived(this.state.storeID, beforeDate, nowDate);

    let f = 0;
    do {
      for (let k = 0; k < purchasesData.length; k += 1) {
        if (purchasesData[k].storeId === receivingDataStore[f].store.id) {
          let forn = 0;
          do {
            if (purchasesData[k].storeId === historicalData[forn].storeId) {
              receivingStoreAPI.push([
                receivingDataStore[f].store.tradingName,
                historicalData[forn].amount,
                purchasesData[k].amount
              ]);
              receivingStoreAPIData.push(receivingDataStore[f].store);
            }
            forn++;
          } while (forn < historicalData.length);
        }
      }
      f++;
    } while (f < receivingStoreId.length);

    const chartData = [['Loja', 'Pedidos recebidos', 'Pedidos enviados aos fornecedores']];

    for (let i = 0; i < receivingStoreAPI.length; i += 1) {
      chartData.push(receivingStoreAPI[i]);
    }

    if (this.state.storeData.length === 0) {
      this.setState({
        storeData: receivingStoreAPIData
      });
    }
    this.setState({
      chartData,
      loading: false
    });
  }

  componentDidUpdate(prevProps) {
    const hasDateChanged = prevProps.filters.date !== this.props.filters.date;
    const hasStoreChanged = prevProps.filters.store !== this.props.filters.store;
    const hasPeriodChanged = prevProps.filters.period !== this.props.filters.period;

    if (hasDateChanged || hasStoreChanged || hasPeriodChanged) {
      this.update();
    }
  }

  async componentWillMount() {
    this.update();
  }
}

const styles = theme => ({
  chart: {
    minWidth: '66vw',
    minHeight: '66vh'
  },
  chartContainer: {
    padding: theme.spacing.unit * 2,
    width: 'fit-content',
    minWidth: `calc(66vw + ${theme.spacing.unit * 2}px)`,
    minHeight: `calc(66vh + ${theme.spacing.unit * 2}px)`,
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  }
});

function mapStateToProps(state) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {
    addNotification({ message, variant }) {
      dispatch(NotificationCreators.addNotification(createNotification({ message, variant })));
    }
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ChartOrdersSendSuppliers);
