import { reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import maxBy from 'lodash/maxBy';
import AddEditProduct from './AddEditProduct';

const selector = formValueSelector('addEditProduct');

function mapStateToProps(state, ownProps) {
  const product = ownProps.order.items[ownProps.productKey];

  const itemSequenceValue =
    ownProps.order.items.length > 0
      ? maxBy(ownProps.order.items, 'itemSequence').itemSequence
      : ownProps.productKey;

  const initialValues = product
    ? {
        ...product,
        selector: {
          label: product.description,
          id: product.sku,
          value: product.sku
        }
      }
    : {
        itemSequence: itemSequenceValue + 1
      };

  return {
    storeId: ownProps.order.storeId,
    initialValues,
    formValues: {
      type: selector(state, 'type'),
      unitOfMeasure: selector(state, 'unitOfMeasure'),
      quantity: selector(state, 'quantity'),
      selector: selector(state, 'selector')
    }
  };
}

export default compose(
  connect(mapStateToProps),
  reduxForm({ form: 'addEditProduct' })
)(AddEditProduct);
