import PropTypes from 'prop-types';
import Store from 'propTypes/Store';
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { fetchOrders } from 'services/orders';
import withStores from './withStores';

function withOrders(options) {
  const { status } = options;

  return function withOptions(WrappedComponent) {
    function Hoc({ ...props }) {
      const [orders, setOrders] = useState([]);
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState();

      const { stores } = props;

      useEffect(() => {
        async function fetchOrdersByStores(storeList) {
          setLoading(true);
          const list = await Promise.all(
            storeList.map(async userStore => {
              const { id } = userStore.store;
              const storeOrders = await fetchOrders(id, status);
              return storeOrders;
            })
          ).then(data =>
            data
              .map(order => order.content)
              .filter(order => order.length)
              .reduce((accumulator, order) => accumulator.concat(order))
          );

          setLoading(false);
          setOrders(list);
        }

        if (stores.length) fetchOrdersByStores(stores);
      }, [orders.length, setOrders, stores]);

      return <WrappedComponent {...props} orders={{ items: orders, loading, error }} />;
    }

    Hoc.propTypes = {
      orders: PropTypes.arrayOf({}).isRequired,
      stores: PropTypes.arrayOf(Store).isRequired
      // fetchOrders: PropTypes.func.isRequired
    };

    // *** PARA QUANDO DO REFAC *** //
    // function mapDispatchToProps(dispatch, props) {
    //   return {
    //     fetchOrders() {
    //       dispatch(OrdersCreators.getOrders(props.user.email));
    //     }
    //   };
    // }

    // function mapStateToProps(state) {
    //   return {
    //     orders: state.orders
    //   };
    // }

    return compose(
      withStores({ activeOnly: false })
      // connect(
      //   mapStateToProps,
      //   mapDispatchToProps
      // )
    )(Hoc);
  };
}

export default withOrders;
