import { Trans } from '@lingui/macro';
import DialogContent from '@material-ui/core/DialogContent';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Creators as OrderCreators } from 'store/ducks/orders';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import AnamneseParameters from 'forms/orders/AnamneseParameters';
import AnamneseParametersList from './partials/AnamneseParametersList';
import BaseOrderDialog from '../BaseOrderDialog';

class AnamneseParametersDialog extends Component {
  submitCallback = values => {
    const { receiveOrder, updatedOrder } = this.props;

    const {
      parameter: { id },
      value
    } = values;
    const objKey = id;
    delete updatedOrder.prescription.anamnese[objKey.toString()];
    const newParameters = {
      ...updatedOrder.prescription.anamnese,
      [objKey]: value
    };

    receiveOrder({
      ...updatedOrder,
      prescription: {
        ...updatedOrder.prescription,
        anamnese: newParameters
      }
    });
    this.forceUpdate();
  };

  deleteCallback = event => this.forceUpdate();

  render() {
    const { getOrderById, order } = this.props;
    const updatedOrder = order && getOrderById(order.orderId);
    return (
      <BaseOrderDialog maxWidth="md" fullWidth name="anamnese" title={<Trans>Anamnese</Trans>}>
        <DialogContent>
          <AnamneseParameters submitCallback={this.submitCallback} />
          <AnamneseParametersList
            data={updatedOrder && updatedOrder.prescription.anamnese}
            storeInfo={this.props.updatedOrder}
            deleteCallback={this.deleteCallback}
          />
        </DialogContent>
      </BaseOrderDialog>
    );
  }
}

AnamneseParametersDialog.propTypes = {
  getOrderById: PropTypes.func.isRequired,
  receiveOrder: PropTypes.func.isRequired,
  order: PropTypes.objectOf({}),
  updatedOrder: PropTypes.objectOf({})
};

AnamneseParametersDialog.defaultProps = {
  order: {},
  updatedOrder: {}
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(OrderCreators, dispatch);
}

const mapStateToProps = state => ({ updatedOrder: getFormValues('editOrder')(state) || {} });

export default connect(mapStateToProps, mapDispatchToProps)(AnamneseParametersDialog);
