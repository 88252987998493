import React, { Component } from 'react';

import AccessToken from 'forms/user-profile/AccessToken';
import Dialog from 'components/dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Trans } from '@lingui/macro';

class AccessTokenDialog extends Component {
  render() {
    const { userEmail, open, onClose } = this.props;

    return (
      <Dialog title={<Trans>Chave de acesso </Trans>} open={open} onClose={onClose}>
        <DialogContent>
          <AccessToken userEmail={userEmail} />
        </DialogContent>
      </Dialog>
    );
  }
}
export default AccessTokenDialog;
