import React, { Component } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Trans } from '@lingui/macro';
import FrameDesign from 'components/frame-design';
import warning from 'assets/images/warning.png';
import BaseOrderDialog from './BaseOrderDialog';

class frameDesignDialog extends Component {
  render() {
    const { payload } = this.props;
    if (!payload) return null;
    const { order, frameDesign } = payload;

    return (
      <BaseOrderDialog
        maxWidth="sm"
        fullWidth
        name="frameDesign"
        title={
          <>
            <Trans>Desenho da armação </Trans> <b>{order.orderNumber}</b>
          </>
        }
      >
        <DialogContent>
          <Card style={{ minHeight: 335 }}>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={6}>
                <CardHeader
                  titleTypographyProps={{ variant: 'h6' }}
                  title="Olho Esquerdo"
                  style={{ textAlign: 'center' }}
                />
                <CardContent style={{ textAlign: 'center' }}>
                  {frameDesign.leftRadius !== undefined && frameDesign.leftRadius !== '' ? (
                    <FrameDesign frameDesign={frameDesign.leftRadius.replace(/;/g, ',')} />
                  ) : (
                    <>
                      <img style={{ maxWidth: '100%' }} src={warning} alt="warning" />
                      <Typography variant="body2" gutterBottom>
                        Dados não encontrados para o olho esquerdo.
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CardHeader
                  titleTypographyProps={{ variant: 'h6' }}
                  title="Olho Direito"
                  style={{ textAlign: 'center' }}
                />
                <CardContent style={{ textAlign: 'center' }}>
                  {frameDesign.rightRadius !== undefined && frameDesign.rightRadius !== '' ? (
                    <FrameDesign frameDesign={frameDesign.rightRadius.replace(/;/g, ',')} />
                  ) : (
                    <>
                      <img style={{ maxWidth: '100%' }} src={warning} alt="warning" />
                      <Typography variant="body2" gutterBottom>
                        Dados não encontrados para o olho direito.
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
      </BaseOrderDialog>
    );
  }
}

export default frameDesignDialog;
