import React, { Component } from 'react';
import ChangeDueDate from 'forms/orders/ChangeDueDate';
import DialogContent from '@material-ui/core/DialogContent';
import { Trans } from '@lingui/macro';
import { hoursWithoutUTC } from 'utils/date/formatHours';
import BaseOrderDialog from './BaseOrderDialog';

class ChangeDueDateDialog extends Component {
  render() {
    const { order } = this.props;

    const Title = () => (
      <>
        <Trans>Alterar prazo de entrega do pedido </Trans> <b>{order.orderNumber}</b>
      </>
    );

    return order ? (
      <BaseOrderDialog name="changeDueDate" title={<Title />}>
        <DialogContent>
          <ChangeDueDate
            initialValues={{ orderDueDate: order.orderDueDateTime }}
            onSubmit={this.submitForm}
          />
        </DialogContent>
      </BaseOrderDialog>
    ) : null;
  }

  submitForm = values => {
    const {
      changeDueDate,
      closeDialog,
      notify,
      order: { orderId, storeId },
      fetchOrders
    } = this.props;

    const { orderDueDate } = values;

    changeDueDate(orderId, storeId, {
      orderDueDate: hoursWithoutUTC(orderDueDate)
    }).then(res => {
      if (res.status === 204) {
        fetchOrders(orderId);
        closeDialog();
        notify(<Trans>Data de entrega alterada com sucesso</Trans>, 'success');
      } else if (res.status === 403) {
        notify(<Trans>Você não tem permissão para executar essa ação.</Trans>, 'warning');
      } else {
        notify(
          <Trans>
            Nova data de entrega inválida. Verifique o valor informado e tente novamente.
          </Trans>,
          'error'
        );
      }
    });
  };

  static defaultProps = {
    order: {}
  };
}

export default ChangeDueDateDialog;
