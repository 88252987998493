import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import AddEditUser from 'forms/store/users/AddEditUser';
import { Trans } from '@lingui/macro';
import Dialog from 'components/dialog';

const EditUserDialog = ({
  open,
  onClose,
  storeId,
  user: { email, role, allowAddOrder },
  update,
  storeInfo,
  ...other
}) => {
  return (
    <Dialog
      title={email ? <Trans>Edição de usuário</Trans> : <Trans>Adição de usuário</Trans>}
      open={open}
      onClose={onClose}
      {...other}
    >
      <DialogContent>
        <AddEditUser
          email={email}
          storeId={storeId}
          storeInfo={storeInfo}
          allowAddOrder={allowAddOrder}
          role={role}
          submitCallback={() => {
            update();
            onClose();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditUserDialog;
