import React from 'react';
import { Field } from 'redux-form-normalize-on-blur';
import BaseInput from 'components/form/inputs/base-input';
import { verticalPrismAxis, horizontalPrismAxis } from 'utils/prescription/prismAxis';
import MenuItem from '@material-ui/core/MenuItem';
import randomId from 'utils/random-id';
import { Trans } from '@lingui/macro';

export const PrismAxisField = ({ axis, ...props }) => (
  <Field {...props} select component={BaseInput} type="select">
    <MenuItem key={randomId()} value="">
      <Trans>Nenhum</Trans>
    </MenuItem>
    {axis === 'horizontal'
      ? horizontalPrismAxis.map(({ value, text }) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))
      : verticalPrismAxis.map(({ value, text }) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
  </Field>
);
export default PrismAxisField;
