import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styles from "./styles";

function DragAndDrop(props) {
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone();
  const {
    input: { accept, onChange }
  } = props;

  const { baseStyle, activeStyle } = styles();

  acceptedFiles.map((file, i) => {
    onChange(file);
    remove(i);
  });

  function remove(file) {
    acceptedFiles.splice(file, 1);
  }

  const style = {
    ...baseStyle,
    ...(isDragActive ? activeStyle : {})
  };

  return (
    <div {...getRootProps({style})}>
      <div
        {...getInputProps()}
        style={{ display: 'none', width: '100%' }}
        accept={accept}
        type="file"
      />
      {props.children}
    </div>
  );
}

export default DragAndDrop;

DragAndDrop.propTypes = {
  input: PropTypes.shape({
    accept: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string
  }).isRequired
};
