import { SUPPLIERS_ENDPOINT } from '../endpoints';
import { flatFetcher } from '../fetcher';

export const fetchSuppliers = () => flatFetcher(SUPPLIERS_ENDPOINT, { method: 'GET' });

export function getBaseList(supplierId, sku) {
  const url = `${SUPPLIERS_ENDPOINT}/${supplierId}/resources/${sku}/bases`;
  const options = {
    method: 'GET'
  };
  return flatFetcher(url, options);
}

export function getSupplierResources({
  description = '',
  supplierId,
  resourceType,
  sku = '',
  storeCnpj = '',
  page = 0
}) {
  const url = `${SUPPLIERS_ENDPOINT}/${supplierId}/resources?type=${resourceType}&description=${description}&sku=${sku}&storeCnpj=${storeCnpj}&page=${page}&size=1000`;

  const options = {
    method: 'GET'
  };

  return flatFetcher(url, options);
}
