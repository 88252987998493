export default function styles(theme) {
  return {
    notFound: {
      marginTop: theme.spacing.unit * 4
    },
    list: {
      marginTop: theme.spacing.unit * 2
    }
  };
}
