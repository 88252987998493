import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import Fab from 'components/fab';
import { Trans } from '@lingui/macro';

function AddProduct({ actions, order, ...props }) {
  const isSendOrder = order && order.status === 'ORDERED';
  const openDialog = key => event =>
    actions('addEditProduct', { order, productKey: key, addItem: 'new' });
  return (
    isSendOrder && (
      <Tooltip title={<Trans>Adicionar item ao pedido</Trans>}>
        <Fab style={{ right: 115, zIndex: 2 }} onClick={openDialog(order.items.length)}>
          <AddIcon />
        </Fab>
      </Tooltip>
    )
  );
}
export default AddProduct;
