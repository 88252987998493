import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import withNotification from 'store/providers/withNotification';
import withOverlay from 'store/providers/withOverlay';
import Register from './Register';

export default compose(
  withOverlay,
  withNotification,
  reduxForm({ form: 'Register' })
)(Register);
