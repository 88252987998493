import { connect } from 'react-redux';
import { compose } from 'redux';
import withOverlay from './withOverlay';

function withUser(WrappedComponent) {
  function mapStateToProps(state, props) {
    return { user: state.user };
  }

  return compose(
    withOverlay,
    connect(mapStateToProps)
  )(WrappedComponent);
}

export default withUser;
