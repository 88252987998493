import { takeLatest, put, call } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';

import { getStoreSuppliers, getUserStores } from 'services/stores';

export const INITIAL_STATE = [];

export function getStores(state = INITIAL_STATE, action) {
  return [...state];
}

export function receiveStores(state = INITIAL_STATE, action) {
  const { payload } = action;
  return [...payload];
}

export function* watchGetStores() {
  yield takeLatest(Types.GET_STORES, getStoresHanlder);
}

export function* getStoresHanlder(action) {
  const { payload: userEmail } = action;

  try {
    const storeList = yield call(getUserStores, userEmail);
    const suppliers = yield Promise.all(
      storeList.map(userStore => getStoreSuppliers(userStore.store.id))
    );

    const storeListWithSuppliers = yield storeList.map((userStore, index) => ({
      ...userStore,
      suppliers: suppliers[index]
    }));

    if (!storeList) {
      throw new Error(`Nenhum pedido encontrado para as lojas de id: ${userEmail}`);
    }

    yield put(Creators.receiveStores(storeListWithSuppliers));
  } catch (err) {
    console.log(err);
    yield put(Creators.receiveStores([]));
  }
}

export const { Types, Creators } = createActions({
  getStores: ['payload'],
  receiveStores: ['payload']
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_STORES]: getStores,
  [Types.RECEIVE_STORES]: receiveStores
});
