import { createActions, createReducer } from 'reduxsauce';
import { store } from 'App';
import randomId from 'utils/random-id';

export const INITIAL_STATE = [];

export const createNotification = ({ message, variant, id = randomId() }) => ({
  message,
  variant,
  id
});

const addNotification = (state = INITIAL_STATE, { payload }) => [...state, payload];

const removeNotification = (state = INITIAL_STATE, { payload: id }) => [
  ...state.filter(item => item.id !== id)
];

export const { Types, Creators } = createActions({
  addNotification: ['payload'],
  removeNotification: ['payload']
});

export default createReducer(INITIAL_STATE, {
  [Types.ADD_NOTIFICATION]: addNotification,
  [Types.REMOVE_NOTIFICATION]: removeNotification
});

export const notify = ({ variant, message, id }) =>
  store.dispatch(Creators.addNotification(createNotification({ message, variant, id })));
