import React, { Component } from 'react';
import Dashboard from '../dashboard';

export default function(WrappedComponent) {
  return class extends Component {
    render() {
      return (
        <Dashboard>
          <WrappedComponent {...this.props} />
        </Dashboard>
      );
    }
  };
}
