import { withStyles } from '@material-ui/core/styles';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Creators as SuppliersCreators } from '../../../../store/ducks/suppliers';
import { Creators as NotificationCreators } from '../../../../store/ducks/notifications';
import SupplierInfo from './SupplierInfo';
import styles from './styles';

function mapStateToProps(state) {
  const formValues = getFormValues('wizard')(state) || {};
  return { suppliersList: state.suppliers, formValues };
}

function mapDispatchToProps(dispatch) {
  return {
    notify(message, variant, persist) {
      dispatch(NotificationCreators.addNotification({ message, variant, persist }));
    },
    fetchList: _ => dispatch(SuppliersCreators.getSuppliers())
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(SupplierInfo);
