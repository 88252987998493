import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import withHelperText from '../helper-text/withHelperText';
import styles from './styles';
import PrescriptionInput from './PrescriptionInput';

export default compose(
  withStyles(styles),
  withHelperText
)(PrescriptionInput);
