import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Trans } from '@lingui/macro';
import TableActions from './TableActions';

export default ({ data, handleChangePage, handleChangeRowsPerPage, ...restProps }) => (
  <TableRow>
    <TablePagination
      labelRowsPerPage={<Trans>Linhas por página</Trans>}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={TableActions}
      count={data && data.length}
      {...restProps}
    />
  </TableRow>
);
