import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { isUserSigned } from 'store/selectors/auth';
import withDashboard from '../components/dashboard/withDashboard';
import Notifications from '../components/notifications';
import Overlay from '../components/overlay';
import PrivateRoute from './private-route';
import { routesIndex } from './Routes';

class AppRouter extends Component {
  render() {
    const { auth } = this.props;
    return (
      <>
        <Router history={history}>
          <Switch>
            {routesIndex.map((route, index) => {
              if (route.private) {
                return (
                  <PrivateRoute
                    auth={auth}
                    key={index}
                    component={
                      route.path === '/choose-store' ? route.view : withDashboard(route.view)
                    }
                    {...route}
                  />
                );
              }

              return <Route key={index} component={route.view} {...route} />;
            })}

            <Route render={() => <Redirect to="/home" />} />
          </Switch>
        </Router>
        <Notifications />
        <Overlay />
      </>
    );
  }
}

export const history = createBrowserHistory();

function mapStateToProps(state) {
  return {
    auth: isUserSigned(state)
  };
}

export default connect(mapStateToProps)(AppRouter);
