import React, { Component } from 'react';

import Button from 'components/buttons/button';
import FormControl from 'components/form/wrappers/form-control';
import TextField from '@material-ui/core/TextField';
import { Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core/styles';

class ArchiveOrder extends Component {
  render() {
    const { classes, order } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <FormControl className={classes.formGroup}>
          <TextField
            autoFocus
            id="validation"
            label={<Trans>Digite aqui*</Trans>}
            type="validation"
            variant="outlined"
            margin="dense"
            onChange={this.handleText}
          />
        </FormControl>
        <Button
          color="primary"
          className={classes.button}
          disabled={!this.state.confirmEnabled}
          variant="contained"
          fullWidth
          type="submit"
        >
          {order && order.status && order.status === 'ORDERED' ? (
            <Trans>Cancelar Pedido</Trans>
          ) : (
            <Trans>Arquivar Pedido</Trans>
          )}
        </Button>
      </form>
    );
  }

  handleText = event => {
    this.setState({
      confirmEnabled: event.target.value.toUpperCase() === 'SIM'
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { formCallback } = this.props;

    formCallback();
  };

  state = {
    confirmEnabled: false
  };
}

const styles = theme => ({
  button: {
    margin: `${theme.spacing.unit}px 0px`
  },
  formGroup: {
    width: '100%',
    margin: `${theme.spacing.unit}px 0px`
  }
});

export default withStyles(styles)(ArchiveOrder);
