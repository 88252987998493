export const type = [
  {
    id: 'FULL_RIM_PLASTIC_STRONG',
    text: 'Aro fechado rígido'
  },
  {
    id: 'FULL_RIM_PLASTIC_FLEXIBLE',
    text: 'Aro fechado flexível'
  },
  {
    id: 'FULL_RIM_PLASTIC_METAL',
    text: 'Aro fechado metal'
  },
  {
    id: 'RIMLESS',
    text: 'Três peças'
  },
  {
    id: 'NYLON',
    text: 'Nylon'
  }
];
