import React, { Component } from 'react';
import { Received, Waiting } from 'components/utils/FrameStatus';
import { ErrorStatus } from 'components/utils/ErrorStatus';
import { PromoStatus } from 'components/utils/PromoStatus';
import { SupplierStatus } from 'components/utils/SupplierStatus';
import { OrderData } from 'components/utils/OrderData';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Date from 'components/time/Date';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import FileCopy from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import { Trans } from '@lingui/macro';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import { findUser } from 'store/selectors/orders';
import moment from 'moment';

class CustomCard extends Component {
  render = () => {
    const {
      classes,
      id,
      code,
      dueDate,
      frameStatus,
      getOrderById,
      getStoreById,
      isOrderLate,
      isOrderACopy,
      laneId,
      storeName,
      userName,
      moveCard,
      suppliersList
    } = this.props;

    const order = getOrderById(this.props.id);
    const store = getStoreById(order.storeId);

    const company =
      suppliersList.length > 0 &&
      order.sentToSupplier !== undefined &&
      suppliersList.find(item => item.id === order.sentToSupplier);

    const salesMan = findUser(order.members, 'SALESMAN');

    return (
      <>
        <Card title="" className={classes.root}>
          <CardContent className={classes.content}>
            <div>
              <Typography className={classes.number} component="h3" variant="h6" gutterBottom>
                {code}
              </Typography>

              <div className={classes.cardContentRight}>
                <Typography className={classes.userName} component="p" variant="subheading">
                  {userName}
                </Typography>

                <Typography className={classes.subInfo} component="p" variant="subheading">
                  <Trans>Dt Venda: </Trans> {moment(order.orderDateTime).format('DD/MM/YYYY H')}H
                </Typography>

                <Typography className={classes.subInfo} component="p" variant="subheading">
                  <Trans>Dt Prometido: </Trans> {moment(dueDate).format('DD/MM/YYYY')}
                </Typography>

                {salesMan && (
                  <Typography className={classes.subInfo} component="p" variant="subheading">
                    <Trans>Vendedor: </Trans> {salesMan && salesMan.name}
                  </Typography>
                )}
              </div>
              {company && company !== undefined ? (
                <div className={classes.cardContentLeft}>
                  <Tooltip title={company.companyName} placement="right">
                    <Typography
                      className={classes.companyNameContent}
                      component="p"
                      variant="caption"
                    >
                      {company.companyName}
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={<Date format="LLLL">{order.supplierDueDate}</Date>}
                    placement="right"
                  >
                    <Typography className={classes.DateSupplier} component="p" variant="caption">
                      {moment(order.supplierDueDate).format('DD/MM/YYYY H')}H
                    </Typography>
                  </Tooltip>
                </div>
              ) : (
                undefined
              )}
            </div>

            <div className={classes.mainMenu}>
              {frameStatus && (
                <div className={classnames(classes.info, classes.mainMenuItem)}>
                  {showFrameStatus(frameStatus)}
                </div>
              )}
              {order.hasErrors && (
                <div
                  onClick={this.menuItemCallback('supplierRequest')}
                  className={classnames(classes.info, classes.mainMenuItem)}
                >
                  <ErrorStatus />
                </div>
              )}

              {order.promotionOrder && (
                <div className={classnames(classes.info, classes.mainMenuItem)}>
                  <PromoStatus />
                </div>
              )}

              {order.status ? (
                <div
                  onClick={this.menuItemCallback('updateOrder')}
                  className={classnames(classes.info, classes.mainMenuItem)}
                >
                  <OrderData laneId={laneId} />
                </div>
              ) : (
                undefined
              )}

              {order.status !== 'ORDERED' ? (
                <div
                  onClick={this.menuItemCallback('supplierStatus')}
                  className={classnames(classes.info, classes.mainMenuItem)}
                >
                  <SupplierStatus />
                </div>
              ) : (
                undefined
              )}

              {isOrderACopy(order.orderNumber, order.orderId) && (
                <div className={classnames(classes.info, classes.mainMenuItem)}>
                  <Tooltip
                    title={<Trans>Este pedido é uma cópia</Trans>}
                    style={{ cursor: 'default' }}
                  >
                    <FileCopy />
                  </Tooltip>
                </div>
              )}
              <div className={classes.mainMenuItem}>
                <Tooltip title={<Trans>Clique para exibir opções</Trans>}>
                  <IconButton
                    className={classes.mainMenuButton}
                    color="secondary"
                    onClick={this.openMenu('mainMenu')}
                  >
                    <MoreIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </CardContent>
        </Card>
        <Menu
          anchorEl={this.state.mainMenu.anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={this.closeMenu('mainMenu')}
          open={Boolean(this.state.mainMenu.anchorEl)}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem onClick={this.menuItemCallback('updateOrder')}>
            {laneId === 'ORDERED' ? <Trans>Editar pedido</Trans> : <Trans>Dados do pedido</Trans>}
          </MenuItem>

          <MenuItem onClick={this.openMenu('subMenu')}>
            <Trans>Mover para</Trans>
            <span className={classes.subMenuIcon}>
              <ArrowRightIcon />
            </span>
          </MenuItem>
          {laneId === 'ON_SUPPLIER' && (
            <MenuItem onClick={this.menuItemCallback('showOrderPromotional')}>
              <Trans>Gerar pedido promocional</Trans>
            </MenuItem>
          )}

          {order && !isOrderACopy(order.orderNumber, order.orderId) && (
            <MenuItem onClick={this.menuItemCallback('copyOrder')}>
              <Trans>Exibir cópias</Trans>
            </MenuItem>
          )}

          {store && store.erpSoftwareProviderName === 'Grupo Acert' ? (
            <MenuItem onClick={this.menuItemCallback('orderAttachsNotAdd')}>
              <Trans>Anexar arquivos</Trans>
            </MenuItem>
          ) : (
            <MenuItem onClick={this.menuItemCallback('orderAttachs')}>
              <Trans>Anexar arquivos</Trans>
            </MenuItem>
          )}

          {store && store.storeDetail.receiveSendFrames && (
            <MenuItem onClick={this.openMenu('frameMenu')}>
              <Trans>Status da Armação</Trans>
              <span className={classes.subMenuIcon}>
                <ArrowRightIcon />
              </span>
            </MenuItem>
          )}

          {order.hasErrors ? (
            <MenuItem onClick={this.openMenu('supplierMenu')}>
              <Trans>Fornecedor</Trans>
              <span className={classes.subMenuIcon}>
                <Badge badgeContent="!" color="error" classes={{ badge: classes.badge }} />
                <ArrowRightIcon />
              </span>
            </MenuItem>
          ) : (
            <MenuItem onClick={this.openMenu('supplierMenu')}>
              <Trans>Fornecedor</Trans>
              <span className={classes.subMenuIcon}>
                <ArrowRightIcon />
              </span>
            </MenuItem>
          )}

          <MenuItem onClick={this.menuItemCallback('changeDueDate')}>
            <Trans>Alterar prazo de entrega </Trans>
          </MenuItem>

          <MenuItem onClick={this.menuItemCallback('showOrderHistory')}>
            <Trans>Exibir histórico</Trans>
          </MenuItem>

          <Divider />

          <MenuItem onClick={this.menuItemCallback('archiveOrder')}>
            {laneId === 'ORDERED' ? <Trans>Cancelar</Trans> : <Trans>Arquivar</Trans>}
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={this.state.subMenu.anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={this.closeMenu('subMenu')}
          open={Boolean(this.state.subMenu.anchorEl)}
          TransitionComponent={Fade}
        >
          <MenuItem
            disabled={laneId === 'ORDERED'}
            onClick={this.subMenuItemCallback('changeOrderStatus', 'ORDERED')}
          >
            <Trans>Abertos</Trans>
          </MenuItem>

          <MenuItem
            disabled={laneId === 'ON_SUPPLIER'}
            onClick={this.subMenuItemCallback('changeOrderStatus', 'ON_SUPPLIER', {
              callback: moveCard('ON_SUPPLIER', laneId)
            })}
          >
            <Trans>Fornecedor</Trans>
          </MenuItem>

          <MenuItem
            disabled={laneId === 'ASSEMBLY_LAB'}
            onClick={this.subMenuItemCallback('changeOrderStatus', 'ASSEMBLY_LAB')}
          >
            <Trans>Montadora</Trans>
          </MenuItem>

          <MenuItem
            disabled={laneId === 'AWAITING_STORE'}
            onClick={this.subMenuItemCallback('changeOrderStatus', 'AWAITING_STORE')}
          >
            <Trans>Loja</Trans>
          </MenuItem>

          <MenuItem
            disabled={laneId === 'RECEIVED'}
            onClick={this.subMenuItemCallback('changeOrderStatus', 'RECEIVED')}
          >
            <Trans>Recebido</Trans>
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={this.state.frameMenu.anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={this.closeMenu('frameMenu')}
          open={Boolean(this.state.frameMenu.anchorEl)}
          TransitionComponent={Fade}
        >
          <MenuItem
            disabled={frameStatus === 'WAITING'}
            onClick={this.subMenuItemCallback('changeFrameStatus', 'WAITING')}
          >
            <Trans>Aguardando armação</Trans>
          </MenuItem>
          <MenuItem
            disabled={frameStatus === 'RECEIVED'}
            onClick={this.subMenuItemCallback('changeFrameStatus', 'RECEIVED')}
          >
            <Trans>Armação recebida</Trans>
          </MenuItem>
          <Divider />
          <MenuItem
            disabled={frameStatus === 'NONE'}
            onClick={this.subMenuItemCallback('changeFrameStatus', 'NONE')}
          >
            <Trans>Nenhum</Trans>
          </MenuItem>
          <Divider />
        </Menu>

        <Menu
          anchorEl={this.state.supplierMenu.anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={this.closeMenu('supplierMenu')}
          open={Boolean(this.state.supplierMenu.anchorEl)}
          TransitionComponent={Fade}
        >
          <MenuItem className={classes.classMenu} onClick={this.menuItemCallback('supplierStatus')}>
            <Trans>Status</Trans>
          </MenuItem>
          {order.hasErrors ? (
            <MenuItem
              className={classes.classMenu}
              onClick={this.menuItemCallback('supplierRequest')}
            >
              <Trans>Envios</Trans>
              <span className={classes.subMenuIcon}>
                <Badge badgeContent="!" color="error" classes={{ badge: classes.badge }} />
              </span>
            </MenuItem>
          ) : (
            <MenuItem
              className={classes.classMenu}
              onClick={this.menuItemCallback('supplierRequest')}
            >
              <Trans>Envios</Trans>
            </MenuItem>
          )}
          <Divider />
        </Menu>
      </>
    );
  };

  subMenuItemCallback = (menu, status, payload = {}) =>
    this.menuItemCallback(menu, { status, ...payload });

  menuItemCallback = (action, payload) => event => {
    this.closeMenu('frameMenu')();
    this.closeMenu('supplierMenu')();
    this.closeMenu('mainMenu')();
    this.props.actions(action, { orderId: this.props.id, ...payload });
  };

  changeMenuState = (menu, state) =>
    this.setState({
      [menu]: {
        anchorEl: state
      }
    });

  closeMenu = menu => event => this.changeMenuState(menu, null);

  openMenu = menu => event => this.changeMenuState(menu, event.target);

  state = {
    mainMenu: {
      anchorEl: null
    },
    subMenu: {
      anchorEl: null
    },
    frameMenu: {
      anchorEl: null
    },
    supplierMenu: {
      anchorEl: null
    }
  };
}

function showFrameStatus(frameStatus) {
  if (frameStatus === 'WAITING') {
    return <Waiting />;
  }
  if (frameStatus === 'RECEIVED') {
    return <Received />;
  }
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4
  },
  cardInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  number: {
    fontWeight: 700,
    marginTop: 0,
    color: theme.palette.primary.main,
    width: 100,
    display: 'flex'
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing.unit * 2
    }
  },
  late: {
    color: theme.palette.error.dark
  },
  userName: {
    flexBasis: '100%',
    fontWeight: 500,
    minWidth: 250
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: 'white'
  },
  info: {
    opacity: 0.5
  },
  subInfo: {
    fontSize: '0.8rem',
    minWidth: 180,
    fontWeight: 500
  },
  cardContentRight: {
    display: 'inline-block',
    width: '60%'
  },
  cardContentLeft: {
    display: 'inline-block',
    width: '40%',
    textAlign: 'right'
  },
  companyNameContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 11,
    color: '#000',
    fontWeight: 500
  },
  DateSupplier: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    position: 'relative',
    top: 3,
    fontWeight: 500,
    color: '#000',
    fontSize: 10
  },
  mainMenuItem: {
    padding: theme.spacing.unit / 2,
    marginTop: -theme.spacing.unit
  },
  mainMenuButton: {
    padding: 0
  },
  mainMenu: {
    position: 'absolute',
    top: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
    display: 'flex'
  },
  classMenu: {
    minWidth: 85
  },
  badge: {
    left: theme.spacing.unit + 2,
    border: `2px solid`
  },
  subMenuIcon: {
    alignItems: 'center',
    display: 'flex'
  }
});

export default withStyles(styles)(CustomCard);
