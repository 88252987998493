import React from 'react';
import { Field } from 'redux-form';
import SelectInput from 'components/form/inputs/select';
import PropTypes from 'prop-types';

function SelectField({ children, ...props }) {
  return (
    <Field {...props} component={SelectInput}>
      {children}
    </Field>
  );
}

SelectField.propTypes = {
  children: PropTypes.node,
  onChangeCallback: PropTypes.func
};

export default SelectField;
