import { Trans } from '@lingui/macro';
import { Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { getStoreResources } from 'services/stores';
import resourcesTypes from 'utils/types';
import IntegrationDialog from 'views/IntegrationDialog';
import SearchBar from './SearchBar';
import StoreResourcesList from './StoreResourcesList';

const StoreResources = ({ storeId, classes, cnpj, user }) => {
  const [storeResources, setStoreResources] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [openDialog, setOpenDialog] = useState();
  const [storeResource, setStoreResource] = useState();
  const [loading, setLoading] = useState(false);
  const handleChange = event => {
    setSearchValue(event.target.value);
  };
  const doRecursiveFetch = async (resourceType, page = 0, allItems = []) => {
    try {
      let storage = [];
      const { last, content } = await getStoreResources({
        storeId,
        resourceType,
        page
      });
      storage = [...allItems, ...content];
      return last ? storage : doRecursiveFetch(resourceType, page + 1, storage);
    } catch (error) {
      console.log(error);
    }
  };

  const getResources = () => {
    try {
      setLoading(true);
      const selectedTypes = resourcesTypes.filter(
        ({ value }) => value !== 'FRAME' && value !== 'FRAME_SHAPE' && value !== 'BLANK'
      );
      const storage = [];
      selectedTypes.map(async ({ value: resourceType }) => {
        const data = await doRecursiveFetch(resourceType);
        storage.push(...data);
        setStoreResources([...storage]);
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const addTranslatedTypeInto = resources => {
    return resources.map(resource => {
      return {
        ...resource,
        translatedType: ReactDOMServer.renderToStaticMarkup(
          resourcesTypes.find(({ value }) => value === resource.type).text
        )
      };
    });
  };

  useEffect(() => {
    getResources();
    // eslint-disable-next-line
  }, [storeId]);

  const withTranslatedType = addTranslatedTypeInto(storeResources);

  const filteredResources =
    searchValue.trim() !== ''
      ? withTranslatedType.filter(
          resource =>
            resource.sku.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
            resource.description.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        )
      : withTranslatedType;

  if (loading) {
    return (
      <h2>
        <Trans>Carregando lista de recursos, aguarde...</Trans>
      </h2>
    );
  }

  const handleDialog = () => setOpenDialog(!openDialog);

  const integrationCallback = args => {
    handleDialog();
    setStoreResource(args);
  };

  return (
    <>
      <Grid container spacing={24}>
        {storeResources && storeResources.length > 0 && (
          <Grid item xs={12} md={12}>
            <Paper className={classes.searchBar}>
              <SearchBar searchCallback={handleChange} searchValue={searchValue} />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <StoreResourcesList
            user={user}
            getResources={() => getResources()}
            storeResources={filteredResources}
            storeId={storeId}
            integrationCallback={integrationCallback}
          />
        </Grid>
      </Grid>
      {storeResource && (
        <IntegrationDialog
          open={openDialog}
          onClose={handleDialog}
          storeResource={storeResource}
          storeId={storeId}
          cnpj={cnpj}
        />
      )}
    </>
  );
};

const styles = theme => ({
  searchBar: {
    padding: theme.spacing.unit * 3
  }
});

export default withStyles(styles)(StoreResources);
