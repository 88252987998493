import React from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';
import red from '@material-ui/core/colors/red';
import { Trans } from '@lingui/macro';

export const ErrorStatus = props => (
  <MuiThemeProvider theme={mui}>
    <Tooltip title={<Trans>Falha no envio ao fornecedor</Trans>}>
      <Avatar {...props} style={{ backgroundColor: 'transparent', color: red[800] }}>
        <ErrorIcon />
      </Avatar>
    </Tooltip>
  </MuiThemeProvider>
);

const mui = createMuiTheme({
  overrides: {
    MuiAvatar: {
      root: {
        maxWidth: 25,
        margin: '0!important',
        borderWidth: '!important',
        maxHeight: 25
      }
    }
  }
});
