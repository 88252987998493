import { Trans } from '@lingui/macro';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'components/buttons/button';
import AutocompleteField from 'components/form/fields/autocomplete';
import AdditionField from 'components/form/fields/prescription/addition';
import AxisField from 'components/form/fields/prescription/axis';
import CylindricalField from 'components/form/fields/prescription/cylindrical';
import FittingHeightField from 'components/form/fields/prescription/fitting-height';
import InterpupillaryDistanceField from 'components/form/fields/prescription/interpupillary-distance';
import SphericalField from 'components/form/fields/prescription/spherical';
import PrismAxisField from 'components/form/fields/prism/PrismAxisField';
import PrismValueField from 'components/form/fields/prism/PrismValueField';
import FormSection from 'components/form/wrappers/form-section';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { FieldArray } from 'redux-form';
import { OrdersConsumer } from 'views/home/orders/OrdersProvider';

function Prescription({
  actions,
  order,
  receiveOrder,
  formValues,
  removeStep,
  negativeNumber,
  storeResources,
  loading,
  notify,
  ...props
}) {
  const { orderId } = order;
  const [showPrism, setShowPrism] = useState(false);
  const [negativeSphericalLeft, setNegativeSphericalLeft] = useState(false);
  const [negativeSphericalRight, setNegativeSphericalRight] = useState(false);
  const [negativeCylindricalLeft, setNegativeCylindricalLeft] = useState(false);
  const [negativeCylindricalRight, setNegativeCylindricalRight] = useState(false);

  useEffect(() => {
    if (formValues && formValues.prescription && formValues.prescription.eyesPrescription) {
      const shouldShowPrism = formValues.prescription.eyesPrescription.some(value => {
        const comparison =
          value.verticalPrismAxis ||
          value.verticalPrismValue ||
          value.horizontalPrismAxis ||
          value.horizontalPrismValue;
        return comparison;
      });

      setShowPrism(shouldShowPrism);
    }
  }, [formValues]);

  const erpSoftwareName =
    formValues && formValues.store && formValues.store.erpSoftwareProviderName;

  return (
    <>
      <Grid container spacing={24}>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={showPrism}
                disabled={erpSoftwareName === 'Grupo Acert'}
                onChange={event => setShowPrism(event.target.checked)}
                color="secondary"
              />
            }
            label={<Trans>Ver prisma</Trans>}
          />
        </Grid>

        <FieldArray
          name="prescription.eyesPrescription"
          component={Fields}
          prism={showPrism}
          loading={loading}
          storeResources={storeResources}
          order={order}
          receiveOrder={receiveOrder}
          formValues={formValues}
          removeStep={removeStep}
          negativeNumber={negativeNumber}
          setNegativeSphericalLeft={setNegativeSphericalLeft}
          negativeSphericalLeft={negativeSphericalLeft}
          setNegativeSphericalRight={setNegativeSphericalRight}
          negativeSphericalRight={negativeSphericalRight}
          setNegativeCylindricalLeft={setNegativeCylindricalLeft}
          negativeCylindricalLeft={negativeCylindricalLeft}
          setNegativeCylindricalRight={setNegativeCylindricalRight}
          negativeCylindricalRight={negativeCylindricalRight}
          notify={notify}
          erpSoftwareName={erpSoftwareName}
        />
        <Grid item>
          <Button onClick={() => actions('individualParameters', { orderId })} variant="outlined">
            <Trans>Informar parâmetros individuais</Trans>
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => actions('anamnese', { orderId })} variant="outlined">
            <Trans>Anamnese</Trans>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

const Fields = ({
  fields,
  prism,
  loading,
  storeResources,
  receiveOrder,
  formValues,
  removeStep,
  negativeNumber,
  setNegativeSphericalLeft,
  negativeSphericalLeft,
  setNegativeSphericalRight,
  negativeSphericalRight,
  setNegativeCylindricalLeft,
  negativeCylindricalLeft,
  setNegativeCylindricalRight,
  negativeCylindricalRight,
  notify,
  erpSoftwareName
}) =>
  fields.map((item, key) => {
    const { side } = fields.get(key);
    const title = side === 'LEFT' ? <Trans>Olho Esquerdo</Trans> : <Trans>Olho Direito</Trans>;

    return (
      <Grid item xs={12}>
        <FormSection title={title}>
          <Grid item xs={12}>
            <Tooltip
              placement="top"
              disableFocusListener
              title={ReactDOMServer.renderToString(
                <Trans>
                  Você pode buscar um produto por código ou descrição. Caso não exista, você terá a
                  opção de incluí-lo.
                </Trans>
              )}
            >
              <AutocompleteField
                disabled={loading}
                fullWidth
                isCreatable
                name={`${item}.selector`}
                label={<Trans>Informe o produto...</Trans>}
                selectionCallback={(selectedOption, previousValue) => {
                  const resourceItem = storeResources.find(
                    resource => resource.sku === selectedOption.value
                  );
                  let newItem;
                  if (resourceItem) newItem = resourceItem;
                  else newItem = newItemStructure(selectedOption.value);
                  const oldItem = formValues.items.find(item => item.sku === previousValue.id);
                  const hasItem =
                    newItem.sku && formValues.items.find(item => item.sku === newItem.sku);

                  if (hasItem) {
                    const eyesPrescription = changeItemSequence(
                      formValues,
                      side,
                      hasItem.itemSequence
                    );
                    let items;
                    const canIRemoveItem = canIRemove(formValues, oldItem, side);
                    if (canIRemoveItem) {
                      items = removeItem(formValues.items, oldItem.itemSequence);
                    } else {
                      items = formValues.items;
                    }
                    const itemsOder = adjustItemsQuantity(items, eyesPrescription);
                    receiveOrder({
                      ...formValues,
                      items: itemsOder,
                      prescription: {
                        ...formValues.prescription,
                        eyesPrescription
                      }
                    });
                  } else {
                    const canIRemoveItem = canIRemove(formValues, oldItem, side);
                    if (canIRemoveItem) {
                      const itemSequence = oldItem && oldItem.itemSequence;
                      const items = removeItem(formValues.items, itemSequence);
                      const item = {
                        ...newItem,
                        itemSequence
                      };
                      const itemsOder = adjustItemsQuantity(items, formValues.prescription);
                      addItem(items, item);
                      receiveOrder({
                        ...formValues,
                        items: itemsOder
                      });
                    } else {
                      const items = formValues.items;
                      const newItemSequence = getMaxItemSequence(formValues.items) + 1;
                      const item = {
                        ...newItem,
                        itemSequence: newItemSequence
                      };
                      addItem(items, item);
                      const eyesPrescription = changeItemSequence(
                        formValues,
                        side,
                        newItemSequence
                      );
                      const itemsOder = adjustItemsQuantity(items, eyesPrescription);
                      receiveOrder({
                        ...formValues,
                        items: itemsOder,
                        prescription: {
                          ...formValues.prescription,
                          eyesPrescription
                        }
                      });
                    }
                  }
                }}
                options={
                  storeResources &&
                  storeResources.map(({ description, sku }) => ({
                    label: description,
                    value: sku,
                    id: sku
                  }))
                }
                clearCallback={previousValue => {
                  try {
                    const { id } = previousValue;
                    const { itemSequence } = formValues.items.find(item => item.sku === id);
                    const items = removeItem(formValues.items, itemSequence);
                    receiveOrder({
                      ...formValues,
                      items
                    });
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
            </Tooltip>
          </Grid>
          <Grid container spacing={24}>
            <Grid item xs={12} md={2}>
              <SphericalField
                label={<Trans>Esférico</Trans>}
                name={`${item}.spherical`}
                negativeSpherical={side === 'LEFT' ? negativeSphericalLeft : negativeSphericalRight}
                disabled={erpSoftwareName === 'Grupo Acert'}
              />
              <FormGroup row className={negativeNumber}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={side === 'LEFT' ? negativeSphericalLeft : negativeSphericalRight}
                      onChange={() =>
                        negativeValueSpherical(
                          setNegativeSphericalLeft,
                          negativeSphericalLeft,
                          setNegativeSphericalRight,
                          negativeSphericalRight,
                          notify,
                          side
                        )
                      }
                      name="negativeNumberSpherical"
                      color="primary"
                    />
                  }
                  label={<Trans>Negativo</Trans>}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={2}>
              <CylindricalField
                label={<Trans>Cilíndrico</Trans>}
                name={`${item}.cylindrical`}
                disabled={erpSoftwareName === 'Grupo Acert'}
                negativeCylindrical={
                  side === 'RIGHT' ? negativeCylindricalRight : negativeCylindricalLeft
                }
              />
              <FormGroup row className={negativeNumber}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        side === 'RIGHT' ? negativeCylindricalRight : negativeCylindricalLeft
                      }
                      onChange={() =>
                        negativeValueCylindrical(
                          setNegativeCylindricalLeft,
                          negativeCylindricalLeft,
                          setNegativeCylindricalRight,
                          negativeCylindricalRight,
                          notify,
                          side
                        )
                      }
                      name="negativeNumberSpherical"
                      color="primary"
                    />
                  }
                  label={<Trans>Negativo</Trans>}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={2}>
              <AxisField
                disabled={erpSoftwareName === 'Grupo Acert'}
                label={<Trans>Eixo</Trans>}
                name={`${item}.axis`}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <AdditionField
                disabled={erpSoftwareName === 'Grupo Acert'}
                label={<Trans>Adição</Trans>}
                name={`${item}.addition`}
              />
            </Grid>

            <Grid item xs={12} md={2} className={removeStep}>
              <InterpupillaryDistanceField
                disabled={erpSoftwareName === 'Grupo Acert'}
                label={<Trans>DNP</Trans>}
                name={`${item}.interPupillaryDistance`}
              />
            </Grid>

            <Grid item xs={12} md={2} className={removeStep}>
              <FittingHeightField
                disabled={erpSoftwareName === 'Grupo Acert'}
                label={<Trans>Altura</Trans>}
                name={`${item}.fittingHeight`}
              />
            </Grid>
          </Grid>

          {prism && (
            <Grid container spacing={24}>
              <Grid item xs={12} md={3}>
                <PrismValueField
                  fullWidth
                  disabled={erpSoftwareName === 'Grupo Acert'}
                  label={<Trans>Prisma vertical</Trans>}
                  name={`${item}.verticalPrismValue`}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <PrismAxisField
                  label={<Trans>Eixo vertical</Trans>}
                  disabled={erpSoftwareName === 'Grupo Acert'}
                  fullWidth
                  name={`${item}.verticalPrismAxis`}
                  axis="vertical"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <PrismValueField
                  fullWidth
                  disabled={erpSoftwareName === 'Grupo Acert'}
                  label={<Trans>Prisma horizontal</Trans>}
                  name={`${item}.horizontalPrismValue`}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <PrismAxisField
                  fullWidth
                  disabled={erpSoftwareName === 'Grupo Acert'}
                  label={<Trans>Eixo horizontal</Trans>}
                  name={`${item}.horizontalPrismAxis`}
                  axis="horizontal"
                />
              </Grid>
            </Grid>
          )}
        </FormSection>
      </Grid>
    );
  });

const removeItem = (items, itemSequenceToRemove) =>
  items.filter(item => item.itemSequence !== itemSequenceToRemove);

const canIRemove = (order, oldItem, side) =>
  oldItem &&
  order.prescription.eyesPrescription.find(eye => eye.side === (side === 'LEFT' ? 'RIGHT' : 'LEFT'))
    .itemSequence !== oldItem.itemSequence;

const addItem = (items, itemToAdd) => items.push(itemToAdd);

const adjustItemsQuantity = (items, eyePrescription) => {
  return items.map(item => {
    let eyes;
    if (eyePrescription.length > 0) {
      eyes = eyePrescription.filter(eye => eye.itemSequence === item.itemSequence);
      const quantity =
        eyes.length === 0 ? item.quantity : eyes.length * checkUnitOfMeasure(item.unitOfMeasure);
      return { ...item, quantity };
    }
    return { ...item };
  });
};

const checkUnitOfMeasure = unitOfMeasure => {
  if (unitOfMeasure === 'EACH') return 1;
  if (unitOfMeasure === 'PAIR') return 0.5;
};

const negativeValueSpherical = (
  setNegativeSphericalLeft,
  negativeSphericalLeft,
  setNegativeSphericalRight,
  negativeSphericalRight,
  notify,
  side
) => {
  try {
    if (side === 'LEFT') {
      setNegativeSphericalLeft(!negativeSphericalLeft);
      if (negativeSphericalLeft) {
        notify({
          message: (
            <Trans>
              Funcionalidade ativada! Para funcionar corretamente o campo esférico não pode estar
              preenchido, se ele já estiver preenchido, por favor, dígite novamente!
            </Trans>
          ),
          variant: 'success'
        });
      }
    } else if (side === 'RIGHT') {
      setNegativeSphericalRight(!negativeSphericalRight);
      if (negativeSphericalRight) {
        notify({
          message: (
            <Trans>
              Funcionalidade ativada! Para funcionar corretamente o campo esférico não pode estar
              preenchido, se ele já estiver preenchido, por favor, dígite novamente!
            </Trans>
          ),
          variant: 'success'
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
};

const negativeValueCylindrical = (
  setNegativeCylindricalLeft,
  negativeCylindricalLeft,
  setNegativeCylindricalRight,
  negativeCylindricalRight,
  notify,
  side
) => {
  try {
    if (side === 'LEFT') {
      setNegativeCylindricalLeft(!negativeCylindricalLeft);
      if (negativeCylindricalLeft) {
        notify({
          message: (
            <Trans>
              Funcionalidade ativada! Para funcionar corretamente o campo cilíndrico não pode estar
              preenchido, se ele já estiver preenchido, por favor, dígite novamente!
            </Trans>
          ),
          variant: 'success'
        });
      }
    } else if (side === 'RIGHT') {
      setNegativeCylindricalRight(!negativeCylindricalRight);
      if (negativeCylindricalRight) {
        notify({
          message: (
            <Trans>
              Funcionalidade ativada! Para funcionar corretamente o campo cilíndrico não pode estar
              preenchido, se ele já estiver preenchido, por favor, dígite novamente!
            </Trans>
          ),
          variant: 'success'
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
};

const newItemStructure = description => ({
  description,
  sku: null,
  type: 'LENS',
  unitOfMeasure: 'EACH',
  unitPrice: 1.0
});

const changeItemSequence = (order, side, newItemSequence) =>
  order.prescription.eyesPrescription &&
  order.prescription.eyesPrescription.map(eye => ({
    ...eye,
    itemSequence: eye.side === side ? newItemSequence : eye.itemSequence
  }));

const getMaxItemSequence = items =>
  items && items.length === 0 ? 0 : Math.max(...items.map(item => item.itemSequence)) || 0;

export default ({ ...props }) => (
  <OrdersConsumer>
    {({ actions, receiveOrder }) => (
      <Prescription {...props} actions={actions} receiveOrder={receiveOrder} />
    )}
  </OrdersConsumer>
);
