import { hoursWithoutUTC } from 'utils/date/formatHours';

export const formatOrderData = values => ({
  ...values,
  orderDueDateTime: hoursWithoutUTC(values.orderDueDateTime),
  prescription: {
    ...values.prescription,
    frame: {
      ...values.prescription.frame,
      type: values.prescription.frame.type === '' ? undefined : values.prescription.frame.type,
      changeModel:
        values.prescription.frame.changeModel === ''
          ? undefined
          : values.prescription.frame.changeModel,
      nasal: values.prescription.frame.nasal,
      temporal: values.prescription.frame.temporal,
      higher: values.prescription.frame.higher,
      bottom: values.prescription.frame.bottom
    },
    eyesPrescription: values.prescription.eyesPrescription.map(eye => ({
      ...eye,
      horizontalPrismAxis: eye.horizontalPrismAxis === '' ? undefined : eye.horizontalPrismAxis,
      verticalPrismAxis: eye.verticalPrismAxis === '' ? undefined : eye.verticalPrismAxis
    }))
  },
  items: values.items.map(
    ({ description, color, itemSequence, quantity, sku, type, unitOfMeasure, unitPrice }) => ({
      color,
      description,
      itemSequence,
      quantity,
      sku,
      type,
      unitOfMeasure,
      unitPrice
    })
  )
});
