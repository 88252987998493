export default function styles(theme) {
  return {
    field: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    button: {
      margin: theme.spacing.unit
    },
    input: {
      flex: '1'
    },
    helper: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit
    },
    divider: {
      width: 1,
      height: theme.spacing.unit * 4,
      margin: 'auto 0'
    }
  };
}
