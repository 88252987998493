import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import FormSection from 'components/form/wrappers/form-section';
import AutocompleteField from 'components/form/fields/autocomplete';
import TextField from 'components/form/fields/text';
import SelectField from 'components/form/fields/select';
import MenuItem from '@material-ui/core/MenuItem';
import parameters from 'utils/dictionaries/individualParameters';
import Grid from '@material-ui/core/Grid';
import Button from 'components/buttons/button';
import Zoom from '@material-ui/core/Zoom';
import PropTypes from 'prop-types';

const eyes = [
  { value: 'RIGHT', label: <Trans>Direito</Trans> },
  { value: 'LEFT', label: <Trans>Esquerdo</Trans> }
];

const IndividualParameters = ({ classes, change, handleSubmit, submitCallback, formValues }) => {
  const [perEye, setPerEye] = useState(false);

  const erpSoftwareName =
    formValues && formValues.store && formValues.store.erpSoftwareProviderName;

  return (
    <form onSubmit={handleSubmit(submitCallback)}>
      <FormSection title={<Trans>Inserir / editar parâmetros individuais</Trans>}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={5}>
            <AutocompleteField
              name="parameter"
              label={<Trans>Parâmetro</Trans>}
              options={parameters.map(({ value, label }) => ({
                label,
                value,
                id: value
              }))}
              clearCallback={() => {
                setPerEye(false);
                change('value', null);
                change('eye', null);
              }}
              selectionCallback={selectedOption => {
                const { value } = selectedOption;
                const parameter = parameters.find(param => param.value === value);
                const hasEye = parameter.eye && parameter.eye === true;
                setPerEye(hasEye);
                if (hasEye) change('eye', 'RIGHT');
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField name="value" fullWidth label={<Trans>Valor</Trans>} />
          </Grid>
          <Zoom in={perEye}>
            <Grid
              item
              xs={12}
              sm={2}
              className={!perEye ? classes.hideSelectEye : classes.selectEye}
            >
              <SelectField name="eye" fullWidth label={<Trans>Olho</Trans>}>
                {eyes.map(({ value, label }) => (
                  <MenuItem value={value}>{label}</MenuItem>
                ))}
              </SelectField>
            </Grid>
          </Zoom>
          <Grid item xs={12} sm={2}>
            <Button
              className={classes.button}
              disabled={
                !formValues.value || !formValues.parameter || erpSoftwareName === 'Grupo Acert'
              }
              type="submit"
            >
              <Trans>Gravar</Trans>
            </Button>
          </Grid>
        </Grid>
      </FormSection>
    </form>
  );
};

IndividualParameters.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitCallback: PropTypes.func.isRequired,
  classes: PropTypes.objectOf({}),
  formValues: PropTypes.objectOf({})
};

IndividualParameters.defaultProps = {
  classes: {},
  formValues: {}
};

export default IndividualParameters;
