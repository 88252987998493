import React from 'react';
import HelperText from '../helper-text';

function withHelperText(WrappedComponent) {
  return function withHelperTextComponent(props) {
    const {
      hideHelpers = false,
      information,
      meta: { error, touched, warning }
    } = props;
    return (
      <>
        <WrappedComponent {...props} />
        {!hideHelpers && (
          <HelperText error={error} information={information} touched={touched} warning={warning} />
        )}
      </>
    );
  };
}

export default withHelperText;
