import { Trans } from '@lingui/macro';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { SelectInput } from 'components/form/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { getFormValues } from 'redux-form';
import { getSupplierResources } from '../../../../services/suppliers';
import { getSupplierSku } from '../../../../services/stores';
import CustomTableCell from '../../../../components/table/CustomTableCell';
import Loading from '../../../../components/utils/Loading';

class ModelInfo extends Component {
  render() {
    const { shapeSkuField, storeSku } = this.props;
    const { loading, shapeResources } = this.state;
    if (loading) {
      return <Loading />;
    }
    return (
      <>
        <CustomTableCell>
          {loading || storeSku === 'TRACER' || storeSku === 'FRAME_SHAPE_09' ? (
            <></>
          ) : (
            <SelectInput
              name={shapeSkuField}
              style={{ width: '-webkit-fill-available' }}
              onChange={this.changeShapeSku}
              label={<Trans>Desenho da armação</Trans>}
            >
              <MenuItem value="" disabled />
              {shapeResources &&
                shapeResources.map(data => (
                  <MenuItem key={data.sku} value={data.sku}>
                    {data.description}
                  </MenuItem>
                ))}
            </SelectInput>
          )}
        </CustomTableCell>
      </>
    );
  }

  state = {
    loading: false,
    hasIntegration: false,
    shapeResources: []
  };

  changeShapeSku = event => {
    const { shapeSkuField, change } = this.props;
    change('changeShapeSkuField', false);
    if (event !== null) {
      change(shapeSkuField, event.target.value);
      change('frameModel', event.target.value);
    }
  };

  async getItemInformationBySku() {
    const { storeId, supplierId, storeSku, storeSkuShape, change, shapeSkuField } = this.props;

    await this.setShapeResources();

    await getSupplierSku(storeId, supplierId, 'FRAME_SHAPE', storeSku).then(result => {
      const item =
        result.content && result.content.find(result => result.supplierSkuType === storeSkuShape);

      if (item) {
        this.setState({ supplierSku: item.supplierSku });
        if (this.props.formValues.changeShapeSkuField) {
          change(shapeSkuField, item.supplierSku);
        }
      }
      this.setState({ hasIntegration: !!item });
    });
  }

  async setShapeResources() {
    const { supplierId } = this.props;
    try {
      await getSupplierResources({
        supplierId,
        resourceType: 'FRAME_SHAPE'
      }).then(content => {
        this.setState({ shapeResources: content.content });
      });
    } catch (error) {
      console.log(error);
    }
  }

  async componentWillMount() {
    this.setState({ loading: true });
    this.getItemInformationBySku();
    this.setState({ loading: false });
  }
}

function mapStateToProps(state) {
  const formValues = getFormValues('wizard')(state) || {};
  return { formValues };
}

export default compose(withRouter, connect(mapStateToProps, null))(ModelInfo);
