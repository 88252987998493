import React from 'react';
import { Trans } from '@lingui/macro';

export function setSupplierResourcesDiff(props, side) {
  const { formValues, change } = props;
  const { surfacing_right, surfacing_left, resourcesContent } = formValues;
  if (side === 'right') {
    if (surfacing_right !== undefined) {
      if (returnResourcesWithLensType(surfacing_right, resourcesContent).length === 0)
        change('rightLens', true);
      else
        change(
          'supplierResource_right',
          returnResourcesWithLensType(surfacing_right, resourcesContent)
        );
    }
  } else if (side === 'left') {
    if (surfacing_left !== undefined) {
      if (returnResourcesWithLensType(surfacing_left, resourcesContent).length === 0)
        change('leftLens', true);
      else
        change(
          'supplierResource_left',
          returnResourcesWithLensType(surfacing_left, resourcesContent)
        );
    }
  }
}

export function setSupplierResourcesNotDiff(props) {
  const { formValues, change } = props;
  const { surfacing_right, surfacing_left, resourcesContent } = formValues;
  let lensTypeRight = [];
  let lensTypeLeft = [];
  if (surfacing_right !== undefined) {
    lensTypeRight = returnResourcesWithLensType(surfacing_right, resourcesContent);
    if (lensTypeRight.length === 0) change('bothLens', true);
    else change('supplierResource_both', lensTypeRight);
  }
  if (surfacing_left !== undefined) {
    if (surfacing_left !== surfacing_right) {
      lensTypeLeft = returnResourcesWithLensType(surfacing_left, resourcesContent);
      if (lensTypeLeft.length === 0 && lensTypeRight.length === 0) change('bothLens', true);
      else change('supplierResource_both', lensTypeLeft.concat(lensTypeRight));
    }
  }
}

export function selectEyesPrescription(eyesPrescription, itemSequence, lensType, change) {
  if (eyesPrescription.length > 0) {
    eyesPrescription.map(eyesValue => {
      // if (eyesValue.itemSequence === itemSequence) {
      if (eyesValue.side === 'LEFT') {
        changeLensWithType('surfacing_left', lensType, change);
      } else if (eyesValue.side === 'RIGHT') {
        changeLensWithType('surfacing_right', lensType, change);
      }
      // }
    });
  }
}

export function diffItemSku(formValues, sequence, eyesPrescription, change) {
  const filtered = formValues.items.filter(({ type }) => type === 'LENS');
  const filteredLens = eyesPrescription.filter(({ itemSequence }) => itemSequence === sequence);
  if (filtered.length > 1 && filteredLens.length === 1 && filteredLens.length < 2) {
    change('diff_sku', true);
  } else if (filtered.length > 1 && filteredLens.length === 2) {
    change('diff_sku', false);
  }
}

export function checkLens(formValues) {
  const { bothLens, rightLens, leftLens } = formValues;
  if (bothLens || rightLens || leftLens) return true;
  return false;
}

function changeLensWithType(side, lensType, change) {
  switch (lensType) {
    case 'FINISHED_PROGRESSIVE_LENS': {
      change(side, 'FINISHED_PROGRESSIVE_LENS');
      break;
    }

    case 'FINISHED_BIFOCAL': {
      change(side, 'FINISHED_BIFOCAL');
      break;
    }

    case 'FINISHED_SINGLE_VISION': {
      change(side, 'FINISHED_SINGLE_VISION');
      break;
    }

    case 'SEMI_FINISHED_PROGRESSIVE_LENS': {
      change(side, 'SEMI_FINISHED_PROGRESSIVE_LENS');
      break;
    }

    case 'SEMI_FINISHED_BIFOCAL': {
      change(side, 'SEMI_FINISHED_BIFOCAL');
      break;
    }

    case 'SEMI_FINISHED_SINGLE_VISION': {
      change(side, 'SEMI_FINISHED_SINGLE_VISION');
      break;
    }

    case 'SEMI_FINISHED_INTERMEDIATE_VISION': {
      change(side, 'SEMI_FINISHED_INTERMEDIATE_VISION');
      break;
    }

    case 'FREE_FORM': {
      change(side, 'FREE_FORM');
      break;
    }

    default: {
      break;
    }
  }
}

export function returnResourcesWithLensType(lensType, content) {
  switch (lensType) {
    case 'FINISHED_PROGRESSIVE_LENS': {
      const filteredContent = content.filter(
        ({ lensType }) => lensType === 'FINISHED_PROGRESSIVE_LENS'
      );
      return filteredContent;
    }

    case 'FINISHED_BIFOCAL': {
      const filteredContent = content.filter(({ lensType }) => lensType === 'FINISHED_BIFOCAL');
      return filteredContent;
    }

    case 'FINISHED_SINGLE_VISION': {
      const filteredContent = content.filter(
        ({ lensType }) => lensType === 'FINISHED_SINGLE_VISION'
      );
      return filteredContent;
    }

    case 'SEMI_FINISHED_PROGRESSIVE_LENS': {
      const filteredContent = content.filter(
        ({ lensType }) => lensType === 'SEMI_FINISHED_PROGRESSIVE_LENS'
      );
      return filteredContent;
    }

    case 'SEMI_FINISHED_BIFOCAL': {
      const filteredContent = content.filter(
        ({ lensType }) => lensType === 'SEMI_FINISHED_BIFOCAL'
      );
      return filteredContent;
    }

    case 'SEMI_FINISHED_SINGLE_VISION': {
      const filteredContent = content.filter(
        ({ lensType }) => lensType === 'SEMI_FINISHED_SINGLE_VISION'
      );
      return filteredContent;
    }

    case 'SEMI_FINISHED_INTERMEDIATE_VISION': {
      const filteredContent = content.filter(
        ({ lensType }) => lensType === 'SEMI_FINISHED_INTERMEDIATE_VISION'
      );
      return filteredContent;
    }

    case 'FREE_FORM': {
      const filteredContent = content.filter(({ lensType }) => lensType === 'FREE_FORM');
      return filteredContent;
    }

    default: {
      break;
    }
  }
}

export function getResourcesWithLens(formValues) {
  const { supplierResource_both, supplierResource_left, supplierResource_right } = formValues;

  if (supplierResource_both.length > 0) {
    return supplierResource_both;
  }
  if (supplierResource_right.length > 0) {
    return supplierResource_right;
  }
  if (supplierResource_left.length > 0) {
    return supplierResource_left;
  }
}

export function clearResources(formValues, change) {
  const { supplierResource_both, supplierResource_left, supplierResource_right } = formValues;

  if (supplierResource_both.length > 0) {
    change('supplierResource_both', []);
  }
  if (supplierResource_right.length > 0) {
    change('supplierResource_right', []);
  }
  if (supplierResource_left.length > 0) {
    change('supplierResource_left', []);
  }
}

export function returnType(itemSequence, sku, type, items, prescription) {
  try {
    let returnText;
    if (type === 'LENS') {
      const bothEyes =
        prescription.eyesPrescription.filter(eye => eye.itemSequence === itemSequence).length === 2;
      if (bothEyes) {
        returnText = <Trans>Lente / Ambos os olhos</Trans>;
      } else {
        const eyesSide = prescription.eyesPrescription.filter(
          eye => eye.itemSequence === itemSequence
        );

        if (eyesSide.length > 0) {
          if (eyesSide[0].side === 'RIGHT') returnText = <Trans>Lente / Olho direito</Trans>;
          if (eyesSide[0].side === 'LEFT') returnText = <Trans>Lente / Olho esquerdo</Trans>;
        } else {
          returnText = 'Lente';
        }

        // items
        //   .filter(item => sku === item.sku)
        //   .map(item => {
        //     const eyesSide = prescription.eyesPrescription.filter(
        //       eye => eye.itemSequence === itemSequence
        //     );
        //     if (eyesSide.length > 0) {
        //       if (eyesSide[0].side === 'RIGHT') returnText = <Trans>Lente / Olho direito</Trans>;
        //       if (eyesSide[0].side === 'LEFT') returnText = <Trans>Lente / Olho esquerdo</Trans>;
        //     } else {
        //       returnText = 'Lente';
        //     }
        //   });
      }
    } else {
      returnText = returnNameOfType(type);
    }
    return returnText;
  } catch (error) {
    console.log(error);
    return <Trans>Lente</Trans>;
  }
}

export function returnNameOfType(type) {
  switch (type) {
    case 'LENS': {
      return <Trans>Produto</Trans>;
    }

    case 'SERVICE': {
      return <Trans>Serviço</Trans>;
    }

    case 'ASSEMBLY': {
      return <Trans>Montagem</Trans>;
    }

    case 'COLORING': {
      return <Trans>Coloração</Trans>;
    }

    case 'COATING': {
      return <Trans>Tratamento</Trans>;
    }

    case 'FRAME': {
      return <Trans>Armação</Trans>;
    }

    case 'FRAME_SHAPE': {
      return <Trans>Desenho de Armação</Trans>;
    }

    case 'BLANK': {
      return <Trans>Outros</Trans>;
    }

    default: {
      return <Trans>Outros</Trans>;
    }
  }
}

export function returnAvatarNameOfType(type) {
  switch (type) {
    case 'LENS': {
      return <Trans>P</Trans>;
    }

    case 'SERVICE': {
      return <Trans>S</Trans>;
    }

    case 'ASSEMBLY': {
      return <Trans>M</Trans>;
    }

    case 'COLORING': {
      return <Trans>C</Trans>;
    }

    case 'COATING': {
      return <Trans>T</Trans>;
    }

    case 'FRAME': {
      return <Trans>A</Trans>;
    }

    case 'FRAME_SHAPE': {
      return <Trans>D</Trans>;
    }

    case 'BLANK': {
      return <Trans>O</Trans>;
    }

    default: {
      return <Trans>O</Trans>;
    }
  }
}
