import PropTypes from 'prop-types';
import React from 'react';
import InputBase from '@material-ui/core/InputBase';

function PlainInput({ input, meta: { error, touched }, ...props }) {
  return <InputBase {...input} {...props} error={touched && Boolean(error)} />;
}

PlainInput.propTypes = {
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({}).isRequired
};

export default PlainInput;
