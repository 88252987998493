import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import 'moment/locale/pt-br';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { getCurrentLocale } from 'store/selectors/i18nSelectors';

Moment.globalFormat = 'LLLL';

function PickerWrapper(props) {
  const { locale, children } = props;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
      {children}
    </MuiPickersUtilsProvider>
  );
}

const mapStateToProps = state => ({
  currentLocale: getCurrentLocale(state)
});

export default connect(mapStateToProps)(PickerWrapper);
