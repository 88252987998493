import React, { Component } from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import { Link, withRouter } from 'react-router-dom';
import Logo from 'assets/logo.png';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Search from 'forms/header/Search';
import Toolbar from '@material-ui/core/Toolbar';
import { Trans } from '@lingui/macro';
import Typography from '@material-ui/core/Typography';
import { Creators as UserCreators } from 'store/ducks/user';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { logout } from 'store/ducks/auth';
import { routesIndex } from 'routes/Routes';

import { withStyles } from '@material-ui/core/styles';
import MobileDrawer from './MobileDrawer';
import Menu from './Menu';
import DesktopMenu from './DestkopMenu';

class Header extends Component {
  render() {
    const { classes } = this.props;
    const { featureMenu, profileMenu, stores } = this.state;
    return (
      <>
        <AppBar position="static" className={classes.bar}>
          <Toolbar className={classes.toolbar}>
            <Link to="/home" className={classes.title}>
              <img className={classes.logo} src={Logo} alt="Acert Conecta" />
            </Link>
            <IconButton
              color="primary"
              aria-haspopup="true"
              className={classes.featureMenuButton}
              onClick={this.openMenu.bind(this, 'featureMenu')}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.desktopLinks}>
              <DesktopMenu
                stores={this.props.stores}
                routes={routesIndex.filter(item => item.menus && item.menus.includes('desktop'))}
              />
            </div>
            <IconButton
              onClick={this.openMenu.bind(this, 'profileMenu')}
              className={classes.profileMenuButton}
            >
              <Avatar
                aria-haspopup="true"
                src={this.props.imageError ? undefined : this.props.imageUrl}
                onError={() => {
                  const imageError = true;
                  return this.props.changeImageStatus(imageError);
                }}
                color="inherit"
              >
                <AccountCircle />
              </Avatar>
            </IconButton>
            <Search className={classes.InputSearch} />
          </Toolbar>
        </AppBar>

        <MobileDrawer
          className={classes.featureMenu}
          anchor={featureMenu}
          open={Boolean(featureMenu)}
          close={this.closeMenu.bind(this, 'featureMenu')}
          routes={routesIndex.filter(route => route.menus && route.menus.includes('mobile'))}
        >
          <Divider light />

          {this.renderAditionalOptions()}
        </MobileDrawer>

        <Menu
          anchor={profileMenu}
          isOpen={Boolean(profileMenu)}
          onClose={this.closeMenu.bind(this, 'profileMenu')}
          routes={routesIndex.filter(route => route.menus && route.menus.includes('profile'))}
        >
          <Divider light />

          {this.renderAditionalOptions()}
        </Menu>
      </>
    );
  }

  state = {
    featureMenu: null,
    profileMenu: null
  };

  renderAditionalOptions = () => {
    const { classes } = this.props;
    return (
      <>
        <a
          className={classes.aditionalLink}
          href="https://grupoacert.movidesk.com/kb/article/306794/manual-integracao-com-acert-conecta"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MenuItem>
            <HelpIcon color="primary" />
            <Typography className={classes.aditionalText} variant="body1">
              <Trans>Ajuda</Trans>
            </Typography>
          </MenuItem>
        </a>

        <MenuItem
          onClick={() => {
            logout();
          }}
        >
          <LogoutIcon color="primary" />

          <Typography className={classes.aditionalText} variant="body1">
            <Trans>Sair</Trans>
          </Typography>
        </MenuItem>
      </>
    );
  };

  openMenu = (menu, event) => {
    this.setState({
      [menu]: event.currentTarget
    });
  };

  closeMenu = (menu, event) => {
    this.setState({
      [menu]: null
    });
  };
}

const styles = theme => ({
  bar: {
    backgroundColor: theme.palette.primary.dark,
    position: 'sticky',
    top: 0
  },
  logo: {
    maxWidth: '200px',
    maxHeight: '60px'
  },
  desktopLinks: {
    display: 'none',
    flex: 1,
    justifyContent: 'flex-end',
    margin: `0px ${theme.spacing.unit * 2}px`,
    marginLeft: 'auto',
    order: 4,
    color: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  InputSearch: {
    order: '3',
    flexGrow: '1',
    [theme.breakpoints.up('sm')]: {
      flexGrow: 0,
      marginLeft: theme.spacing.unit * 4
    }
  },
  profileMenuButton: {
    display: 'none',
    marginLeft: 'auto',
    order: '4',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  },
  profileOptions: {
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  profilePicture: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      margin: theme.spacing.unit * 2
    },
    display: 'none'
  },
  featureMenuButton: {
    color: theme.palette.common.white,
    order: '-1',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  aditionalLink: {
    textDecoration: 'none'
  },
  aditionalText: {
    marginLeft: theme.spacing.unit
  },
  title: {
    order: '1',
    color: 'white',
    textDecoration: 'none',
    padding: `${theme.spacing.unit}px `
  },
  toolbar: {
    padding: theme.spacing.unit,
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      padding: `0px ${theme.spacing.unit * 4}px`,
      flexWrap: 'no-wrap'
    }
  }
});

function mapStateToProps(state) {
  return {
    profilePicture: state.user.profilePicture,
    imageUrl: state.user.imageUrl,
    imageError: state.user.imageError,
    stores: state.stores
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeImageStatus(imageError) {
      dispatch(UserCreators.changeImageStatus(imageError));
    }
  };
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
