import { DatePicker as MUiDatePicker } from 'material-ui-pickers';
import React from 'react';
import withHelperText from 'components/form/inputs/helper-text/withHelperText';
import PickerWrapper from '../../wrappers/PickerWrapper';

function DatePickerInput({ input, ...props }) {
  async function handleChange(moment) {
    const { onChangeCallback } = props;
    const { onChange } = input;

    const value = moment.format();

    await onChange(value);
    onChangeCallback && onChangeCallback(moment);
  }

  return (
    <PickerWrapper>
      <MUiDatePicker
        {...input}
        {...props}
        autoOk
        cancelLabel={null}
        okLabel={null}
        onChange={handleChange}
        style={{
          backgroundColor: '#eee'
        }}
      />
    </PickerWrapper>
  );
}

export default withHelperText(DatePickerInput);
