import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/card';
import CardContent from 'components/card-content';
import Typography from '@material-ui/core/Typography';
import { FormSection as RxFormSection } from 'redux-form';

function FormSection({ children, name, title }) {
  return (
    <Card>
      <CardContent>
        <Typography
          color="textSecondary"
          component="h2"
          gutterBottom
          variant="h6"
          style={{ marginBottom: 16 }}
        >
          {title}
        </Typography>
        <RxFormSection name={name}>{children}</RxFormSection>
      </CardContent>
    </Card>
  );
}

FormSection.prototype = {
  children: PropTypes.node,
  title: PropTypes.node,
  name: PropTypes.string.isRequired
};

export default FormSection;
