export default function styles(theme) {
  return {
    root: {
      gridGap: `${theme.spacing.unit * 2}px`
    },
    grid: {
      display: 'grid'
    }
  };
}
