import { DatePicker as MUiDatePicker, TimePicker as MUiTimePicker } from 'material-ui-pickers';
import React, { Component } from 'react';

import CustomTableCell from 'components/table/CustomTableCell';
import { Creators as NotificationCreators } from 'store/ducks/notifications/';
import { Trans } from '@lingui/macro';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import classnames from 'classnames';
import PickerWrapper from './PickerWrapper';

class TimePicker extends Component {
  render() {
    const { classes, grid, inline, dateOnly } = this.props;

    if (grid) {
      return (
        <PickerWrapper>
          <CustomTableCell>
            <MUiDatePicker onChange={this.handleChange('date')} value={this.state.time} />
          </CustomTableCell>

          <CustomTableCell>
            <MUiTimePicker onChange={this.handleChange('hours')} value={this.state.time} />
          </CustomTableCell>
        </PickerWrapper>
      );
    }
    return (
      <PickerWrapper className={classnames({ [classes.wrapperInline]: inline })}>
        <MUiDatePicker
          className={classnames({ [classes.input]: !inline })}
          label={<Trans>Data</Trans>}
          onChange={this.handleChange('date')}
          value={this.state.time}
        />

        {!dateOnly && (
          <MUiTimePicker
            className={classnames({ [classes.input]: !inline })}
            label={<Trans>Hora</Trans>}
            onChange={this.handleChange('hours')}
            value={this.state.time}
          />
        )}
      </PickerWrapper>
    );
  }

  concatTime = (date, hour) => [date, hour].join('T');

  getDate = time => time.split('T')[0];

  getHours = time => time.split('T')[1];

  getTime = (partialName, partial, oldTime) => {
    let date;
    let hour;

    if (partialName === 'date') {
      date = this.getDate(partial);
      hour = this.getHours(oldTime);
    } else if (partialName === 'hours') {
      date = this.getDate(oldTime);
      hour = this.getHours(partial);
    }

    return this.concatTime(date, hour);
  };

  isNewTimeValid = newTime => {
    const newDate = new Date(newTime);
    const currentDate = new Date();
    return +newDate > +currentDate;
  };

  handleChange = inputName => event => {
    const {
      notify,
      input: { onChange }
    } = this.props;
    const partial = event.format();

    const newTime = this.getTime(inputName, partial, this.state.time);

    if (this.isNewTimeValid(newTime)) {
      this.setState({ time: newTime }, () => onChange(this.state.time));
    } else {
      notify(<Trans>A nova data não pode ser menor do que a atual</Trans>, 'warning');
    }
  };

  state = {
    time: this.props.input.value || this.props.meta.initial,
    initialTime: this.props.meta.initial
  };
}

const styles = theme => ({
  input: {
    paddingBottom: theme.spacing.unit * 3
  },
  wrapperInline: {
    display: 'flex'
  }
});

const mapDispatchToProps = dispatch => ({
  notify: (message, variant) => dispatch(NotificationCreators.addNotification({ message, variant }))
});

export default compose(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(TimePicker);
