import { Trans } from '@lingui/macro';
import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React, { Component } from 'react';
import types from 'utils/types';
import { getBaseList, getSupplierResources } from '../../../../services/suppliers';
import {
  getSupplierSku as getIntegrationsByStoreSku,
  getSupplierSku
} from '../../../../services/stores';
import { AutocompleteInput, SelectInput } from '../../../../components/form/Input';
import { required } from '../../../../components/form/validation';
import CustomTableCell from '../../../../components/table/CustomTableCell';
import Loading from '../../../../components/utils/Loading';
import {
  selectEyesPrescription,
  returnResourcesWithLensType,
  getResourcesWithLens,
  clearResources,
  checkLens,
  diffItemSku
} from '../utils/lensType';

export default class SupplierInfo extends Component {
  render() {
    const { supplierId, supplierSkuField, baseField, classes, baseValue, sendValue } = this.props;

    const {
      supplierResources,
      loadingBases,
      baseList,
      supplierSku,
      quantityIntegrated,
      descriptionSku
    } = this.state;

    const validadeSupplierSKU = sendValue ? [required] : [];

    const quantCompositeIntegration =
      quantityIntegrated &&
      quantityIntegrated.filter(({ supplierSku }) => supplierSku !== this.state.supplierSku);

    return (
      <>
        <CustomTableCell className={classes.newIntegration}>
          {supplierResources && supplierResources !== undefined ? (
            <div>
              <Tooltip
                title={
                  <div style={{ maxWidth: 300 }}>{descriptionSku && <>{descriptionSku}</>}</div>
                }
              >
                <AutocompleteInput
                  name={supplierSkuField}
                  onChange={this.changeSupplierSku}
                  validate={validadeSupplierSKU}
                  label={<Trans>Fornecedor</Trans>}
                  options={supplierResources.map(userStore => ({
                    label: userStore.description,
                    id: userStore.sku,
                    value: userStore.sku
                  }))}
                />
              </Tooltip>
              <>
                {quantCompositeIntegration && +quantCompositeIntegration.length > 0 ? (
                  <div className={classes.textAdditionalItems}>
                    + {+quantCompositeIntegration.length}{' '}
                    {+quantCompositeIntegration.length > 1 ? (
                      <Trans>Itens Integrados</Trans>
                    ) : (
                      <Trans>Item Integrado</Trans>
                    )}
                    <Tooltip
                      title={quantCompositeIntegration.map(value => (
                        <>
                          {`${this.getSupplierSkuType(value.supplierSkuType)} - ${
                            value.supplierSku
                          } - ${this.getSupplierSkuDescription(value.supplierSku)}`}
                          <br />
                        </>
                      ))}
                    >
                      <IconButton className={classes.iconButton} aria-label="delete">
                        <InfoIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  undefined
                )}
              </>
            </div>
          ) : (
            <Loading />
          )}
        </CustomTableCell>
        <CustomTableCell className={classes.baseIntegration}>
          {loadingBases ? (
            <Loading />
          ) : baseList || baseValue ? (
            <SelectInput name={baseField}>
              <MenuItem value="" disabled />
              <MenuItem value="0">
                <Trans>Não informar a base</Trans>
              </MenuItem>
              {baseList &&
                baseList.map(item => (
                  <MenuItem key={item.replace(',', '.')} value={item.replace(',', '.')}>
                    {item.replace(',', '.')}
                  </MenuItem>
                ))}
            </SelectInput>
          ) : (
            <Button
              color="primary"
              variant="outlined"
              className={classes.button}
              onClick={() => this.getListOfBase(supplierId, supplierSku && supplierSku)}
            >
              <Trans>Escolher</Trans>
            </Button>
          )}
        </CustomTableCell>
      </>
    );
  }

  state = {
    loading: false,
    loadingBases: false,
    baseList: undefined,
    supplierSku: undefined,
    supplierResources: [],
    allResources: [],
    descriptionSku: undefined,
    quantityIntegrated: undefined,
    notIntegratedSupplierSku: undefined
  };

  // Função para troca de fornecedor no autocomplet
  changeSupplierSku = event => {
    const { supplierSkuField, baseField, change, formValues, items, itemSku } = this.props;
    const { supplierResources } = this.state;
    const skuValue = event && event.value;
    // filtro o recurso do fornecedor
    const filtered = supplierResources.filter(({ sku }) => sku === skuValue);
    // filtro dados do item de acordo com o SKU
    const filteredSku = items.filter(({ sku }) => sku === itemSku);

    // Função para filtro de lente
    if (filtered.length > 0) {
      this.selectLensLensType(filtered, filteredSku, formValues, items, change);
    }

    change('changeSupplierSkuField', false);
    change(baseField, null);
    this.setState({ baseList: null });
    // trocando o sku no autocomplet
    if (event !== null) {
      this.setState({
        notIntegratedSupplierSku: event.value,
        descriptionSku: event.label,
        supplierSku: event.value
      });
      change(supplierSkuField, {
        id: event.id,
        label: event.label,
        value: event.value
      });
      this.filterSupplierResources(event.value);
    }
  };

  // Função para retornar a descrição do Sku
  getSupplierSkuDescription(supplierSkuValue) {
    const { allResources, supplierSku } = this.state;
    try {
      if (allResources.length !== 0 && supplierSku !== supplierSkuValue) {
        const filteredResources = allResources.filter(({ sku }) => sku === supplierSkuValue);
        if (filteredResources.length > 0 && filteredResources[0].description !== false) {
          return filteredResources[0].description;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Função para retornar todos os recursos do Supplier
  async getAllSupplierResources() {
    const { supplierId, shop } = this.props;
    const storeCnpj = shop && shop.cnpj;
    const selectedTypes = types.filter(
      ({ value }) => value !== 'FRAME' && value !== 'FRAME_SHAPE' && value !== 'BLANK'
    );
    const storage = [];
    selectedTypes.map(async ({ value: resourceType }) => {
      const data = await this.doRecursiveFetch(supplierId, resourceType, storeCnpj);
      if (data && data.length > 0) {
        storage.push(...data);
      }
      this.setState({ allResources: [...storage] });
    });
  }

  // Função para retornar Tipo do sku
  getSupplierSkuType(SkuType) {
    switch (SkuType) {
      case 'LENS': {
        return <Trans>Produto</Trans>;
      }

      case 'SERVICE': {
        return <Trans>Serviço</Trans>;
      }

      case 'ASSEMBLY': {
        return <Trans>Montagem</Trans>;
      }

      case 'COLORING': {
        return <Trans>Coloração</Trans>;
      }

      case 'COATING': {
        return <Trans>Tratamento</Trans>;
      }

      default: {
        return <Trans>Outros</Trans>;
      }
    }
  }

  // Função para buscar sku integrados
  async getItemInformationBySku() {
    const {
      storeId,
      supplierId,
      storeSku,
      storeSkuType,
      change,
      supplierSkuField,
      sendField,
      quantityField,
      quantityValue,
      baseValue,
      formValues,
      items,
      itemSku,
      itemSequenceId,
      additionalItemsCallback
    } = this.props;

    const { quantityIntegrated } = this.state;

    const storage = [];

    change(supplierSkuField, []);
    change(sendField, false);

    // Consumindo a api "getSupplierResources" e populando os dados
    await this.setSupplierResources();
    // 1 - buscar as integrações para o sku da loja
    await getSupplierSku(storeId, supplierId, 'RESOURCE', storeSku).then(result => {
      // 2 - percorrer a lista retornada pelo backend.
      try {
        result.content.forEach(integration => {
          // 3 - encontrar o item na lista que é do mesmo tipo que o da loja (LENS=LENS)
          if (integration.supplierSkuType === storeSkuType) {
            const filteredResources = this.state.supplierResources.filter(
              ({ sku }) => sku === integration.supplierSku
            );
            if (filteredResources.length > 0 && filteredResources[0].description !== false) {
              const filteredSku = items.filter(({ sku }) => sku === itemSku);

              this.setState({ supplierSku: integration.supplierSku });

              if (filteredResources.length > 0) {
                this.selectLensLensType(filteredResources, filteredSku, formValues, items, change);
              }

              if (this.props.formValues.changeSupplierSkuField) {
                this.setState({ descriptionSku: filteredResources[0].description });
                change(supplierSkuField, {
                  id: integration.supplierSku,
                  label: `${integration.supplierSku} - ${filteredResources.length > 0 &&
                    filteredResources[0].description}`,
                  value: integration.supplierSku
                });
                this.filterSupplierResources(integration.supplierSku);
              }
              change(quantityField, quantityValue * integration.conversionFactor);
              change(sendField, true);
              // se escolhi a base durante o processo, sempre atualizo a lista.
              if (baseValue) this.getListOfBase(supplierId, integration.supplierSku);
            }
          } else {
            const { itemSequence, quantity } = items.find(({ sku }) => sku === itemSku);

            const notLens = quantityIntegrated.find(item => item.supplierSkuType !== 'LENS');

            const quantityLens = items
              .filter(item => item.type === 'LENS')
              .reduce((sum, record) => sum + record.quantity, 0);

            if (
              quantityIntegrated &&
              quantityIntegrated.length === quantityLens &&
              items.length === quantityIntegrated.length &&
              notLens
            ) {
              for (let indexItem = 0; indexItem < items.length; ) {
                for (let index = 0; index < quantityIntegrated.length; ) {
                  storage.push({
                    sku: itemSku,
                    itemSequence: items[indexItem].itemSequence,
                    quantity: items[indexItem].quantity,
                    send: true,
                    supplierSku: { value: notLens.supplierSku },
                    type: notLens.supplierSkuType
                  });
                  indexItem++;
                  index++;
                }
              }
            } else {
              // 4 - o restante dos itens, armazenar no estado local e exibir em um label.
              storage.push({
                sku: itemSku,
                itemSequence,
                quantity,
                send: true,
                supplierSku: { value: integration.supplierSku },
                type: integration.supplierSkuType
              });
            }
          }
          additionalItemsCallback(storage);
        });
      } catch (error) {
        console.log(error);
      }
    });
  }

  doRecursiveFetch = async (supplierId, storeSkuType, storeCnpj, page = 0, allItems = []) => {
    try {
      let storage = [];
      const { last, content } = await getSupplierResources({
        supplierId,
        resourceType: storeSkuType,
        storeCnpj,
        page
      });
      storage = [...allItems, ...content];
      if (last) {
        return storage;
      }
      return this.doRecursiveFetch(supplierId, storeSkuType, storeCnpj, page + 1, storage);
    } catch (error) {
      console.error(error);
    }
  };

  async setSupplierResources() {
    const {
      supplierId,
      storeSkuType,
      formValues,
      itemSku,
      change,
      prescription,
      items,
      shop
    } = this.props;
    const { resourcesContent } = formValues;
    const storeCnpj = shop && shop.cnpj;
    const eyesPrescription = formValues && formValues.prescription.eyesPrescription;
    const filteredSku = items.filter(({ sku }) => sku === itemSku);
    try {
      const data = await this.doRecursiveFetch(supplierId, storeSkuType, storeCnpj);
      this.setState({ supplierResources: data }, () => {
        if (resourcesContent.length === 0) {
          const filtered = []
            .concat(...this.state.supplierResources)
            .filter(({ type }) => type !== 'LENS');
          if (prescription) {
            diffItemSku(
              formValues,
              filteredSku[0].itemSequence,
              prescription.eyesPrescription,
              change
            );
          }

          if (filtered.length > 0) {
          } else if (filteredSku.length > 0) {
            if (filteredSku[0].sku === itemSku) {
              change('resourcesContent', [].concat(...this.state.supplierResources));
              this.filterResourcesWithLens(
                eyesPrescription,
                filteredSku[0].itemSequence,
                [].concat(...this.state.supplierResources),
                formValues
              );
            }
          }
        } else if (storeSkuType === 'LENS') {
          this.filterResourcesWithLens(
            eyesPrescription,
            filteredSku[0].itemSequence,
            resourcesContent,
            formValues
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async componentWillMount() {
    this.setState({ loading: true });
    await this.getIntegrations();
    await this.getItemInformationBySku();
    await this.getAllSupplierResources();
    this.setState({ loading: false });
  }

  async getIntegrations() {
    const { storeId, supplierId, items, itemSku, additionalItemsCallback } = this.props;
    const { quantityIntegrated } = this.state;
    const { itemSequence, quantity } = items.find(({ sku }) => sku === itemSku);
    const storage = [];
    await getIntegrationsByStoreSku(storeId, supplierId, 'RESOURCE', itemSku).then(result => {
      const { content } = result;
      try {
        this.setState({
          quantityIntegrated: content
        });
      } catch (error) {
        console.log(error);
      }
    });
    if (quantityIntegrated && quantityIntegrated.length > 0) {
      quantityIntegrated
        .filter(({ supplierSku }) => supplierSku !== this.state.supplierSku)
        .map(value =>
          storage.push({
            sku: itemSku,
            itemSequence,
            quantity,
            send: true,
            supplierSku: { value: value.supplierSku },
            type: value.supplierSkuType
          })
        );
      additionalItemsCallback(storage);
    }
  }

  async componentDidUpdate(prevProps) {
    const { formValues, change } = this.props;
    if (formValues !== prevProps.formValues) {
      if (formValues.checkIntegrations) {
        await this.getIntegrations();
        change('checkIntegrations', false);
      }
      if (getResourcesWithLens(formValues)) {
        this.setSupplierResources();
        clearResources(formValues, change);
      }
      if (checkLens(formValues)) {
        this.setSupplierResources();
      }
    }
  }

  // traz as bases do sku
  async getListOfBase(supplierId, supplierSku) {
    const { notIntegratedSupplierSku } = this.props;
    const { notify } = this.props;
    this.setState({ loadingBases: true });
    if (notIntegratedSupplierSku !== undefined) {
      await getBaseList(supplierId, notIntegratedSupplierSku).then(result => {
        if (result.sku) {
          this.setState({ baseList: result.bases });
        } else {
          notify(<Trans>Dados da base não encontrados para esse fornecedor.</Trans>, 'warning');
        }
      });
    } else {
      await getBaseList(supplierId, supplierSku).then(result => {
        if (result.sku) {
          if (result.sku) this.setState({ baseList: result.bases });
        } else {
          notify(<Trans>Dados da base não encontrados para esse fornecedor.</Trans>, 'warning');
        }
      });
    }
    this.setState({ loadingBases: false });
  }

  // Função para verificar tipo de lente
  selectLensLensType(filtered, filteredSku, formValues, items, change) {
    const { supplierResources } = this.state;
    const eyesPrescription = formValues && formValues.prescription.eyesPrescription;
    const filteredLensType = supplierResources.filter(({ sku }) => sku === filtered[0].sku);

    if (items.length > 0) {
      if (filtered[0] !== undefined) {
        items.map(value => {
          selectEyesPrescription(
            eyesPrescription,
            filteredSku[0].itemSequence,
            filteredLensType[0].lensType,
            change
          );
        });
      }
    }
  }

  filterResourcesWithLens(eyesPrescription, itemSequence, content, formValues) {
    if (formValues.supplierResource_both.length > 0 || formValues.bothLens) {
      if (formValues.supplierResource_both.length > 0)
        this.setState({ supplierResources: formValues.supplierResource_both });
      else if (formValues.bothLens) {
        this.props.change('bothLens', false);
        this.setState({ supplierResources: [] });
      }
    } else if (
      formValues.supplierResource_left.length > 0 ||
      formValues.supplierResource_right.length > 0
    ) {
      this.setResourcesBySide(eyesPrescription, formValues, itemSequence);
    } else if (formValues.rightLens || formValues.leftLens) {
      this.setResourcesBySide(eyesPrescription, formValues, itemSequence);
    } else {
      this.setState({ supplierResources: content });
    }
  }

  setResourcesBySide(eyesPrescription, formValues, itemSequence) {
    if (eyesPrescription.length > 0) {
      eyesPrescription.map(eyesValue => {
        if (eyesValue.itemSequence === itemSequence) {
          if (eyesValue.side === 'RIGHT') {
            if (formValues.supplierResource_right.length > 0 || formValues.rightLens) {
              if (formValues.rightLens) {
                this.props.change('rightLens', false);
                this.setState({ supplierResources: [] });
              } else if (formValues.supplierResource_right.length > 0) {
                this.setState({
                  supplierResources: formValues.supplierResource_right
                });
              }
            }
          } else if (eyesValue.side === 'LEFT') {
            if (formValues.supplierResource_left.length > 0 || formValues.leftLens) {
              if (formValues.leftLens) {
                this.props.change('leftLens', false);
                this.setState({ supplierResources: [] });
              } else if (formValues.supplierResource_left.length > 0) {
                this.setState({
                  supplierResources: formValues.supplierResource_left
                });
              }
            }
          }
        }
      });
    }
  }

  filterSupplierResources(item) {
    const { supplierResources } = this.state;
    const filtered = supplierResources.filter(({ sku }) => sku === item);

    if (filtered.length > 0 && filtered[0].type === 'LENS') {
      this.setState({
        supplierResources: returnResourcesWithLensType(filtered[0].lensType, supplierResources)
      });
    }
  }
}
