import React from 'react';
import { Trans } from '@lingui/macro';

export default [
  { value: 'CLIENT', text: <Trans>Cliente</Trans> },
  { value: 'PATIENT', text: <Trans>Paciente</Trans> },
  { value: 'DOCTOR', text: <Trans>Médico</Trans> },
  { value: 'SALESMAN', text: <Trans>Vendedor</Trans> },
  { value: 'MANAGER', text: <Trans>Gerente</Trans> }
];
