import { createActions, createReducer } from 'reduxsauce';

const defaultLocale = 'pt';

const INITIAL_STATE = { locale: defaultLocale };

const changeLocale = (state = INITIAL_STATE, { payload }) => ({
  locale: payload
});

export const { Types, Creators } = createActions({
  changeLocale: ['payload']
});

export default createReducer(INITIAL_STATE, {
  [Types.CHANGE_LOCALE]: changeLocale
});
