import React from 'react';
import { Trans } from '@lingui/macro';

function ResourceUnities({ unitName, quantity = 0 }) {
  switch (unitName) {
    case 'EACH': {
      if (quantity > 1) return <Trans>Unidades</Trans>;
      return <Trans>Unidade</Trans>;
    }
    case 'PAIR': {
      if (quantity > 1) return <Trans>Pares</Trans>;
      return <Trans>Par</Trans>;
    }
    case 'HOUR': {
      if (quantity > 1) return <Trans>Horas</Trans>;
      return <Trans>Hora</Trans>;
    }
    case 'DAYS': {
      if (quantity === 1) return <Trans>Dia</Trans>;
      return <Trans>Dias</Trans>;
    }
    default:
      return <Trans />;
  }
}

export default ResourceUnities;
