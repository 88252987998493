export default function styles(theme) {
  return {
    responsiveTable: {
      overflowX: `scroll`,
      [theme.breakpoints.up('md')]: {
        overflowX: `auto`
      }
    }
  };
}
