import React from 'react';
import PropTypes from 'prop-types';
import MuiCard from '@material-ui/core/Card';

function Card({ children, ...props }) {
  return <MuiCard {...props}>{children}</MuiCard>;
}

Card.prototypes = {
  children: PropTypes.node
};

export default Card;
