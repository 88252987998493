import { Trans } from '@lingui/macro';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import resourcesTypes from 'utils/types';

const styles = theme => {
  return {
    notFound: {
      marginTop: theme.spacing.unit * 4
    },
    list: {
      marginTop: theme.spacing.unit * 2,
      width: '100%'
    }
  };
};

const IntegrationList = ({ integrations, supplierResources, classes, deleteCallback }) => {
  const getResourceDescription = supplierSku => {
    const resource = supplierResources.find(item => item.sku === supplierSku);
    return resource && resource.description;
  };

  return (
    <>
      {integrations.length > 0 && supplierResources.length > 0 ? (
        <>
          <Paper className={classes.list}>
            <List>
              {integrations.map(({ id, supplierSku, supplierSkuType }) => (
                <ListItem>
                  <ListItemText
                    primary={`${supplierSku} - ${getResourceDescription(supplierSku)}`}
                    secondary={`${ReactDOMServer.renderToStaticMarkup(
                      resourcesTypes.find(({ value }) => value === supplierSkuType).text
                    )}`}
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title={<Trans>Desfazer integração</Trans>}>
                      <IconButton
                        edge="end"
                        onClick={() => {
                          deleteCallback(id);
                        }}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>

            {/* <List>
              {sanitizedValues.map(({ id, key, value, eye }) => (
                <ListItem>
                  <Avatar>{key.substr(0, 2)}</Avatar>
                  <ListItemText
                    primary={primaryText(key, value)}
                    secondary={
                      eye
                        ? `${ReactDOMServer.renderToStaticMarkup(<Trans>Olho </Trans>)} ${eye}`
                        : undefined
                    }
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title={<Trans>Remover</Trans>}>
                      <IconButton
                        edge="end"
                        onClick={() => {
                          delete data[id.toString()];
                          deleteCallback();
                        }}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List> */}
          </Paper>
        </>
      ) : (
        <Typography className={classes.notFound} color="textSecondary" gutterBottom variant="h6">
          <Trans>Não há integrações para serem exibidas.</Trans>
        </Typography>
      )}
    </>
  );
};

export default withStyles(styles)(IntegrationList);
