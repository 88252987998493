import { Trans } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import FileCopy from '@material-ui/icons/FileCopyOutlined';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import Button from 'components/buttons/button';
import HiddenField from 'components/form/fields/hidden';
import PasswordField from 'components/form/fields/password';
import { required } from 'components/form/fields/validation';
import FormControl from 'components/form/wrappers/form-control';
import AlertDialog from 'components/utils/AlertDialog';
import { loginAPI, logout } from 'services/security';
import withNotification from 'store/providers/withNotification';
import withOverlay from 'store/providers/withOverlay';

class AccessToken extends Component {
  render() {
    const { handleSubmit, notify } = this.props;
    const { token } = this.state;
    return (
      <>
        {!token && (
          <form onSubmit={handleSubmit(this.submit)}>
            <Typography gutterBottom variant="caption">
              <Trans>Insira sua senha para visualizar a chave de acesso</Trans>
            </Typography>
            <HiddenField name="email" />
            <PasswordField
              fullWidth
              disabled={token}
              label={<Trans>Senha*</Trans>}
              name="password"
              validate={[required]}
            />
            <Grid container justify="flex-end">
              <FormControl margin="dense">
                <Button color="primary" variant="contained" type="submit">
                  <Trans>Confirmar</Trans>
                </Button>
              </FormControl>
            </Grid>
          </form>
        )}
        {token && (
          <>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Chave de integração"
                  margin="normal"
                  multiline
                  rows="5"
                  value={token}
                  variant="outlined"
                />
              </Grid>
              <Grid item container justify="space-between" xs={12}>
                <CopyToClipboard
                  text={token}
                  onCopy={() =>
                    notify({
                      message: (
                        <Trans>Chave de acesso copiada para a sua área de tranferência!</Trans>
                      ),
                      variant: 'success'
                    })
                  }
                >
                  <Button variant="text">
                    <FileCopy />
                    <Trans>Copiar chave</Trans>
                  </Button>
                </CopyToClipboard>
                <Button variant="text" type="submit" onClick={() => this.handeAlertDialog(true)}>
                  <AutoRenewIcon />
                  <Trans>Nova chave</Trans>
                </Button>
              </Grid>
            </Grid>

            <AlertDialog
              title={<Trans>Caro usuário...</Trans>}
              text={
                <Trans>
                  Gerar uma nova chave de acesso significa que haverá um novo token de comunição
                  entre os sistemas que interagem com o Acert Conecta. Isso quer dizer que eles
                  deverão ser atualizados com esta nova chave. Deseja continuar ?
                </Trans>
              }
              acceptText={<Trans>Gerar mesmo assim</Trans>}
              declineText={<Trans>Cancelar esta operação</Trans>}
              open={this.state.isOpen}
              handleClose={() => this.handeAlertDialog(false)}
              submitCallback={this.generateNewAccessToken}
            />
          </>
        )}
      </>
    );
  }

  async doLogin(email, password) {
    const {
      notify,
      overlay: { show, hide }
    } = this.props;
    try {
      show(<Trans>Processando...</Trans>);
      await loginAPI(email, password)
        .then(response => response.json())
        .then(response => {
          if (response.accessToken) {
            this.setState({ token: response.accessToken });
            notify({
              message: <Trans>Chave de acesso obtida com sucesso!</Trans>,
              variant: 'success'
            });
          } else {
            this.setState({ token: null });
            notify({
              message: (
                <Trans>Não foi possível obter a chave de acesso. Verifique suas credenciais.</Trans>
              ),
              variant: 'error'
            });
          }
          hide();
        });
    } catch (error) {
      hide();
    }
  }

  submit = values => {
    const { email, password } = values;
    this.doLogin(email, password);
  };

  handeAlertDialog = (open, event) => {
    this.setState({ isOpen: open });
  };

  generateNewAccessToken = event => {
    const { token } = this.state;
    const { formValues, notify } = this.props;
    const { email, password } = formValues;
    logout(token).then(result => {
      if (result.status === 200) this.doLogin(email, password);
      else
        notify(
          <Trans>Não foi possível obter a nova chave de acesso. Tente novamente mais tarde!</Trans>,
          'error',
          false
        );
    });
  };

  state = {
    isOpen: false,
    token: null
  };
}

function mapStateToProps(state, ownProps) {
  const formValues = getFormValues('accessToken')(state) || {};
  const { userEmail } = ownProps;
  return {
    initialValues: {
      email: userEmail
    },
    formValues
  };
}

export default compose(
  withOverlay,
  withNotification,
  connect(mapStateToProps),
  reduxForm({ form: 'accessToken' })
)(AccessToken);
