import { compose } from 'redux';
import orderBy from 'lodash/orderBy';
import orderStatusName from 'components/utils/orderStatusName';

export const orderStatus = ['ORDERED', 'ON_SUPPLIER', 'ASSEMBLY_LAB', 'AWAITING_STORE', 'RECEIVED'];

export function getOrderStoreName(userStores, order) {
  return userStores
    .map(userStore => userStore.store)
    .find(userStore => userStore.id === order.storeId).tradingName;
}

export function filterPerLane(status, orders) {
  return orders.filter(order => order.status === status);
}

export function filterByDueDate(onlyLateOrders) {
  return function(orders) {
    if (onlyLateOrders === 'true')
      return orders.filter(order => {
        const date = new Date().getTime();

        const cardDate = new Date(order.orderDueDateTime).getTime();

        return cardDate < date;
      });

    return orders;
  };
}

export function filterByStore(storeId) {
  return function(orders) {
    if (!storeId) return orders;
    return orders.filter(order => +storeId === +order.storeId);
  };
}

export function filterBySupplier(sentToSupplier) {
  return function(orders) {
    if (!sentToSupplier) return orders;
    return orders.filter(order => +sentToSupplier === +order.sentToSupplier);
  };
}

export function sortBy(orders, filter) {
  if (!filter) return orders;

  let sortCriteria = 'asc';
  let property = '';

  if (filter === 'codeDesc') {
    sortCriteria = 'desc';
    property = 'code';
  } else if (filter === 'codeAsc') {
    property = 'code';
  } else {
    property = filter;
  }

  return orderBy(orders, [order => order[property].toLowerCase()], sortCriteria);
}

export function filterByText(filter) {
  const validKeys = ['code', 'dueDate', 'storeName', 'userName', 'name'];
  return function(orders) {
    if (filter && filter.trim() !== '') {
      return orders.filter(item =>
        Object.keys(item).some(
          key =>
            validKeys.includes(key.toString()) &&
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(filter && filter.toString().toLowerCase())
        )
      );
    }
    return orders;
  };
}

export const findUser = (members, type) => members.find(member => member.type === type);

export function formatCard(userStores, order) {
  const {
    orderId,
    orderNumber,
    status,
    orderDueDateTime,
    prescription: {
      frame: { frameStatus }
    },
    members,
    warning,
    error
  } = order;

  const storeName = getOrderStoreName(userStores, order);

  let user = findUser(members, 'PATIENT');
  if (!user) user = findUser(members, 'CLIENT');

  return {
    code: orderNumber,
    dueDate: orderDueDateTime,
    error,
    id: `${orderId}`,
    storeName,
    userName: user && user.name,
    frameStatus,
    warning,
    laneId: status && status
  };
}

export function createLane(
  orders,
  userStores,
  status,
  textFilter,
  sort,
  getStatusName = orderStatusName
) {
  const filteredOrders = filterPerLane(status, orders);

  const isOrdersInLane = filteredOrders.length > 0;

  if (!isOrdersInLane)
    return {
      id: status,
      title: getStatusName(status),
      cards: []
    };

  const formatedCards = filteredOrders.map(formatCard.bind(this, userStores));

  const filteredCards = textFilter(formatedCards);

  const ordernedCards = sortBy(filteredCards, sort);

  return {
    id: status,
    title: getStatusName(status),
    cards: ordernedCards
  };
}

export function formatOrders(state, filterOptions) {
  const { orders, stores: userStores } = state;
  if (orders.length === 0 || userStores.length === 0) return;

  const { textFilter, store, sort, late, supplier } = filterOptions;

  const filterCards = filterByText(textFilter);

  const filteredOrders = compose(
    filterByDueDate(late),
    filterByStore(store),
    filterBySupplier(supplier)
  )(orders);

  return {
    lanes: orderStatus.map(status =>
      createLane(filteredOrders, userStores, status, filterCards, sort)
    )
  };
}

export function getStoreById(state, storeId) {
  const { stores } = state;
  return stores.map(result => result.store).find(result => +result.id === +storeId);
}

export function getStoreRoleByStoreId(state, storeId) {
  const { stores } = state;
  return stores.find(result => +result.store.id === +storeId).role;
}

export function getOrderById(state, orderId) {
  const { orders } = state;
  return orders.find(order => +order.orderId === +orderId);
}
