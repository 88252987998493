import { Trans } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import React, { Component } from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import Button from 'components/buttons/button';
import PhoneNumberField from 'components/form/fields/phone-number';
import SelectField from 'components/form/fields/select';
import TextField from 'components/form/fields/text';
import CPFField from 'components/form/fields/cpf/';
import { maxLength, minLength, required } from 'components/form/fields/validation';
import { changeUserProfile } from 'services/users';
import { getUserStores } from 'services/stores';
import withNotification from 'store/providers/withNotification';
import withOverlay from 'store/providers/withOverlay';

export class UserProfile extends Component {
  componentWillMount = async () => {
    const { userData, initialize } = this.props;

    const initialValues = {
      name: userData.name,
      email: userData.email,
      phonenumber: !userData.userDetail
        ? undefined
        : this.phoneMask(userData.userDetail.phoneNumber),
      gender: !userData.userDetail ? undefined : userData.userDetail.gender,
      identity:
        userData.userDetail && userData.userDetail.identity
          ? userData.userDetail.identity.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
          : '',
      storeIdDefault: !userData.userDetail ? undefined : userData.userDetail.storeIdDefault
    };

    await this.getStores();
    initialize(initialValues);
  };

  phoneMask(inputValue) {
    const withoutDigits = inputValue.replace(/\D/g, '');
    const dddMask = withoutDigits.replace(/^(\d\d)(\d)/g, '($1) $2');
    const numberMask = dddMask.replace(/(\d{4,5})(\d{4})(\d)?/, '$1-$2');

    return numberMask;
  }

  submit(values) {
    const { name, gender, phonenumber, identity, storeIdDefault } = values;
    const phoneNumber = phonenumber !== undefined ? phonenumber.replace(/\D/g, '') : '';
    const identityNumber = identity ? identity.replace(/\D/g, '') : '';
    const { setOrders } = this.props;
    const userDetail = {
      gender,
      phoneNumber,
      storeIdDefault,
      identity: identityNumber
    };
    this.props.overlay.show(<Trans>Aguarde, processando...</Trans>);
    changeUserProfile(this.props.userData.userId, name, userDetail).then(response => {
      switch (response.status) {
        case 204:
          this.props.notify({
            variant: 'success',
            message: <Trans>Informações do perfil alteradas com sucesso!</Trans>
          });
          setOrders([]);
          localStorage.setItem('storeIdDefault', storeIdDefault);
          break;
        case 400:
          setOrders([]);
          this.props.notify({
            variant: 'error',
            message: (
              <Trans>
                Houve um problema ao tentar atualizar os dados do perfil. Verifique as informações e
                tente novamente.
              </Trans>
            )
          });
          break;
        default:
          setOrders([]);
          this.props.notify({
            variant: 'error',
            message: <Trans>Falha de comunicação com o servidor. Tente novamente mais tarde!</Trans>
          });
          break;
      }
      this.props.overlay.hide();
    });
  }

  render() {
    const { handleSubmit, submiting } = this.props;
    const { stores } = this.state;

    return (
      <form onSubmit={handleSubmit(this.submit.bind(this))}>
        <TextField disabled fullWidth id="email" label={<Trans>Email</Trans>} name="email" />
        <TextField
          fullWidth
          id="name"
          label={<Trans>Nome*</Trans>}
          name="name"
          validate={[required, nameMaxLength, nameMinLength]}
        />
        <SelectField name="storeIdDefault" fullWidth label={<Trans>Selecione a loja padrão</Trans>}>
          <MenuItem key="null" value="null">
            <Trans>Nenhum</Trans>
          </MenuItem>
          {stores
            .filter(item => item.store.storeDetail.status === 1)
            .map(store => (
              <MenuItem key={store.store.id} value={store.store.id}>
                {store.store.tradingName}
              </MenuItem>
            ))}
        </SelectField>
        <CPFField fullWidth label={<Trans>CPF</Trans>} name="identity" />
        <PhoneNumberField fullWidth id="phone" label={<Trans>Telefone</Trans>} name="phonenumber" />
        <SelectField fullWidth id="gender" label={<Trans>Gênero*</Trans>} name="gender">
          <MenuItem value="" disabled>
            <Trans />
          </MenuItem>
          <MenuItem key="FEMALE" value="FEMALE">
            <Trans>Feminino</Trans>
          </MenuItem>
          <MenuItem key="MALE" value="MALE">
            <Trans>Masculino</Trans>
          </MenuItem>
          <MenuItem key="UNDEFINED" value="UNDEFINED">
            <Trans>Prefiro não dizer</Trans>
          </MenuItem>
        </SelectField>

        <Grid container justify="flex-end">
          <Button disabled={submiting} type="submit">
            <Trans>Confirmar</Trans>
          </Button>
        </Grid>
      </form>
    );
  }

  state = {
    stores: []
  };

  getStores = async () => {
    const { userData } = this.props;
    const stores = await getUserStores(userData.email);

    this.setState({ stores });
  };
}

const nameMaxLength = maxLength(50);
const nameMinLength = minLength(4);

export default compose(
  reduxForm({
    form: 'userProfile'
  }),
  withOverlay,
  withNotification
)(UserProfile);
