import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import Card from 'components/card';
import CardContent from 'components/card-content';
import Logo from 'assets/logo.png';
import Access from 'forms/login/access';
import Register from 'forms/login/register';
import ResetPassord from 'forms/login/reset-password';
import { Creators as AuthCreators } from 'store/ducks/auth';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ACCESS, REGISTER, RESET } from './enums';

function Login({ classes, page, ...props }) {
  const isRegister = page === REGISTER;
  const isAccess = page === ACCESS;
  const isReset = page === RESET;
  const {
    match: { params },
    validateAuth,
    location: { search }
  } = props;

  function validateAccessToken() {
    try {
      const tokenAux = search.replace('?', '');
      if (tokenAux) {
        const token = tokenAux.replace(';', '.');
        if (token) validateAuth(token);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(validateAccessToken, []);

  return (
    <div className={classes.wrapperLogin}>
      <Card className={classes.container}>
        <CardContent>
          <Fade in>
            <img className={classes.logo} src={Logo} alt="Acert Conecta" />
          </Fade>

          <Fade className={classnames({ [classes.hide]: !isAccess })} in={isAccess} timeout={500}>
            <div>
              <Access />
            </div>
          </Fade>

          <Fade
            className={classnames({ [classes.hide]: !isRegister })}
            in={isRegister}
            timeout={500}
          >
            <div>
              <Register />
            </div>
          </Fade>

          <Fade className={classnames({ [classes.hide]: !isReset })} in={isReset} timeout={500}>
            <div>
              <ResetPassord />
            </div>
          </Fade>
        </CardContent>
      </Card>

      <ul className={classes.bgBubbles}>
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
    </div>
  );
}

Login.prototypes = {
  classes: PropTypes.object,
  page: PropTypes.string
};
const mapDispatchToProps = dispatch => ({
  validateAuth: accessToken => dispatch(AuthCreators.validateAuth({ accessToken }))
});

export default compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps
  )
)(Login);
