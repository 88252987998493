import { Trans } from '@lingui/macro';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { findUser } from 'store/selectors/orders';

import moment from 'moment';
import View from 'components/view';
import React from 'react';

export default function ArchivedOrders(props) {
  const { orders, classes } = props;

  const { items } = orders;

  return (
    <View size="sm" title={<Trans>Pedidos arquivados</Trans>}>
      {items.length > 0 && (
        <List className={classes.root}>
          {items.map(order => {
            const { orderNumber, orderDateTime, members } = order;

            const [firstLetter] = orderNumber.split('');

            let user = findUser(members, 'PATIENT');
            if (!user) user = findUser(members, 'CLIENT');
            if (!user) user = findUser(members, 'SALESMAN');

            return (
              <ListItem>
                <ListItemAvatar className={classes.avatar}>
                  <Avatar>{firstLetter.toUpperCase()}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={orderNumber}
                  secondary={`
                    ${user && user.name} - ${moment(orderDateTime).format('DD/MM/YYYY')}
                  `}
                />
              </ListItem>
            );
          })}
        </List>
      )}

      {!items.length && (
        <Typography variant="h6" color="textSecondary">
          <Trans>Nenhum pedido encontrado</Trans>
        </Typography>
      )}
    </View>
  );
}

ArchivedOrders.defaultProps = {
  orders: [],
  classes: {}
};
