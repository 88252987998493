import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { Trans } from '@lingui/macro';

class PrescriptionErrors extends Component {
  render() {
    const { expanded } = this.state;
    const { errors } = this.props;
    if (!errors) return null;
    const RightEyeErrors = errors.filter(error => error.eye === 'Right');
    const LeftEyeErrors = errors.filter(error => error.eye === 'Left');
    const GeralErrors = errors.filter(error => error.eye === 'None');
    return (
      <div>
        {RightEyeErrors.length > 0 && (
          <ExpansionPanel
            square
            expanded={expanded === 'panel1'}
            onChange={this.handleChange('panel1')}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <Trans>Olho Direito</Trans>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ul>
                {RightEyeErrors.map(error => (
                  <li>{error.translatedMessage}</li>
                ))}
              </ul>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        {LeftEyeErrors.length > 0 && (
          <ExpansionPanel
            square
            expanded={expanded === 'panel2'}
            onChange={this.handleChange('panel2')}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <Trans>Olho Esquerdo</Trans>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ul>
                {LeftEyeErrors.map(error => (
                  <li>{error.translatedMessage}</li>
                ))}
              </ul>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        {GeralErrors.length > 0 && (
          <ExpansionPanel
            square
            expanded={expanded === 'panel3'}
            onChange={this.handleChange('panel3')}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <Trans>Dados da armação</Trans>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ul>
                {GeralErrors.map(error => (
                  <li>{error.translatedMessage}</li>
                ))}
              </ul>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
      </div>
    );
  }

  state = {
    expanded: 'panel1'
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };
}

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  },
  expanded: {
    margin: 'auto'
  }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0,0,0,.03)',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing.unit * 2
  }
}))(MuiExpansionPanelDetails);

export default PrescriptionErrors;
