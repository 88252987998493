import React from 'react';
import { Field } from 'redux-form';
import { createTextMask } from 'redux-form-input-masks';
import BaseInput from 'components/form/inputs/base-input';
import { validateCnpj } from '../validation';

export default function CNPJField(props) {
  const cnpjMask = createTextMask({
    pattern: '99.999.999/9999-99'
  });

  const validate = props.validate ? [...props.validate, validateCnpj] : [validateCnpj];

  return <Field {...props} {...cnpjMask} component={BaseInput} type="text" validate={validate} />;
}
