import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Creators } from 'store/ducks/stores';
import { compose, bindActionCreators } from 'redux';

import { getEnabledStores } from 'store/selectors/stores';
import withUser from './withUser';
import withSuppliers from './withSuppliers';

function withStores(options) {
  return function(WrappedComponent) {
    function Hoc({ ...props }) {
      const {
        getStores,
        user: { email }
      } = props;

      useEffect(() => {
        getStores(email);
      }, [email, getStores]);

      return <WrappedComponent {...props} />;
    }

    function mapStateToProps(state, props) {
      if (options.activeOnly) {
        return { stores: getEnabledStores(state) };
      }
      return { stores: state.stores };
    }

    function mapDispatchToProps(dispatch, props) {
      return bindActionCreators(Creators, dispatch);
    }

    return compose(
      withSuppliers,
      withUser,
      connect(
        mapStateToProps,
        mapDispatchToProps
      )
    )(Hoc);
  };
}

export default withStores;
