import { SECURITY } from '../endpoints';
import { fetcher, flatFetcher } from '../fetcher';

import { loginInterceptor, insertOrderInterceptor as interceptor } from '../interceptor';

export const login = (user, password, interceptor) =>
  fetcher(
    `${SECURITY}/login`,
    {
      method: 'POST',
      body: JSON.stringify({ user, password })
    },
    interceptor
  );

export function loginAPI(user, password) {
  return fetcher(`${SECURITY}/login-api`, {
    method: 'POST',
    body: JSON.stringify({ user, password })
  });
}

export function logout(token) {
  return fetcher(`${SECURITY}/logout?token=${token}`, { method: 'GET' });
}

export const validateToken = token =>
  flatFetcher(`${SECURITY}/validate-token?token=${token}`, {
    method: 'GET'
  });

export const loginWithNotification = (user, password) => login(user, password, loginInterceptor);
