import FRAME_SHAPE_01 from 'assets/frameDesign/FRAME_SHAPE_01.jpg';
import FRAME_SHAPE_02 from 'assets/frameDesign/FRAME_SHAPE_02.jpg';
import FRAME_SHAPE_03 from 'assets/frameDesign/FRAME_SHAPE_03.jpg';
import FRAME_SHAPE_04 from 'assets/frameDesign/FRAME_SHAPE_04.jpg';
import FRAME_SHAPE_05 from 'assets/frameDesign/FRAME_SHAPE_05.jpg';
import FRAME_SHAPE_06 from 'assets/frameDesign/FRAME_SHAPE_06.jpg';
import FRAME_SHAPE_07 from 'assets/frameDesign/FRAME_SHAPE_07.jpg';
import FRAME_SHAPE_08 from 'assets/frameDesign/FRAME_SHAPE_08.jpg';
import ATTACHMENT from 'assets/frameDesign/ATTACHMENT.png';

export const frameShapes = [
  {
    id: 'FRAME_SHAPE_01',
    image: FRAME_SHAPE_01
  },
  {
    id: 'FRAME_SHAPE_02',
    image: FRAME_SHAPE_02
  },
  {
    id: 'FRAME_SHAPE_03',
    image: FRAME_SHAPE_03
  },
  {
    id: 'FRAME_SHAPE_04',
    image: FRAME_SHAPE_04
  },
  {
    id: 'FRAME_SHAPE_05',
    image: FRAME_SHAPE_05
  },
  {
    id: 'FRAME_SHAPE_06',
    image: FRAME_SHAPE_06
  },
  {
    id: 'FRAME_SHAPE_07',
    image: FRAME_SHAPE_07
  },
  {
    id: 'FRAME_SHAPE_08',
    image: FRAME_SHAPE_08
  },
  {
    id: 'TRACER',
    image: ATTACHMENT
  }
];
