import React from 'react';
import { Trans } from '@lingui/macro';

const regex = pattern => new RegExp(pattern);
const validate = (pattern, value) => regex(pattern).test(value);

const regexes = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  cnpj: /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/
};

export const testEmail = value => !validate(regexes.email, value);

export const testCnpj = value => !validate(regexes.cnpj, value) || !isValidCnpj(value);

export const validateEmail = value =>
  value && testEmail(value) ? <Trans>Endereço de email inválido</Trans> : undefined;

export const validateCnpj = value =>
  value && testCnpj(value) ? <Trans>CNPJ inválido</Trans> : undefined;

export const required = value =>
  (value && value.toString().trim()) || typeof value === 'number' ? (
    undefined
  ) : (
    <Trans>Campo obrigatório</Trans>
  );

export const isEqualTo = text => value =>
  value && value.trim() !== text ? <Trans>Digite "{text}"</Trans> : undefined;

export const passwordMatch = text => value =>
  value && value.trim() !== text ? <Trans>Senhas não coincidem</Trans> : undefined;

export const maxLength = max => value =>
  value && value.trim().length > max
    ? `Este campo deve possuir no máximo ${max} caracteres`
    : undefined;

export const minLength = min => value =>
  value && value.trim().length < min ? (
    <Trans>Este campo deve possuir no mínimo {min} caracteres</Trans>
  ) : (
    undefined
  );

export const mustBeBetween = (initialValue, finalValue, nullIsValid = false) => value => {
  const valueIsValid = nullIsValid
    ? value && (value > finalValue || value < initialValue)
    : value > finalValue || value < initialValue;
  return valueIsValid ? `O valor deve estar entre ${initialValue} e ${finalValue}` : undefined;
};

export const validatePositiveNumbers = (initialValue, nullIsValid = false) => value => {
  const valueIsValid = nullIsValid ? value && value < initialValue : value < initialValue;
  return valueIsValid ? <Trans>O valor deve ser maior que zero</Trans> : undefined;
};

export const isMultipleOf = multiple => value =>
  value % multiple !== 0 ? `O valor deve ser múltiplo de ${multiple}` : undefined;

const isValidCnpj = value => {
  let cnpj = value;
  const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  if ((cnpj = cnpj.replace(/[^\d]/g, '')).length !== 14) return false;

  if (/0{14}/.test(cnpj)) return false;

  for (var i = 0, n = 0; i < 12; n += cnpj[i] * b[++i]);
  if (cnpj[12] !== ((n %= 11) < 2 ? 0 : 11 - n).toString()) return false;

  for (i = 0, n = 0; i <= 12; n += cnpj[i] * b[i++]);
  if (cnpj[13] !== ((n %= 11) < 2 ? 0 : 11 - n).toString()) return false;

  return true;
};

export const requiredAutocomplete = options => inputValue => {
  const idList = options.map(({ id }) => id);
  const isInputValue = inputValue === undefined || inputValue === null;

  return idList.includes(inputValue) || isInputValue ? (
    <Trans>Selecione uma opção válida</Trans>
  ) : (
    undefined
  );
};

export const maxFileSize = fileSize => file =>
  file.size && file.size < fileSize ? undefined : <Trans>O arquivo deve ter no máximo 6Mb.</Trans>;

export function noLateDate(newTime) {
  const newDate = new Date(newTime);
  const currentDate = new Date();
  const isLate = +newDate < +currentDate;
  return !isLate ? undefined : <Trans>A nova data não pode ser menor do que a atual</Trans>;
}
