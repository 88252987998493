import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Trans } from '@lingui/macro';

export class Overlay extends Component {
  render() {
    const { classes, loadingText, visible } = this.props;
    return (
      <Modal className={classes.modal} onClose={() => {}} open={visible}>
        <div className={classes.content}>
          <CircularProgress color="inherit" />
          <Typography variant="body1" color="inherit" className={classes.text}>
            {loadingText || <Trans>Carregando...</Trans>}
          </Typography>
        </div>
      </Modal>
    );
  }

  static propTypes = {
    classes: PropTypes.object,
    loadingText: PropTypes.object,
    visible: PropTypes.bool.isRequired
  };
}

function styles(theme) {
  return {
    modal: {
      alignItems: 'center',
      color: 'white',
      display: 'flex',
      justifyContent: 'center'
    },
    content: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
      outlineWidth: 0
    },
    text: {
      animation: `blink 2s ${theme.transitions.easing.easeInOut} infinite`,
      marginTop: theme.spacing.unit * 2
    },
    '@keyframes blink': {
      '0%': {
        opacity: '0'
      },
      '50%': {
        opacity: '1'
      },
      '100%': {
        opacity: '0'
      }
    }
  };
}

function mapStateToProps(state) {
  return {
    visible: state.overlay.visible,
    loadingText: state.overlay.loadingText
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Overlay);
