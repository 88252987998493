import { withStyles } from '@material-ui/core/styles';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import UploadCSV from './UploadCSV';
import styles from './styles';

const selector = formValueSelector('FastAttach');

function mapStateToProps(state) {
  return {
    formValues: {
      file: selector(state, 'file'),
      description: selector(state, 'description')
    }
  };
}

export default compose(
  connect(mapStateToProps),
  reduxForm({ form: 'FastAttach' }),
  withStyles(styles)
)(UploadCSV);
