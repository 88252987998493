import { Trans } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import { withTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import React, { useEffect, useState } from 'react';
import HiddenField from 'components/form/fields/hidden';
import ItemList from 'components/item-list';
import types from 'utils/types';
import { getStoreSupplierResources } from 'services/stores';
import AddProduct from './AddProduct';

function ProductFields({ actions, order, ...props }) {
  const { fields, theme, classes } = props;
  const isSendOrder = order && order.status === 'ORDERED';
  const { formValues } = props;
  const [colors, setColors] = useState([]);

  const getResources = async ({ sentToSupplier }) => {
    if (sentToSupplier) {
      const result = await getStoreSupplierResources(sentToSupplier, 'COLOR');
      const filteredResult = result.content.filter(
        item => item.colorType === 'BOTH' || item.colorType === 'COLORING'
      );
      setColors(filteredResult);
    }
  };

  useEffect(() => {
    if (formValues.sentToSupplier) getResources({ sentToSupplier: formValues.sentToSupplier });
  }, [formValues.sentToSupplier]);

  const openDialog = key => event =>
    actions('addEditProduct', { order: formValues, productKey: key });

  const erpSoftwareName =
    formValues && formValues.store && formValues.store.erpSoftwareProviderName;

  return (
    <>
      {fields.map((item, key) => {
        const { unitOfMeasure, quantity, description, sku, type, itemSequence } = fields.get(key);
        const translatedType = types.find(item => item.value === type).text;
        const itemEyesPrescription =
          formValues.prescription.eyesPrescription.filter(
            item => item.itemSequence === itemSequence
          ).length > 0;

        return (
          <>
            <Grid item xs={12}>
              <Paper>
                <ListItem>
                  {formValues.items &&
                    formValues.items
                      .filter(item => item.itemSequence === itemSequence)
                      .map(data => (
                        <ItemList
                          data={{ ...data, colors: colors.find(cor => cor.sku === data.color) }}
                        />
                      ))}
                  {itemEyesPrescription ? (
                    <ListItemSecondaryAction className={classes.secondaryButton}>
                      <Tooltip
                        title={
                          <Trans>
                            Item está sendo utilizado em pelo menos um dos olhos, para remover ou
                            editar o item é necessário desvincular a sua utilização
                          </Trans>
                        }
                        placement="left"
                      >
                        <IconButton color="primary" style={{ cursor: 'default' }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  ) : (
                    isSendOrder &&
                    erpSoftwareName !== 'Grupo Acert' && (
                      <ListItemSecondaryAction className={classes.secondaryButton}>
                        <IconButton color="primary" onClick={openDialog(key)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            const items = formValues.items
                              .map((item, index) => index !== key && item)
                              .filter(Boolean);

                            props.receiveOrder({
                              ...formValues,
                              items
                            });
                          }}
                          style={{ color: theme.palette.error.main }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )
                  )}
                </ListItem>
                <HiddenField name={`${item}`} />
              </Paper>
            </Grid>
          </>
        );
      })}
      {isSendOrder && <AddProduct actions={actions} order={formValues} />}
    </>
  );
}

export default withTheme()(ProductFields);
