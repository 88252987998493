import { debounce } from 'lodash';
import React, { useState } from 'react';
import InputSearch from 'components/form/inputs/search-input';
import { compose } from 'redux';
import withQuery from 'utils/query/withQuery';
import withRouter from 'react-router-dom/withRouter';
import { Trans } from '@lingui/macro';
import { connect } from 'react-redux';
import { Creators } from 'store/ducks/orders';
import { searchOrder, fetchOrders } from 'services/orders';
import ReactDOMServer from 'react-dom/server';
import { Creators as OverlayCreators } from 'store/ducks/overlay';

function Search({ ...props }) {
  const { query, location, overlay, setOrders } = props;
  const [filter, setFilter] = useState(query.params.textFilter || '');
  const { pathname } = location;
  const { store } = query.params;
  const isHome = pathname === '/home';
  const isIntegration = pathname === '/integration';
  const isHistoricLane = pathname === '/historic-lane';
  const placeholder = isIntegration
    ? ReactDOMServer.renderToString(<Trans>Pesquisar recursos...</Trans>)
    : ReactDOMServer.renderToString(<Trans>Pesquisar pedidos...</Trans>);

  return (
    <form onSubmit={handleSubmit} {...props}>
      <InputSearch
        onChange={handleChange}
        name="textFilter"
        placeholder={placeholder}
        value={filter}
      />
    </form>
  );

  async function resetOrders() {
    const ordersList = await Promise.all([
      fetchOrders(store, 'ORDERED'),
      fetchOrders(store, 'ON_SUPPLIER'),
      fetchOrders(store, 'ASSEMBLY_LAB'),
      fetchOrders(store, 'AWAITING_STORE'),
      fetchOrders(store, 'RECEIVED')
    ])
      .then(orders => orders.map(order => order.content))
      .then(orders => orders.filter(order => order && orders.length > 0))
      .then(orders => {
        if (orders && orders.length > 0)
          return orders.reduce((accumulator, order) => accumulator.concat(order));
      });
    if (!ordersList) {
      throw new Error(`Nenhum pedido encontrado para as lojas de id: ${store}`);
      overlay.hide();
    }
    await setOrders(ordersList);
    overlay.hide();
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const { history } = props;
    const value = event.target[0].value;
    if (value && store) {
      const filterOrder = await searchOrder(store, value);
      setOrders([]);
      setOrders(filterOrder.content);
    } else {
      setOrders([]);
      overlay.show(<Trans>Aguarde, processando...</Trans>);
      if (store) resetOrders();
      else overlay.hide();
    }
    query.set({
      property: 'textFilter',
      value
    });

    if (isHistoricLane) {
      history.push({
        pathname: '/home',
        search: `textFilter=${value}`
      });
    } else if (!isIntegration) {
      history.push({
        pathname: '/home',
        search: query.stringify()
      });
    }
  }

  async function searchOrders(storeId, value) {
    if (value) {
      const filterOrder = await searchOrder(storeId, value);
      setOrders([]);
      setOrders(filterOrder.content);
    } else {
      setOrders([]);
      overlay.show(<Trans>Aguarde, processando...</Trans>);
      if (storeId) resetOrders();
      else overlay.hide();
    }
  }

  function handleChange(event) {
    event.preventDefault();

    const {
      target: { value }
    } = event;

    const waitAndSet = debounce(query.set, 600);
    const awaitAndSearch = debounce(() => searchOrders(store, value), 900);

    setFilter(value);

    if (isHome || isIntegration) {
      if (store) {
        awaitAndSearch();
      }
      waitAndSet({ property: 'textFilter', value: event.target.value });
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setOrders: ordersList => dispatch(Creators.receiveOrders(ordersList)),
    overlay: {
      show: loadingText => dispatch(OverlayCreators.showOverlay({ loadingText })),
      hide: _ => dispatch(OverlayCreators.hideOverlay())
    }
  };
}

export default compose(connect(null, mapDispatchToProps), withQuery, withRouter)(Search);
