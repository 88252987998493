import { API_URL_ZIPCODE } from 'services/endpoints';

export async function searchAddressByZipCode(zipCode) {
  try {
    const cleanZipCode = zipCode.replaceAll('_', '');

    if (!zipCode || cleanZipCode.length !== 9) return;

    const url = API_URL_ZIPCODE(zipCode);

    const response = await fetch(url, {
      method: 'GET'
    });

    return response;
  } catch (error) {
    console.error(error);
  }
}
