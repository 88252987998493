import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FRAME_SHAPE_01 from 'assets/frameDesign/FRAME_SHAPE_01.jpg';
import FRAME_SHAPE_02 from 'assets/frameDesign/FRAME_SHAPE_02.jpg';
import FRAME_SHAPE_03 from 'assets/frameDesign/FRAME_SHAPE_03.jpg';
import FRAME_SHAPE_04 from 'assets/frameDesign/FRAME_SHAPE_04.jpg';
import FRAME_SHAPE_05 from 'assets/frameDesign/FRAME_SHAPE_05.jpg';
import FRAME_SHAPE_06 from 'assets/frameDesign/FRAME_SHAPE_06.jpg';
import FRAME_SHAPE_07 from 'assets/frameDesign/FRAME_SHAPE_07.jpg';
import FRAME_SHAPE_08 from 'assets/frameDesign/FRAME_SHAPE_08.jpg';
import ATTACHMENT from 'assets/frameDesign/ATTACHMENT.png';
import { Trans } from '@lingui/macro';

export const shapeText = shapeValue => {
  let text;

  switch (shapeValue) {
    case 'FRAME_SHAPE_01':
      text = renderShapeText(FRAME_SHAPE_01, 'Modelo 01');
      break;
    case 'FRAME_SHAPE_02':
      text = renderShapeText(FRAME_SHAPE_02, 'Modelo 02');
      break;
    case 'FRAME_SHAPE_03':
      text = renderShapeText(FRAME_SHAPE_03, 'Modelo 03');
      break;
    case 'FRAME_SHAPE_04':
      text = renderShapeText(FRAME_SHAPE_04, 'Modelo 04');
      break;
    case 'FRAME_SHAPE_05':
      text = renderShapeText(FRAME_SHAPE_05, 'Modelo 05');
      break;
    case 'FRAME_SHAPE_06':
      text = renderShapeText(FRAME_SHAPE_06, 'Modelo 06');
      break;
    case 'FRAME_SHAPE_07':
      text = renderShapeText(FRAME_SHAPE_07, 'Modelo 07');
      break;
    case 'FRAME_SHAPE_08':
      text = renderShapeText(FRAME_SHAPE_08, 'Modelo 08');
      break;
    default:
      if (shapeValue !== '') {
        text = renderShapeText(ATTACHMENT, 'Arquivo tracer');
      } else {
        text = 'Valor não reconhecido';
      }
  }

  return text;
};

function renderShapeText(img, text) {
  return (
    <div style={{ flexGrow: '1' }}>
      <Grid container spacing={2}>
        <Grid item>
          <img
            style={{ maxWidth: text === 'Arquivo tracer' ? '25px' : '50px', marginRight: '12px' }}
            src={img}
            alt={text}
          />
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography variant="body2" gutterBottom>
                {text}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export const typeText = [
  {
    value: 'RIMLESS',
    text: (
      <Typography variant="body2" gutterBottom>
        <Trans>Três peças</Trans>
      </Typography>
    )
  },
  {
    value: 'NYLON',
    text: (
      <Typography variant="body2" gutterBottom>
        <Trans>Nylon</Trans>
      </Typography>
    )
  },
  {
    value: 'FULL_RIM_PLASTIC_METAL',
    text: (
      <Typography variant="body2" gutterBottom>
        <Trans>Aro fechado metal</Trans>
      </Typography>
    )
  },
  {
    value: 'FULL_RIM_PLASTIC_FLEXIBLE',
    text: (
      <Typography variant="body2" gutterBottom>
        <Trans>Aro fechado flexível</Trans>
      </Typography>
    )
  },
  {
    value: 'FULL_RIM_PLASTIC_STRONG',
    text: (
      <Typography variant="body2" gutterBottom>
        <Trans>Aro fechado rígido</Trans>
      </Typography>
    )
  }
];
