import { Trans } from '@lingui/macro';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useCallback, useEffect, useState } from 'react';
import MVA from 'assets/helperVisual/ava.png';
import DMA from 'assets/helperVisual/dma.png';
import MHA from 'assets/helperVisual/mha.png';
import PONTE from 'assets/helperVisual/ponte.png';
import { SelectInput } from 'components/form/Input';
import Popper from 'components/popper';
import FormSection from 'components/form/wrappers/form-section';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import { DistanceBetweenLensesField } from 'components/form/fields/frame/distance-between-lenses';
import { DiameterField } from 'components/form/fields/frame/diameter';
import { HboxField } from 'components/form/fields/frame/hbox';
import { VboxField } from 'components/form/fields/frame/vbox';
import { LargestDiameterField } from 'components/form/fields/frame/largest-diameter';
import { SelectShape } from 'components/form/fields/frame/shape';
import { Field } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import TextField from 'components/form/fields/text';
import NumberNegativeField from 'components/form/fields/numberNegative';
import FrameDesign from 'components/frame-design';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import warning from 'assets/images/warning.png';
import SelectField from 'components/form/fields/select';
import { getStoreSupplierResources } from 'services/stores';

const FrameSwitch = field => (
  <FormControlLabel
    control={
      <Switch checked={field.input.value} onChange={field.input.onChange} color="secondary" />
    }
    label={<Trans>A armação será enviada para montagem</Trans>}
  />
);

const ModelSwitch = field => (
  <FormControlLabel
    control={
      <Switch checked={field.input.value} onChange={field.input.onChange} color="secondary" />
    }
    label={<Trans>Alteração Modelo</Trans>}
  />
);

const FrameSwitchDisabled = field => (
  <FormControlLabel
    control={
      <Switch
        checked={field.input.value}
        disabled
        onChange={field.input.onChange}
        color="secondary"
      />
    }
    label={<Trans>A armação será enviada para montagem</Trans>}
  />
);

const ModelSwitchDisabled = field => (
  <FormControlLabel
    control={
      <Switch
        checked={field.input.value}
        disabled
        onChange={field.input.onChange}
        color="secondary"
      />
    }
    label={<Trans>Alteração Modelo</Trans>}
  />
);

export default function Frame({
  designs,
  formValues,
  removeStep,
  attachedFiles,
  negativeNumber,
  change
}) {
  const [popper, setPopper] = useState();

  const [colors, setColors] = useState([]);
  const supplierColors = useCallback(async () => {
    if (formValues.sentToSupplier) {
      const result = await getStoreSupplierResources(formValues.sentToSupplier, 'COLOR');
      const filteredResult = result.content.filter(
        item => item.colorType === 'BOTH' || item.colorType === 'FRAME'
      );
      setColors(filteredResult);
    }
  }, [formValues]);

  useEffect(() => {
    supplierColors();
  }, [supplierColors]);

  const getPopperName = popper => {
    if (!popper) return null;

    const popperWords = popper.name.split('.');
    const popperWordsLastIndex = popperWords.length - 1;
    const popperName = popperWords[popperWordsLastIndex];
    return popperName;
  };

  const showFrameFields =
    formValues &&
    formValues.prescription &&
    formValues.prescription.frame &&
    formValues.prescription.frame.sendFrame;

  useEffect(() => {
    if (showFrameFields === false) {
      change('prescription.frame.color', '');
      change('prescription.frame.designer', '');
      change('prescription.frame.reference', '');
      change('prescription.frame.changeModel', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFrameFields]);

  const showModelFields = formValues.prescription.frame.changeModel;

  useEffect(() => {
    if (showModelFields === false) {
      change('prescription.frame.nasal', '');
      change('prescription.frame.temporal', '');
      change('prescription.frame.higher', '');
      change('prescription.frame.bottom', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModelFields]);

  const popperName = getPopperName(popper);

  const enablePopper = event => setPopper(event.currentTarget);
  const disablePopper = () => setPopper();

  const erpSoftwareName =
    formValues && formValues.store && formValues.store.erpSoftwareProviderName;

  return (
    <FormSection name="prescription.frame">
      <Grid container spacing={24}>
        <Grid item xs={12} md={3}>
          <VboxField
            fullWidth
            disabled={erpSoftwareName === 'Grupo Acert'}
            className={removeStep}
            label={<Trans>M.V.A</Trans>}
            name="vbox"
            onBlur={disablePopper}
            onFocus={enablePopper}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <HboxField
            fullWidth
            disabled={erpSoftwareName === 'Grupo Acert'}
            className={removeStep}
            label={<Trans>M.H.A</Trans>}
            name="hbox"
            onBlur={disablePopper}
            onFocus={enablePopper}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <DistanceBetweenLensesField
            fullWidth
            disabled={erpSoftwareName === 'Grupo Acert'}
            className={removeStep}
            label={<Trans>Ponte</Trans>}
            name="distanceBetweenLenses"
            onBlur={disablePopper}
            onFocus={enablePopper}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <LargestDiameterField
            fullWidth
            disabled={erpSoftwareName === 'Grupo Acert'}
            className={removeStep}
            label={<Trans>D.M.A</Trans>}
            name="largestDiameter"
            onBlur={disablePopper}
            onFocus={enablePopper}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SelectInput fullWidth name="type" label={<Trans>Tipo</Trans>}>
            {types.map(({ value, text }) => (
              <MenuItem disabled={erpSoftwareName === 'Grupo Acert'} key={value} value={value}>
                {text}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectShape
            label={<Trans>Desenho da armação</Trans>}
            name="shape"
            fullWidth
            disabled={erpSoftwareName === 'Grupo Acert'}
            data={designs}
          />
        </Grid>
        <Grid item xs={12} md={4} className={removeStep}>
          <DiameterField
            disabled={erpSoftwareName === 'Grupo Acert'}
            fullWidth
            label={<Trans>Diâmetro</Trans>}
            name="assemblyDiameter"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          {erpSoftwareName !== 'Grupo Acert' ? (
            <>
              <Field component={FrameSwitch} name="sendFrame" />
              <Field
                component={showFrameFields ? ModelSwitch : ModelSwitchDisabled}
                name="changeModel"
              />
            </>
          ) : (
            <>
              <Field component={FrameSwitchDisabled} name="sendFrame" />
              <Field component={ModelSwitchDisabled} name="changeModel" />
            </>
          )}
        </Grid>

        {showFrameFields ? (
          <>
            <Grid
              item
              xs={12}
              md={4}
              style={{ maxHeight: '73px', marginTop: '8px', display: 'flex' }}
            >
              <SelectField fullWidth label={<Trans>Cor</Trans>} name="color">
                {colors.map(({ description, sku }) => (
                  <MenuItem disabled={erpSoftwareName === 'Grupo Acert'} key={sku} value={sku}>
                    {description}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={erpSoftwareName === 'Grupo Acert'}
                fullWidth
                label={<Trans>Grife</Trans>}
                name="designer"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={erpSoftwareName === 'Grupo Acert'}
                fullWidth
                label={<Trans>Referência</Trans>}
                name="reference"
              />
            </Grid>
          </>
        ) : (
          undefined
        )}

        {showModelFields ? (
          <>
            <Grid item xs={12} md={3}>
              <NumberNegativeField
                disabled={erpSoftwareName === 'Grupo Acert'}
                fullWidth
                label={<Trans>Nasal</Trans>}
                step={1}
                name="nasal"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <NumberNegativeField
                disabled={erpSoftwareName === 'Grupo Acert'}
                fullWidth
                label={<Trans>Temporal</Trans>}
                name="temporal"
                step={1}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <NumberNegativeField
                disabled={erpSoftwareName === 'Grupo Acert'}
                fullWidth
                label={<Trans>Superior</Trans>}
                name="higher"
                step={1}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <NumberNegativeField
                disabled={erpSoftwareName === 'Grupo Acert'}
                fullWidth
                label={<Trans>Inferior</Trans>}
                name="bottom"
                step={1}
              />
            </Grid>
          </>
        ) : (
          undefined
        )}
      </Grid>

      <Popper open={popper} anchor={popper}>
        <Grow in={popper}>
          <Paper style={{ maxWidth: 200, overflow: 'hidden' }}>
            {popperName === 'vbox' && (
              <img style={{ maxWidth: '100%' }} src={MVA} alt="helper hbox" />
            )}

            {popperName === 'hbox' && (
              <img style={{ maxWidth: '100%' }} src={MHA} alt="helper dma" />
            )}

            {popperName === 'largestDiameter' && (
              <img style={{ maxWidth: '100%' }} src={DMA} alt="helper dma" />
            )}

            {popperName === 'distanceBetweenLenses' && (
              <img style={{ maxWidth: '100%' }} src={PONTE} alt="helper vbox" />
            )}
          </Paper>
        </Grow>
      </Popper>
      {attachedFiles &&
        attachedFiles.length > 0 &&
        attachedFiles.filter(
          item => item.leftRadius !== undefined || item.rightRadius !== undefined
        ).length > 0 && (
          <Card style={{ minHeight: 335 }}>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={6}>
                <CardHeader
                  titleTypographyProps={{ variant: 'h6' }}
                  title="Olho Esquerdo"
                  style={{ textAlign: 'center' }}
                />
                <CardContent style={{ textAlign: 'center', maxWidth: '50%', margin: '0 auto' }}>
                  {attachedFiles.filter(
                    file => file.leftRadius !== undefined && file.leftRadius !== ''
                  )[0] ? (
                    <FrameDesign
                      frameDesign={attachedFiles
                        .filter(file => file.leftRadius !== undefined && file.leftRadius !== '')[0]
                        .leftRadius.replace(/;/g, ',')}
                    />
                  ) : (
                    <>
                      <img style={{ maxWidth: '100%' }} src={warning} alt="warning" />
                      <Typography variant="body2" gutterBottom>
                        Dados não encontrados para o olho esquerdo.
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CardHeader
                  titleTypographyProps={{ variant: 'h6' }}
                  title="Olho Direito"
                  style={{ textAlign: 'center' }}
                />
                <CardContent style={{ textAlign: 'center', maxWidth: '50%', margin: '0 auto' }}>
                  {attachedFiles.filter(
                    file => file.rightRadius !== undefined && file.rightRadius !== ''
                  )[0] ? (
                    <FrameDesign
                      frameDesign={attachedFiles
                        .filter(
                          file => file.rightRadius !== undefined && file.rightRadius !== ''
                        )[0]
                        .rightRadius.replace(/;/g, ',')}
                    />
                  ) : (
                    <>
                      <img style={{ maxWidth: '100%' }} src={warning} alt="warning" />
                      <Typography variant="body2" gutterBottom>
                        Dados não encontrados para o olho direito.
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        )}
    </FormSection>
  );
}

const types = [
  { value: 'FULL_RIM_PLASTIC_STRONG', text: <Trans>Aro fechado rígido</Trans> },
  {
    value: 'FULL_RIM_PLASTIC_FLEXIBLE',
    text: <Trans>Aro fechado flexível</Trans>
  },
  { value: 'FULL_RIM_PLASTIC_METAL', text: <Trans>Aro fechado metal</Trans> },
  { value: 'RIMLESS', text: <Trans>Três peças</Trans> },
  { value: 'NYLON', text: <Trans>Nylon</Trans> }
];
