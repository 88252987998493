// Stores
export const STORES_ENDPOINT = process.env.REACT_APP_STORES;

// Users
export const USERS_ENDPOINT = process.env.REACT_APP_USERS;

// Auth
export const SECURITY = process.env.REACT_APP_SECURITY;

// Orders
export const ORDERS_ENDPOINT = process.env.REACT_APP_ORDERS;

// Historical
export const HISTORICAL_ENDPOINT = process.env.REACT_APP_HISTORICAL;

// Suppliers
export const SUPPLIERS_ENDPOINT = process.env.REACT_APP_SUPPLIERS;

export const API_URL_ZIPCODE = zipCode =>
  `${process.env.REACT_APP_API_URL_ZIPCODE}/${zipCode}/json/`;
export const PROFILE_IMAGE_URL = process.env.REACT_APP_PROFILE_IMAGE_URL;
