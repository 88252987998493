import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';

import withHelperText from '../helper-text/withHelperText';
import styles from './styles';
import Autocomplete from './Autocomplete';

export default compose(
  withHelperText,
  withStyles(styles)
)(Autocomplete);
