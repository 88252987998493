import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import FormControl from 'components/form/wrappers/form-control';
import Button from 'components/buttons/button';

import EmailField from 'components/form/fields/email';
import PasswordField from 'components/form/fields/password';
import { required } from 'components/form/fields/validation';

class Access extends Component {
  render() {
    const { handleSubmit } = this.props;

    if (this.props.isUserSigned) {
      this.props.overlay.hide();
      return <Redirect to="/choose-store" />;
    }

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <EmailField
          fullWidth
          name="user"
          type="email"
          label={<Trans>Email*</Trans>}
          validate={[required]}
        />

        <PasswordField
          name="password"
          fullWidth
          label={<Trans>Senha*</Trans>}
          validate={[required]}
        />

        <FormControl fullWidth margin="dense">
          <Button variant="contained" color="primary" type="submit">
            <Trans>Login</Trans>
          </Button>
        </FormControl>

        <Link to="/login/register" style={{ textDecoration: 'none' }}>
          <FormControl fullWidth margin="dense">
            <Button variant="text">
              <Trans>Criar conta</Trans>
            </Button>
          </FormControl>
        </Link>

        <Link to="/login/reset" style={{ textDecoration: 'none' }}>
          <FormControl fullWidth margin="dense">
            <Button color="secondary" variant="text">
              <Trans>Esqueceu sua senha?</Trans>
            </Button>
          </FormControl>
        </Link>
      </form>
    );
  }

  submit = values => {
    const { user, password } = values;
    this.props.overlay.show(<Trans>Realizando Login...</Trans>);
    this.props.login(user, password);
  };
}

export default Access;
