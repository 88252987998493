import { Trans } from '@lingui/macro';
import Chip from '@material-ui/core/Chip';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import Drawer from 'components/drawer';
import IntegrationFilterForm from 'forms/integration/filters';
import React, { useState } from 'react';
import { compose } from 'redux';
import withStores from 'store/providers/withStores';
import withSuppliers from 'store/providers/withSuppliers';
import types from 'utils/types';
import lensTypeList from 'utils/types/lensType';
import { withIntegrations } from '../provider';

function Filters({ stores, suppliers, query, ...props }) {
  const { storeId, resourceType, supplierId, lensType } = query.params;
  const isFormSet = Boolean(resourceType && storeId && supplierId);
  const selectedType = types.find(listType => listType.value === resourceType);
  const selectedLensType = lensTypeList.find(type => type.value === lensType);

  const [isDrawerOpen, setDrawerOpen] = useState(!isFormSet);

  return (
    <>
      <Drawer open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          <Trans>Filtros de integração</Trans>
        </Typography>

        <Fade in={isFormSet}>
          <Typography variant="caption" color="textSecondary">
            <Trans>Selecione tipo, loja e fornecedor para integrar</Trans>
          </Typography>
        </Fade>

        <Grid container style={{ marginTop: 16 }}>
          <Grid item xs={12}>
            <IntegrationFilterForm stores={stores} suppliers={suppliers} />
          </Grid>
        </Grid>
      </Drawer>
      <Grid container alignItems="center" spacing={24}>
        <Grid item>
          <Tooltip title={<Trans>Filtrar integrações</Trans>}>
            <IconButton color="primary" onClick={() => setDrawerOpen(true)}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        {stores.length > 0 && storeId && (
          <Grid item>
            <Chip
              color="primary"
              label={stores.find(userStore => userStore.store.id === +storeId).store.tradingName}
            />
          </Grid>
        )}

        {resourceType && (
          <Grid item>
            <Chip color="primary" label={`${selectedType.text.props.id}`} />
          </Grid>
        )}

        {lensType && (
          <Grid item>
            <Chip color="primary" label={`${selectedLensType.text.props.id}`} />
          </Grid>
        )}

        {suppliers.length > 0 && supplierId && (
          <Grid item>
            <Chip
              color="primary"
              label={suppliers.find(storeSupplier => storeSupplier.id === +supplierId).companyName}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default compose(withStores({ activeOnly: true }), withSuppliers, withIntegrations)(Filters);
