import PropTypes from 'prop-types';
import React from 'react';
import HistoricLaneTable from './partials/HistoricLaneTable';

const propTypes = {
  filters: PropTypes.shape({
    date: PropTypes.string,
    store: PropTypes.arrayOf(PropTypes.string),
    period: PropTypes.string
  })
};

export default function Table(props) {
  const { filters } = props;

  return (
    <>
      <HistoricLaneTable filters={filters} />
    </>
  );
}

Table.propTypes = propTypes;
