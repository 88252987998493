import { Trans } from '@lingui/macro';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Button from 'components/buttons/button';
import AutocompleteField from 'components/form/fields/autocomplete';
import { maxLengthAutocomplete } from 'components/form/fields/validation';
import FormControl from 'components/form/wrappers/form-control';
import FormSection from 'components/form/wrappers/form-section';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { changeStoreProfile, getUserStores, patchChangeStore, addStore } from 'services/stores';
import BillingAddress from './partials/BillingAddress';
import Configuration from './partials/Configuration';
import GroupStore from './partials/GroupStore';
import Information from './partials/Information';
import PlanVersionStore from './partials/PlanVersionStore';
import ResourceStore from './partials/ResourceStore';
import TypeStore from './partials/TypeStore';

class AddEditStore extends Component {
  componentWillMount = async () => {
    await this.getStores();
  };

  render() {
    const { handleSubmit, classes, storeId } = this.props;
    const { stores, typeStoreDisabled } = this.state;
    const isStores = stores && stores.length > 0;
    const hasStoreInformation = Boolean(this.props.initialValues.information);

    const erpSoftware = [
      {
        label: <Trans>Grupo Acert</Trans>,
        id: 'Grupo Acert',
        value: 'Grupo Acert'
      },
      {
        label: <Trans>Desempenho</Trans>,
        id: 'DESEMPENHO',
        value: 'DESEMPENHO'
      },
      {
        label: <Trans>LINX</Trans>,
        id: 'LINX',
        value: 'LINX'
      }
    ];

    const planVersions = [
      {
        label: <Trans>START</Trans>,
        id: 'START',
        value: 'START'
      },
      {
        label: <Trans>ADVANCED</Trans>,
        id: 'ADVANCED',
        value: 'ADVANCED'
      },
      {
        label: <Trans>PREMIUM</Trans>,
        id: 'PREMIUM',
        value: 'PREMIUM'
      },
      {
        label: <Trans>MASTER</Trans>,
        id: 'MASTER',
        value: 'MASTER'
      }
    ];

    const typeStore = [
      {
        label: <Trans>Loja única sem escritório</Trans>,
        id: 'NO_BACKOFFICE',
        value: 'NO_BACKOFFICE'
      },
      {
        label: <Trans>Loja escritório</Trans>,
        id: 'BACKOFFICE',
        value: 'BACKOFFICE'
      },
      {
        label: <Trans>Loja pertencente a um escritório</Trans>,
        id: 'WITH_BACKOFFICE',
        value: 'WITH_BACKOFFICE'
      }
    ];

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <FormSection name="information" title={<Trans>Informações de contato</Trans>}>
              <Information disableCNPJ={hasStoreInformation} />
            </FormSection>
          </Grid>

          <Grid item xs={12}>
            <FormSection name="billingAddress" title={<Trans>Endereço de cobrança</Trans>}>
              <BillingAddress zipCodeCallback={this.handleZipCode} />
            </FormSection>
          </Grid>
          {hasStoreInformation && (
            <Grid item xs={12}>
              <FormSection name="configuration" title={<Trans>Configurações</Trans>}>
                <Configuration />
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.CrtlNumber}
                        onChange={this.handleChange}
                        name="enableControlNumber"
                        color="primary"
                      />
                    }
                    label="Obriga preenchimento do campo controle"
                  />
                </FormControl>
              </FormSection>
            </Grid>
          )}
          {isStores && (
            <>
              <Grid item xs={12} sm={6}>
                <FormSection
                  name="typeStoreValue"
                  title={
                    <>
                      <Trans>Tipo da loja</Trans>
                      <Tooltip
                        title={
                          <Trans>
                            O tipo informado determina se a loja pode compartilhar recursos,
                            utilizará recursos de outra loja ou se será loja única.
                          </Trans>
                        }
                        placement="right"
                      >
                        <InfoIcon style={{ position: 'relative', top: 5, left: 10 }} />
                      </Tooltip>
                    </>
                  }
                >
                  <TypeStore typeStore={typeStore} />
                </FormSection>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormSection
                  name="resources"
                  title={<Trans>Selecione uma loja para utilizar seus recursos</Trans>}
                >
                  <ResourceStore
                    stores={stores}
                    storeId={storeId}
                    typeStoreDisabled={typeStoreDisabled}
                  />
                </FormSection>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <FormSection name="plan" title={<Trans>Informe o plano contratado</Trans>}>
                  <PlanVersionStore planVersions={planVersions} />
                </FormSection>
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <FormSection
                  name="groupStore"
                  title={
                    <>
                      <Trans>Loja que referencia toda a organização</Trans>
                      <Tooltip
                        title={
                          <Trans>
                            Esta loja fará o papel de grupo a fim de obter descontos no Acert
                            Conecta por quantidade de lojas contratadas
                          </Trans>
                        }
                        placement="right"
                      >
                        <InfoIcon style={{ position: 'relative', top: 5, left: 10 }} />
                      </Tooltip>
                    </>
                  }
                >
                  <GroupStore stores={stores} storeId={storeId} />
                </FormSection>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormSection
                  name="erpSoftwareProviderName"
                  title={<Trans>Informe o provedor de software ERP</Trans>}
                >
                  <AutocompleteField
                    fullWidth
                    isCreatable
                    label=""
                    hideHelpers
                    name="erpSoftware"
                    options={erpSoftware.map(item => ({
                      label: item.label,
                      id: item.id,
                      value: item.value
                    }))}
                    validate={[erpMaxLength]}
                  />
                </FormSection>
              </Grid>
            </>
          )}
        </Grid>

        <FormControl className={classes.confirmButton}>
          <Button type="submit">
            <Trans>Confirmar</Trans>
          </Button>
        </FormControl>
      </form>
    );
  }

  state = {
    stores: [],
    CrtlNumber: false,
    typeStoreDisabled: true
  };

  handleChange = () => {
    this.setState({ CrtlNumber: !this.state.CrtlNumber });
  };

  componentDidUpdate = prevProps => {
    const { typeStoreDisabled } = this.state;
    if (
      this.props.formValues &&
      this.props.formValues.typeStoreValue &&
      prevProps.formValues &&
      prevProps.formValues.typeStoreValue
    ) {
      if (prevProps.formValues.typeStoreValue.type !== this.props.formValues.typeStoreValue.type) {
        if (!typeStoreDisabled && this.props.formValues.typeStoreValue.type === 'WITH_BACKOFFICE') {
          this.setState({ typeStoreDisabled: true });
        } else {
          this.setState({ typeStoreDisabled: false });
        }
      }
    }
  };

  componentDidMount = () => {
    const { initialValues } = this.props;
    if (initialValues.enableControlNumber && initialValues.enableControlNumber !== null) {
      this.setState({ CrtlNumber: true });
    }
    if (
      (initialValues.typeStoreValue.type &&
        initialValues.typeStoreValue.type === 'NO_BACKOFFICE') ||
      initialValues.typeStoreValue.type === 'BACKOFFICE'
    ) {
      this.setState({ typeStoreDisabled: false });
    }
  };

  submit = values => {
    const { notify, storeData } = this.props;
    if (storeData && storeData.productPlans && storeData.productPlans.length > 0) {
      this.registerAndEditStore(notify, values);
    } else {
      this.registerAndEditStore(notify, values);
    }
  };

  registerAndEditStore = (notify, values) => {
    if (values.typeStoreValue.type === 'WITH_BACKOFFICE' && !values.resources) {
      notify({
        variant: 'error',
        message: <Trans>Necessário selecionar uma loja para utilizar seus recursos!</Trans>
      });
    } else {
      const withBackOffice = {
        ...values,
        type: values.typeStoreValue.type,
        backOffice: values.resources ? values.resources.resourceStore : 0
      };
      if (this.isEditStore) {
        this.editStore(withBackOffice);
      } else {
        this.addStore(withBackOffice);
      }
    }
  };

  get isEditStore() {
    const { initialValues } = this.props;
    return Boolean(initialValues.information);
  }

  handleZipCode = async response => {
    try {
      const { notify } = this.props;

      // Dont show zipCode not found, if the zipCode is not complete
      if (!response) return;

      const data = await response.json();

      if (data.erro) {
        notify({
          variant: 'warning',
          message: <Trans>CEP não encontrado.</Trans>
        });
        this.props.change('billingAddress.address', '');
        this.props.change('billingAddress.neighborhood', '');
        this.props.change('billingAddress.city', '');
        this.props.change('billingAddress.state', '');
      } else {
        this.props.change('billingAddress.address', data.logradouro);
        this.props.change('billingAddress.neighborhood', data.bairro);
        this.props.change('billingAddress.city', data.localidade);
        this.props.change('billingAddress.state', data.uf);
      }
    } catch {
      const { notify } = this.props;

      notify({
        variant: 'warning',
        message: <Trans>CEP não encontrado.</Trans>
      });
      this.props.change('billingAddress.address', '');
      this.props.change('billingAddress.neighborhood', '');
      this.props.change('billingAddress.city', '');
      this.props.change('billingAddress.state', '');
    }
  };

  addStore = values => {
    let erpValue;
    const { CrtlNumber } = this.state;
    let CrtlNumberAux = false;
    if (CrtlNumber) {
      CrtlNumberAux = true;
    }
    const {
      billingAddress,
      information,
      resources,
      groupStore,
      erpSoftwareProviderName,
      type,
      backOffice,
      plan
    } = values;
    if (erpSoftwareProviderName !== undefined) {
      const { value } = erpSoftwareProviderName.erpSoftware;
      erpValue = value;
    } else {
      erpValue = '';
    }
    const store = {
      ...information,
      ...billingAddress,
      ...resources,
      ...groupStore,
      type,
      backOffice,
      productPlans: [
        {
          planVersion: 'START',
          productVersion: 'ORDER_TRACKING'
        }
      ],
      enableControlNumber: CrtlNumberAux,
      integratorType: 'WITHOUT_INTEGRATOR',
      erpSoftwareProviderName: erpValue
    };

    this.props.overlay.show(<Trans>Aguarde, processando...</Trans>);

    addStore(store).then(this.addStoreResponseCallback);
  };

  addStoreResponseCallback = res => {
    const { history } = this.props;

    switch (res.status) {
      case 201: {
        this.props.notify({
          variant: 'success',
          message: <Trans>Loja cadastrada com sucesso!</Trans>
        });

        history.push({
          pathname: `/stores`
        });

        break;
      }
      case 409: {
        this.props.notify({
          variant: 'warning',
          message: <Trans>este CNPJ já está em uso!</Trans>
        });
        break;
      }
      default: {
        this.props.notify({
          variant: 'error',
          message: (
            <Trans>
              Ocorreu uma falha ao tentar cadastrar a loja. Por favor, tente mais tarde!
            </Trans>
          )
        });
        break;
      }
    }
    this.props.overlay.hide();
  };

  editStore = values => {
    const { CrtlNumber } = this.state;
    let CrtlNumberAux = false;
    if (CrtlNumber) {
      CrtlNumberAux = true;
    }
    let {
      billingAddress,
      information: { socialName, tradingName, phoneNumber, email },
      configuration: { printCertificateship, receiveSendFrames, opticsResources },
      resources: { resourceStore },
      groupStore: { billingGroupStore },
      erpSoftwareProviderName,
      type,
      backOffice,
      plan,
      salesPlan
    } = values;

    const { value } = erpSoftwareProviderName.erpSoftware;

    const phonenumber = phoneNumber ? phoneNumber.replace(/\D/g, '') : '';

    const store = {
      socialName,
      tradingName,
      phoneNumber: phonenumber,
      email,
      resourceStore,
      billingGroupStore,
      type,
      backOffice,
      enableControlNumber: CrtlNumberAux,
      erpSoftwareProviderName: value,
      ...billingAddress
    };

    this.props.overlay.show(<Trans>Aguarde, atualizando as informações da loja...</Trans>);

    patchChangeStore(this.props.storeId, store).then(res => {
      if (res.status === 204) {
        this.props.notify({
          variant: 'success',
          message: <Trans>Loja atualizada com sucesso!</Trans>
        });
        setTimeout(() => {
          this.props.history.push({
            pathname: `/stores`
          });
        }, 800);
      } else {
        this.props.notify({
          variant: 'error',
          message: (
            <Trans>Ocorreu uma falha de conexão com o servidor. Tente novamente mais tarde!</Trans>
          )
        });
      }
      this.props.overlay.hide();
      if (!printCertificateship) {
        printCertificateship = false;
      }
      if (!receiveSendFrames) {
        receiveSendFrames = false;
      }

      if (!opticsResources) {
        opticsResources = true;
      }
      if (res === 204) {
        changeStoreProfile(
          this.props.storeId,
          printCertificateship,
          receiveSendFrames,
          opticsResources
        );
      }
    });
  };

  getStores = async () => {
    const { email } = this.props.user;
    const stores = await getUserStores(email);

    this.setState({ stores });
  };
}

function mapStateToProps(state) {
  return { user: state.user };
}

const styles = theme => ({
  confirmButton: {
    [theme.breakpoints.up('md')]: {
      float: 'right'
    },
    position: 'relative',
    float: 'initial',
    marginTop: theme.spacing.unit + 12
  }
});

const erpMaxLength = maxLengthAutocomplete(150);

export default compose(connect(mapStateToProps))(withStyles(styles)(AddEditStore));
