import React, { Component } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import EditOrderDetail from 'forms/orders/edit-order-detail';
import { Creators as NotificationCreators } from 'store/ducks/notifications';
import { Trans } from '@lingui/macro';
import { connect } from 'react-redux';
import { copyOrder } from 'services/orders';
import { withStyles } from '@material-ui/core/styles';
import { formatOrderData } from 'utils/orders/formatOrderData';
import { prescriptionTranslator } from 'utils/orders/prescriptionTranslator';
import BaseOrderDialog from './BaseOrderDialog';

class CopyOrderDialog extends Component {
  render() {
    const { order, getOrderById, fetchOrders, closeDialog, getStoreResourcesDesign } = this.props;
    const { errors } = this.state;

    return order ? (
      <BaseOrderDialog
        fullScreen
        name="copyOrder"
        title={
          <>
            <Trans>Copiar pedido </Trans> <b>{order.orderNumber}</b>
          </>
        }
        closeCallback={() => this.setState({ errors: null })}
      >
        <DialogContent>
          <EditOrderDetail
            orderId={order.orderId}
            fetchOrders={fetchOrders}
            storeId={order.storeId}
            getOrderById={getOrderById}
            closeDialog={closeDialog}
            copy
            submitCallback={this.submitCallback}
            getStoreResourcesDesign={getStoreResourcesDesign}
            errors={errors}
          />
        </DialogContent>
      </BaseOrderDialog>
    ) : null;
  }

  submitCallback = values => {
    const {
      order: { orderId, storeId },
      closeDialog,
      notify,
      fetchOrders
    } = this.props;

    const orderData = formatOrderData(values);

    copyOrder(orderId, storeId, orderData).then(res => {
      if (res.status === 201) {
        fetchOrders();
        this.setState({ errors: null });
        closeDialog('copyOrder')();
        closeDialog('orderCopies')();

        notify(<Trans>Pedido copiado com sucesso.</Trans>, 'success');
      } else if (res.status === 422) {
        res.json().then(res => {
          const prescriptionErros = res.errors;
          const translatedErrors = prescriptionErros.map(error => prescriptionTranslator(error));
          this.setState({ errors: translatedErrors });
        });
      } else if (res.status === 403) {
        notify(<Trans>Você não tem permissão para executar essa ação.</Trans>, 'warning');
      } else {
        notify(
          <Trans>
            Não foi possível atualizar seu pedido, verifique os campos e tente novamente
          </Trans>,
          'error'
        );
      }
    });
  };

  state = {
    errors: null
  };
}

function styles(theme) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    notify(message, variant) {
      dispatch(NotificationCreators.addNotification({ message, variant }));
    }
  };
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(CopyOrderDialog));
