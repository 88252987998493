import React, { Component } from 'react';

import ArchiveOrder from 'forms/orders/ArchiveOrder';
import DialogContent from '@material-ui/core/DialogContent';
import { Trans } from '@lingui/macro';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import BaseOrderDialog from './BaseOrderDialog';

class ArchiveOrderDialog extends Component {
  render = () => {
    const { order } = this.props;
    return (
      <BaseOrderDialog
        name="archiveOrder"
        title={
          order &&
          order.status && (
            <>
              {order.status === 'ORDERED' ? (
                <>
                  <Trans>Cancelar pedido </Trans> <b>{order.orderNumber}</b>
                </>
              ) : (
                <>
                  <Trans>Arquivar pedido</Trans>
                  <b>{order.orderNumber}</b>
                </>
              )}
            </>
          )
        }
      >
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            <Trans>Atenção!</Trans>
          </Typography>

          <Typography variant="body1">
            {order && order.status && (
              <>
                {order.status === 'ORDERED' ? (
                  <>
                    <Trans>
                      O pedido será cancelado! Você realmente confirma esta operação?
                      <br />
                      Digite <b>SIM</b> para confirmar.
                    </Trans>
                  </>
                ) : (
                  <>
                    <Trans>
                      O pedido será arquivado! Você realmente confirma esta operação?
                      <br />
                      Digite <b>SIM</b> para confirmar.
                    </Trans>
                  </>
                )}
              </>
            )}
          </Typography>
          <ArchiveOrder order={order} formCallback={this.submitForm} />
        </DialogContent>
      </BaseOrderDialog>
    );
  };

  static defaultProps = {
    order: {}
  };

  submitForm = () => {
    if (this.props.order && this.props.order.status && this.props.order.status === 'ORDERED') {
      this.props.changeOrder(this.props.order, { orderStatus: 'CANCELED' });
    } else {
      this.props.changeOrder(this.props.order, { orderStatus: 'ARCHIVED' });
    }
    this.props.closeDialog('archiveOrder')();
  };
}

function styles(theme) {
  return {
    root: {
      paddingTop: theme.spacing.unit * 3
    }
  };
}

export default withStyles(styles)(ArchiveOrderDialog);
