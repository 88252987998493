import React, { Component, Fragment } from 'react';
import AddEditStore from 'forms/store/add-edit';
import Button from 'components/buttons/button';
import { Trans } from '@lingui/macro';
import FormControl from 'components/form/wrappers/form-control';
import { StoreContext } from '../settings';
import InactivateStore from './InactivateStore';
import ActivateStore from './ActivateStore';

class StoreInfo extends Component {
  render = () => {
    return (
      <StoreContext.Consumer>
        {context => {
          const isActive = !!(
            !context.state.store.storeDetail || context.state.store.storeDetail.status === 1
          );

          return (
            <Fragment>
              <AddEditStore storeData={context.state.store} storeId={this.props.storeId} />
              {isActive ? (
                <InactivateStore
                  open={this.state.isOpen}
                  onClose={this.closeChangeState}
                  tradingName={context.state.store.tradingName}
                  storeId={context.state.store.id}
                  formCallback={() => {
                    context.update();
                    this.closeChangeState();
                  }}
                />
              ) : (
                <ActivateStore
                  open={this.state.isOpen}
                  onClose={this.closeChangeState}
                  tradingName={context.state.store.tradingName}
                  storeId={context.state.store.id}
                  formCallback={() => {
                    context.update();
                    this.closeChangeState();
                  }}
                />
              )}
              <FormControl margin="normal">
                <Button
                  onClick={this.openChangeState}
                  color={isActive ? 'secondary' : 'primary'}
                  variant="text"
                >
                  {isActive ? <Trans>Inativar loja</Trans> : <Trans>Ativar loja</Trans>}
                </Button>
              </FormControl>
            </Fragment>
          );
        }}
      </StoreContext.Consumer>
    );
  };

  state = {
    isOpen: false
  };

  closeChangeState = () => {
    this.setState({ isOpen: false });
  };

  openChangeState = () => {
    this.setState({ isOpen: true });
  };
}

export default StoreInfo;
