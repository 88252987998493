import { Creators, createNotification } from 'store/ducks/notifications';
import React, { Component } from 'react';

import EmailField from 'components/form/fields/email';
import SelectField from 'components/form/fields/select';
import CheckboxField from 'components/form/fields/checkbox';

import Button from 'components/buttons/button';
import FormControl from 'components/form/wrappers/form-control';
import MenuItem from '@material-ui/core/MenuItem';
import { Creators as OverlayCreators } from 'store/ducks/overlay';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { connect } from 'react-redux';
import { editUser, inviteUser } from 'services/stores';
import { reduxForm } from 'redux-form';
import { required } from 'components/form/fields/validation';
import userRoles from 'components/utils/userRoles';
import { withStyles } from '@material-ui/core/styles';

const invitationUrl = process.env.REACT_APP_URL;

class EditUser extends Component {
  render() {
    const { handleSubmit, submiting, email, storeInfo } = this.props;

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <EmailField fullWidth name="email" disabled={!!email} label={<Trans>Email*</Trans>} />

        <SelectField
          fullWidth
          name="role"
          id="role"
          label={<Trans>Perfil*</Trans>}
          validate={[required]}
        >
          {roles.map(({ roleValue, roleName }) => (
            <MenuItem key={roleValue} value={roleValue}>
              {roleName}
            </MenuItem>
          ))}
        </SelectField>

        {storeInfo && storeInfo.erpSoftwareProviderName !== 'Grupo Acert' && (
          <FormControl fullWidth>
            <CheckboxField name="allowAddOrder" label={<Trans>Permite incluir pedidos</Trans>} />
          </FormControl>
        )}

        <FormControl margin="normal" fullWidth>
          <Button disabled={submiting} color="primary" variant="contained" type="submit">
            <Trans>Confirmar</Trans>
          </Button>
        </FormControl>
      </form>
    );
  }

  componentWillMount = () => {
    const { email, role, allowAddOrder, initialize, storeInfo } = this.props;

    initialize({ email, role, allowAddOrder, storeInfo });
  };

  submit = values => {
    this.props.email ? this.editUser(values) : this.addUser(values);
  };

  addUser = values => {
    const { storeId, notify, submitCallback, reset, overlay } = this.props;
    let { email, role, allowAddOrder } = values;

    if (!allowAddOrder) {
      allowAddOrder = false;
    }

    const data = { email, invitationUrl, role, allowAddOrder };
    overlay.show(<Trans>Processando, aguarde...</Trans>);
    inviteUser(storeId, data).then(res => {
      if (res.status === 201)
        notify({
          variant: 'success',
          message: <Trans>Convite enviado com sucesso! </Trans>
        });
      else if (res.status === 409)
        notify({
          variant: 'error',
          message: <Trans>Esse email já recebeu um convite.</Trans>
        });
      else
        notify({
          variant: 'error',
          message: <Trans>Não foi possível enviar o convite, por favor tente mais tarde.</Trans>
        });
      reset();
      overlay.hide();
      submitCallback && submitCallback();
    });
  };

  editUser = values => {
    const { storeId, notify, submitCallback, overlay } = this.props;
    let { email, role, allowAddOrder } = values;

    if (!allowAddOrder) {
      allowAddOrder = false;
    }

    const data = { email, invitationUrl, role, allowAddOrder };

    overlay.show(<Trans>Aguarde, processando...</Trans>);

    editUser(storeId, data).then(res => {
      if (res.status === 204)
        notify({
          variant: 'success',
          message: <Trans>Usuário editado com sucesso! </Trans>
        });
      else if (res.status === 404)
        notify({
          variant: 'error',
          message: <Trans>Usuário não encontrado</Trans>
        });
      else
        notify({
          variant: 'error',
          message: <Trans>Não foi possível enviar o convite, por favor tente mais tarde.</Trans>
        });

      submitCallback && submitCallback();
      overlay.hide();
    });
  };
}

const roles = [
  'ADMINISTRATOR',
  'ACERT',
  'ASSEMBLER',
  'SALESMAN',
  'READ_ONLY',
  'OPTOMETRIST',
  'CASHIER',
  'MANAGER',
  'EXTERNAL_SALESMAN'
].map(role => ({
  roleValue: role,
  roleName: userRoles({ role })
}));

EditUser.propTypes = {
  storeId: PropTypes.string.isRequired
};

const style = theme => ({});

const mapDispatchToProps = dispatch => ({
  notify: ({ message, variant }) =>
    dispatch(Creators.addNotification(createNotification({ message, variant }))),
  overlay: {
    show: loadingText => dispatch(OverlayCreators.showOverlay({ loadingText })),
    hide: _ => dispatch(OverlayCreators.hideOverlay())
  }
});

export const EditUserForm = connect(null, mapDispatchToProps)(withStyles(style)(EditUser));

export default reduxForm({ form: 'editUser' })(EditUserForm);
