import { createMuiTheme } from '@material-ui/core/styles';

export const defaultThemeBase = {
  palette: {
    primary: {
      main: '#047eb4',
      light: '#00a0e3',
      contrastText: '#fff'
    },
    secondary: {
      main: '#eca21e'
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        boxShadow: '1px 1px 1px rgba(0,0,0, .25) !important'
      }
    }
  }
};

const theme = createMuiTheme(defaultThemeBase);

export default theme;
