import { Trans } from '@lingui/macro';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FormControl from 'components/form/wrappers/form-control';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { getOrderById } from 'store/selectors/orders';
import { formatFrameValues, formatPrescriptionValues } from 'utils/formatters/formatters';
import { OrdersConsumer } from 'views/home/orders/OrdersProvider';
import Review from './review/Review';

class ReviewEditOrder extends Component {
  render() {
    const { classes, handleSubmit } = this.props;
    const { requiredItem } = this.state;
    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <Typography className={classes.titleHead} variant="title" gutterBottom>
          <Trans>Revisão do pedido</Trans>
        </Typography>
        <Typography className={classes.subTitle} variant="subheading" gutterBottom>
          <Trans>
            Abaixo estão os dados cadastrados para o pedido{' '}
            <b>{this.props.initialValues.orderNumber}</b> para revisão.
          </Trans>
        </Typography>
        <div className={classes.root}>
          <Review
            eyesPrescription={formatPrescriptionValues(
              this.props.initialValues.prescription.eyesPrescription
            )}
            frame={formatFrameValues(this.props.initialValues.prescription.frame)}
            notes={this.props.initialValues.notes}
            items={this.props.initialValues.items}
            requiredItem={requiredItem}
          />
        </div>

        <div className={classes.actions}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <FormControl>
                <Fab
                  variant="contained"
                  className={classes.button}
                  onClick={() => {
                    this.props.actions('updateOrder', {
                      orderId: this.props.order.orderId
                    });
                  }}
                  className={classes.button}
                >
                  <EditIcon className={classes.extendedIcon} />
                  <Trans>Editar o Pedido</Trans>
                </Fab>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} style={{ textAlign: 'end' }}>
              <FormControl>
                <Fab
                  variant="contained"
                  disabled={requiredItem}
                  className={classes.button}
                  type="submit"
                >
                  <NavigateNextIcon className={classes.extendedIcon} />
                  <Trans>Próximo</Trans>
                </Fab>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  }

  state = {
    requiredItem: false
  };

  submit = () => {
    this.props.nextStep();
  };

  componentDidUpdate() {
    const {
      prescription: {
        frame: { type }
      }
    } = this.props.initialValues;

    const { requiredItem } = this.state;

    if (type !== null && requiredItem) {
      this.setState({ requiredItem: false });
    }
  }

  componentDidMount() {
    this.props.fetchOrders(this.props.orderId);
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 2
  },
  titleHead: {
    color: '#757575'
  },
  subTitle: {
    color: '#757575',
    fontSize: '0.8em',
    marginBottom: theme.spacing.unit * 2
  },
  extendedIcon: {
    marginRight: theme.spacing.unit - 3,
    paddingLeft: theme.spacing.unit - 3
  },
  button: {
    [theme.breakpoints.up('md')]: {
      width: 165
    },
    width: 110,
    borderRadius: 0,
    fontSize: '0.8em',
    backgroundColor: '#047eb4',
    color: '#fff',
    paddingRight: theme.spacing.unit,
    '&:hover': {
      backgroundColor: '#04587D'
    }
  },
  actions: {
    position: 'fixed',
    top: '90%',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: '95%'
    }
  }
});

const ConectOrdersProvider = props => {
  return (
    <OrdersConsumer>
      {({ actions }) => <ReviewEditOrder {...props} actions={actions} />}
    </OrdersConsumer>
  );
};

function mapStateToProps(state, ownProps) {
  const order = getOrderById(state, ownProps.order.orderId);
  const stableOder = order || ownProps.order;
  return {
    initialValues: {
      ...stableOder,
      requiredEye: 'BOTH',
      diopter_left: 'FAR',
      diopter_right: 'FAR',
      changeSupplierSkuField: true,
      changeTypeSkuField: true,
      changeShapeSkuField: true,
      diff_sku: false,
      bothLens: false,
      rightLens: false,
      leftLens: false,
      checkIntegrations: false,
      resourcesContent: [],
      supplierResource_left: [],
      supplierResource_right: [],
      supplierResource_both: [],
      frameCode: null,
      supplierId:
        ownProps.order.promotionOrder || ownProps.order.sentToSupplier
          ? ownProps.order.sentToSupplier
          : null,
      promotionOrder: ownProps.order.promotionOrder ? ownProps.order.promotionOrder : false,
      frameModel: null
    }
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null),
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
  })
)(ConectOrdersProvider);

ReviewEditOrder = connect(mapStateToProps)(ReviewEditOrder);
