import { Trans } from '@lingui/macro';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import GenericIconFileField from 'components/form/fields/GenericIconFileField';
import PlainField from 'components/form/fields/PlainField';
import { required } from 'components/form/validation';
import { Send as SendIcon, Upload as UploadIcon } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

export default function FastAttach(props) {
  const { classes, onSubmit, handleSubmit, submitSucceeded, reset, formValues } = props;
  const { file, description } = formValues;

  if (submitSucceeded) reset();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.field}>
        <span className={classes.button}>
          <GenericIconFileField
            hideHelpers
            icon={<UploadIcon />}
            name="file"
            validate={[required]}
          />
        </span>

        <PlainField
          className={classes.input}
          hideHelpers
          name="description"
          placeholder={ReactDOMServer.renderToString(<Trans>Descrição</Trans>)}
          validate={[required]}
        />

        <Divider className={classes.divider} />

        <IconButton
          disabled={!file || !description}
          className={classes.button}
          color="primary"
          type="submit"
        >
          <SendIcon />
        </IconButton>
      </Paper>
      <FormHelperText className={classes.helper}>
        {!description && !file && <Trans>Selecione um arquivo no botão a esquerda</Trans>}
        {description && !file && <Trans>Selecione um arquivo no botão a esquerda</Trans>}
        {!description && file && <Trans>Adicione uma descrição para o anexo</Trans>}
        {description && file && <Trans>Pronto! Agora é só enviar</Trans>}
      </FormHelperText>
    </form>
  );
}

FastAttach.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  classes: PropTypes.shape({}),
  reset: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    description: PropTypes.string.isRequired,
    file: PropTypes.shape({}).isRequired
  }).isRequired
};

FastAttach.defaultProps = {
  classes: {}
};
