import 'normalize-css';

import { Provider } from 'react-redux';
import React from 'react';
import I18nWrapper from './i18n/I18nWrapper';
import Router from './routes';
import ThemeProvider from './theme';
import configureStore from './store';

export const store = configureStore();

const App = () => (
  <Provider store={store}>
    <I18nWrapper>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </I18nWrapper>
  </Provider>
);

export default App;
