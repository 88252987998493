import React from 'react';
import { Trans } from '@lingui/macro';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import {
  returnNameOfType,
  returnAvatarNameOfType
} from '../../forms/orders/sendOrder/utils/lensType';

function ItemList({ data }) {
  return (
    <>
      <Avatar style={{ backgroundColor: '#047eb4' }}>{returnAvatarNameOfType(data.type)}</Avatar>
      <ListItemText
        inset
        primary={
          <>
            <b>{returnNameOfType(data.type)}</b> {data.sku} - {data.description}
          </>
        }
        secondary={
          <>
            <b>
              <Trans>Quantidade:</Trans>
            </b>{' '}
            {data.quantity}{' '}
            {data.type === 'COLORING' && data.colors && (
              <>
                {' '}
                <b>
                  <Trans>Cor:</Trans>
                </b>{' '}
                {data.colors.description}
              </>
            )}
          </>
        }
      />
    </>
  );
}

export default ItemList;
