import React from 'react';
import { Field } from 'redux-form-normalize-on-blur';
import BaseInput from 'components/form/inputs/base-input';
import { decimalFormat } from 'utils/formatters/formatters';
import { validatePositiveNumbers } from 'components/form/validation';
import PropTypes from 'prop-types';

export const QuantityField = ({ step = '0.5', unit, ...props }) => (
  <Field
    {...props}
    step={step}
    component={BaseInput}
    type="number"
    normalizeOnBlur={value => decimalFormat(value)}
    validate={[positiveNumbers]}
  >
    {unit}
  </Field>
);

const positiveNumbers = validatePositiveNumbers(0.01, true);

QuantityField.propTypes = {
  unit: PropTypes.string.isRequired
};

export default QuantityField;
