import React from 'react';
import { Polar } from 'react-chartjs-2';

class FrameDesign extends React.Component {
  render() {
    const { frameDesign } = this.props;

    const data = {
      datasets: [
        {
          data: frameDesign.split(',').map(parseFloat),
          backgroundColor: '#047eb4',
          borderColor: '#047eb4'
        }
      ]
    };
    const options = {
      scale: {
        display: false
      },
      legend: {
        display: false
      },
      tooltips: {
        callbacks: {
          label(tooltipItem) {
            return tooltipItem.yLabel;
          }
        }
      },
      startAngle: -1.0 * Math.PI
    };
    return <Polar data={data} width={150} height={150} options={options} />;
  }
}
export default FrameDesign;
