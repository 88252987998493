import React from 'react';
import MuiPopper from '@material-ui/core/Popper';

export default function Popper({ open = false, anchor, placement = 'top', children, ...props }) {
  return (
    <MuiPopper
      open={open}
      anchorEl={anchor}
      placement={placement}
      disablePortal
      modifiers={{
        flip: {
          enabled: false
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'window'
        }
      }}
    >
      {children}
    </MuiPopper>
  );
}
