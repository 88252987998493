import { Trans } from '@lingui/macro';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { getFormValues } from 'redux-form';
import { SelectInput } from 'components/form/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { getSupplierResources } from '../../../../services/suppliers';
import { getSupplierSku } from '../../../../services/stores';
import CustomTableCell from '../../../../components/table/CustomTableCell';
import Loading from '../../../../components/utils/Loading';

class TypeInfo extends Component {
  render() {
    const { typeSkuField } = this.props;
    const { loading, typeResources } = this.state;
    if (loading) {
      return <Loading />;
    }
    return (
      <>
        <CustomTableCell>
          {loading ? (
            <Loading />
          ) : (
            <SelectInput
              name={typeSkuField}
              style={{ width: '-webkit-fill-available' }}
              onChange={this.changeTypeSku}
              label={<Trans>Tipo da armação</Trans>}
            >
              <MenuItem value="" disabled />
              {typeResources &&
                typeResources.map(data => (
                  <MenuItem key={data.sku} value={data.sku}>
                    {data.description}
                  </MenuItem>
                ))}
            </SelectInput>
          )}
        </CustomTableCell>
      </>
    );
  }

  state = {
    loading: false,
    hasIntegration: false,
    typeResources: []
  };

  changeTypeSku = event => {
    const { typeSkuField, change } = this.props;
    change('changeTypeSkuField', false);
    if (event !== null) {
      change(typeSkuField, event.target.value);
      change('frameCode', event.target.value);
    }
  };

  async getItemInformationBySku() {
    const { storeId, supplierId, storeSku, storeSkuType, change, typeSkuField } = this.props;

    await this.setTypeResources();

    await getSupplierSku(storeId, supplierId, 'FRAME', storeSku).then(result => {
      const item =
        result.content && result.content.find(result => result.supplierSkuType === storeSkuType);

      if (item) {
        this.setState({ supplierSku: item.supplierSku });
        if (this.props.formValues.changeTypeSkuField) {
          change(typeSkuField, item.supplierSku);
        }
      }
      this.setState({ hasIntegration: !!item });
    });
  }

  async setTypeResources() {
    const { supplierId } = this.props;
    try {
      await getSupplierResources({
        supplierId,
        resourceType: 'FRAME'
      }).then(content => {
        this.setState({ typeResources: content.content });
      });
    } catch (error) {
      console.log(error);
    }
  }

  async componentWillMount() {
    this.setState({ loading: true });
    this.getItemInformationBySku();
    this.setState({ loading: false });
  }
}

function mapStateToProps(state) {
  const formValues = getFormValues('wizard')(state) || {};
  return { formValues };
}

export default compose(withRouter, connect(mapStateToProps, null))(TypeInfo);
