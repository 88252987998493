import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import withHelperText from '../helper-text/withHelperText';
import SelectInput from './SelectInput';

export default compose(
  withHelperText,
  withStyles(styles)
)(SelectInput);
