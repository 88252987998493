import { Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import Button from 'components/buttons/button';
import { maxFileSize, required } from 'components/form/fields/validation';
import { FileUploadCustom, TextInput } from 'components/form/Input';
import FormControl from 'components/form/wrappers/form-control';
import { addAttachOrder } from 'services/orders';
import { createNotification, Creators as NotificationCreators } from 'store/ducks/notifications';
import { Creators as OverlayCreators } from 'store/ducks/overlay';

class AttachFile extends Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form>
        <FormControl fullWidth margin="normal">
          <FileUploadCustom
            validate={[required, maxAttachSize]}
            name="file"
            label={<Trans>Selecione um arquivo</Trans>}
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextInput
            id="description"
            name="description"
            label={<Trans>Descrição do anexo</Trans>}
            multiline
            validate={[required]}
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Button type="submit" onClick={handleSubmit(this.submit)}>
            <Trans>Salvar</Trans>
          </Button>
        </FormControl>
      </form>
    );
  }

  submit = values => {
    const { description, file } = values;

    const { submitCallback, overlay, order } = this.props;

    const { orderId, storeId } = order;
    overlay.show(<Trans>Aguarde, processando...</Trans>);

    addAttachOrder(orderId, storeId, file, description)
      .then(response => {
        this.notifyByStatus(response.status);
        overlay.hide();
      })
      .then(submitCallback);
  };

  notifyByStatus(status) {
    const { addNotification } = this.props;

    switch (status) {
      case 201:
        addNotification({
          variant: 'success',
          message: <Trans>Anexo adicionado com sucesso!</Trans>
        });
        break;
      case 400:
        addNotification({
          variant: 'error',
          message: (
            <Trans>
              Por favor, verifique o seu anexo novamente, ele possui um formato invalido para
              upload.
            </Trans>
          )
        });
        break;
      default:
        addNotification({
          variant: 'error',
          message: (
            <Trans>
              Não foi possível estabelecer conexão com o servidor. Tente novamente mais tarde!
            </Trans>
          )
        });
        break;
    }
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired
  };
}

const maxAttachSize = maxFileSize(6291456);

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
});

const mapDispatchToProps = dispatch => ({
  addNotification: ({ message, variant }) =>
    dispatch(NotificationCreators.addNotification(createNotification({ message, variant }))),
  overlay: {
    show: loadingText => dispatch(OverlayCreators.showOverlay({ loadingText })),
    hide: _ => dispatch(OverlayCreators.hideOverlay())
  }
});

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
  reduxForm({ form: 'AddAttachOrderDialog', destroyOnUnmount: true })
)(AttachFile);
