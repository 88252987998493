import React, { Component } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import { Trans } from '@lingui/macro';
import BaseOrderDialog from '../BaseOrderDialog';
import SupplierStatusListContainer from './SupplierStatusListContainer';

class SupplierStatusDialog extends Component {
  render() {
    const { order } = this.props;

    const Title = () => (
      <>
        <Trans>Status do fornecedor - </Trans> <b>{order && order.orderNumber}</b>
      </>
    );

    return order ? (
      <BaseOrderDialog
        closeCallback={this.close}
        maxWidth="md"
        fullWidth
        name="supplierStatus"
        title={<Title />}
      >
        <DialogContent>
          <SupplierStatusListContainer orderId={order.orderId} storeId={order.storeId} />
        </DialogContent>
      </BaseOrderDialog>
    ) : null;
  }
}
export default SupplierStatusDialog;
