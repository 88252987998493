import React from 'react';
import { Trans } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import SelectField from 'components/form/fields/select';
import MenuItem from '@material-ui/core/MenuItem';

function TypeStore({ typeStore }) {
  return (
    <>
      <Grid item xs={12} sm={12}>
        <SelectField fullWidth label={<Trans>Tipo de loja</Trans>} name="type">
          {typeStore.map(plan => (
            <MenuItem key={plan.id} value={plan.value}>
              {plan.label}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
    </>
  );
}

export default TypeStore;
