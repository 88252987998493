import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';

class CustomLaneHeader extends Component {
  render() {
    const { classes, title, allowAddOrderValue, handleDialogs, store } = this.props;
    return (
      <>
        <Grid className={classes.root} container justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.title} inline variant="h6">
              {title}
            </Typography>
            <Tooltip title={<Trans>Quantidade de pedidos</Trans>}>
              <Typography className={classes.count} inline variant="caption">
                ({this.props.cards && this.props.cards.length})
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item>
            {this.props.id === 'ORDERED' &&
              allowAddOrderValue &&
              store &&
              store.erpSoftwareProviderName !== 'Grupo Acert' && (
                <Tooltip title={<Trans>Incluir novo pedido</Trans>}>
                  <IconButton
                    className={classes.button}
                    onClick={() => handleDialogs('insertOrder', { orderId: 0 })}
                    variant="text"
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
          </Grid>
        </Grid>
      </>
    );
  }

  state = {
    anchorEl: null
  };

  handleOrderMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };
}

function styles(theme) {
  return {
    root: {
      minHeight: theme.spacing.unit * 6,
      padding: theme.spacing.unit
    },
    title: {
      color: theme.palette.primary.dark
    },
    count: {
      marginLeft: theme.spacing.unit,
      color: theme.palette.primary.dark
    },
    button: {
      color: theme.palette.primary.dark,
      padding: '0px'
    }
  };
}

export default withStyles(styles)(CustomLaneHeader);
