const styles = theme => {
  return {
    button: {
      marginTop: theme.spacing.unit * 2
    },
    hideSelectEye: {
      display: 'none'
    },
    selectEye: {
      alignSelf: 'center'
    }
  };
};

export default styles;
