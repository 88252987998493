import React from 'react';
import { Field } from 'redux-form';

const propTypes = {};

const defaultProps = {};

export default function HiddenField({ ...props }) {
  return (
    <Field
      component={({ input, ...props }) => <input {...props} {...input} type="hidden" />}
      {...props}
    />
  );
}

HiddenField.propTypes = propTypes;
HiddenField.defaultProps = defaultProps;
