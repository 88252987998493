import 'moment/locale/pt';

import React, { Component } from 'react';

import Moment from 'react-moment';
import { connect } from 'react-redux';
import { getCurrentLocale } from 'store/selectors/i18nSelectors';

export class Date extends Component {
  render() {
    const { children, format, locale } = this.props;
    return (
      <Moment format={format} locale={locale}>
        {children}
      </Moment>
    );
  }
}

Date.defaultProps = {
  format: 'll'
};

function mapStateToProps(state) {
  return {
    locale: getCurrentLocale(state)
  };
}

export default connect(mapStateToProps)(Date);
