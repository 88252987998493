import React from 'react';
import PropTypes from 'prop-types';
import MuiFormControl from '@material-ui/core/FormControl';

function FormControl({ children, classes, ...props }) {
  return <MuiFormControl {...props}>{children}</MuiFormControl>;
}

FormControl.propTypes = {
  classes: PropTypes.object
};

export default FormControl;
