import { Trans } from '@lingui/macro';
import Button from 'components/buttons/button';
import DateTimePickerField from 'components/form/fields/date-time-picker';
import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

function ChangeDueDate(props) {
  const { handleSubmit, onSubmit } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DateTimePickerField label={<Trans>Data de entrega</Trans>} name="orderDueDate" />
      <Button type="submit">
        <Trans>Alterar prazo de entrega</Trans>
      </Button>
    </form>
  );
}

export default compose(
  reduxForm({
    form: 'changeDueDate'
  })
)(ChangeDueDate);
