import TableCell from 'components/table/TableCell';
import Date from 'components/time/Date';
import Done from '@material-ui/icons/Done';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TableRow from '@material-ui/core/TableRow';
import { Trans } from '@lingui/macro';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import WarningIcon from '@material-ui/icons/Warning';

import Table from 'components/table/Table';
import Tooltip from '@material-ui/core/Tooltip';

class SupplierRequestList extends Component {
  render() {
    const { classes, suppliersList, supplierRequest } = this.props;

    const cols = [
      { label: <Trans>ID do pedido</Trans>, id: 'supplierOrderId' },
      { label: <Trans>Número do pedido</Trans>, id: 'supplierOrderNumber' },
      { label: <Trans>Fornecedor</Trans>, id: 'companyName' },
      { label: <Trans>Data</Trans>, id: 'dateTime' },
      { label: <Trans>Data de expedição</Trans>, id: 'supplierExpeditionDate' },
      { label: <Trans>Observação</Trans>, id: 'notes' }
    ];

    const withCompanyName =
      supplierRequest.length > 0 &&
      supplierRequest.map(supplier => {
        const foundSupplier = suppliersList.find(item => item.id === supplier.supplierId);
        return {
          ...supplier,
          companyName: foundSupplier ? foundSupplier.companyName : '-'
        };
      });

    return withCompanyName ? (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} cols={cols} data={withCompanyName} paginated>
            {({
              supplierOrderId,
              supplierOrderNumber,
              supplierId,
              dateTime,
              supplierExpeditionDate,
              notes,
              companyName
            }) => (
              <TableRow key={supplierOrderId}>
                <TableCell>
                  {supplierOrderId ? <span>{supplierOrderId}</span> : <span> - </span>}
                </TableCell>
                <TableCell>
                  {supplierOrderNumber !== null ? (
                    <span>{supplierOrderNumber}</span>
                  ) : (
                    <span> - </span>
                  )}
                </TableCell>
                <TableCell>{companyName}</TableCell>
                <TableCell>
                  <Date>{dateTime}</Date>
                </TableCell>
                <TableCell>
                  {supplierExpeditionDate !== null ? (
                    <Date>{supplierExpeditionDate}</Date>
                  ) : (
                    <span> - </span>
                  )}
                </TableCell>
                <TableCell>
                  {notes !== null ? (
                    <Tooltip title={notes}>
                      <div>
                        <WarningIcon className={classes.iconWarning} />

                        <Trans>O envio possui críticas</Trans>
                      </div>
                    </Tooltip>
                  ) : (
                    <div>
                      {supplierOrderNumber !== null ? (
                        <div>
                          <Done className={classes.iconDone} />

                          <Trans>Pedido enviado com sucesso</Trans>
                        </div>
                      ) : (
                        <span>
                          <Trans>não possui observações!</Trans>
                        </span>
                      )}
                    </div>
                  )}
                </TableCell>
              </TableRow>
            )}
          </Table>
        </div>
      </Paper>
    ) : (
      <Typography color="textSecondary" variant="h6">
        <Trans>Nenhum envio encontrado</Trans>
      </Typography>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  TableCell: {
    textAlign: 'center',
    padding: '4px 4px 4px 15px'
  },
  tableCell: {
    width: 'auto',
    textAlign: 'center',
    padding: '4px 4px 4px 15px'
  },
  tableCellError: {
    width: 'auto',
    textAlign: 'center',
    padding: '4px 4px 4px 15px',
    backgroundColor: red[50]
  },
  iconDone: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing.unit
    },
    top: '6px',
    paddingRight: '0px',
    position: 'relative',
    color: green[600]
  },
  iconWarning: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing.unit
    },
    top: '6px',
    paddingRight: '0px',
    position: 'relative',
    color: amber[500]
  },
  notFound: {
    textAlign: 'center',
    fontWeight: '300',
    paddingBottom: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2
  }
});

SupplierRequestList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SupplierRequestList);
