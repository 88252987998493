import React from 'react';
import Typography from '@material-ui/core/Typography';

function SingleValue(props) {
  return (
    <Typography
      style={{ maxWidth: 300, fontSize: '0.9em' }}
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

export default SingleValue;
