import React from 'react';
import { Field } from 'redux-form';
import BaseInput from 'components/form/inputs/base-input';
import { validateCpf } from '../validation';

export default function CPFField(props) {
  const cpfMask = inputValue => inputValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  const validate = props.validate ? [...props.validate, validateCpf] : [validateCpf];
  return (
    <Field {...props} normalize={cpfMask} component={BaseInput} type="text" validate={validate} />
  );
}
