import React from 'react';
import { Field } from 'redux-form';
import DatePickerInput from 'components/form/inputs/date-picker';

export default function DatePickerField({ ...props }) {
  return <Field {...props} component={DatePickerInput} />;
}

DatePickerField.defaultProps = {
  variant: 'outlined',
  margin: 'dense'
};
