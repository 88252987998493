import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Login from './Login';
import { LOGIN, ACCESS, REGISTER } from './enums';
import styles from './styles';

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { page }
      }
    } = this.props;

    if (page !== REGISTER && page !== ACCESS)
      this.props.history.push({
        pathname: `/${LOGIN}/${ACCESS}`
      });
  }

  render() {
    const {
      classes,
      match: {
        params: { page }
      }
    } = this.props;

    return <Login classes={classes} page={page} />;
  }
}

export default withStyles(styles)(LoginContainer);
