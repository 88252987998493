import { Trans } from '@lingui/macro';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import AutocompleteField from 'components/form/fields/autocomplete';
import CheckboxField from 'components/form/fields/checkbox';
import { SelectShape } from 'components/form/fields/frame/shape';
import HiddenField from 'components/form/fields/hidden';
import NumberField from 'components/form/fields/number';
import CustomTableCell from 'components/table/TableCell';
import ResourceUnities from 'components/utils/ResourceUnities';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';
import withNotification from 'store/providers/withNotification';
import randomId from 'utils/random-id/';
import { IntegrationConsumer } from 'views/integration/provider';
import { InformationOutline as InfoIcon } from 'mdi-material-ui';

import lensTypes from 'utils/types/lensType';

function createIntegrationForm(resources = {}) {
  const id = randomId();
  const STORE = 'storeResource';
  const SUPPLIER = 'supplierResource';
  const SKU = 'sku';
  const DESCRIPTION = 'description';

  class IntegrationForm extends Component {
    render() {
      const {
        classes,
        storeResources,
        supplierResources,
        handleSubmit,
        integrated,
        initialValues
      } = this.props;
      const { lensType } = initialValues;

      const [lensTypeName] = lensTypes
        .filter(lens => lensType === lens.value)
        .map(lens => lens.text);

      const type = storeResources && storeResources.length > 0 && storeResources[0].type;
      const typeSupplier =
        supplierResources && supplierResources.length > 0 && supplierResources[0].type;

      return (
        <TableRow className={this.state.error && classes.error}>
          <HiddenField name="id" />
          <HiddenField name="supplierSku" />

          <CustomTableCell>
            <div style={{ display: 'flex' }}>
              {type === 'FRAME_SHAPE' && (
                <>
                  {resources.supplierResource.image && resources.supplierResource.image !== null ? (
                    <div className={classes.imageContainer}>
                      <img
                        src={resources.supplierResource.image}
                        alt="Imagem do desenho da armação"
                        width="75"
                        height="75"
                      />
                    </div>
                  ) : (
                    <div className={classes.notImage}>
                      <span className={classes.notImageText}>Sem Imagem</span>
                    </div>
                  )}
                </>
              )}
              <Typography className={classes.supplier}>
                {resources.supplierResource.description}
                <Typography className={classes.supplierSku} inline variant="caption">
                  ({resources.supplierResource.sku})
                </Typography>
                {type === 'LENS' && (
                  <Tooltip title={lensTypeName}>
                    <InfoIcon className={classes.info} />
                  </Tooltip>
                )}
              </Typography>
            </div>
          </CustomTableCell>

          {typeSupplier !== 'COLOR' && (
            <CustomTableCell style={{ minWidth: 350 }}>
              <Tooltip
                placement="top"
                disableFocusListener
                title={<Trans>Busque por descrição ou código do produto</Trans>}
              >
                {type === 'FRAME_SHAPE' ? (
                  <SelectShape
                    label=""
                    name="storeSku"
                    fullWidth
                    disabled={this.props.integrated}
                    data={storeResources.map(resource => {
                      return {
                        value: resource.sku,
                        text: resource.description
                      };
                    })}
                  />
                ) : (
                  <AutocompleteField
                    fullWidth
                    label=""
                    hideHelpers
                    name="storeSku"
                    options={storeResources.map(userStore => ({
                      label: userStore.description,
                      id: userStore.sku,
                      value: userStore.sku
                    }))}
                    disabled={this.props.integrated}
                  />
                )}
              </Tooltip>
            </CustomTableCell>
          )}

          {typeSupplier !== 'COLOR' && (
            <CustomTableCell>
              <CheckboxField
                disabled={!this.isFormSet}
                onChange={handleSubmit(this.submit)}
                color="primary"
                name="integrated"
              />
            </CustomTableCell>
          )}
        </TableRow>
      );
    }

    createOptionsFromResource = resourceName => resourceField => {
      const resources = this.getResorucesByName(resourceName);

      return resources.map(formatOptionFromResource(resourceField));
    };

    getRelatedSubField = subField => this.subFieldsRelations[subField];

    subFieldsRelations = {
      [SKU]: [DESCRIPTION],
      [DESCRIPTION]: [SKU]
    };

    getResorucesByName = resourceName => {
      const { storeResources, supplierResources } = this.props;

      if (resourceName === STORE) return storeResources;
      if (resourceName === SUPPLIER) return supplierResources;
    };

    getValueFromResources = (resourceName, resourceField, value) => {
      const resources = this.getResorucesByName(resourceName);

      return resources.find(resource => resource[resourceField] === value);
    };

    isStoreSet = () =>
      Boolean(this.props.formValues.storeSku && this.props.formValues.storeSku.value);

    getUnities = () => {
      if (!this.isStoreSet()) return;
      try {
        const {
          formValues: {
            storeSku: { value: storeSku },
            supplierSku
          },
          storeResources,
          supplierResources,
          getResourceFromSku
        } = this.props;

        const { unitOfMeasure: storeUnitOfMeasure } = getResourceFromSku(storeResources, storeSku);

        const { unitOfMeasure: supplierUnitOfMeasure } = getResourceFromSku(
          supplierResources,
          supplierSku
        );

        const translatedStoreUnitOfMeasure = (
          <ResourceUnities unitName={storeUnitOfMeasure || supplierUnitOfMeasure} />
        );

        const translatedSupplierUnitOfMeasure = (
          <ResourceUnities unitName={supplierUnitOfMeasure} />
        );
        return {
          store: translatedStoreUnitOfMeasure,
          supplier: translatedSupplierUnitOfMeasure
        };
      } catch (error) {
        return {
          store: '',
          supplier: ''
        };
      }
    };

    get isFormSet() {
      const {
        formValues: { storeSku }
      } = this.props;

      return !!storeSku;
    }

    renderUnitLabel = () => {
      const unities = this.getUnities();

      if (!unities) return '';

      const { store, supplier } = unities;

      return `de ${supplier} para ${store}`;
    };

    extractValues = values => {
      const {
        supplierSku,
        storeSku: { value: storeSku },
        integrated,
        id
      } = values;

      return { storeSku, supplierSku, integrated, id };
    };

    submit = values => {
      const { sendIntegration, removeIntegration } = this.props;
      const { id, integrated, storeSku, supplierSku } = this.extractValues(values);

      if (!integrated) {
        const validSku = storeSku === undefined ? values.storeSku : storeSku;
        const sendData = { conversionFactor: 1, supplierSku, storeSku: validSku };
        sendIntegration(sendData).then(this.sendCallback);
      } else {
        removeIntegration(id).then(this.removeCallback);
      }
    };

    sendCallback = httpResponse => {
      const { change, updateResources } = this.props;
      const { status } = httpResponse;

      const success = status === 201;
      const readOnly = status === 403;
      const duplicated = status === 409;

      if (success) {
        const location = httpResponse.headers.get('Location');
        const id = this.getIdFromLocation(location);
        this.removeHighlightError();
        updateResources();
        this.notify('sendSuccess');
        change('id', id);
      } else if (readOnly) {
        this.notify('sendReadOnly');
        change('integrated', null);
      } else {
        this.highlightError();
        if (duplicated) {
          this.notify('sendErrorAlreadyUsed');
        } else {
          this.notify('sendErrorGeneric');
        }
        change('integrated', null);
      }
    };

    removeCallback = httpResponse => {
      const { change } = this.props;
      const { status } = httpResponse;
      const success = status === 204;
      const readOnly = status === 403;

      if (success) {
        this.removeHighlightError();
        this.notify('removeSuccess');
      } else if (readOnly) {
        this.highlightError();
        this.notify('removeReadOnly');
        change('integrated', true);
      } else {
        this.highlightError();
        this.notify('removeError');
        change('integrated', true);
      }
    };

    getIdFromLocation = location => {
      const locationArray = location.split('/');
      const idIndex = locationArray.length - 1;
      const id = locationArray[idIndex];

      return id;
    };

    notify = status => {
      const { notify } = this.props;

      switch (status) {
        case 'sendSuccess':
          notify({
            message: <Trans>Integração enviada com sucesso! A lista foi reorganizada!</Trans>,
            variant: 'success'
          });
          break;

        case 'removeSuccess':
          notify({
            message: <Trans>Integração removida com sucesso!</Trans>,
            variant: 'success'
          });
          break;

        case 'sendErrorAlreadyUsed':
          notify({
            message: <Trans>Recurso já utilizado. Verifique e tente novamente.</Trans>,
            variant: 'error'
          });
          break;

        case 'removeError':
          notify({
            message: (
              <Trans>Ocorreu um erro na remoção da integração. Verifique e tente novamente.</Trans>
            ),
            variant: 'error'
          });
          break;

        case 'removeReadOnly':
          notify({
            message: <Trans>Você não tem permissão para executar essa ação.</Trans>,
            variant: 'warning'
          });
          break;

        case 'sendErrorGeneric':
          notify({
            message: (
              <Trans>Não foi possível enviar sua integração. Verifique e tente novamente.</Trans>
            ),
            variant: 'error'
          });
          break;

        case 'sendReadOnly':
          notify({
            message: <Trans>Você não tem permissão para executar essa ação.</Trans>,
            variant: 'warning'
          });
          break;

        default:
          break;
      }
    };

    removeHighlightError = () => {
      this.setState({ error: false });
    };

    highlightError = () => {
      this.setState({ error: true });
    };

    state = {
      error: false
    };
  }

  const resourceMaps = {
    [DESCRIPTION]: {
      id: SKU,
      label: DESCRIPTION,
      value: DESCRIPTION
    },
    [SKU]: {
      id: SKU,
      label: SKU,
      value: SKU
    }
  };

  const formatOptionFromResource = resourceField => resource => {
    return {
      id: resource[resourceMaps[resourceField].id],
      label: resource[resourceMaps[resourceField].label],
      value: resource[resourceMaps[resourceField].value]
    };
  };

  function styles(theme) {
    return {
      supplier: {
        display: 'flex',
        alignItems: 'center'
      },
      supplierSku: {
        marginLeft: theme.spacing.unit / 2
      },
      notImage: {
        marginRight: 18,
        width: 75,
        height: 75,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#cccccc'
      },
      notImageText: {
        color: '#272727',
        textAlign: 'center',
        opacity: 0.5
      },
      imageContainer: {
        marginRight: 18,
        width: 75,
        height: 75
      },
      info: {
        opacity: 0.75,
        marginLeft: theme.spacing.unit,
        ...theme.typography.subtitle2
      },
      error: {
        border: `2px solid ${theme.palette.error.dark}`
      }
    };
  }

  function initialValuesCreator() {
    const values = {};

    const type = resources.storeResource && resources.storeResource.type;

    values.lensType = resources.supplierResource && resources.supplierResource.lensType;

    values.supplierSku = resources.supplierResource.sku;
    if (type === 'FRAME_SHAPE') values.storeSku = resources.storeResource.sku;
    else {
      values.storeSku = resources.storeResource
        ? {
            value: resources.storeResource.sku,
            id: resources.storeResource.sku,
            label: resources.storeResource.description
          }
        : {};
    }
    values.id = resources.id;
    values.integrated = resources.integrated;

    return values;
  }

  function mapStateToProps(state) {
    const selector = formValueSelector(`integration-${id}`);
    const supplierSku = selector(state, 'supplierSku');
    const integrated = selector(state, 'integrated');
    const storeSku = selector(state, 'storeSku');

    const props = {
      initialValues: initialValuesCreator(),
      formValues: {
        supplierSku,
        storeSku,
        integrated
      }
    };

    return props;
  }

  const ConnectedIntegrationForm = props => (
    <IntegrationConsumer>
      {({
        storeResources,
        supplierResources,
        sendIntegration,
        getResourceFromSku,
        updateResources,
        removeIntegration,
        notify
      }) => (
        <IntegrationForm
          storeResources={storeResources}
          supplierResources={supplierResources}
          sendIntegration={sendIntegration}
          removeIntegration={removeIntegration}
          updateResources={updateResources}
          getResourceFromSku={getResourceFromSku}
          notify={notify}
          {...props}
        />
      )}
    </IntegrationConsumer>
  );

  const IntegratedForm = compose(
    withNotification,
    withStyles(styles),
    connect(mapStateToProps),
    reduxForm({ form: `integration-${id}` })
  )(ConnectedIntegrationForm);

  return <IntegratedForm />;
}

export default createIntegrationForm;
