import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  AppBar,
  Tabs,
  Tab,
  Typography
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Trans } from '@lingui/macro';
import UploadCSV from 'forms/orders/UploadCSV';
import withNotification from 'store/providers/withNotification';
import { compose } from 'redux';
import { uploadResources } from 'services/stores';
import ImageUploadDefault from 'assets/images/DefaultUploadResourses.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const ImportResources = ({ storeId, classes, notify, theme }) => {
  const [open, setOpen] = React.useState(false);
  const [importOpen, setImportOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, index) => {
    setValue(index);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImportOpen(false);
  };

  const notifyByStatus = status => {
    switch (status) {
      case 202:
        setImportOpen(true);
        break;
      case 400:
        notify({
          variant: 'error',
          message: (
            <Trans>
              Por favor, verifique o seu anexo novamente, ele possui um formato inválido para
              upload.
            </Trans>
          )
        });
        break;
      case 403:
        notify({
          variant: 'warning',
          message: <Trans>Você não tem permissão para executar essa ação</Trans>
        });
        break;
      default:
        notify({
          variant: 'error',
          message: (
            <Trans>
              Não foi possível estabelecer conexão com o servidor. Tente novamente mais tarde!
            </Trans>
          )
        });
        break;
    }
  };

  const submit = values => {
    notifyByStatus(202);

    const { file } = values;

    uploadResources(storeId, file).then(response => {
      notifyByStatus(response.status);
    });
  };

  return (
    <>
      <Grid container spacing={24}>
        <Grid item xs={12} md={12}>
          <Paper className={classes.searchPaper}>
            <div className={classes.titleGrid}>
              <h3 className={classes.title}>
                <Trans>Para importar recursos, siga as instruções clicando no botão ao lado.</Trans>
              </h3>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleClickOpen}
              >
                <Trans>Instruções</Trans>
              </Button>
            </div>
            <UploadCSV onSubmit={submit} />
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Precisa de ajuda?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" className={classes.justify}>
            <Trans>
              Siga as instruções a seguir para realizar corretamente a importação dos recursos.
            </Trans>
          </DialogContentText>
          <div>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label={<Trans>Arquivo Manual</Trans>} />
                <Tab label={<Trans>Arquivo Exemplo</Trans>} />
              </Tabs>
            </AppBar>
            <SwipeableViews axis="x" index={value} onChangeIndex={handleChangeIndex}>
              <TabContainer>
                <Trans>
                  <p>
                    O arquivo para importação dos produtos e serviços deve seguir o exemplo abaixo:
                    <img
                      className={classes.imageUpload}
                      src={ImageUploadDefault}
                      alt="Exemplo Upload"
                    />
                  </p>
                </Trans>
                <Trans>
                  <p>
                    <b>1. SKU do produto na ótica:</b> Deve ser preenchido o SKU do produto na
                    ótica.
                  </p>
                </Trans>
                <Trans>
                  <p>
                    <b>2. Descrição do produto na ótica: </b> Deve ser preenchida a descrição do
                    produto na ótica.
                  </p>
                </Trans>
                <Trans>
                  <p>
                    <b>3. Tipo de recurso: </b> Deve ser preenchido o tipo do recurso que está sendo
                    importado.
                  </p>
                </Trans>
                <Trans>
                  <p>
                    <b>4. CNPJ do fornecedor: </b> Deve ser preenchido o CNPJ do laboratório, o
                    campo não é obrigatório, podendo ter seu relacionamento feito após a importação
                    dos produtos.
                  </p>
                </Trans>
                <Trans>
                  <p>
                    <b>5. SKU do produto no laboratório: </b> Deve ser preenchido o SKU do produto
                    no laboratório, podendo relacionar o produto na ótica com mais de um produto no
                    laboratório, os produtos subsequentes devem ser acrescidos na coluna da direita
                    (esse campo não é obrigatório e os produtos podem ser relacionados depois da
                    importação dentro do Acert Conecta) conforme tópico{' '}
                    <i>"Editar um produto importado"</i>.
                  </p>
                </Trans>
              </TabContainer>
              <TabContainer>
                <Trans>
                  <p>
                    <b>Passo 1: </b>Clique{' '}
                    <Link to={process.env.REACT_APP_API_DOWNLOAD} target="_blank" download>
                      aqui
                    </Link>{' '}
                    para realizar o download do exemplo para importação de recursos.
                  </p>
                </Trans>
                <Trans>
                  <p>
                    <b>Passo 2: </b>Preencha as informações dos recursos conforme o exemplo.
                  </p>
                </Trans>
                <Trans>
                  <p>
                    <b>Passo 3: </b>Após o preenchimento não esqueça de apagar as linhas do exemplo,
                    deixando apenas as informações que você inseriu.
                  </p>
                </Trans>
                <Trans>
                  <p>
                    <b>Passo 4: </b>Finalizado o preenchimento e salvo as alterações, carregue o
                    arquivo no campo de importação e clique em enviar.
                  </p>
                </Trans>
              </TabContainer>
            </SwipeableViews>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Trans>Fechar</Trans>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={importOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Importação</DialogTitle>
        <DialogContent>
          <Trans>
            Dados recebidos com sucesso, dentro de alguns instantes eles estarão disponíveis para
            uso. Agradecemos sua compreensão.
          </Trans>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Trans>Ok</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  },
  searchPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px'
  },
  titleGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  title: {
    fontWeight: 'normal',
    fontSize: '16px'
  },
  searchBar: {
    display: 'flex'
  },
  button: {
    alignSelf: 'flex-end',
    marginBottom: '10px'
  },
  instructinos: {
    '& p': {
      margin: '0 0 8px'
    }
  },
  justify: {
    textAlign: 'justify'
  },
  imageUpload: {
    width: '100%',
    marginTop: '5px'
  }
});

ImportResources.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  storeId: PropTypes.number.isRequired,
  notify: PropTypes.func.isRequired
};

export default compose(withNotification, withStyles(styles))(ImportResources);
