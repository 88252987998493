import React from 'react';
import { Field } from 'redux-form-normalize-on-blur';
import PrescriptionInput from 'components/form/inputs/prescription-input';
import { decimalFormat } from 'utils/formatters/formatters';
import { isMultipleOf } from 'components/form/validation';

export const AdditionField = ({ step = '0.25', ...props }) => (
  <Field
    {...props}
    step={step}
    component={PrescriptionInput}
    type="number"
    normalizeOnBlur={value => decimalFormat(value)}
    validate={[isMultipleOf025]}
  />
);
const isMultipleOf025 = isMultipleOf(0.25);

export default AdditionField;
