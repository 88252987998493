import { ORDERS_ENDPOINT, STORES_ENDPOINT } from '../endpoints';
import { defaultHeaders, fetcher, flatFetcher, flatFetcherRest } from '../fetcher';
import { patchOrderInterceptor, insertOrderInterceptor as interceptor } from '../interceptor';

export function insertReducedOrder({ storeId, ...data }) {
  return fetcher(
    `${ORDERS_ENDPOINT}/reduced/${storeId}`,
    {
      method: 'POST',
      body: JSON.stringify({
        ...data
      })
    },
    interceptor
  );
}

export const patchOrder = (orderId, storeId, data, interceptor) =>
  fetcher(
    `${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}`,
    {
      method: 'PATCH',
      body: JSON.stringify({ orderId, storeId, ...data })
    },
    interceptor
  );

export const patchOrderWithNotification = (orderId, storeId, data) =>
  patchOrder(orderId, storeId, data, patchOrderInterceptor);

export const getSupplierRequest = (orderId, storeId) =>
  flatFetcher(`${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}/purchases`, {
    method: 'GET'
  });

export const getSupplierStatus = (orderId, storeId) =>
  flatFetcher(`${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}/purchase-statuses`, {
    method: 'GET'
  });

export const getWebPedidos = (orderId, storeId, supplierId) =>
  flatFetcherRest(`${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}/lab-tracking/${supplierId}`, {
    method: 'GET'
  }).then(response => {
    return response;
  });

export const getSupplierRequestChart = (storeId, ìnitial_date, final_date) =>
  flatFetcher(
    `${ORDERS_ENDPOINT}/purchases?storesId=${storeId}&initialDate=${ìnitial_date}&finalDate=${final_date}`,
    {
      method: 'GET'
    }
  );

export function fetchOrders(storeId, status = '') {
  const fetchOrdersUrl = `${ORDERS_ENDPOINT}?storesId=${storeId}&simplified=false&orderStatus=${status}`;

  const fetchOrdersoptions = {
    method: 'GET'
  };

  return flatFetcher(fetchOrdersUrl, fetchOrdersoptions);
}

export const searchOrder = (storeId, orderNumber) =>
  flatFetcher(
    `${ORDERS_ENDPOINT}?storesId=${storeId}&simplified=false&search=orderNumber=="*${orderNumber}*"`,
    {
      method: 'GET'
    }
  );

export function fetchPaginatedOrders(storeId, page, lane) {
  const fetchOrdersUrl = `${ORDERS_ENDPOINT}?storesId=${storeId}&simplified=false&page=${page}&orderStatus=${lane}`;

  const fetchOrdersoptions = {
    method: 'GET'
  };

  return flatFetcher(fetchOrdersUrl, fetchOrdersoptions);
}

export function fetchOrder(orderId, storeId) {
  const fetchOrdersUrl = `${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}`;

  const fetchOrdersoptions = {
    method: 'GET'
  };

  return flatFetcher(fetchOrdersUrl, fetchOrdersoptions);
}

export const changeDueDate = (orderId, storeId, data) =>
  fetcher(`${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}`, {
    method: 'PATCH',
    body: JSON.stringify({ orderId, storeId, ...data })
  });

export const sendOrderSupplier = (orderId, storeId, orderStatus) =>
  fetcher(`${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}`, {
    method: 'PATCH',
    body: JSON.stringify({ orderId, storeId, orderStatus })
  });

export const getPromotionalOrder = (orderId, storeId) =>
  flatFetcher(`${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}/promotions-available`, {
    method: 'GET'
  });

export const postPromotionalOrder = (orderId, storeId, promoData) =>
  fetcher(`${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}/promotions`, {
    method: 'POST',
    body: JSON.stringify(promoData)
  });

export const getAttachOrder = (orderId, storeId) =>
  flatFetcher(`${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}/attachments`, {
    method: 'GET'
  });

export const removeAttachOrder = (orderId, storeId, attachId) =>
  fetcher(`${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}/attachments/${attachId}`, {
    method: 'DELETE'
  });

export function addAttachOrder(orderId, storeId, fileUpload, description) {
  const attachedFile = new FormData();

  attachedFile.append('file', fileUpload);
  attachedFile.append('description', description);

  const fileUploadHeaders = { ...defaultHeaders() };
  delete fileUploadHeaders['Content-Type'];

  const fileUploadOptions = {
    method: 'POST',
    body: attachedFile,
    headers: fileUploadHeaders
  };

  return fetcher(`${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}/attachments`, fileUploadOptions);
}

export function editOrder(orderId, storeId, orderData) {
  const fetchOrdersUrl = `${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}`;

  const fetchOrdersoptions = {
    method: 'PUT',
    body: JSON.stringify(orderData)
  };

  return fetcher(fetchOrdersUrl, fetchOrdersoptions);
}

export const sendOrderToSupplier = (orderId, storeId, orderData) =>
  fetcher(`${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}/onsupplier`, {
    method: 'POST',
    body: JSON.stringify(orderData)
  });

export function copyOrder(orderId, storeId, orderData) {
  const url = `${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}/replicas`;

  const options = {
    method: 'POST',
    body: JSON.stringify(orderData)
  };

  return fetcher(url, options);
}

export function getOrderCopies(orderId, storeId) {
  const url = `${ORDERS_ENDPOINT}/${orderId}/stores/${storeId}/replicas`;

  const options = {
    method: 'GET'
  };

  return flatFetcher(url, options);
}
