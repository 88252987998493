import { Trans } from '@lingui/macro';
import HistoryIcon from '@material-ui/icons/History';
import PersonIcon from '@material-ui/icons/Person';
import StoreIcon from '@material-ui/icons/Store';
import IntegrationIcon from '@material-ui/icons/SwapHoriz';
import TrendingUp from '@material-ui/icons/TrendingUp';
import { Archive } from 'mdi-material-ui';
import React from 'react';
import ArchivedOrders from 'views/ArchivedOrders';
import HistoricLane from 'views/historicLane';
import Home from 'views/home';
import Indicators from 'views/indicators';
import ChooseStore from 'views/chooseStore';
import Integration from 'views/integration/';
import Login from 'views/login';
import NewStore from 'views/store/new';
import StoreSettings from 'views/store/settings';
import StoreUsers from 'views/store/users';
import Stores from 'views/stores';
import UserConfirmation from 'views/user-confirmation';
import UserProfile from 'views/user-profile/';

export const routesIndex = [
  {
    title: <Trans>Minhas Lojas</Trans>,
    icon: StoreIcon,
    path: '/stores',
    exact: true,
    private: true,
    view: Stores,
    menus: ['profile', 'mobile']
  },
  {
    exact: true,
    menus: [],
    path: '/store/new',
    private: true,
    title: <Trans>Nova Loja</Trans>,
    view: NewStore
  },
  {
    exact: true,
    menus: [],
    path: '/store/:id/users',
    private: true,
    title: <Trans>Usuários</Trans>,
    view: StoreUsers
  },
  {
    exact: true,
    menus: [],
    path: '/store/:id/settings/:tab?',
    private: true,
    title: <Trans>Configuração da loja</Trans>,
    view: StoreSettings
  },
  {
    exact: true,
    menus: [],
    path: '/home/:textFilter?',
    private: true,
    title: <Trans>Home</Trans>,
    view: Home
  },
  {
    exact: true,
    menus: ['mobile', 'desktop'],
    icon: HistoryIcon,
    path: '/historic-lane',
    private: true,
    title: <Trans>Historico de entradas e saídas por pedido em cada status</Trans>,
    view: HistoricLane
  },
  {
    exact: true,
    menus: ['mobile', 'desktop'],
    icon: IntegrationIcon,
    path: '/integration/:storeId?/:supplierId?/:type?',
    private: true,
    title: <Trans>Integração com produtos do fornecedor</Trans>,
    view: Integration
  },
  {
    exact: true,
    icon: PersonIcon,
    menus: ['profile', 'mobile'],
    path: '/user-profile',
    private: true,
    title: <Trans>Meu perfil</Trans>,
    view: UserProfile
  },
  {
    exact: true,
    menus: ['mobile', 'desktop'],
    icon: TrendingUp,
    path: '/indicators',
    private: true,
    title: <Trans>Indicadores</Trans>,
    view: Indicators
  },

  {
    exact: true,
    icon: TrendingUp,
    path: '/choose-store',
    private: true,
    title: <Trans>Escolher Loja</Trans>,
    view: ChooseStore
  },

  //
  // ────────────────────────────────────────────────────  ──────────
  //   :::::: R O U T E S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  // Espaço para novas rotas, ao fim de todas sprint deve ser organizado por
  // regras de negócios

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: J O A O : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //
  // ────────────────────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── II ──────────
  //   :::::: F I L I P E : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  // ────────────────────────────────────────────────────────────────────────────────

  //
  // ────────────────────────────────────────────────────── III ──────────
  //   :::::: R O D R I G O : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  {
    path: '/user-confirmation/:userId/:userToken/:redirectTo?',
    title: <Trans>Confirmação de usuário</Trans>,
    view: UserConfirmation
  },
  {
    exact: true,
    path: '/login/:page?/:accessToken?',
    view: Login
  },
  {
    path: '/archived-orders/',
    title: <Trans>Pedidos arquivados</Trans>,
    menus: ['mobile', 'desktop'],
    view: ArchivedOrders,
    private: true,
    icon: Archive
  }
  // ────────────────────────────────────────────────────────────────────────────────
];
