import Dialog from 'components/dialog';
import React from 'react';
import { OrdersConsumer } from './OrdersProvider';

const ConnectedBaseOrderDialog = props => (
  <OrdersConsumer>
    {({ closeDialog, dialogs, changeOrder }) => (
      <Dialog
        changeOrder={changeOrder}
        closeDialog={closeDialog}
        open={Boolean(dialogs[props.name])}
        {...props}
      />
    )}
  </OrdersConsumer>
);

export default ConnectedBaseOrderDialog;
