import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

export const hocComponentName = WrappedComponent => {
  const hocComponent = props => {
    const { location, history } = props;
    const params = queryString.parse(location.search, {
      arrayFormat: 'bracket',
      encode: true
    });

    const query = {
      params,
      stringify: () =>
        queryString.stringify(query.params, {
          arrayFormat: 'bracket',
          encode: true
        }),
      set: ({ property, value }) => {
        query.params[property] = value;
        const search = query.stringify();
        history.push({ search });
      },
      remove: ({ property }) => {
        delete query.params[property];
        const search = query.stringify();
        history.push({ search });
      }
    };

    return <WrappedComponent query={query} {...props} />;
  };

  hocComponent.propTypes = {};

  return hocComponent;
};

export default WrapperComponent =>
  compose(
    withRouter,
    hocComponentName
  )(WrapperComponent);
