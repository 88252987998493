import { Trans } from '@lingui/macro';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

export default function GenericFileInput(props) {
  const {
    icon,
    input: { onChange, name, value }
  } = props;

  function handleChange(event) {
    const file = event.target.files[0];
    event.target.value = null;
    onChange(file);
  }

  return (
    <label htmlFor={name}>
      <input
        style={{ display: 'none' }}
        id={name}
        accept=".xml,.dat,.txt,.oma,.mdk,.csv,.vca"
        onChange={handleChange}
        type="file"
      />
      <Tooltip placement="right-start" title={value.name || <Trans>Fazer upload do arquivo</Trans>}>
        <IconButton component="div" color={value && 'primary'}>
          {icon}
        </IconButton>
      </Tooltip>
    </label>
  );
}

GenericFileInput.propTypes = {
  icon: PropTypes.node.isRequired,
  input: PropTypes.shape({
    accept: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.bool
  }).isRequired
};
