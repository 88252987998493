import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';

function BarSettings({ value, handleChange, children }) {
  return (
    <Paper>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
      >
        {children}
      </Tabs>
    </Paper>
  );
}

export default BarSettings;
