import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Trans } from '@lingui/macro';

export const prescriptionTranslator = ({ field, message, eye }) => {
  const translatedMessage = translateMessage(message);
  return { field, translatedMessage, eye };
};

const translateMessage = message => {
  let translatedMessage = translateStaticMessage(message);
  if (!translatedMessage) {
    translatedMessage = translateDinamicMessage(message);
  }
  return translatedMessage;
};

const translateDinamicMessage = message => {
  let result = null;
  const rangeNumbers = extractNumbers(message);
  if (message.includes('Invalid range for addition having a spherical')) {
    result = `Intervalo inválido para adição contendo um grau esférico. Deve estar entre ${rangeNumbers[0]}{' '}
        e ${rangeNumbers[1]}`;
  } else if (message.includes('Invalid range for axis')) {
    result = `Intervalo Inválido para eixo. Deve estar entre ${rangeNumbers[0]} e ${rangeNumbers[1]}`;
  } else if (message.includes('Invalid range for spherical')) {
    result = `Intervalo inválido para grau esférico. Deve estar entre
        ${rangeNumbers[0]} e ${rangeNumbers[1]}`;
  } else if (message.includes('Invalid range for cylindrical')) {
    result = `Intervalo inválido para grau cilíndrico. Deve estar entre
        ${rangeNumbers[0]} e ${rangeNumbers[1]}`;
  } else if (message.includes('Invalid range for Horizontal Prism Value')) {
    result = `Intervalo inválido para prisma horizontal. Deve estar entre
        ${rangeNumbers[0]} e ${rangeNumbers[1]}`;
  } else if (message.includes('Invalid range for Vertical Prism Value')) {
    result = `Intervalo inválido para prisma vertical. Deve estar entre
        ${rangeNumbers[0]} e ${rangeNumbers[1]}`;
  } else if (message.includes('Invalid range for vbox')) {
    result = `Intervalo inválido para altura da armação. Deve estar entre
        ${rangeNumbers[0]} e ${rangeNumbers[1]}`;
  } else if (message.includes('Invalid range for largest diameter')) {
    result = `Intervalo inválido para DMA. Deve estar entre
        ${rangeNumbers[0]} e ${rangeNumbers[1]}`;
  } else if (message.includes('Invalid range for inter pupillary distance')) {
    result = `Intervalo inválido para DNP. Deve estar entre
        ${rangeNumbers[0]} e ${rangeNumbers[1]}`;
  } else if (message.includes('Invalid range for fitting height')) {
    result = `Intervalo inválido para altura de montagem. Deve estar entre
        ${rangeNumbers[0]} e ${rangeNumbers[1]}`;
  } else if (message.includes('Invalid range for distance between lenses')) {
    result = `Intervalo inválido para a DPA. Deve estar entre ${rangeNumbers[0]} e ${rangeNumbers[1]}`;
  }
  return result;
};

const extractNumbers = message => message.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g).map(Number);

const translateStaticMessage = message => {
  let result = null;

  switch (message) {
    case 'Invalid scale of 0.25 for addition':
      result = <Trans>Escala inválida de 0.25 para adição.</Trans>;
      break;
    case 'Invalid value for addition having a spherical':
      result = <Trans>Valor inválido para adição contendo um grau esférico.</Trans>;
      break;
    case 'Invalid value for axis having a cylindrical':
      result = <Trans>Valor inválido para eixo contendo um grau cilíndrico.</Trans>;
      break;
    case 'Invalid scale of 0.25 for spherical':
      result = <Trans>Escala inválida de 0.25 para grau esférico.</Trans>;
      break;
    case 'Invalid value for spherical having a cylindrical':
      result = <Trans>Valor inválido para grau esférico contendo um grau cilíndrico.</Trans>;
      break;
    case 'Invalid value for cylindrical having an axis':
      result = <Trans>Valor inválido para grau cilíndrico contendo um eixo.</Trans>;
      break;
    case 'Invalid scale of 0.25 for cylindrical':
      result = <Trans>Escala inválida de 0.25 para grau cilíndrico.</Trans>;
      break;
    case 'Invalid value for horizontal prism':
      result = <Trans>Valor inválido para prisma horizontal.</Trans>;
      break;
    case 'Invalid axis for horizontal prism':
      result = <Trans>Eixo de prisma horizontal inválido.</Trans>;
      break;
    case 'Invalid value for vertical prism':
      result = <Trans>Valor inválido para prisma vertical.</Trans>;
      break;
    case 'Invalid axis for vertical prism':
      result = <Trans>Eixo inválido para prisma vertical.</Trans>;
      break;
    case 'Invalid value for fitting Height':
      result = <Trans>Altura de montagem inválida.</Trans>;
      break;
    case 'Invalid value for vbox':
      result = <Trans>Valor inválido para altura da armação.</Trans>;
      break;
    default:
      result = null;
      break;
  }
  return result ? ReactDOMServer.renderToStaticMarkup(result) : null;
};
