import { createActions, createReducer } from 'reduxsauce';
import { getUserByID } from 'services/users';
import { call, put, takeLatest } from 'redux-saga/effects';

const persistedUserId = localStorage.getItem('userId');

export const INITIAL_STATE = {
  id: null,
  profilePicture: '',
  imageUrl: '',
  userId: persistedUserId,
  email: localStorage.getItem('email'),
  name: '',
  isEnabled: false,
  isSystem: false,
  imageError: false,
  userDetail: null
};

function getUser(state = INITIAL_STATE) {
  return {
    ...state
  };
}

function receiveUser(
  state = INITIAL_STATE,
  { payload: { userId, imageUrl, profilePicture, email, name, isEnabled, isSystem, userDetail } }
) {
  return {
    ...state,
    userId,
    name,
    isEnabled,
    imageUrl,
    isSystem,
    userDetail,
    email,
    profilePicture
  };
}

function changeProfileImage(state = INITIAL_STATE, action) {
  const { payload: imageUrl } = action;

  return {
    ...state,
    imageUrl
  };
}

function changeImageStatus(state = INITIAL_STATE, action) {
  const { payload: imageError } = action;

  return {
    ...state,
    imageError
  };
}

export const { Types, Creators } = createActions({
  getUser: ['payload'],
  receiveUser: ['payload'],
  changeProfileImage: ['payload'],
  changeImageStatus: ['payload']
});

export function* userHandler({ payload: { userId } }) {
  try {
    const { name, isEnabled, imageUrl, profilePicture, isSystem, userDetail, email } = yield call(
      getUserByID,
      userId
    );
    yield put(
      Creators.receiveUser({
        userId,
        name,
        isEnabled,
        imageUrl,
        profilePicture,
        isSystem,
        userDetail,
        email
      })
    );
  } catch (err) {
    console.error(err);
    yield put(Creators.receiveUser({ userId: null, email: null }));
  }
}

export function* watchUser() {
  yield takeLatest(Types.GET_USER, userHandler);
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_USER]: getUser,
  [Types.RECEIVE_USER]: receiveUser,
  [Types.CHANGE_PROFILE_IMAGE]: changeProfileImage,
  [Types.CHANGE_IMAGE_STATUS]: changeImageStatus
});
