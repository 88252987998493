import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';

function PrescriptionInput({
  input,
  meta: { error, touched },
  step,
  onFocus,
  variant = 'outlined',
  margin = 'dense',
  ...props
}) {
  function handleChange(event) {
    const { onChangeCallback } = props;
    const { onChange } = input;
    const {
      target: { value }
    } = event;

    onChange(value);
    onChangeCallback && onChangeCallback(event);
  }

  const { classes } = props;

  return (
    <TextField
      {...input}
      {...props}
      inputProps={{ step }}
      error={touched && Boolean(error)}
      onFocus={input.onFocus}
      onChange={props.onChangeCallback ? handleChange : input.onChange}
      variant={variant}
      margin={margin}
      className={input.value && parseFloat(input.value) < 0 ? classes.negative : classes.positive}
    />
  );
}

PrescriptionInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  step: PropTypes.string,
  variant: PropTypes.string,
  margin: PropTypes.string
};

export default PrescriptionInput;
