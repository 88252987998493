import React, { useCallback } from 'react';
import { Field } from 'redux-form-normalize-on-blur';
import PrescriptionInput from 'components/form/inputs/prescription-input';

export const NumberNegativeField = ({ step = 0.25, negativeSpherical, ...props }) => {
  const isMultipleOfStep = useCallback(
    value => (value % step !== 0 ? `O valor deve ser múltiplo de ${step}` : undefined),
    [step]
  );
  return (
    <Field
      {...props}
      step={step}
      component={PrescriptionInput}
      type="number"
      validate={[isMultipleOfStep]}
    />
  );
};

export default NumberNegativeField;
