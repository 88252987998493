import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Header from './Header';
import Overlay from '../overlay';

export class Dashboard extends Component {
  render() {
    const { classes, children } = this.props;
    return (
      <div className={classes.root}>
        <Header />
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
        <Overlay />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    overflowX: 'hidden',
    backgroundColor: theme.palette.grey[300]
  },
  container: {
    width: '100%',
    minHeight: '100vh',
    overflowX: 'hidden',
    '@media screen and (max-width: 1610px)': {
      minHeight: '91vh'
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 6}px`
    }
  }
});

export default withStyles(styles)(Dashboard);
