import { createReducer, createActions } from 'reduxsauce';
import { call, takeLatest, put } from 'redux-saga/effects';
import { fetchSuppliers } from 'services/suppliers';

const INITIAL_STATE = [];

const getSuppliers = (state = INITIAL_STATE, action) => state;

const receiveSuppliers = (state = INITIAL_STATE, { payload: { data } }) => [...data];

export const { Types, Creators } = createActions({
  getSuppliers: ['payload'],
  receiveSuppliers: ['payload']
});

export function* suppliersHandler() {
  const data = yield call(fetchSuppliers);
  yield put(Creators.receiveSuppliers({ data }));
}

export function* watchSuppliers() {
  yield takeLatest(Types.GET_SUPPLIERS, suppliersHandler);
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_SUPPLIERS]: getSuppliers,
  [Types.RECEIVE_SUPPLIERS]: receiveSuppliers
});
