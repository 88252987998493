import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';

function Menu({ children, innerProps, selectProps, ...props }) {
  const { classes } = selectProps;
  return (
    <Grow in={selectProps.menuIsOpen}>
      <Paper className={classes.menu} {...innerProps} {...props}>
        {children}
      </Paper>
    </Grow>
  );
}

export default Menu;
