import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import AddAttachToOrderDialog from './AddAttachToOrderDialog';
import FrameDesignDialog from './FrameDesignDialog';
import ArchiveOrderDialog from './ArchiveOrderDialog';
import ChangeDueDateDialog from './ChangeDueDateDialog';
import CopyOrderDialog from './CopyOrderDialog';
import OrderAttachs from './OrderAttachs';
import OrderAttachsNotAdd from './OrderAttachsNotAdd';
import OrderCopiesDialog from './OrderCopiesDialog';
import OrderHistoryDialog from './OrderHistoryDialog';
import OrderPromotionalDialog from './OrderPromotionalDialog';
import { OrdersConsumer } from './OrdersProvider';
import RemoveAttachFromOrderDialog from './RemoveAttachFromOrderDialog';
import SendOrderDialog from './SendOrderDialog';
import SupplierRequestDialog from './supplierRequest/SupplierRequestDialog';
import SupplierStatusDialog from './supplierStatus/SupplierStatusDialog';
import UpdateOrderDialog from './UpdateOrderDialog';
import AddEditProductDialog from './AddEditProductDialog';
import InsertOrderDialog from './new/InsertOrderDialog';
import AddEditMembersDialog from './AddEditMembersDialog';
import IndividualParametersDialog from './IndividualParameters';
import AnamneseDialog from './Anamnese';

class OrdersDialogs extends PureComponent {
  render() {
    const {
      actions,
      changeOrder,
      closeDialog,
      dialogs,
      notify,
      fetchOrders,
      getOrderById,
      getStoreById,
      openDialog,
      getStoreResourcesDesign,
      changeDueDate
    } = this.props;
    return (
      <>
        <UpdateOrderDialog
          open={Boolean(dialogs.updateOrder)}
          order={dialogs.updateOrder}
          fetchOrders={fetchOrders}
          getOrderById={getOrderById}
          closeDialog={closeDialog('updateOrder')}
          getStoreById={getStoreById}
          getStoreResourcesDesign={getStoreResourcesDesign}
        />

        <CopyOrderDialog
          open={Boolean(dialogs.copyOrder)}
          order={dialogs.copyOrder}
          openDialog={openDialog}
          fetchOrders={fetchOrders}
          getOrderById={getOrderById}
          getStoreById={getStoreById}
          closeDialog={closeDialog}
          getStoreResourcesDesign={getStoreResourcesDesign}
        />
        <OrderCopiesDialog
          open={Boolean(dialogs.orderCopies)}
          order={dialogs.orderCopies}
          getOrderById={getOrderById}
          openDialog={openDialog}
        />
        <ArchiveOrderDialog
          changeOrder={changeOrder}
          order={dialogs.archiveOrder}
          closeDialog={closeDialog}
        />
        <ChangeDueDateDialog
          changeOrder={changeOrder}
          order={dialogs.changeDueDate}
          closeDialog={closeDialog('changeDueDate')}
          notify={notify}
          changeDueDate={changeDueDate}
          fetchOrders={fetchOrders}
        />
        <OrderHistoryDialog order={dialogs.orderHistory} />

        <SendOrderDialog
          open={Boolean(dialogs.sendOrder)}
          closeDialog={closeDialog}
          order={dialogs.sendOrder}
          getOrderById={getOrderById}
          getStoreById={getStoreById}
          fetchOrders={fetchOrders}
        />
        <SupplierRequestDialog order={dialogs.supplierRequest} />

        <SupplierStatusDialog order={dialogs.supplierStatus} />

        <OrderPromotionalDialog
          open={Boolean(dialogs.orderPromotional)}
          changeOrder={changeOrder}
          closeDialog={closeDialog}
          order={dialogs.orderPromotional}
          getOrderById={getOrderById}
          getStoreById={getStoreById}
          fetchOrders={fetchOrders}
        />

        <OrderAttachs
          open={Boolean(dialogs.orderAttachs)}
          closeDialog={closeDialog}
          actions={actions}
          openDialog={openDialog}
          order={dialogs.orderAttachs}
        />

        <OrderAttachsNotAdd
          open={Boolean(dialogs.orderAttachsNotAdd)}
          closeDialog={closeDialog}
          actions={actions}
          openDialog={openDialog}
          order={dialogs.orderAttachsNotAdd}
        />

        <AddAttachToOrderDialog
          open={Boolean(dialogs.addAttachToOrder)}
          closeDialog={closeDialog('addAttachToOrder')}
          payload={dialogs.addAttachToOrder}
        />

        <FrameDesignDialog
          open={Boolean(dialogs.frameDesign)}
          closeDialog={closeDialog}
          payload={dialogs.frameDesign}
        />

        <RemoveAttachFromOrderDialog
          open={Boolean(dialogs.removeAttachFromOrder)}
          closeDialog={closeDialog('removeAttachFromOrder')}
          payload={dialogs.removeAttachFromOrder}
        />

        <AddEditProductDialog
          open={Boolean(dialogs.addEditProduct)}
          closeDialog={closeDialog('addEditProduct')}
          payload={dialogs.addEditProduct}
        />
        <InsertOrderDialog
          open={Boolean(dialogs.insertOrder)}
          closeDialog={closeDialog('insertOrder')}
          handleDialogs={actions}
        />
        <AddEditMembersDialog
          open={Boolean(dialogs.addEditMembers)}
          closeDialog={closeDialog('addEditMembers')}
          payload={dialogs.addEditMembers}
        />
        <AddEditMembersDialog
          open={Boolean(dialogs.addEditMembers)}
          closeDialog={closeDialog('addEditMembers')}
          payload={dialogs.addEditMembers}
        />
        <IndividualParametersDialog
          open={Boolean(dialogs.individualParameters)}
          closeDialog={closeDialog('individualParameters')}
          order={dialogs.individualParameters}
          getOrderById={getOrderById}
        />
        <AnamneseDialog
          open={Boolean(dialogs.anamnese)}
          closeDialog={closeDialog('anamnese')}
          order={dialogs.anamnese}
          getOrderById={getOrderById}
        />
      </>
    );
  }

  static propTypes = {
    closeDialog: PropTypes.func,
    dialogs: PropTypes.object
  };
}

const ConnectedOrdersDialogs = props => (
  <OrdersConsumer>
    {({
      actions,
      closeDialog,
      dialogs,
      changeOrder,
      fetchOrders,
      getOrderById,
      openDialog,
      notify,
      getStoreById,
      getStoreResourcesDesign,
      changeDueDate
    }) => (
      <OrdersDialogs
        actions={actions}
        changeOrder={changeOrder}
        closeDialog={closeDialog}
        openDialog={openDialog}
        dialogs={dialogs}
        notify={notify}
        fetchOrders={fetchOrders}
        getOrderById={getOrderById}
        getStoreById={getStoreById}
        getStoreResourcesDesign={getStoreResourcesDesign}
        changeDueDate={changeDueDate}
        {...props}
      />
    )}
  </OrdersConsumer>
);

export default ConnectedOrdersDialogs;
