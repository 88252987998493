import { Trans } from '@lingui/macro';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import Chart from 'react-google-charts';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from 'components/utils/Loading';
import { getOrderReceived } from 'services/historical';
import { getUserStores } from 'services/stores';
import withNotification from 'store/providers/withNotification';

const dateFormat = require('dateformat');

class ChartOrderReceived extends Component {
  render() {
    const { classes } = this.props;
    const isOrderReceived = this.state.chartData.length > 1;
    return (
      <>
        {this.state.loading ? (
          <Loading />
        ) : isOrderReceived ? (
          <Paper className={classes.chartContainer}>
            <Chart
              className={classes.chart}
              chartType="ComboChart"
              loader={<Loading />}
              data={this.state.chartData}
              options={{
                title: ReactDOMServer.renderToStaticMarkup(
                  <Trans>Quantidade de pedidos recebidos</Trans>
                ),
                vAxis: {
                  title: ReactDOMServer.renderToStaticMarkup(<Trans>Pedidos recebidos</Trans>)
                },
                seriesType: 'bars',
                series: { 5: { type: 'line' } }
              }}
              rootProps={{ 'data-testid': '1' }}
            />
          </Paper>
        ) : (
          <Typography component="h1" variant="h6" color="textSecondary">
            <Trans>Pedidos recebidos não encontrados nesse período</Trans>
          </Typography>
        )}
      </>
    );
  }

  state = {
    store: ['all'],
    periodMonth: 1,
    dateTime: null,
    chartData: [],
    checkAllStore: false,
    loading: false,
    storeID: [],
    GetstoreID: [],
    storeData: []
  };

  componentDidUpdate(prevProps) {
    const hasDateChanged = prevProps.filters.date !== this.props.filters.date;
    const hasStoreChanged = prevProps.filters.store !== this.props.filters.store;
    const hasPeriodChanged = prevProps.filters.period !== this.props.filters.period;

    if (hasDateChanged || hasStoreChanged || hasPeriodChanged) {
      this.update();
    }
  }

  async update() {
    this.setState({ loading: true });
    // Recebendo storeId
    const receivingStoreId = [];
    // Recebendo dados da API
    const receivingStoreAPI = [];
    // Populando o select
    const receivingStoreAPIData = [];
    // Recebendo todas as lojas relacionadas ao usuario
    const receivingDataStore = await getUserStores(this.props.user.email);

    let now;

    if (this.props.filters.date === null) {
      now = new Date();
    } else {
      now = new Date(this.props.filters.date);
    }

    // Variavel data anterior
    const before = new Date(
      now.getFullYear(),
      now.getMonth() - this.props.filters.period,
      now.getDay()
    );
    // Data atual
    const nowDate = `${dateFormat(now, 'yyyy-mm-dd')}T23:59:59`;
    // Data antes
    const beforeDate = `${dateFormat(before, 'yyyy-mm-dd')}T23:59:59`;

    for (let j = 0; j < receivingDataStore.length; j += 1) {
      receivingStoreId.push(receivingDataStore[j].store.id);
    }

    if (this.props.filters.store[0] === 'all') {
      this.setState({ storeID: receivingStoreId });
    } else {
      this.setState({ storeID: this.props.filters.store });
    }

    const historicalData = await getOrderReceived(this.state.storeID, beforeDate, nowDate);

    let f = 0;
    do {
      for (let k = 0; k < historicalData.length; k += 1) {
        if (historicalData[k].storeId === receivingDataStore[f].store.id) {
          receivingStoreAPI.push([
            receivingDataStore[f].store.tradingName,
            historicalData[k].amount
          ]);
          receivingStoreAPIData.push(receivingDataStore[f].store);
        }
      }
      f++;
    } while (f < receivingStoreId.length);

    const chartData = [['Nome da loja', 'Pedidos']];

    for (let i = 0; i < receivingStoreAPI.length; i += 1) {
      chartData.push(receivingStoreAPI[i]);
    }

    if (this.state.storeData.length === 0) {
      this.setState({
        storeData: receivingStoreAPIData
      });
    }
    this.setState({
      chartData,
      loading: false
    });
  }

  async componentWillMount() {
    this.update();
  }
}

const styles = theme => ({
  chart: {
    minWidth: '66vw',
    minHeight: '66vh'
  },
  chartContainer: {
    padding: theme.spacing.unit * 2,
    width: 'fit-content',
    minWidth: `calc(66vw + ${theme.spacing.unit * 2}px)`,
    minHeight: `calc(66vh + ${theme.spacing.unit * 2}px)`,
    display: 'flex',
    jusitfyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  }
});

function mapStateToProps(state) {
  return { user: state.user };
}

export default compose(
  connect(mapStateToProps),
  withNotification,
  withStyles(styles)
)(ChartOrderReceived);
