import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import withNotification from 'store/providers/withNotification';
import withOverlay from 'store/providers/withOverlay';
import ResetPassword from './ResetPassword';

export default compose(
  withRouter,
  withOverlay,
  withNotification,
  reduxForm({ form: 'Recover Password' })
)(ResetPassword);
