import React, { Component } from 'react';
import { required, requiredAutocomplete } from 'components/form/fields/validation';

import withNotification from 'store/providers/withNotification';
import withOverlay from 'store/providers/withOverlay';
import Grid from '@material-ui/core/Grid';
import TextField from 'components/form/fields/text';
import EmailField from 'components/form/fields/email';
import PasswordField from 'components/form/fields/password';
import AutocompleteField from 'components/form/fields/autocomplete';
import Button from 'components/buttons/button';
import IconAdd from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose } from 'redux';
import { addSupplier as fetchSupplier } from 'services/stores';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';

class AddSupplier extends Component {
  render() {
    const { classes, options, handleSubmit } = this.props;
    const { integrationTypeElement } = this.state;
    return (
      <form onSubmit={handleSubmit(this.submit.bind(this))} className={classes.form}>
        <Grid container spacing={24}>
          <Grid item xs={12} md={12} style={{ padding: 0 }}>
            <AutocompleteField
              fullWidth
              name="supplier"
              label={<Trans>Fornecedor</Trans>}
              validate={[required]}
              onChange={this.handleChange}
              options={options.map(option => ({
                label: option.companyName,
                value: option.id,
                id: option.cnpj
              }))}
            />
          </Grid>

          {integrationTypeElement === 'NONE' ||
          integrationTypeElement === 'SGO_WS' ||
          integrationTypeElement === 'REST_ACERT' ? (
            <>
              <Grid container spacing={24}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="storePurchaseCode"
                    label={<Trans>Meu código de cliente</Trans>}
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="awardsCode"
                    label={<Trans>Código na plataforma de prêmios</Trans>}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={24}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    name="defaultMessage"
                    label={<Trans>Mensagem Padrão</Trans>}
                  />
                </Grid>
              </Grid>

              <Grid container justify="flex-end" spacing={24}>
                <Grid item>
                  <Button type="submit">
                    <IconAdd />
                    <Trans>Adicionar fornecedor</Trans>
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {integrationTypeElement === 'FTP_ZEISS' ? (
                <>
                  <Grid container spacing={24}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="storePurchaseCode"
                        label={<Trans>Meu código de cliente</Trans>}
                        validate={[required]}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="defaultMessage"
                        label={<Trans>Mensagem Padrão</Trans>}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={24}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="integrationUser"
                        label={<Trans>Usuário Zeiss</Trans>}
                        validate={[required]}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PasswordField
                        name="integrationPassword"
                        fullWidth
                        label={<Trans>Senha Zeiss</Trans>}
                        validate={[required]}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justify="flex-end" spacing={24}>
                    <Grid item>
                      <Button type="submit">
                        <IconAdd />
                        <Trans>Adicionar fornecedor</Trans>
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                undefined
              )}
            </>
          )}
        </Grid>
      </form>
    );
  }

  state = {
    integrationTypeElement: null
  };

  static propTypes = {
    suppliersLists: PropTypes.arrayOf(PropTypes.object)
  };

  handleChange = value => {
    const { options } = this.props;
    try {
      if (value) {
        const typeAux = value && options.filter(item => item.id === value.value)[0].integrationType;
        switch (typeAux) {
          case 'NONE': {
            return this.setState({ integrationTypeElement: 'NONE' });
          }
          case 'SGO_WS': {
            return this.setState({ integrationTypeElement: 'SGO_WS' });
          }
          case 'REST_ACERT': {
            return this.setState({ integrationTypeElement: 'REST_ACERT' });
          }
          case 'FTP_ZEISS': {
            return this.setState({ integrationTypeElement: 'FTP_ZEISS' });
          }
          default:
            return this.setState({ integrationTypeElement: 'NONE' });
            break;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  submit(values) {
    const { storeId, notify, submitCallback, reset, options, overlay } = this.props;

    const {
      awardsCode,
      companyCode,
      defaultMessage,
      supplier: { value: id, label: companyName },
      storePurchaseCode,
      integrationUser,
      integrationPassword
    } = values;

    overlay.show(<Trans>Associando Fornecedor</Trans>);

    fetchSupplier(storeId, id, {
      companyName,
      awardsCode,
      companyCode,
      storePurchaseCode,
      defaultMessage,
      integrationUser,
      integrationPassword,
      priority: options.findIndex(item => item.id === id)
    }).then(res => {
      submitCallback && submitCallback();
      reset();
      overlay.hide();

      if (res.status === 201) {
        notify({
          variant: 'success',
          message: <Trans>Fornecedor adicionado com sucesso</Trans>
        });
      } else if (res.status === 409) {
        notify({
          variant: 'warning',
          message: <Trans>Fornecedor ja adicionado a loja</Trans>
        });
      } else {
        notify({
          variant: 'error',
          message: (
            <Trans>
              Estamos com problemas para realizar sua solicitação. Por favor, tente mais tarde.
            </Trans>
          )
        });
      }
    });
  }
}

function styles(theme) {}

export default compose(
  reduxForm({ form: 'addSupplier' }),
  withNotification,
  withOverlay,
  withStyles(styles)
)(AddSupplier);
