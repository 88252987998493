import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Trans } from '@lingui/macro';
import ReactDOMServer from 'react-dom/server';
import Control from './partials/Control';
import Menu from './partials/Menu';
import NoOptionsMessage from './partials/NoOptionMessage';
import Option from './partials/Option';
import SingleValue from './partials/SingleValue';
import ValueContainer from './partials/ValueContainer';
import MultiValue from './partials/MultiValue';

class Autocomplete extends Component {
  render() {
    const {
      classes,
      clearCallback,
      margin = 'dense',
      information,
      input,
      variant = 'outlined',
      label,
      labelAsPlaceholder,
      options,
      selectionCallback,
      createCallback,
      theme,
      isCreatable = false,
      addEdit = false,
      ...props
    } = this.props;

    const { touched, error } = props.meta;

    const customStyles = {
      container: provider => ({
        ...provider,
        zIndex: addEdit ? 5 : 'none',
        width: '100%'
      }),
      control: provider => ({
        ...provider,
        minHeight: '56px',
        borderRadius: 13,
        '> div': {
          padding: '0 14px'
        }
      }),
      menuList: provider => ({
        ...provider,
        zIndex: 5,
        maxHeight: 110
      }),
      valueContainer: base => ({
        ...base,
        overflow: 'visible'
      })
    };

    const textLabel = () => ReactDOMServer.renderToStaticMarkup(<Trans>Criar item</Trans>);
    return (
      <div className={classes.root}>
        {isCreatable ? (
          <CreatableSelect
            {...input}
            {...props}
            isClearable
            menuPlacement="auto"
            menuPosition="fixed"
            classes={classes}
            components={components}
            error={touched && Boolean(error)}
            onBlur={() => input.onBlur(input.value)}
            formatCreateLabel={inputValue => `${textLabel()} "${inputValue}"`}
            getOptionValue={option => option.id}
            margin={margin}
            options={options}
            placeholder={label}
            variant={variant}
            onChange={this.handleChange}
            onCreateOption={this.handleCreateOption}
            styles={customStyles}
          />
        ) : (
          <Select
            {...input}
            {...props}
            isClearable
            menuPlacement="auto"
            menuPosition="fixed"
            classes={classes}
            components={components}
            error={touched && Boolean(error)}
            onBlur={() => input.onBlur(input.value)}
            getOptionValue={option => option.id}
            margin={margin}
            options={options}
            placeholder={label}
            variant={variant}
            onChange={this.handleChange}
            styles={customStyles}
          />
        )}
      </div>
    );
  }

  handleChange = value => {
    const {
      selectionCallback,
      clearCallback,
      input: { onChange }
    } = this.props;

    const { previousValue } = this.state;

    onChange(value);

    value && selectionCallback && selectionCallback(value, previousValue);

    !value && clearCallback && clearCallback(previousValue);
    value && this.setState({ previousValue: value });
  };

  handleCreateOption = value => {
    const {
      input: { onChange },
      selectionCallback
    } = this.props;

    const formatedValue = {
      id: value,
      label: value,
      value
    };
    onChange(formatedValue);
    const { previousValue } = this.state;

    selectionCallback && selectionCallback(formatedValue, previousValue);
    this.setState({ previousValue: formatedValue });
  };

  state = {
    previousValue: null
  };

  componentDidUpdate() {
    const { previousValue } = this.state;
    !previousValue &&
      this.props.input.value &&
      this.setState({ previousValue: this.props.input.value });
  }
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  MultiValue,
  SingleValue,
  ValueContainer
};

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default Autocomplete;
