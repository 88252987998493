import React from 'react';
import { Trans } from '@lingui/macro';
import { hide } from 'store/ducks/overlay';
import { logout } from 'store/ducks/auth';
import { notify } from 'store/ducks/notifications';

export const loginInterceptor = httpResponse => {
  const { status } = httpResponse;

  if (status !== 200) {
    if (status === 401)
      notify({
        variant: 'error',
        message: <Trans>Usuário ou senha não encontrados</Trans>
      });
    else
      notify({
        variant: 'error',
        message: <Trans>Estamos com problemas na comunicação, por favor tente mais tarde</Trans>
      });
    hide();
  }

  return httpResponse.json();
};

export const insertOrderInterceptor = httpResponse => {
  const { status } = httpResponse;
  try {
    if (status === 403) {
      notify({
        variant: 'warning',
        message: <Trans>Você não tem permissão para executar essa ação</Trans>
      });
    } else if (status === 201) {
      notify({
        variant: 'success',
        message: <Trans>Pedido inserido com sucesso</Trans>
      });
    } else if (status === 400) {
      notify({
        variant: 'warning',
        message: (
          <Trans>Não foi possível inserir o pedido. Verifique os dados e tente novamente.</Trans>
        )
      });
    } else if (status === 409) {
      notify({
        variant: 'error',
        message: <Trans>Já existe um pedido com o com o código de referência informado.</Trans>
      });
    } else {
      notify({
        variant: 'error',
        message: (
          <Trans>
            Falha ao tentar estabelecer comunicação com o servidor. Tente novamente mais tarde!
          </Trans>
        )
      });
    }
    return httpResponse;
  } catch (error) {
    notify({
      variant: 'error',
      message: (
        <Trans>
          Falha ao tentar estabelecer comunicação com o servidor. Tente novamente mais tarde!
        </Trans>
      )
    });
    hide();
    return httpResponse;
  }
};

export const patchOrderInterceptor = httpResponse => {
  const { status } = httpResponse;
  if (status !== 204) {
    try {
      if (status === 422) {
        httpResponse.json().then(res => {
          const message = res.message;
          if (message === 'Order is not totally cancelled by suppliers') {
            notify({
              variant: 'error',
              message: (
                <Trans>
                  Este pedido está em processo de produção no fornecedor. Portanto não será possível
                  enviá-lo novamente.
                </Trans>
              )
            });
          } else {
            notify({
              variant: 'warning',
              message: <Trans>Esta ação não é permitida</Trans>
            });
          }
        });
      } else if (status === 403)
        notify({
          variant: 'warning',
          message: <Trans>Você não tem permissão para executar essa ação</Trans>
        });
      else
        notify({
          variant: 'error',
          message: <Trans>Estamos com problemas na comunicação, por favor tente mais tarde</Trans>
        });
      hide();
    } catch (error) {
      notify({
        variant: 'error',
        message: (
          <Trans>Não foi possível executar a ação solicitada. Tente novamente mais tarde.</Trans>
        )
      });
      hide();
    }
  }
  return httpResponse;
};

function interceptor(httpResponse) {
  if (
    httpResponse.status === 401 ||
    (httpResponse.status === 500 && !localStorage.getItem('accessToken'))
  )
    logout();

  return httpResponse;
}

export default interceptor;
