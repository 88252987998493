import React from 'react';
import { Field } from 'redux-form-normalize-on-blur';
import PrescriptionInput from 'components/form/inputs/prescription-input';
import { decimalFormat } from 'utils/formatters/formatters';
import { isMultipleOf, mustBeBetween } from 'components/form/validation';


export const SphericalField = ({ step = '0.25',negativeSpherical, ...props }) => (
  <Field
    {...props}
    step={step}
    component={PrescriptionInput}
    type="number"
    validate={[isMultipleOf025, mustBe]}
    normalizeOnBlur={value => negativeSpherical ? decimalFormat(-value): decimalFormat(value)}
  />
);
const isMultipleOf025 = isMultipleOf(0.25);
const mustBe = mustBeBetween(-40, 40, true);

export default SphericalField;
