import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import Button from 'components/buttons/button';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import randomId from 'utils/random-id';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import CustomTableCell from 'components/table/TableCell';
import UserRole from 'components/utils/userRoles';
import { deleteUser } from 'services/stores';
import { compose } from 'redux';
import Fab from 'components/fab';
import Tooltip from '@material-ui/core/Tooltip';
import withOverlay from 'store/providers/withOverlay';
import Table from 'components/table/Table';
import TableRow from '@material-ui/core/TableRow';
import EditUseDialog from './EditUserDialog';
import { StoreContext } from '../settings';

class StoreUsers extends Component {
  render = () => {
    const { classes, storeId, userRole } = this.props;

    const cols = [
      { label: <Trans>E-mail</Trans>, id: 'userEmail' },
      { label: <Trans>Perfil</Trans>, id: 'role' },
      {},
      {}
    ];
    return (
      <StoreContext.Consumer>
        {context => (
          <>
            {context.state.store.storeUsers && context.state.store.storeUsers.length ? (
              <Paper className={classes.responsiveTable}>
                <Table cols={cols} data={context.state.store.storeUsers}>
                  {({ userEmail, role, allowAddOrder }) => (
                    <>
                      {role !== 'ACERT' ? (
                        <TableRow key={randomId()}>
                          <CustomTableCell>{userEmail}</CustomTableCell>
                          <CustomTableCell>
                            <UserRole role={role} />
                          </CustomTableCell>

                          <CustomTableCell>
                            <Tooltip title={<Trans>Editar usuário</Trans>}>
                              <Button
                                color="default"
                                variant="text"
                                onClick={this.openEditUser.bind(
                                  this,
                                  userEmail,
                                  role,
                                  allowAddOrder,
                                  context.update
                                )}
                              >
                                <EditIcon color="primary" />
                              </Button>
                            </Tooltip>
                          </CustomTableCell>
                          <CustomTableCell>
                            <Tooltip title={<Trans>Remover usuário</Trans>}>
                              <Button
                                color="default"
                                variant="text"
                                onClick={this.removeUser.bind(this, userEmail, context.update)}
                              >
                                <DeleteIcon color="error" />
                              </Button>
                            </Tooltip>
                          </CustomTableCell>
                        </TableRow>
                      ) : (
                        <>
                          {role === 'ACERT' && userRole === 'ACERT' ? (
                            <TableRow key={randomId()}>
                              <CustomTableCell>{userEmail}</CustomTableCell>
                              <CustomTableCell>
                                <UserRole role={role} />
                              </CustomTableCell>

                              <CustomTableCell>
                                <Tooltip title={<Trans>Editar usuário</Trans>}>
                                  <Button
                                    color="default"
                                    variant="text"
                                    onClick={this.openEditUser.bind(
                                      this,
                                      userEmail,
                                      role,
                                      allowAddOrder,
                                      context.update
                                    )}
                                  >
                                    <EditIcon color="primary" />
                                  </Button>
                                </Tooltip>
                              </CustomTableCell>
                              <CustomTableCell>
                                <Tooltip title={<Trans>Remover usuário</Trans>}>
                                  <Button
                                    color="default"
                                    variant="text"
                                    onClick={this.removeUser.bind(this, userEmail, context.update)}
                                  >
                                    <DeleteIcon color="error" />
                                  </Button>
                                </Tooltip>
                              </CustomTableCell>
                            </TableRow>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Table>
              </Paper>
            ) : (
              <Typography variant="h5">
                <Trans>Nenhum usuário encontrado</Trans>
              </Typography>
            )}

            <EditUseDialog
              open={this.state.editUser.isOpen}
              onClose={this.closeEditUser}
              user={this.state.editUser.user}
              storeId={storeId}
              update={context.update}
              storeInfo={context.state.store}
            />

            <Tooltip title={<Trans>Clique aqui para cadastrar um usuário</Trans>}>
              <Fab onClick={() => this.openEditUser()}>
                <AddIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </StoreContext.Consumer>
    );
  };

  state = {
    editUser: {
      isOpen: false,
      user: {}
    }
  };

  closeEditUser = () => {
    this.setState(prevState => ({
      editUser: {
        isOpen: false,
        user: {}
      }
    }));
  };

  openEditUser = (email, role, allowAddOrder) => {
    this.setState(prevState => ({
      editUser: {
        ...prevState.editUser,
        isOpen: true,
        user: {
          email,
          role,
          allowAddOrder
        }
      }
    }));
  };

  removeUser = (userEmail, callback) => {
    const { storeId, overlay } = this.props;
    overlay.show(<Trans>Aguarde, processando...</Trans>);
    deleteUser(storeId, userEmail).then(res => {
      callback && callback();
      overlay.hide();
    });
  };
}

const styles = theme => ({
  responsiveTable: {
    overflowX: `scroll`,
    [theme.breakpoints.up('md')]: {
      overflowX: `auto`
    }
  }
});

StoreUsers.propTypes = {
  storeId: PropTypes.string.isRequired,
  userList: PropTypes.array
};

export default compose(withStyles(styles), withOverlay)(StoreUsers);
