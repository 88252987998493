import React, { Component } from 'react';

import AttachFile from 'forms/orders/AttachFile';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import BaseOrderDialog from './BaseOrderDialog';

class AddAttachOrderDialog extends Component {
  render() {
    const { payload } = this.props;
    if (!payload) return null;

    const { order } = payload;

    return (
      <BaseOrderDialog
        name="addAttachToOrder"
        title={
          <>
            <Trans>Novo anexo do pedido </Trans> <b>{order.orderNumber}</b>
          </>
        }
      >
        <DialogContent>
          <AttachFile submitCallback={this.submitCallback} order={order} />
        </DialogContent>
      </BaseOrderDialog>
    );
  }

  submitCallback = res => {
    const {
      closeDialog,
      payload: { getAttachedFiles }
    } = this.props;

    closeDialog();
    getAttachedFiles();
  };

  static propTypes = {
    order: PropTypes.object
  };
}

export default AddAttachOrderDialog;
