import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MuiFab from '@material-ui/core/Fab';

function Fab({ classes, className, color = 'primary', fixed = true, ...props }) {
  const mergedClassName = classNames(className, { [classes.fixed]: fixed });

  return <MuiFab color={color} className={mergedClassName} {...props} />;
}

Fab.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string,
  fixed: PropTypes.bool
};

export default Fab;
