import React from 'react';
import { Trans } from '@lingui/macro';

export default [
  { value: 'FRAME', text: <Trans>Armação</Trans> },
  { value: 'COLORING', text: <Trans>Coloração</Trans> },
  {
    value: 'BOTH',
    text: <Trans>Ambos</Trans>
  }
];
