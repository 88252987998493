import { Trans } from '@lingui/macro';
import React from 'react';
import View from 'components/view/';
import PropTypes from 'prop-types';
import Store from 'propTypes/Store';
import IndicatorsFilters from './partials/IndicatorsFilters';
import IndicatorsCharts from './partials/IndicatorsCharts';

export default function Indicators(props) {
  const { query, stores } = props;
  const {
    params: { chart, ...filters }
  } = query;

  return (
    <View title={<Trans>Indicadores</Trans>} size="sm">
      <IndicatorsFilters query={query} stores={stores} />
      <IndicatorsCharts chart={chart} filters={filters} />
    </View>
  );
}

Indicators.propTypes = {
  query: PropTypes.shape({
    chart: PropTypes.string,
    date: PropTypes.string,
    store: PropTypes.arrayOf(PropTypes.string),
    period: PropTypes.string
  }),
  stores: PropTypes.arrayOf(Store)
};
