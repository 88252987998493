import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

class Notes extends Component {
  render() {
    const { classes, notes } = this.props;
    return (
      <>
        <Card>
          <CardHeader subheader="Observações" />
          <CardContent className={classes.root}>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12}>
                {notes !== undefined ? (
                  <Typography className={classes.classTypography} variant="body1" gutterBottom>
                    <b>{notes}</b>
                  </Typography>
                ) : (
                  <Typography className={classes.classTypography} variant="body1" gutterBottom>
                    <b>
                      <Trans>Nenhuma observação encontrada</Trans>
                    </b>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 2
  },
  classTypography: {
    fontSize: '0.8em'
  }
});

export default withStyles(styles)(Notes);
