import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { reduxForm, change } from 'redux-form';
import moment from 'moment';
import InsertOrder from './InsertOrder';
import styles from './styles';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ change }, dispatch);
}

function mapStateToProps(state) {
  return {
    initialValues: {
      orderDueDateTime: moment().format()
    },
    stores: state.stores.filter(
      data => data.allowAddOrder && data.store && data.store.storeDetail.status === 1
    )
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'insertOrder'
  })
)(InsertOrder);
