import Date from 'components/time/Date';
import React from 'react';
import { Trans } from '@lingui/macro';
import orderStatusName from 'components/utils/orderStatusName';
import FrameStatusTranslator from 'components/utils/FrameStatusTranslator';

function orderHistoryTranslator({ title, details }) {
  const isThereErrors = findErrorInDetails(details);

  if (!isThereErrors) return getTranslation({ title, details });
  return getErrorInDetails(details);
}

function getErrorInDetails(details) {
  const error = details.split('/')[1];

  const errorText = getValuesFromError(error);

  return errorText;
}

function findErrorInDetails(details) {
  const errorRegExp = /ERROR/;
  const isThereErrors = errorRegExp.test(details);
  return isThereErrors;
}

function getTranslation({ title, details }) {
  switch (title) {
    case 'ORDER_RECEIVED': {
      return <Trans>Pedido recebido no Acert Conecta</Trans>;
    }

    case 'ORDER_STATUS_CHANGED': {
      const [OLD_ORDER_STATUS, NEW_ORDER_STATUS] = getSanitizedValues(details).map(status =>
        orderStatusName(status)
      );

      return `Status do pedido alterado de "${OLD_ORDER_STATUS}" para "${NEW_ORDER_STATUS}"`;
    }

    case 'FRAME_STATUS_CHANGED': {
      const [OLD_FRAME_STATUS, NEW_FRAME_STATUS] = getSanitizedValues(details).map(
        FrameStatusTranslator
      );

      return `Status da armação alterado de "${OLD_FRAME_STATUS}" para "${NEW_FRAME_STATUS}"`;
    }

    case 'ORDER_CHANGED': {
      return <Trans>Alguns dados do pedido foram alterados</Trans>;
    }

    case 'ORDER_DUE_DATE_CHANGED': {
      const [OLD_DUE_DATE, NEW_DUE_DATE] = getSanitizedValues(details);

      return `A data de entrega foi alterada de <Date>${OLD_DUE_DATE}</Date> para{' '}
          {<Date>${NEW_DUE_DATE}</Date>}`;
    }

    case 'SUPPLIER_PURCHASE': {
      const [SUPPLIER_NAME, , , SUPPLIER_ORDER_NUMBER] = getSanitizedValues(details);

      return `Pedido ${SUPPLIER_ORDER_NUMBER} no fornecedor ${SUPPLIER_NAME}.`;
    }

    case 'NEW_REPLICA': {
      return <Trans>Uma nova cópia foi criada</Trans>;
    }

    case 'ORDER_ATTACHMENT_ADDED': {
      const [DESCRIPTION] = getSanitizedValues(details);
      return `Anexo ${DESCRIPTION} adicionado`;
    }

    case 'ORDER_ATTACHMENT_REMOVED': {
      const [DESCRIPTION] = getSanitizedValues(details);
      return `Anexo ${DESCRIPTION} removido`;
    }

    default:
      break;
  }
}

function getSanitizedValues(details, sanitizeFunction = sanitizeValuesBetweenBrackets) {
  return getValues(details).map(sanitizeFunction);
}

function getValuesFromError(details) {
  const values = details.replace(' ERROR: ', '').toString();
  const sanitizedValues = values.slice(1, -1);

  return sanitizedValues;
}

function getValues(details) {
  const wordRegExp = /\[.*?\]/g;
  const sentenceRegExp = /\[([^)]*)\][^(]*$/g;

  const valuesBetweenBrackets = details.match(wordRegExp) || details.match(sentenceRegExp) || [];

  return valuesBetweenBrackets;
}

function sanitizeValuesBetweenBrackets(word) {
  return word.slice(1, -1);
}

export default orderHistoryTranslator;
