import React from 'react';
import { Field } from 'redux-form-normalize-on-blur';
import BaseInput from 'components/form/inputs/base-input';
import { decimalFormat } from 'utils/formatters/formatters';
import { mustBeBetween } from 'components/form/validation';
import PropTypes from 'prop-types';

export const LargestDiameterField = ({ step = '0.01', ...props }) => (
  <Field
    {...props}
    step={step}
    component={BaseInput}
    type="number"
    normalizeOnBlur={value => decimalFormat(value)}
    validate={[mustBe]}
  />
);
const mustBe = mustBeBetween(15, 80, true);

LargestDiameterField.propTypes = {
  step: PropTypes.string.isRequired
};
export default LargestDiameterField;
