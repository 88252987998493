import React from 'react';
import View from 'components/view';
import './home.css';
import OrdersDialogs from './orders/OrdersDialogs';
import OrdersProvider from './orders/OrdersProvider';
import Board from './trello/Board';
import Filters from './Filters';

function Home() {
  return (
    <View>
      <OrdersProvider>
        <Filters />
        <Board />
        <OrdersDialogs />
      </OrdersProvider>
    </View>
  );
}

export default Home;
