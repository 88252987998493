import { Trans } from '@lingui/macro';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import DatePickerField from 'components/form/fields/date-picker';
import SelectField from 'components/form/fields/select';

export default function FiltersForm(props) {
  const { handleSubmit, onSubmit, stores } = props;

  const storeOptions = [
    {
      value: 'all',
      label: ReactDOMServer.renderToStaticMarkup(<Trans>Todas as lojas</Trans>)
    },
    ...stores.map(userStore => ({
      value: userStore.store.id,
      label: userStore.store.tradingName
    }))
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <SelectField
        label={<Trans>Status</Trans>}
        name="chart"
        onChangeCallback={handleSubmit(onSubmit)}
        options={[
          { value: 'ORDERED', label: <Trans>Abertos</Trans> },
          {
            value: 'ON_SUPPLIER',
            label: <Trans>Fornecedor</Trans>
          },
          {
            value: 'ASSEMBLY_LAB',
            label: <Trans>Montadora</Trans>
          },
          {
            value: 'AWAITING_STORE',
            label: <Trans>Loja</Trans>
          },
          {
            value: 'RECEIVED',
            label: <Trans>Recebido</Trans>
          }
        ]}
      /> */}

      <SelectField
        label={<Trans>Escolha a Loja</Trans>}
        name="store"
        options={storeOptions}
        onChangeCallback={handleSubmit(onSubmit)}
      />

      <SelectField
        label={<Trans>Período inicial</Trans>}
        name="period"
        options={[
          { value: '1', label: <Trans>1 Mês</Trans> },
          { value: '2', label: <Trans>2 Meses</Trans> },
          {
            value: '3',
            label: <Trans>3 Meses</Trans>
          }
        ]}
        onChangeCallback={handleSubmit(onSubmit)}
      />

      <DatePickerField
        label={<Trans>Data final</Trans>}
        onChangeCallback={handleSubmit(onSubmit)}
        name="date"
      />
    </form>
  );
}
