import { Trans } from '@lingui/macro';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Fab from 'components/fab';
import CustomTableCell from 'components/table/TableCell';
import Loading from 'components/utils/Loading';
import UserRole from 'components/utils/userRoles';
import View from 'components/view';
import Tooltip from '@material-ui/core/Tooltip';
import { getUserStores } from 'services/stores';
import Table from 'components/table/Table';

export class Stores extends Component {
  render() {
    const { stores, loading } = this.state;
    const { classes } = this.props;

    if (loading) return <Loading />;
    const cols = [
      { id: 'store.tradingName', label: <Trans>Loja</Trans> },
      { id: 'role', label: <Trans>Posição</Trans> },
      { id: 'store.storeDetail.status', label: <Trans>Status</Trans> }
    ];
    return (
      <View title={<Trans>Minhas Lojas</Trans>} size="sm">
        {stores.length ? (
          <Paper className={classes.responsiveTable}>
            <Table cols={cols} data={stores}>
              {({ store: { tradingName, id, storeDetail }, role }) => (
                <TableRow
                  key={id}
                  className={this.isAdministrator(role) && classes.tableRow}
                  hover={this.isAdministrator(role) && true}
                  onClick={this.isAdministrator(role) && (() => this.callStoreSettings(id))}
                >
                  <CustomTableCell>
                    <Typography
                      variant="body1"
                      className={!this.isActive(storeDetail) && classes.inactiveText}
                    >
                      {tradingName}
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Typography
                      variant="body1"
                      className={!this.isActive(storeDetail) && classes.inactiveText}
                    >
                      <UserRole role={role} />
                    </Typography>
                  </CustomTableCell>
                  {this.isActive(storeDetail) ? (
                    <CustomTableCell>
                      <Typography
                        variant="body1"
                        className={!this.isActive(storeDetail) && classes.inactiveText}
                      >
                        <Trans>Ativa</Trans>
                      </Typography>
                    </CustomTableCell>
                  ) : (
                    <CustomTableCell>
                      <Typography
                        variant="body1"
                        className={!this.isActive(storeDetail) && classes.inactiveText}
                      >
                        <Trans>Inativa</Trans>
                      </Typography>
                    </CustomTableCell>
                  )}
                </TableRow>
              )}
            </Table>
          </Paper>
        ) : (
          <Typography variant="h5">
            <Trans>Nenhuma loja encontrada</Trans>
          </Typography>
        )}
        <Tooltip title={<Trans>Clique aqui para cadastrar uma loja</Trans>}>
          <Fab
            onClick={() =>
              this.props.history.push({
                pathname: '/store/new'
              })
            }
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </View>
    );
  }

  state = {
    stores: [],
    loading: false
  };

  componentDidMount = async _ => {
    const { email } = this.props;
    this.setState({ loading: true });
    const stores = await getUserStores(email);
    this.setState({ stores, loading: false });
  };

  isAdministrator = role => role === 'ADMINISTRATOR' || role === 'ACERT';

  isActive = storeDetail => !!(!storeDetail || storeDetail.status === 1);

  callStoreSettings(id) {
    this.props.history.push({
      pathname: `/store/${id}/settings/info`
    });
  }
}

export const styles = theme => ({
  responsiveTable: {
    overflowX: `scroll`,
    [theme.breakpoints.up('md')]: {
      overflowX: `auto`
    }
  },
  tableRow: {
    cursor: 'pointer'
  },
  inactiveText: {
    color: '#c7b8b8'
  }
});

const mapStateToProps = ({ user: { email } }) => ({
  email
});

export default connect(mapStateToProps)(withStyles(styles)(Stores));

// import { Trans } from "@lingui/macro";
// import Avatar from "@material-ui/core/Avatar";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import Typography from "@material-ui/core/Typography";
// import AddIcon from "@material-ui/icons/Add";
// import React from "react";
// import Fab from "components/fab";
// import UserRole from "components/utils/userRoles";
// import View from "components/view";

// const defaultProps = {
//   userStores: []
// };

// export default function StoreList({ userStores, history, ...props }) {
//   return (
//     <List style={{ backgroundColor: "#fff" }}>
//       {this.state.stores.map(userStore => {
//         const {
//           store: {
//             tradingName,
//             storeDetail: { status }
//           },
//           role
//         } = userStore;

//         const isAdministrator = role === "ADMINISTRATOR" ? true : false;

//         const isActive = status === 1 ? true : false;

//         return (
//           // <Link>
//           <ListItem button={isAdministrator ? true : undefined}>
//             <Avatar
//               style={isActive ? { backgroundColor: "#047eb4" } : undefined}
//             >
//               {tradingName[0]}
//             </Avatar>
//             <ListItemText
//               primary={tradingName}
//               secondary={<UserRole role={role} />}
//             />
//           </ListItem>
//           // </Link>
//         );
//       })}
//     </List>
//   );
// }

// StoreList.defaultProps = defaultProps;

/*          
           <Paper>
              
              <Table cols={cols} data={this.state.stores}>
              {userStore => {
                const isAdministrator = role =>
                  userStore.role === "ADMINISTRATOR" ? true : false;
                const isActive =
                  userStore.store.storeDetail.status === 1 ? true : false;

                return (
                  <TableRow
                    key={userStore.id}
                    className={classNames({
                      [classes.tableRow]: isAdministrator
                    })}
                    hover={isAdministrator ? true : false}
                  >
                    <TableCell>
                      <b>{userStore.store.tradingName}</b>
                    </TableCell>

                    <TableCell>
                      <UserRole role={userStore.role} />
                    </TableCell>

                    <TableCell>
                      {isActive ? <Trans>Ativa</Trans> : <Trans>Inativa</Trans>}
                    </TableCell>
                  </TableRow>
                );
              }}
              </Paper>
            </Table> */
