import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { formValueSelector, reduxForm, change } from 'redux-form';
import withQuery from 'utils/query/withQuery';
import IntegrationFiltersForm from './IntegrationFiltersForm';

const selector = formValueSelector('integrationFilters');

function mapStateToProps(state, props) {
  return {
    initialValues: props.query.params,
    formValues: {
      storeId: selector(state, 'storeId'),
      resourceType: selector(state, 'resourceType'),
      supplierId: selector(state, 'resourceType')
    }
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ change }, dispatch);
}

export default compose(
  withQuery,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({ form: 'integrationFilters' })
)(IntegrationFiltersForm);
