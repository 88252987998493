export const decimalFormat = (value, decimals = 2) => {
  if (value === '') return;
  return parseFloat(Math.round(value * 100) / 100).toFixed(decimals);
};
export const formatPrescriptionValues = eyesPrescription =>
  eyesPrescription.map(item => ({
    ...item,
    addition: item.addition && decimalFormat(item.addition),
    cylindrical: item.cylindrical && decimalFormat(item.cylindrical),
    fittingHeight: item.fittingHeight && decimalFormat(item.fittingHeight),
    interPupillaryDistance:
      item.interPupillaryDistance && decimalFormat(item.interPupillaryDistance),
    spherical: item.spherical && decimalFormat(item.spherical),
    verticalPrismValue: item.verticalPrismValue && decimalFormat(item.verticalPrismValue),
    horizontalPrismValue: item.horizontalPrismValue && decimalFormat(item.horizontalPrismValue)
  }));

export const formatFrameValues = frame => ({
  ...frame,
  hbox: frame.hbox && decimalFormat(frame.hbox),
  vbox: frame.vbox && decimalFormat(frame.vbox),
  distanceBetweenLenses: frame.distanceBetweenLenses && decimalFormat(frame.distanceBetweenLenses),
  largestDiameter: frame.largestDiameter && decimalFormat(frame.largestDiameter),
  assemblyDiameter: frame.assemblyDiameter && decimalFormat(frame.assemblyDiameter)
});

export const onlyIntegers = value => value.replace(/\D/g, '');
