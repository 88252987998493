import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import FormControl from 'components/form/wrappers/form-control';
import Button from 'components/buttons/button';
import Form from 'components/form/wrappers/form';

import EmailField from 'components/form/fields/email';
import PasswordField from 'components/form/fields/password';
import TextField from 'components/form/fields/text';
import { required, minLength, maxLength } from 'components/form/fields/validation';

import { addUser } from 'services/users';

class Register extends Component {
  render = () => {
    const { handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.submit)}>
        <TextField
          fullWidth
          id="name"
          label={<Trans>Nome*</Trans>}
          margin="dense"
          name="name"
          validate={[required, nameMaxLength, nameMinLength]}
        />

        <EmailField
          fullWidth
          id="email"
          label={<Trans>Email*</Trans>}
          margin="dense"
          name="email"
          type="email"
          validate={[required]}
        />

        <PasswordField
          fullWidth
          margin="dense"
          id="password"
          label={<Trans>Senha*</Trans>}
          name="password"
          validate={[required, minLengthPassword]}
        />

        <PasswordField
          fullWidth
          margin="dense"
          id="confirmpassword"
          label={<Trans>Confirme sua senha*</Trans>}
          name="confirmpassword"
          validate={[required, minLengthPassword]}
        />

        <FormControl margin="normal" fullWidth>
          <Button type="submit">
            <Trans>Confirmar</Trans>
          </Button>
        </FormControl>

        <Link to="/login/access" style={{ textDecoration: 'none' }}>
          <FormControl margin="normal" fullWidth>
            <Button variant="text">
              <Trans>Fazer login</Trans>
            </Button>
          </FormControl>
        </Link>
      </Form>
    );
  };

  submit = values => {
    const { notify, overlay } = this.props;
    const { name, email, password, confirmpassword } = values;
    if (password === confirmpassword) {
      overlay.show(<Trans>Aguarde, estamos concluindo seu cadastro...</Trans>);
      addUser(
        email,
        name,
        password,
        `${process.env.REACT_APP_URL}user-confirmation/userId={userId}/userToken={userToken}?redirectto=${process.env.REACT_APP_URL}login`
      ).then(res => {
        if (res.status === 201) {
          notify({
            variant: 'success',
            message: `${name} um email de ativação foi enviado para ${email}. Por favor, verifique seu email
                e clique no link para ativar sua conta.`
          });

          setTimeout(function() {
            window.location.replace('/login');
          }, 2000);
        } else if (res.status === 409) {
          notify({
            variant: 'warning',
            message: `${name}, verificamos que já existe uma conta com esse e-mail.`
          });
        } else {
          notify({
            variant: 'error',
            message: (
              <Trans>
                Estamos com problemas para realizar sua solicitação. Por favor, tente mais tarde.
              </Trans>
            )
          });
        }
        if (res.status !== 201) {
          overlay.hide();
        }
      });
    } else {
      notify({
        variant: 'error',
        message: <Trans>Senhas não coincidem !</Trans>
      });
    }
  };
}

const minLengthPassword = minLength(6);
const nameMaxLength = maxLength(50);
const nameMinLength = minLength(4);

export default Register;
