import { Trans } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from 'components/form/fields/select';
import TextField from 'components/form/fields/text';
import { maxLength, minLength, required } from 'components/form/fields/validation';
import { ZipCodeInput } from 'components/form/Input';
import React, { Component } from 'react';
import { searchAddressByZipCode } from 'services/zip-code';
import withOverlay from 'store/providers/withOverlay';
import estates from 'utils/estates';

class BillingAddress extends Component {
  render() {
    return (
      <>
        <Grid container spacing={24}>
          <Grid item xs={12} md={4}>
            <ZipCodeInput
              fullWidth
              id="zipcode"
              label={<Trans>CEP*</Trans>}
              name="zipCode"
              onBlur={this.searchZipCode}
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={this.state.loading}
              fullWidth
              id="address"
              label={<Trans>Endereço*</Trans>}
              name="address"
              validate={[required, addressMaxLength, addressMinLength]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={this.state.loading}
              fullWidth
              id="number"
              label={<Trans>Número</Trans>}
              name="number"
            />
          </Grid>
        </Grid>

        <Grid container spacing={24}>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={this.state.loading}
              fullWidth
              id="addressdetails"
              information={<Trans>Ex: Bloco 1 Ap. 505</Trans>}
              label={<Trans>Complemento</Trans>}
              name="addressDetails"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={this.state.loading}
              fullWidth
              id="neighborhood"
              label={<Trans>Bairro*</Trans>}
              name="neighborhood"
              validate={[required]}
            />
          </Grid>
        </Grid>

        <Grid container spacing={24}>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={this.state.loading}
              fullWidth
              id="city"
              label={<Trans>Cidade*</Trans>}
              name="city"
              validate={[required]}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <SelectField
              disabled={this.state.loading}
              fullWidth
              id="state"
              label={<Trans>Estado*</Trans>}
              name="state"
              validate={[required]}
            >
              {estates.map(({ value, text }) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
        </Grid>
      </>
    );
  }

  state = {
    loading: false
  };

  searchZipCode = async event => {
    const { value } = event.target;
    const { zipCodeCallback, overlay } = this.props;

    if (!value.replace(/[^a-zA-Z0-9 ]/g, '').trim()) return;

    this.setState({ loading: true });

    overlay.show(<Trans>Aguarde, buscando informações de endereço</Trans>);

    await searchAddressByZipCode(event.target.value)
      .then(zipCodeCallback)
      .catch(() => {
        this.setState({ loading: false });
        overlay.hide();
      });

    overlay.hide();

    this.setState({ loading: false });
  };
}
const addressMaxLength = maxLength(60);
const addressMinLength = minLength(5);

export default withOverlay(BillingAddress);
