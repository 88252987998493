import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  }
});

export default withStyles(style)(TableCell);
