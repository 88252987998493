import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export default function ContentSection({ title, children, ...props }) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom style={{ fontWeight: 700 }}>
          {title}
        </Typography>

        {children}
      </Grid>
    </>
  );
}
