import { STORES_ENDPOINT, SUPPLIERS_ENDPOINT } from '../endpoints';
import { defaultHeaders, fetcher, flatFetcher } from '../fetcher';

export const addStore = storeData =>
  fetcher(`${STORES_ENDPOINT}`, {
    method: 'POST',
    body: JSON.stringify(storeData)
  });

export const getUserStores = userEmail =>
  flatFetcher(`${STORES_ENDPOINT}?userEmail=${userEmail}`, { method: 'GET' });

export const changeStore = (id, storeData) =>
  fetcher(`${STORES_ENDPOINT}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(storeData)
  });

export const changeStoreProfile = (id, printCertificateship, receiveSendFrames, opticsResources) =>
  fetcher(`${STORES_ENDPOINT}/${id}/profile`, {
    method: 'PUT',
    body: JSON.stringify({ printCertificateship, receiveSendFrames, opticsResources })
  });

export const changeStoreStatus = (id, status) =>
  fetcher(`${STORES_ENDPOINT}/${id}/status`, {
    method: 'PATCH',
    body: JSON.stringify({ status })
  });

export const getStore = id => flatFetcher(`${STORES_ENDPOINT}/${id}`, { method: 'GET' });

export const inviteUser = (storeId, { email, invitationUrl, role, allowAddOrder }) =>
  fetcher(`${STORES_ENDPOINT}/${storeId}/users`, {
    method: 'POST',
    body: JSON.stringify({ email, invitationUrl, role, allowAddOrder, appName: 'Acert Conecta' })
  });

export const editUser = (storeId, { email, invitationUrl, role, allowAddOrder }) =>
  fetcher(`${STORES_ENDPOINT}/${storeId}/users?email=${email}`, {
    method: 'PUT',
    body: JSON.stringify({ email, invitationUrl, role, allowAddOrder })
  });

export const deleteUser = (storeId, userEmail) =>
  fetcher(`${STORES_ENDPOINT}/${storeId}/users?email=${userEmail}`, {
    method: 'DELETE'
  });

export const getStoreParameters = storeId =>
  flatFetcher(`${STORES_ENDPOINT}/${storeId}/parameters`, {
    method: 'GET'
  });

export const getStoreSuppliers = storeId =>
  flatFetcher(`${STORES_ENDPOINT}/${storeId}/suppliers`, {
    method: 'GET'
  });

export const getStoreSupplierResources = (supplierId, resourceType, page = 0) =>
  flatFetcher(
    `${SUPPLIERS_ENDPOINT}/${supplierId}/resources?type=${resourceType}&page=${page}&size=1000`,
    {
      method: 'GET'
    }
  );

export const addSupplier = (
  storeId,
  supplierId,
  {
    companyName,
    awardsCode,
    companyCode,
    storePurchaseCode,
    defaultMessage,
    priority,
    integrationUser,
    integrationPassword
  }
) =>
  fetcher(`${STORES_ENDPOINT}/${storeId}/suppliers/${supplierId}`, {
    method: 'POST',
    body: JSON.stringify({
      companyName,
      awardsCode,
      companyCode,
      storePurchaseCode,
      defaultMessage,
      priority,
      integrationUser,
      integrationPassword
    })
  });

export const removeSupplier = (storeId, supplierId) =>
  fetcher(`${STORES_ENDPOINT}/${storeId}/suppliers/${supplierId}`, {
    method: 'DELETE'
  });

export function getSupplierSku(storeId, supplierId, type, storeSku) {
  const url = `${STORES_ENDPOINT}/${storeId}/suppliers/${supplierId}/integrations?integrationType=${type}&storeSku=${storeSku}`;

  const options = {
    method: 'GET'
  };

  return flatFetcher(url, options);
}

export function getStoreResources({ storeId, resourceType, description = '', sku = '', page = 0 }) {
  const url = `${STORES_ENDPOINT}/${storeId}/resources?type=${resourceType}&description=${description}&sku=${sku}&page=${page}&size=1000`;

  const options = {
    method: 'GET'
  };

  return flatFetcher(url, options);
}

export const deleteStoreResources = (storeId, resourceType, sku) =>
  fetcher(`${STORES_ENDPOINT}/${storeId}/resources/${sku}/type/${resourceType}`, {
    method: 'DELETE'
  });

export function getStoreSupplierIntegrations({
  storeId,
  supplierId,
  integrationType,
  resourceType
}) {
  const url = `${STORES_ENDPOINT}/${storeId}/suppliers/${supplierId}/integrations?integrationType=${integrationType}&skuType=${resourceType}`;

  const options = {
    method: 'GET'
  };

  return flatFetcher(url, options);
}

export function sendStoreSupplierIntegration({ storeId, supplierId, integrationData }) {
  const url = `${STORES_ENDPOINT}/${storeId}/suppliers/${supplierId}/integrations`;

  const options = {
    method: 'POST',
    body: JSON.stringify(integrationData)
  };

  return fetcher(url, options);
}

export function removeStoreSupplierIntegration({ storeId, supplierId, id }) {
  const url = `${STORES_ENDPOINT}/${storeId}/suppliers/${supplierId}/integrations/${id}`;

  const options = {
    method: 'DELETE'
  };

  return fetcher(url, options);
}

export const patchChangeStore = (storeId, data) =>
  fetcher(`${STORES_ENDPOINT}/${storeId}`, {
    method: 'PATCH',
    body: JSON.stringify({ ...data })
  });

export function uploadResources(storeId, fileUpload) {
  const attachedFile = new FormData();

  attachedFile.append('file', fileUpload);

  const fileUploadHeaders = { ...defaultHeaders() };
  delete fileUploadHeaders['Content-Type'];

  const fileUploadOptions = {
    method: 'POST',
    body: attachedFile,
    headers: fileUploadHeaders
  };

  return fetcher(`${STORES_ENDPOINT}/${storeId}/integrations`, fileUploadOptions);
}
