import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { I18nProvider } from '@lingui/react';
import { connect } from 'react-redux';
import { getCurrentLocale } from 'store/selectors/i18nSelectors';
import catalogPt from './locales/pt/messages.js';

// TO ADD NEW LOCACLE DATE STRINGS, IMPORT IN THE DATE COMPONENT THE NEW LANGUAGE

class I18nWrapper extends Component {
  render() {
    const { locale, children } = this.props;
    return (
      <I18nProvider language={locale} catalogs={catalogs}>
        {children}
      </I18nProvider>
    );
  }

  static propTypes = {
    locale: PropTypes.string.isRequired
  };
}

const catalogs = { pt: catalogPt };

function mapStateToProps(state) {
  return { locale: getCurrentLocale(state) };
}

export default connect(mapStateToProps)(I18nWrapper);
