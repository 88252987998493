import { Trans } from '@lingui/macro';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import DatePickerField from 'components/form/fields/date-picker';
import SelectField from 'components/form/fields/select';

export default function ChartFiltersForm(props) {
  const { handleSubmit, onSubmit, stores } = props;

  const storeOptions = [
    {
      value: 'all',
      label: ReactDOMServer.renderToStaticMarkup(<Trans>Todas as lojas</Trans>)
    },
    ...stores.map(userStore => ({
      value: userStore.store.id,
      label: userStore.store.tradingName
    }))
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SelectField
        label={<Trans>Escolha o indicador</Trans>}
        name="chart"
        onChangeCallback={handleSubmit(onSubmit)}
        options={[
          { value: 'receivedOrders', label: <Trans>Pedidos recebidos</Trans> },
          {
            value: 'sentOrders',
            label: <Trans>Pedidos enviados ao fornecedor</Trans>
          },
          {
            value: 'ordersPerSupplier',
            label: <Trans>Fornecedor x lojas</Trans>
          }
        ]}
      />

      <SelectField
        label={<Trans>Indicadores de</Trans>}
        multiple
        name="store"
        options={storeOptions}
        onChangeCallback={handleSubmit(onSubmit)}
      />

      <SelectField
        label={<Trans>Período</Trans>}
        name="period"
        options={[
          { value: '1', label: <Trans>1 Mês</Trans> },
          { value: '2', label: <Trans>2 Meses</Trans> },
          {
            value: '3',
            label: <Trans>3 Meses</Trans>
          }
        ]}
        onChangeCallback={handleSubmit(onSubmit)}
      />

      <DatePickerField
        label={<Trans>Data</Trans>}
        onChangeCallback={handleSubmit(onSubmit)}
        name="date"
      />
    </form>
  );
}
