import { Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isEqual from 'react-fast-compare';
import { Link as RouterLink } from 'react-router-dom';
import ReactTrelloBoard from 'react-trello';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Creators as SuppliersCreators } from 'store/ducks/suppliers';
import { Creators } from 'store/ducks/orders';
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import { fetchPaginatedOrders } from 'services/orders';
import { getUserByID } from 'services/users';
import { OrdersConsumer } from '../orders/OrdersProvider';
import CustomCard from './CustomCard';
import CustomLaneHeader from './CustomLaneHeader';

export class Board extends Component {
  render() {
    const {
      actions,
      classes,
      data,
      dataStore,
      getOrderById,
      getStoreById,
      isOrderLate,
      isOrderACopy,
      userStores,
      setOrders,
      orders
    } = this.props;

    const isDataStore = dataStore.length > 0;
    const urlString = window.location.href;
    const url = new URL(urlString);
    const storeIdValue = url.searchParams.get('store');
    const store = getStoreById(storeIdValue);

    const allowAddOrderValue =
      dataStore.length > 0 && dataStore.find(item => item.store.id === Number(storeIdValue));

    const allowAddOrderFilter = allowAddOrderValue ? allowAddOrderValue.allowAddOrder : false;

    const generateCards = async (storeId, laneId) => {
      const { last, count } = this.state;
      if (last[laneId]) {
        setOrders(orders);
        return;
      }
      this.setState({ count: { ...count, [laneId]: count[laneId] + 1 } });
      const { last: lastPage, content } = await fetchPaginatedOrders(
        storeId,
        count[laneId],
        laneId
      );
      this.setState({ last: { ...last, [laneId]: lastPage } });
      setOrders([...orders, ...content]);
    };

    const paginate = (requestedPage, laneId) => {
      const { storeId } = this.state;
      return generateCards(storeId, laneId);
    };

    return (
      <>
        {isDataStore ? (
          <div>
            {this.isData ? (
              <ReactTrelloBoard
                className={classes.board}
                customCardLayout
                customLaneHeader={
                  <CustomLaneHeader
                    allowAddOrderValue={allowAddOrderFilter}
                    handleDialogs={actions}
                    store={store}
                  />
                }
                cardDraggable={this.isCardsDraggable}
                data={data}
                draggable
                eventBusHandle={this.setEventBus}
                handleDragEnd={this.handleDragEnd}
                laneDraggable={false}
                onLaneScroll={paginate}
              >
                <CustomCard
                  actions={actions}
                  getOrderById={getOrderById}
                  getStoreById={getStoreById}
                  isOrderLate={isOrderLate}
                  isOrderACopy={isOrderACopy}
                  suppliersList={this.props.suppliersList}
                  moveCard={this.moveCard}
                />
              </ReactTrelloBoard>
            ) : (
              <>
                {allowAddOrderFilter ? (
                  <Typography className={classes.notFound} variant="h6" color="textSecondary">
                    <Trans>Ainda não existem pedidos. Cadastre um novo pedido </Trans>{' '}
                    <Link component="button" onClick={() => actions('insertOrder', { orderId: 0 })}>
                      <div className={classes.addIcon}>
                        <Trans>aqui</Trans>
                        <AddIcon />
                      </div>
                    </Link>{' '}
                    <Trans>ou acesse </Trans>{' '}
                    <Link
                      href="https://grupoacert.movidesk.com/kb/pt-br/article/44540/manual-integracao-com-o-order-tracking?menuId=5199-17634-44540"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Trans>nossa documentação.</Trans>
                    </Link>
                  </Typography>
                ) : (
                  <Typography className={classes.notFound} variant="h6" color="textSecondary">
                    <Trans>Ainda não existem pedidos. Acesse </Trans>{' '}
                    <Link
                      href="https://grupoacert.movidesk.com/kb/pt-br/article/44540/manual-integracao-com-o-order-tracking?menuId=5199-17634-44540"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Trans>nossa documentação.</Trans>
                    </Link>
                  </Typography>
                )}
              </>
            )}
          </div>
        ) : (
          <Typography className={classes.notFound} variant="h6" color="textSecondary">
            <Trans>Você não está vinculado a nenhuma loja, cadastre uma </Trans>{' '}
            <RouterLink to="/store/new">
              <Trans>aqui</Trans>
            </RouterLink>
            .
          </Typography>
        )}
      </>
    );
  }

  componentDidMount = async () => {
    const { fetchList, userStores } = this.props;
    const { user } = this.state;
    const storeIdDefault = localStorage.getItem('storeIdDefault');
    const storeIdFilter = localStorage.getItem('storeIdFilter');

    if (this.props.token) await this.getUser();
    if (storeIdFilter) {
      this.setState({ storeId: storeIdFilter });
      localStorage.removeItem('storeIdFilter');
    } else if (storeIdDefault) {
      this.setState({ storeId: storeIdDefault });
    } else if (user && user.userDetail && user.userDetail.storeIdDefault) {
      this.setState({ storeId: user.userDetail.storeIdDefault });
    } else if (userStores && userStores.length >= 1) {
      this.setState({ storeId: userStores[0].store.id });
    }
    fetchList();
  };

  getUser = async () => {
    const {
      user: { userId }
    } = this.props;
    const user = await getUserByID(userId);

    if (user && user.userDetail && user.userDetail.storeIdDefault)
      localStorage.setItem('storeIdDefault', user.userDetail.storeIdDefault);

    this.setState({ user });
  };

  moveCard = (currentLane, targetLane) => orderId => {
    if (currentLane === targetLane) return;
    try {
      this.eventBus.publish({
        type: 'MOVE_CARD',
        fromLaneId: currentLane,
        toLaneId: targetLane,
        cardId: orderId,
        index: 0
      });
    } catch (e) {
      console.log(e);
    }
  };

  handleDragEnd = (orderId, currentLane, targetLane) => {
    this.props.actions('changeOrderStatus', {
      orderId,
      status: targetLane
    });
    if (targetLane === 'ON_SUPPLIER' || targetLane === 'ORDERED') return false;
  };

  eventBus = undefined;

  flattenLanes = lanes => lanes.map(lane => lane.cards).reduce((acc, item) => acc.concat(item), []);

  get isData() {
    const { data } = this.props;
    if (!data) return false;
    const list = data.lanes.reduce((acc, lane) => [...acc, ...lane.cards], []);
    return list.length > 0;
  }

  setEventBus = handle => (this.eventBus = handle);

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.data && this.props.data) {
      const nextOrders = this.flattenLanes(nextProps.data.lanes);
      const currentOrders = this.flattenLanes(this.props.data.lanes);
      const update = !isEqual(currentOrders, nextOrders);
      return update;
    }

    return true;
  }

  get isCardsDraggable() {
    const { width } = this.props;
    const isScreenAboveMediumSize = isWidthUp('md', width);

    return isScreenAboveMediumSize;
  }

  state = {
    count: {
      ORDERED: 1,
      ON_SUPPLIER: 1,
      ASSEMBLY_LAB: 1,
      AWAITING_STORE: 1,
      RECEIVED: 1
    },
    storeId: null,
    user: null,
    last: {
      ORDERED: false,
      ON_SUPPLIER: false,
      ASSEMBLY_LAB: false,
      AWAITING_STORE: false,
      RECEIVED: false
    }
  };

  static propTypes = {
    data: PropTypes.object
  };
}

const ConnectedBoard = props => (
  <OrdersConsumer>
    {({
      formatedOrders,
      actions,
      validateOrderChange,
      getOrderById,
      isOrderLate,
      isOrderACopy,
      getStoreById,
      userStores,
      notify
    }) => (
      <Board
        actions={actions}
        data={formatedOrders}
        dataStore={userStores}
        validateOrderChange={validateOrderChange}
        getOrderById={getOrderById}
        getStoreById={getStoreById}
        isOrderLate={isOrderLate}
        isOrderACopy={isOrderACopy}
        notify={notify}
        {...props}
      />
    )}
  </OrdersConsumer>
);

function styles(theme) {
  return {
    board: {
      left: `0`,
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      marginTop: theme.spacing.unit,
      backgroundColor: 'initial',
      minWidth: `calc(100vw - ${theme.spacing.unit * 16}px)`,
      height: 'auto',
      position: 'relative',
      '& > div': {
        minWidth: `calc(100vw - ${theme.spacing.unit * 16}px)`
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: `-${theme.spacing.unit * 4}px`
      }
    },
    notFound: {
      marginTop: theme.spacing.unit * 2,
      marginLeft: theme.spacing.unit * 2
    },
    addIcon: {
      display: 'flex'
    }
  };
}

function mapStateToProps(state) {
  return {
    suppliersList: state.suppliers,
    user: state.user,
    token: state.auth.accessToken,
    userStores: state.stores,
    orders: state.orders
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchList: _ => dispatch(SuppliersCreators.getSuppliers()),
    setOrders: ordersList => dispatch(Creators.receiveOrders(ordersList))
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withWidth()
)(ConnectedBoard);
