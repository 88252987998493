import { Trans } from '@lingui/macro';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { compose } from 'redux';
import Table from 'components/tableIntegration/Table';
import createIntegrationForm from 'forms/integration/createIntegrationForm';
import { withStyles } from '@material-ui/core/styles';
import { withIntegrations } from '../provider';

function IntegrationTable({ integrations, classes, query }) {
  const {
    params: { storeId, supplierId, resourceType, lensType, colorType, textFilter }
  } = query;
  const areFiltersSet = Boolean(storeId && supplierId && resourceType);

  const filterIntegrations = integration =>
    integration.supplierResource.description.includes(textFilter) ||
    integration.supplierResource.sku.includes(textFilter);

  const filterBy = type => data => value => {
    if (!data) return integrations;
    switch (type) {
      case 'text':
        return data.filter(value);
      case 'lensType':
        return data.filter(
          item =>
            item.supplierResource.lensType &&
            item.supplierResource.lensType.toString() === value.toString()
        );
      case 'colorType':
        return data.filter(
          item =>
            item.supplierResource.colorType &&
            item.supplierResource.colorType.toString() === value.toString()
        );
      default:
        return integrations;
    }
  };
  const filterByText = filterBy('text');
  const filterByLenstype = filterBy('lensType');
  const filterByColortype = filterBy('colorType');

  const doFilter = () => {
    let filteredData = integrations;
    if (textFilter) filteredData = filterByText(filteredData)(filterIntegrations);
    if (lensType) filteredData = filterByLenstype(filteredData)(lensType);
    if (colorType) filteredData = filterByColortype(filteredData)(colorType);
    return filteredData;
  };
  const filteredIntegrations = doFilter();

  const cols = [
    { id: 'supplier', label: <Trans>No fornecedor</Trans> },
    { id: 'store', label: <Trans>Na Loja</Trans> },
    { id: 'integrated', label: <Trans>Integração</Trans> }
  ];

  const colsOnlySuplier = [{ id: 'supplier', label: <Trans>No fornecedor</Trans> }];

  if ((filteredIntegrations && filteredIntegrations.length === 0) || !filteredIntegrations)
    return (
      <Typography style={{ marginTop: 24 }} variant="h6" color="textSecondary">
        {areFiltersSet ? (
          <Trans>Nenhum recurso encontrado</Trans>
        ) : (
          <Trans>Selecione tipo, loja e fornecedor associado para integrar</Trans>
        )}
      </Typography>
    );

  return (
    <Paper style={{ marginTop: 24 }} className={classes.responsiveTable}>
      <Table
        cols={resourceType === 'COLOR' ? colsOnlySuplier : cols}
        data={filteredIntegrations}
        paginated={!!(filteredIntegrations && filteredIntegrations.length > 20)}
      >
        {createIntegrationForm}
      </Table>
    </Paper>
  );
}

const styles = theme => ({
  responsiveTable: {
    overflowX: `scroll`,
    overflowY: `hidden `,
    [theme.breakpoints.up('md')]: {
      overflowX: `auto`,
      overflowY: `hidden `
    }
  }
});

export default compose(withStyles(styles), withIntegrations)(IntegrationTable);
