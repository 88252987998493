import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createNotification, Creators as NotificationCreators } from 'store/ducks/notifications';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Trans } from '@lingui/macro';
import ImportIcon from '@material-ui/icons/GetApp';
import { withStyles } from '@material-ui/core/styles';

function DestkopMenu(props) {
  const { classes, routes, addNotification, stores } = props;

  return (
    <>
      {stores && stores.some(store => store.store.erpSoftwareProviderName === 'LINX') && (
        <Tooltip title={<Trans>Importar pedidos</Trans>}>
          <IconButton
            className={classes.button}
            onClick={() => {
              addNotification({
                variant: 'warning',
                message: <Trans>Funcionalidade ainda não disponível por completa.</Trans>
              });
            }}
            style={{ background: '#FFF', color: '#047eb4' }}
          >
            <ImportIcon />
          </IconButton>
        </Tooltip>
      )}
      {routes.map(({ icon: Icon, ...route }) => (
        <Tooltip title={route.title}>
          <Link to={`/${route.path.split('/')[1]}`} className={classes.buttonLink}>
            <IconButton className={classes.button}>
              <Icon />
            </IconButton>
          </Link>
        </Tooltip>
      ))}
    </>
  );
}

DestkopMenu.propTypes = {
  classes: PropTypes.object,
  routes: PropTypes.array
};

function styles(theme) {
  return {
    button: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      margin: `0px ${theme.spacing.unit / 2}px`
    },
    buttonLink: {
      color: theme.palette.common.white,
      textDecoration: 'none'
    }
  };
}

const mapDispatchToProps = dispatch => ({
  addNotification: ({ message, variant }) =>
    dispatch(NotificationCreators.addNotification(createNotification({ message, variant })))
});

export default compose(withStyles(styles), connect(null, mapDispatchToProps))(DestkopMenu);
