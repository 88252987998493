import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { Creators as SuppliersCreators } from 'store/ducks/suppliers';
import withOverlay from 'store/providers/withOverlay';

import StoreSuppliers from './StoreSuppliers';
import styles from './styles';

function mapStateToProps(state) {
  return { suppliersList: state.suppliers };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchList() {
      dispatch(SuppliersCreators.getSuppliers());
    }
  };
}

export default compose(
  withOverlay,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(StoreSuppliers);
