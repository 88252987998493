import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Loading from 'components/utils/Loading';
import People from '@material-ui/icons/People';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ResourcesIcon from '@material-ui/icons/List';
import Import from '@material-ui/icons/ImportExport';
import Tab from '@material-ui/core/Tab';
import { Trans } from '@lingui/macro';
import Typography from '@material-ui/core/Typography';
import BarSettings from 'components/bar-settings';
import View from 'components/view';
import { getStore, getUserStores } from 'services/stores';
import Grid from '@material-ui/core/Grid';
import Suppliers from '../suppliers';
import Users from '../users';
import StoreInfo from '../info/StoreInfo';
import StoreResources from '../StoreResources';
import ImportResources from '../ImportResources';

class StoreSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.getTabId(this.props.match.params.tab) || 0,
      store: {},
      userRole: '',
      loading: false
    };

    // default tab matching
    if (props.match.params.tab === undefined) {
      props.history.push({
        pathname: `/store/${props.match.params.id}/settings/info`
      });
    }
  }

  handleChange = (event, tabId) => {
    const { history } = this.props;
    const { id: storeId } = this.props.match.params;
    const tabName = this.getTabName(tabId);

    const location = {
      pathname: `/store/${storeId}/settings/${tabName}`
    };

    history.push(location);
    this.setState({
      value: tabId
    });
  };

  getTabId = name => {
    switch (name) {
      case 'info':
        return 0;
      case 'suppliers':
        return 1;
      case 'users':
        return 2;
      case 'resources':
        return 3;
      case 'import':
        return 4;
      default: {
        return 0;
      }
    }
  };

  getTabName = tabId => {
    switch (tabId) {
      case 0:
        return 'info';
      case 1:
        return 'suppliers';
      case 2:
        return 'users';
      case 3:
        return 'resources';
      case 4:
        return 'import';
      default:
        return 'info';
    }
  };

  getStores = async () => {
    try {
      const { id: storeId } = this.props.match.params;
      const stores = await getUserStores(this.props.user.email);
      const userRole = stores.find(item => item.store.id === Number(storeId)).role;

      this.setState({ userRole });
    } catch (error) {
      console.error(error);
    }
  };

  componentWillMount = async () => {
    this.setState({ loading: true });
    await this.getStoreData();
    await this.getStores();
    this.setState({ loading: false });
  };

  getStoreData = async () => {
    const {
      params: { id }
    } = this.props.match;

    const store = await getStore(id);

    this.setState({ store });
  };

  render() {
    const {
      params: { tab, id }
    } = this.props.match;
    const { tradingName, cnpj } = this.state.store;
    const userInfo = this.props.user;
    const userRole = this.state.userRole;
    if (this.state.loading) {
      return <Loading />;
    }

    const formatedCnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1 $2 $3/$4-$5');

    return (
      <View title={tradingName} size="sm">
        <Grid container spacing={24}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {formatedCnpj}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <BarSettings {...this.state} handleChange={this.handleChange}>
              <Tab label={<Trans>Informações</Trans>} icon={<AssignmentIcon />} />
              <Tab label={<Trans>Fornecedores</Trans>} icon={<People />} />
              <Tab label={<Trans>Usuários</Trans>} icon={<PersonPinIcon />} />
              <Tab label={<Trans>Recursos da loja</Trans>} icon={<ResourcesIcon />} />
              <Tab label={<Trans>Importar recursos</Trans>} icon={<Import />} />
            </BarSettings>
          </Grid>

          <Grid item xs={12}>
            <StoreContext.Provider
              value={{
                state: this.state,
                update: this.getStoreData
              }}
            >
              {tab === 'info' && <StoreInfo storeId={id} />}
              {tab === 'suppliers' && <Suppliers storeId={id} />}
              {tab === 'users' && <Users storeId={id} userRole={userRole} />}
              {tab === 'resources' && <StoreResources storeId={id} user={userInfo} cnpj={cnpj} />}
              {tab === 'import' && <ImportResources storeId={id} />}
            </StoreContext.Provider>
          </Grid>
        </Grid>
      </View>
    );
  }
}

export const StoreContext = React.createContext();

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(StoreSettings);
