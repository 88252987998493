import { Trans } from '@lingui/macro';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/buttons/button';
import EmailField from 'components/form/fields/email';
import { required } from 'components/form/fields/validation';
import Form from 'components/form/wrappers/form';
import FormControl from 'components/form/wrappers/form-control';
import { resetPassword } from 'services/users';

class ResetPassowrd extends Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.submit)}>
        <EmailField
          fullWidth
          id="email"
          label={<Trans>Email*</Trans>}
          margin="dense"
          name="email"
          type="email"
          validate={[required]}
        />

        <FormControl margin="normal" fullWidth>
          <Button type="submit">
            <Trans>Recuperar senha</Trans>
          </Button>
        </FormControl>

        <Link to="/login/access" style={{ textDecoration: 'none' }}>
          <FormControl margin="normal" fullWidth>
            <Button variant="text">
              <Trans>Fazer login</Trans>
            </Button>
          </FormControl>
        </Link>
      </Form>
    );
  }

  resetPassword = async email => {
    const { notify, history } = this.props;

    const httpResponse = await resetPassword({ email });

    const { status } = httpResponse;

    if (status === 200) {
      notify({
        variant: 'success',
        message: `Senha recuperada com sucesso! Já enviamos um email com a nova senha para ${email}.`
      });
      setTimeout(() => {
        history.push({
          pathname: `/login/access`
        });
      }, 800);
    } else if (status === 404)
      notify({
        variant: 'error',
        message: `Não encontramos o email "${email}". Por favor, verifique e tente novamente.`
      });
    else
      notify({
        variant: 'error',
        message: (
          <Trans>Estamos com problemas de comunição. Por favor, tente novamente mais tarde.</Trans>
        )
      });
  };

  submit = async values => {
    const { overlay } = this.props;
    const { email } = values;
    overlay.show(<Trans>Aguarde, enviando solicitação...</Trans>);
    await this.resetPassword(email);
    overlay.hide(<Trans>Aguarde, enviando solicitação...</Trans>);
  };
}

export default ResetPassowrd;
