import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { Trans } from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import CustomTableCell from 'components/table/TableCell';
import { typeText } from '../utils/ShapeAndTypeText';
import TypeInfo from './TypeInfo';

export default class ChooseTypeIntegrate extends Component {
  render() {
    const { classes, formValues, change, order, type } = this.props;
    return (
      <div className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell>
                <Trans>Tipo da armação</Trans>
              </CustomTableCell>
              <CustomTableCell>
                <Trans>Tipo da armação no fornecedor</Trans>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: '#fff' }}>
            <FieldArray
              name="type"
              component={ItemFields}
              order={order}
              type={type}
              formValues={formValues}
              change={change}
            />
          </TableBody>
        </Table>
      </div>
    );
  }
}

function ItemFields({ order, type, formValues, change }) {
  const { storeId, supplierId } = formValues;
  return (
    <TableRow>
      {order.prescription.frame && (
        <Fragment>
          {type ? (
            <Fragment>
              <CustomTableCell component="th" scope="row" style={{ maxWidth: '35px' }}>
                {order.prescription.frame &&
                  typeText.find(typeText => typeText.value === type).text}
              </CustomTableCell>
              <TypeInfo
                typeSkuField="TypeSku"
                storeId={storeId}
                supplierId={supplierId}
                storeSku={formValues.prescription.frame.type}
                storeSkuType="FRAME"
                change={change}
              />
            </Fragment>
          ) : (
            <Fragment>
              <CustomTableCell component="th" scope="row">
                <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                  Tipo da armação não encontrado, você pode informar o tipo da armação na{' '}
                  <b>edição do pedido</b>.
                </div>
              </CustomTableCell>
              <CustomTableCell>-</CustomTableCell>
            </Fragment>
          )}
        </Fragment>
      )}
    </TableRow>
  );
}

ChooseTypeIntegrate.propTypes = {
  change: PropTypes.object,
  component: PropTypes.string,
  type: PropTypes.object
};
