import { Trans } from '@lingui/macro';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import withOrders from 'views/home/orders/withOrders';

class OrderFilters extends Component {
  render() {
    const { userStores, query, suppliersList } = this.props;
    return (
      <form>
        <Grid container>
          <Grid xs={12}>
            <TextField
              select
              displayEmpty
              fullWidth
              label={<Trans>Pedidos de</Trans>}
              onChange={event => {
                localStorage.setItem('storeIdFilter', event.target.value);
                query.set({ property: 'store', value: event.target.value });
                window.location.reload();
              }}
              name="store"
              variant="outlined"
              margin="dense"
              value={query.params.store}
            >
              {userStores &&
                userStores
                  .filter(userStore => userStore.store && userStore.store.storeDetail.status === 1)
                  .map(userStore => (
                    <MenuItem key={userStore.store.id} value={userStore.store.id}>
                      {userStore.store.tradingName}
                    </MenuItem>
                  ))}
            </TextField>
          </Grid>

          <Grid xs={12}>
            <TextField
              label={<Trans>Ordenar por</Trans>}
              margin="dense"
              name="sort"
              fullWidth
              onChange={event => query.set({ property: 'sort', value: event.target.value })}
              select
              value={query.params.sort}
              variant="outlined"
            >
              <MenuItem value="codeAsc">
                <Trans>Número do pedido - Crescente</Trans>
              </MenuItem>
              <MenuItem value="codeDesc">
                <Trans>Número do pedido - Decrescente</Trans>
              </MenuItem>
              <MenuItem value="dueDate">
                <Trans>Data de entrega</Trans>
              </MenuItem>
              <MenuItem value="storeName">
                <Trans>Loja</Trans>
              </MenuItem>
            </TextField>
          </Grid>

          <Grid xs={12}>
            <TextField
              select
              displayEmpty
              fullWidth
              label={<Trans>Pedidos ao fornecedor</Trans>}
              onChange={event => query.set({ property: 'supplier', value: event.target.value })}
              name="supplier"
              variant="outlined"
              margin="dense"
              value={query.params.supplier}
            >
              <MenuItem value="">
                <Trans>Todas os fornecedores</Trans>
              </MenuItem>
              {suppliersList &&
                suppliersList
                  .filter(
                    suppliers =>
                      suppliers &&
                      suppliers.webServiceUrl !== 'MOCK_SUCCESS' &&
                      suppliers.webServiceUrl !== 'MOCK_FAIL' &&
                      suppliers.webServiceUrl !== 'www.any.com'
                  )
                  .map(suppliers => (
                    <MenuItem key={suppliers.id} value={suppliers.id}>
                      {suppliers.companyName}
                    </MenuItem>
                  ))}
            </TextField>
          </Grid>

          <Grid xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={query.params.late === 'true'}
                  name="late"
                  onChange={event => query.set({ property: 'late', value: event.target.checked })}
                  color="secondary"
                />
              }
              label={<Trans>Somente pedidos atrasados</Trans>}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default compose(
  withRouter,
  withOrders
)(OrderFilters);
