import { Trans } from '@lingui/macro';
import DialogContent from '@material-ui/core/DialogContent';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Creators as OrderCreators } from 'store/ducks/orders';
import { bindActionCreators } from 'redux';
import IndividualParameters from 'forms/orders/IndividualParameters';
import PropTypes from 'prop-types';
import IndividualParametersList from './partials/IndividualParametersList';
import BaseOrderDialog from '../BaseOrderDialog';

class IndividualParametersDialog extends Component {
  submitCallback = values => {
    const { receiveOrder, updatedOrder } = this.props;
    const {
      parameter: { id },
      value,
      eye
    } = values;
    const objKey = eye === 'RIGHT' || !eye ? id[0] : id[1];
    delete updatedOrder.prescription.individualParameters[objKey.toString()];
    const newParameters = {
      ...updatedOrder.prescription.individualParameters,
      [objKey]: value
    };
    receiveOrder({
      ...updatedOrder,
      prescription: {
        ...updatedOrder.prescription,
        individualParameters: newParameters
      }
    });
    this.forceUpdate();
  };

  deleteCallback = event => this.forceUpdate();

  render() {
    const { getOrderById, order } = this.props;
    const updatedOrder = order && getOrderById(order.orderId);
    return (
      <BaseOrderDialog
        maxWidth="md"
        fullWidth
        name="individualParameters"
        title={<Trans>Parâmetros individuais</Trans>}
      >
        <DialogContent>
          <IndividualParameters submitCallback={this.submitCallback} />
          <IndividualParametersList
            data={updatedOrder && updatedOrder.prescription.individualParameters}
            storeInfo={this.props.updatedOrder}
            deleteCallback={this.deleteCallback}
          />
        </DialogContent>
      </BaseOrderDialog>
    );
  }
}

IndividualParametersDialog.propTypes = {
  getOrderById: PropTypes.func.isRequired,
  receiveOrder: PropTypes.func.isRequired,
  order: PropTypes.objectOf({}),
  updatedOrder: PropTypes.objectOf({})
};

IndividualParametersDialog.defaultProps = {
  order: {},
  updatedOrder: {}
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(OrderCreators, dispatch);
}

const mapStateToProps = state => ({ updatedOrder: getFormValues('editOrder')(state) || {} });

export default connect(mapStateToProps, mapDispatchToProps)(IndividualParametersDialog);
