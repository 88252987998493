import PropTypes from 'prop-types';
import React from 'react';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import Fade from '@material-ui/core/Fade';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

function FormHelperText({ error, information, touched, warning }) {
  const show = touched || information;
  const theme = error ? errorTheme : warning ? warningTheme : null;
  const text = error || warning || information;

  return (
    <MuiThemeProvider theme={theme}>
      <Fade in={show}>
        <MuiFormHelperText>{text}</MuiFormHelperText>
      </Fade>
    </MuiThemeProvider>
  );
}

FormHelperText.prototypes = {
  error: PropTypes.string,
  information: PropTypes.string,
  touched: PropTypes.bool,
  warning: PropTypes.string
};

const warningTheme = createMuiTheme({
  overrides: {
    MuiFormHelperText: {
      root: { color: orange[500] }
    }
  }
});

const errorTheme = createMuiTheme({
  overrides: {
    MuiFormHelperText: {
      root: { color: red[500] }
    }
  }
});

export default FormHelperText;
