import React from 'react';
import { Trans } from '@lingui/macro';

export default [
  { value: ['dnp_perto_r', 'dnp_perto_l'], label: <Trans>DNP de perto</Trans>, eye: true },
  {
    value: ['distancia_vertice_r', 'distancia_vertice_l'],
    label: <Trans>Distância vértice</Trans>,
    eye: true
  },
  { value: ['corredor_r', 'corredor_l'], label: <Trans>Corredor</Trans>, eye: true },
  { value: ['cro_r', 'cro_l'], label: <Trans>C.R.O</Trans>, eye: true },
  { value: ['ang_pant'], label: <Trans>Ângulo Pantoscópico</Trans>, eye: false },
  { value: ['ang_curv'], label: <Trans>Ângulo Curvatura</Trans>, eye: false },
  { value: ['dist_leitura'], label: <Trans>Distância de leitura</Trans>, eye: false },
  { value: ['olho_dominante'], label: <Trans>Olho dominante</Trans>, eye: false },
  { value: ['vmap'], label: <Trans>VMAP</Trans>, eye: false },
  { value: ['nvb'], label: <Trans>NVB</Trans>, eye: false },
  { value: ['nvbstatus'], label: <Trans>NVB Status</Trans>, eye: false }
];
