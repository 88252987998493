import { Trans } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from 'components/form/fields/select';
import React from 'react';

function ResourceStore({ stores, storeId, typeStoreDisabled }) {
  return (
    <>
      <Grid item xs={12} sm={12}>
        {typeStoreDisabled ? (
          <SelectField fullWidth label={<Trans>Loja</Trans>} name="resourceStore">
            {stores
              .filter(
                store => store.store.id !== parseInt(storeId) && store.store.type === 'BACKOFFICE'
              )
              .map(store => (
                <MenuItem key={store.store.id} value={store.store.id}>
                  {store.store.tradingName}
                </MenuItem>
              ))}
          </SelectField>
        ) : (
          <SelectField fullWidth label={<Trans>Loja</Trans>} name="resourceStore">
            <MenuItem disabled value="null">
              <Trans>Tipo de loja deve ser igual a Loja pertencente a um escritório</Trans>
            </MenuItem>
          </SelectField>
        )}
      </Grid>
    </>
  );
}

export default ResourceStore;
