import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { Trans } from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import CustomTableCell from 'components/table/TableCell';
import { shapeText } from '../utils/ShapeAndTypeText';
import ModelInfo from './ModelInfo';

export default class ChooseModelIntegrate extends Component {
  render() {
    const { classes, formValues, change, order, shape } = this.props;
    return (
      <div className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell>
                <Trans>Desenho da armação</Trans>
              </CustomTableCell>
              <CustomTableCell>
                <Trans>Desenho da armação no fornecedor</Trans>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: '#fff' }}>
            <FieldArray
              name="shape"
              component={ItemFields}
              order={order}
              shape={shape}
              formValues={formValues}
              change={change}
            />
          </TableBody>
        </Table>
      </div>
    );
  }
}

function ItemFields({ order, shape, formValues, change }) {
  const { storeId, supplierId } = formValues;

  return (
    <TableRow>
      {order.prescription.frame && (
        <Fragment>
          {shape ? (
            <Fragment>
              <CustomTableCell component="th" scope="row" style={{ maxWidth: '35px' }}>
                {order.prescription.frame && shapeText(shape)}
              </CustomTableCell>
              <ModelInfo
                shapeSkuField="ShapeSku"
                storeId={storeId}
                supplierId={supplierId}
                storeSku={formValues.prescription.frame.shape}
                storeSkuShape="FRAME_SHAPE"
                change={change}
              />
            </Fragment>
          ) : (
            <Fragment>
              <CustomTableCell component="th" scope="row">
                <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                  Desenho da armação não encontrado, você pode informar o desenho da armação na{' '}
                  <b>edição do pedido</b>.
                </div>
              </CustomTableCell>
              <CustomTableCell>-</CustomTableCell>
            </Fragment>
          )}
        </Fragment>
      )}
    </TableRow>
  );
}

ChooseModelIntegrate.propTypes = {
  change: PropTypes.object,
  component: PropTypes.string,
  shape: PropTypes.object
};
