import { Trans } from '@lingui/macro';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CustomTableCell from 'components/table/TableCell';
import Date from 'components/time/Date';
import Loading from 'components/utils/Loading';
import Typography from '@material-ui/core/Typography';
import { getOrderHistorical } from 'services/historical';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Table from 'components/table/Table';
import orderHistoryTranslator from './orderHistoryTranslator';

class HistoricalTable extends Component {
  render() {
    const { classes } = this.props;
    const { historical, loading } = this.state;

    const cols = [
      { id: 'details', label: <Trans>Ocorrência</Trans>, sortDisabled: true },
      { id: 'dateTime', label: <Trans>Data</Trans> },
      { id: 'username', label: <Trans>Email</Trans> }
    ];

    if (loading) return <Loading />;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} cols={cols} data={historical} paginated>
            {({ id, title, details, dateTime, username }) => (
              <TableRow key={id}>
                <CustomTableCell>
                  {orderHistoryTranslator({
                    title,
                    details
                  })}
                </CustomTableCell>
                <CustomTableCell>
                  <Date format="LLLL">{dateTime}</Date>
                </CustomTableCell>
                <CustomTableCell>
                  {username || (
                    <Typography variant="caption">
                      <Trans>Não disponível</Trans>
                    </Typography>
                  )}
                </CustomTableCell>
              </TableRow>
            )}
          </Table>
        </div>
      </Paper>
    );
  }

  state = {
    historical: [],
    loading: false
  };

  async componentWillMount() {
    this.setState({ loading: true });
    await this.getHistorical();
    this.setState({ loading: false });
  }

  getHistorical = async (page = 0) => {
    const { orderId, storeId } = this.props;
    const { last, content } = await getOrderHistorical(orderId, storeId, page);
    this.setState(prevState => ({
      historical: [...prevState.historical, ...content]
    }));
    if (!last) this.getHistorical(++page);
  };
}

const styles = theme => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  tableCell: {
    width: '50%'
  }
});

HistoricalTable.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(HistoricalTable);
