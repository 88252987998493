import React from 'react';
import { TextField } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core/styles';
import ReactDOMServer from 'react-dom/server';

const SearchBar = ({ searchCallback, searchValue, classes }) => {
  return (
    <TextField
      margin="dense"
      className={classes.searchBar}
      placeholder={ReactDOMServer.renderToStaticMarkup(
        <Trans>Digite aqui o SKU ou Descrição para encontrar algum recurso...</Trans>
      )}
      variant="outlined"
      value={searchValue}
      onChange={event => searchCallback(event)}
    />
  );
};

const styles = theme => ({
  searchBar: {
    width: '100%',
    backgroundColor: theme.palette.grey[200],
    [theme.breakpoints.up('md')]: {
      width: '70%'
    }
  }
});

export default withStyles(styles)(SearchBar);
