import red from '@material-ui/core/colors/red';

export default function styles(theme) {
  return {
    root: {
      '& label': {
        zIndex: 'auto'
      },
      backgroundColor: theme.palette.grey[200]
    },
    positive: {
      '& input': {
        color: '#212121'
      }
    },
    negative: {
      '& input': {
        color: red[500]
      }
    }
  };
}
