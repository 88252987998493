import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import { compose } from 'redux';
import { WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { Creators as NotificationCreators } from 'store/ducks/notifications/';
import { Creators as OrdersCreators } from 'store/ducks/orders';
import { Creators as UserCreators } from 'store/ducks/user';
import { changeDueDate } from 'services/orders';
import { getStoreResources, getStoreParameters } from 'services/stores';
import {
  formatOrders,
  getOrderById,
  getStoreById,
  getStoreRoleByStoreId
} from 'store/selectors/orders';
import withQuery from 'utils/query/withQuery.js';
import withOverlay from 'store/providers/withOverlay.js';
import { noLateDate } from 'components/form/validation.js';

const changePermissions = require('./changePermissions.json');

const OrdersContext = React.createContext();

export const OrdersConsumer = OrdersContext.Consumer;

export class OrdersProvider extends Component {
  render() {
    const { formatedOrders, notify, userStores, changeOrder } = this.props;
    return (
      <OrdersContext.Provider
        value={{
          actions: this.actions,
          changeOrder,
          closeDialog: this.closeDialog,
          dialogs: this.state.dialogs,
          fetchOrders: this.fetchOrders,
          formatedOrders,
          getOrderById: this.getOrderById,
          getStoreById: this.getStoreById,
          isOrderLate: this.isOrderLate,
          isOrderACopy: this.isOrderACopy,
          receiveOrder: this.props.receiveOrder,
          notify,
          notifyLateDate: this.notifyLateDate,
          openDialog: this.openDialog,
          userStores,
          validateOrderChange: this.validateOrderChange,
          getStoreResourcesDesign: this.getStoreResourcesDesign,
          changeDueDate
        }}
      >
        {this.props.children}
      </OrdersContext.Provider>
    );
  }

  componentWillMount = () => {
    this.fetchOrders();
    this.fetchUser();
  };

  fetchOrders = orderId => {
    const { fetchOrders, userEmail, fetchOrder } = this.props;

    if (orderId) {
      const order = this.getOrderById(orderId);
      fetchOrder(order);
    } else {
      fetchOrders(userEmail);
    }
  };

  fetchUser = () => {
    const {
      getUser,
      user: { userId }
    } = this.props;
    getUser(userId);
  };

  actions = async (action, payload) => {
    const { fetchOrders, userEmail, changeOrder, notify } = this.props;
    const { orderId } = payload;
    const order = this.getOrderById(orderId);
    let storeIdNumber = 0;
    let role = '';

    if (order) {
      const { storeId } = order;
      storeIdNumber = storeId;
      role = this.props.getStoreRoleByStoreId(storeIdNumber);
    }

    let checkChangeOrder = 'false';

    const store = await getStoreParameters(storeIdNumber);

    // Mapeamento das roles para suas respectivas chaves
    const roleKeys = {
      ACERT: true,
      READ_ONLY: true,
      ADMINISTRATOR: 'ALLOW_ADMINISTRATOR_EDIT_ORDER_CONECTA',
      ASSEMBLER: 'ALLOW_ASSEMBLER_EDIT_ORDER_CONECTA',
      SALESMAN: 'ALLOW_SALESMAN_EDIT_ORDER_CONECTA',
      OPTOMETRIST: 'ALLOW_OPTOMETRIST_EDIT_ORDER_CONECTA',
      CASHIER: 'ALLOW_CASHIER_EDIT_ORDER_CONECTA',
      MANAGER: 'ALLOW_MANAGER_EDIT_ORDER_CONECTA',
      EXTERNAL_SALESMAN: 'ALLOW_EXTERNAL_SALESMAN_EDIT_ORDER_CONECTA'
    };

    if (role in roleKeys) {
      if (roleKeys[role] === true) {
        checkChangeOrder = 'true';
      } else {
        const key = roleKeys[role];
        const salesContent = store.content.find(p => p.type === 'SALES');
        if (salesContent) {
          const valueObject = salesContent.values.find(value => value.key === key);
          checkChangeOrder = valueObject ? valueObject.value : undefined;
        }
      }
    }

    switch (action) {
      case 'changeDueDate':
        this.openDialog('changeDueDate', order);
        break;
      case 'updateOrder':
        this.openDialog('updateOrder', order);
        break;
      case 'copyOrder':
        this.openDialog('orderCopies', order);
        break;
      case 'showOrderHistory':
        this.openDialog('orderHistory', order);
        break;
      case 'showOrderPromotional':
        this.openDialog('orderPromotional', order);
        break;
      case 'supplierRequest':
        this.openDialog('supplierRequest', order);
        break;
      case 'supplierStatus':
        this.openDialog('supplierStatus', order);
        break;
      case 'archiveOrder':
        this.openDialog('archiveOrder', order);
        break;
      case 'addEditProduct': {
        this.openDialog('addEditProduct', payload);
        break;
      }
      case 'insertOrder': {
        this.openDialog('insertOrder', payload);
        break;
      }
      case 'addEditMembers': {
        this.openDialog('addEditMembers', payload);
        break;
      }
      case 'individualParameters': {
        this.openDialog('individualParameters', order);
        break;
      }
      case 'anamnese': {
        this.openDialog('anamnese', order);
        break;
      }
      case 'orderAttachs':
        this.openDialog('orderAttachs', order);
        break;
      case 'orderAttachsNotAdd':
        this.openDialog('orderAttachsNotAdd', order);
        break;
      case 'frameDesign': {
        const { frameDesign, order } = payload;
        this.openDialog('frameDesign', { order, frameDesign });
        break;
      }
      case 'addAttachment': {
        const { getAttachedFiles, order } = payload;

        this.openDialog('addAttachToOrder', { order, getAttachedFiles });
        break;
      }
      case 'removeAttachedFile': {
        const { attachedFile, getAttachedFiles, order } = payload;

        this.openDialog('removeAttachFromOrder', {
          order,
          attachedFile,
          getAttachedFiles
        });

        break;
      }
      case 'changeFrameStatus':
        const { status } = payload;
        changeOrder(order, { frameStatus: status });
        break;
      case 'changeOrderStatus':
        const { status: newStatus } = payload;
        if (checkChangeOrder === 'false') {
          notify(<Trans>Você não tem permissão para executar essa ação.</Trans>, 'warning');
          fetchOrders(userEmail);
        } else if (
          (role === 'ACERT' || role === 'READ_ONLY') &&
          !this.validateOrderChange(order, newStatus)
        ) {
          if (order.status !== status) {
            notify(<Trans>Você não tem permissão para executar essa ação.</Trans>, 'warning');
            fetchOrders(userEmail);
          }
        } else if (newStatus === 'ON_SUPPLIER' && order.status !== 'ON_SUPPLIER') {
          this.openDialog('sendOrder', order);
        } else if (order.status !== newStatus) {
          changeOrder(order, { orderStatus: newStatus });
        }

        break;
      default:
        break;
    }
  };

  isOrderLate = orderId => {
    const { orderDueDateTime } = this.getOrderById(orderId);
    const dueDate = new Date(orderDueDateTime);
    const now = new Date();
    const isLate = +dueDate < +now;

    return isLate;
  };

  notifyLateDate = value => {
    const { notify } = this.props;
    const lateDateMessage = noLateDate(value);
    if (lateDateMessage) {
      notify(lateDateMessage, 'warning');
    }
  };

  isOrderACopy = (orderNumber, orderId) => {
    const ordersByNumber = this.props.orders.filter(
      order => order.orderNumber.toString() === orderNumber.toString()
    );
    if (ordersByNumber && ordersByNumber.length > 1) {
      const item = ordersByNumber.sort((a, b) => {
        return a.orderId - b.orderId;
      })[0];

      if (item.orderId === orderId) return false;
      return true;
    }
    return false;
  };

  validateOrderChange = (order, newStatus) => {
    const { storeId, status } = order;
    const role = this.props.getStoreRoleByStoreId(storeId);
    return changePermissions[role][status][newStatus];
  };

  getOrderById = orderId => {
    const { getOrderById } = this.props;
    return getOrderById(orderId);
  };

  getStoreById = storeId => {
    const { getStoreById } = this.props;
    return getStoreById(storeId);
  };

  getStoreResourcesDesign = async storeId => {
    const {
      overlay: { show, hide }
    } = this.props;

    show(<Trans>Aguarde, buscando recursos de armações da loja...</Trans>);
    const { content } = await getStoreResources({
      storeId,
      resourceType: 'FRAME_SHAPE'
    });
    hide();
    return content;
  };

  state = {
    dialogs: {}
  };

  static propTypes = {
    fetchOrder: PropTypes.func.isRequired,
    changeOrder: PropTypes.func.isRequired,
    fetchOrders: PropTypes.func.isRequired,
    getOrderById: PropTypes.func.isRequired,
    userStores: PropTypes.array,
    userEmail: PropTypes.string.isRequired,
    formatedOrders: PropTypes.object
  };

  setDialog = (dialog, isOpen) =>
    this.setState(state => ({
      dialogs: { ...state.dialogs, [dialog]: isOpen }
    }));

  openDialog = (dialog, order) => {
    this.setDialog(dialog, order);
  };

  closeDialog = dialog => event => this.setDialog(dialog, null);
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrders(userEmail) {
      dispatch(OrdersCreators.getOrders(userEmail));
    },
    fetchOrder(order) {
      const { orderId, storeId } = order;
      dispatch(OrdersCreators.getOrder({ orderId, storeId }));
    },
    receiveOrder(order) {
      dispatch(OrdersCreators.receiveOrder(order));
    },
    changeOrder(order, data) {
      const { orderId, storeId } = order;
      const action = {
        ...OrdersCreators.changeOrder({ orderId, storeId, data }),
        [WAIT_FOR_ACTION]: 'RECEIVE_ORDER'
      };

      return dispatch(action);
    },
    getUser(userId) {
      dispatch(UserCreators.getUser({ userId }));
    },
    notify(message, variant) {
      dispatch(NotificationCreators.addNotification({ message, variant }));
    }
  };
}

function mapStateToProps(state, ownProps) {
  const { params } = ownProps.query;

  return {
    getStoreRoleByStoreId(storeId) {
      return getStoreRoleByStoreId(state, storeId);
    },
    getOrderById(orderId) {
      return getOrderById(state, orderId);
    },
    getStoreById(storeId) {
      return getStoreById(state, storeId);
    },
    userStores: state.stores,
    orders: state.orders,
    userEmail: state.user.email,
    user: state.user,
    formatedOrders: formatOrders(state, params)
  };
}

export default compose(
  withRouter,
  withQuery,
  withOverlay,
  connect(mapStateToProps, mapDispatchToProps)
)(OrdersProvider);
