import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from 'components/card';
import { Trans } from '@lingui/macro';
import Fade from '@material-ui/core/Fade';
import Logo from 'assets/logo.png';

import Button from 'components/buttons/button';
import FormControl from 'components/form/wrappers/form-control';
import CardContent from 'components/card-content';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from 'components/form/fields/select';

import { required } from 'components/form/fields/validation';
import { reduxForm } from 'redux-form';
import { logout } from 'store/ducks/auth';

import { compose } from 'redux';

function ChooseStore({ classes, page, stores, submitCallback, ...props }) {
  const { handleSubmit } = props;

  return (
    <div className={classes.wrapperChooseStore}>
      <Card className={classes.container}>
        <CardContent>
          <Fade in>
            <img className={classes.logo} src={Logo} alt="Acert Conecta" />
          </Fade>
          <form onSubmit={handleSubmit(submitCallback)}>
            <SelectField
              name="storeIdDefault"
              fullWidth
              validate={[required]}
              label={<Trans>Selecione a loja</Trans>}
            >
              {stores
                .filter(item => item.store.storeDetail.status === 1)
                .map(store => (
                  <MenuItem key={store.store.id} value={store.store.id}>
                    {store.store.tradingName}
                  </MenuItem>
                ))}
            </SelectField>

            <FormControl margin="normal" fullWidth>
              <Button type="submit">
                <Trans>Continuar</Trans>
              </Button>
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Button
                onClick={() => {
                  logout();
                }}
                variant="text"
              >
                <Trans>Sair</Trans>
              </Button>
            </FormControl>
          </form>
        </CardContent>
      </Card>

      <ul className={classes.bgBubbles}>
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
    </div>
  );
}

ChooseStore.prototypes = {
  classes: PropTypes.object,
  page: PropTypes.string
};

export default compose(
  withRouter,
  reduxForm({
    form: 'chooseStore'
  })
)(ChooseStore);
