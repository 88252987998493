import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { ImageInput } from 'components/form/Input';
import { changeProfileImage } from 'services/users';
import { withStyles } from '@material-ui/core/styles';
import { Creators as UserCreators } from 'store/ducks/user';
import withNotification from 'store/providers/withNotification';
import withOverlay from 'store/providers/withOverlay';

export class ProfileImageAvatar extends Component {
  render() {
    const { classes } = this.props;
    return (
      <form className={classes.filters}>
        <ImageInput onChange={this.submit.bind(this)} name="picture" />
      </form>
    );
  }

  static propTypes = {
    onChange: PropTypes.func
  };

  checkUploadedForImage(uploadedFile) {
    const uploadedFileExtension = uploadedFile.type;
    const supportedFileExtensions = ['jpeg', 'png'];
    const formatExtensions = fileExtension => `image/${fileExtension}`;

    return supportedFileExtensions.map(formatExtensions).includes(uploadedFileExtension);
  }

  processProfilePictureResponse(httpResponse) {
    const { status } = httpResponse;

    if (status === 200 || status === 201) {
      return httpResponse;
    }
    return Promise.reject(status);
  }

  notSuportedCallback(httpResponse) {
    const { notify } = this.props;
    notify({
      variant: 'error',
      message: (
        <Trans>
          Esse formato de arquivo não é suportado. Por favor, tente novamente com outra imagem.
        </Trans>
      )
    });
  }

  sendingSuccessCallback(uploadedFile) {
    const reader = new FileReader();
    let imageError = true;

    const getImage = readingEvent => {
      const { changeProfileImage } = this.props;
      const image = readingEvent.target.result;

      changeProfileImage(image);
    };

    reader.onload = getImage;

    reader.readAsDataURL(uploadedFile);
    imageError = false;
    this.props.changeImageStatus(imageError);
  }

  sendingErrorCallback(status) {
    const { notify } = this.props;

    if (status === 400)
      notify({
        variant: 'error',
        message: <Trans>Pelo menos o tamanho da imagem deve ser de 200 x 200</Trans>
      });
    else
      notify({
        variant: 'error',
        message: <Trans>Não foi possível enviar a imagem. Tente novamente mais tarde.</Trans>
      });
  }

  async sendProfilePicture(uploadedFile) {
    const { userId, overlay } = this.props;

    overlay.show(<Trans>Enviando sua nova foto de perfil!</Trans>);
    await changeProfileImage(userId, uploadedFile)
      .then(this.processProfilePictureResponse.bind(this))
      .then(this.sendingSuccessCallback.bind(this, uploadedFile))
      .catch(this.sendingErrorCallback.bind(this));
    overlay.hide();
  }

  submit(changeEvent) {
    if (!changeEvent) return;
    const isUploadedFileAnImage = this.checkUploadedForImage(changeEvent);

    if (isUploadedFileAnImage) {
      this.sendProfilePicture(changeEvent);
    } else {
      this.notSuportedCallback();
    }
  }
}

function mapStateToProps(state) {
  return {
    userId: state.user.userId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeProfileImage(profileImage) {
      dispatch(UserCreators.changeProfileImage(profileImage));
    },
    changeImageStatus(imageError) {
      dispatch(UserCreators.changeImageStatus(imageError));
    }
  };
}

const styles = theme => ({
  filters: {
    position: 'absolute',
    zIndex: 2,
    '& span:first-child': {
      backgroundColor: 'transparent',
      minHeight: 200,
      minWidth: 200
    }
  }
});

export default compose(
  reduxForm({ form: 'ProfileImageAvatar' }),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withNotification,
  withOverlay
)(ProfileImageAvatar);
