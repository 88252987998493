import React, { useState } from 'react';
import MuiTable from '@material-ui/core/Table';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableFooter from '@material-ui/core/TableFooter';
import MuiTableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import orderBy from 'lodash/orderBy';
import Pagination from './Pagination';
import TableCell from './TableCell';

function DataTable({ data = [], cols, rowsPerPageOptions = [20], paginated = false, ...props }) {
  const [direction, setDirection] = useState('asc');
  const [order, setOrder] = useState(cols[0].id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const emptyRows = data && rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const firstItem = page * rowsPerPage;
  const lastItem = page * parseInt(rowsPerPage) + parseInt(rowsPerPage);

  return (
    <MuiTable>
      <MuiTableHead>
        <MuiTableRow>
          {cols.map(col => {
            const changeSort = event => {
              if (order === col.id) {
                if (direction === 'desc') setDirection('asc');
                else setDirection('desc');
              }
              setOrder(col.id);
            };

            return (
              <TableCell
                key={col.id}
                padding="dense"
                sortDirection={order === col.id ? direction : false}
              >
                <TableSortLabel
                  active={!col.sortDisabled && order === col.id}
                  direction={direction}
                  onClick={!col.sortDisabled && changeSort}
                  style={{ color: 'white', fontWeight: 'light' }}
                >
                  {col.label}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </MuiTableRow>
      </MuiTableHead>
      <MuiTableBody>
        {paginated
          ? orderBy(data, order, direction)
              .slice(firstItem, lastItem)
              .map(props.children)
          : orderBy(data, order, direction).map(props.children)}

        {paginated && emptyRows > 0 && (
          <MuiTableRow style={{ height: 49 * emptyRows }}>
            <TableCell colSpan={6} />
          </MuiTableRow>
        )}
      </MuiTableBody>
      {paginated && (
        <MuiTableFooter>
          <Pagination
            data={data}
            onChangePage={(event, page) => setPage(page)}
            onChangeRowsPerPage={event => setRowsPerPage(event.target.value)}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            colSpan={4}
            page={page}
            SelectProps={{
              native: true
            }}
          />
        </MuiTableFooter>
      )}
    </MuiTable>
  );
}

export default DataTable;
