import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import StarIcon from '@material-ui/icons/Star';
import yellow from '@material-ui/core/colors/yellow';
import { Trans } from '@lingui/macro';

export const PromoStatus = props => (
  <MuiThemeProvider theme={mui}>
    <Tooltip title={<Trans>Pedido promocional</Trans>}>
      <Avatar {...props} style={{ backgroundColor: 'transparent', color: yellow[800] }}>
        <StarIcon />
      </Avatar>
    </Tooltip>
  </MuiThemeProvider>
);

const mui = createMuiTheme({
  overrides: {
    MuiAvatar: {
      root: {
        maxWidth: 25,
        margin: '0!important',
        borderWidth: '!important',
        maxHeight: 25
      }
    }
  }
});
