import { Creators, createNotification } from 'store/ducks/notifications';

import PropTypes from 'prop-types';
import React from 'react';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Notification from './Notification.js';

export const Notifications = ({ notifications, removeNotification, classes }) => (
  <ul className={classes.notifications}>
    {notifications.map(item => (
      <li className={classes.notification} key={item.id}>
        <Slide direction="down" in mountOnEnter unmountOnExit>
          <Notification
            message={item.message}
            remove={removeNotification.bind(this, item.id)}
            variant={item.variant}
            timeout={item.timeout}
            persist={item.persist}
          />
        </Slide>
      </li>
    ))}
  </ul>
);

Notifications.prototypes = {
  classes: PropTypes.object.required,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.node,
      variant: PropTypes.string,
      remove: PropTypes.func
    })
  ).required,
  removeNotification: PropTypes.func.required
};

const mapStateToProps = ({ notifications }) => ({
  notifications
});

const mapDispatchToProps = dispatch => ({
  addNotification: (message, variant) =>
    dispatch(Creators.addNotification(createNotification({ message, variant }))),
  removeNotification: id => dispatch(Creators.removeNotification(id))
});

export const styles = theme => ({
  notifications: {
    paddingInlineStart: 0,
    marginBlockEnd: 0,
    position: 'fixed',
    bottom: 0,
    [theme.breakpoints.up('md')]: {
      bottom: 'auto',
      right: theme.spacing.unit * 4,
      top: theme.spacing.unit * 8
    },
    zIndex: theme.zIndex.snackbar
  },
  notification: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Notifications));
