import { connect } from 'react-redux';
import { Creators } from 'store/ducks/overlay';

function withOverlay(WrappedComponent) {
  function mapDispatchToProps(dispatch) {
    return {
      overlay: {
        show(loadingText) {
          dispatch(Creators.showOverlay({ loadingText }));
        },
        hide() {
          dispatch(Creators.hideOverlay());
        }
      }
    };
  }

  return connect(
    null,
    mapDispatchToProps
  )(WrappedComponent);
}

export default withOverlay;
