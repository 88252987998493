import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Trans } from '@lingui/macro';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import Date from 'components/time/Date';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

class SupplierStatusList extends Component {
  render() {
    const { classes, suppliersList, checkIframe, iframe, supplierStatus } = this.props;
    const { order, theOrder } = this.state;
    const hasSupplierStatus = supplierStatus && supplierStatus.length > 0;
    const grouppedArrayStatus = groupBy(supplierStatus, 'supplierId');

    return hasSupplierStatus && !checkIframe ? (
      <List component="nav" className={classes.root}>
        {orderBy(grouppedArrayStatus, theOrder, order).map(data => (
          <>
            <ListItem>
              <Tooltip
                title={
                  <>
                    <Trans>ID do pedido no fornecedor:</Trans> {data[0].supplierOrderId}
                  </>
                }
              >
                <Avatar>
                  {suppliersList
                    .filter(({ id }) => id === data[0].supplierId)
                    .map(({ companyName }) => companyName.trim().substr(0, 1))}
                </Avatar>
              </Tooltip>
              <ListItemText
                primary={
                  <>
                    {suppliersList
                      .filter(({ id }) => id === data[0].supplierId)
                      .map(({ companyName }) => companyName)}
                  </>
                }
              />
            </ListItem>
            {orderBy(data, 'dateTime', order).map(({ title, dateTime }) => (
              <List component="div" disablePadding>
                <ListItem className={classes.nested}>
                  <ListItemText
                    secondary={
                      <>
                        {title} - <Date format="LLLL">{dateTime}</Date>
                      </>
                    }
                  />
                </ListItem>
              </List>
            ))}
          </>
        ))}
      </List>
    ) : (
      <>
        {checkIframe ? (
          <iframe
            width="100%"
            height="100%"
            style={{ border: 'none', height: '100%', minWidth: 665, minHeight: 250 }}
            srcDoc={iframe}
            src="supplierStatus.html"
          ></iframe>
        ) : (
          <Typography className={classes.notFound} color="textSecondary" variant="h6">
            <Trans>Nenhum status encontrado</Trans>
          </Typography>
        )}
      </>
    );
  }

  state = {
    order: 'desc',
    theOrder: '[0]dateTime'
  };
}

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      maxHeight: 300
    },
    width: '100%',
    overflowX: 'auto',
    maxHeight: 700,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing.unit * 9
  },
  notFound: {
    textAlign: 'center',
    fontWeight: '300',
    paddingBottom: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2
  }
});

SupplierStatusList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SupplierStatusList);
