import { Trans } from '@lingui/macro';
import { Divider, MenuItem, Paper, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DateTimePickerField from 'components/form/fields/date-time-picker';
import SelectField from 'components/form/fields/select';
import TextField from 'components/form/fields/text';
import { maxLength } from 'components/form/fields/validation';
import FormSection from 'components/form/wrappers/form-section';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import memberType from 'utils/types/memberType';
import { getStoreSuppliers } from 'services/stores';

function Information({
  actions,
  order,
  formValues,
  classes,
  notifyLateDate,
  suppliersList,
  storeId,
  ...props
}) {
  const openDialog = key => event =>
    actions('addEditMembers', { order: formValues, productKey: key });

  const [storeSupplierList, setStoreSupplierList] = useState([]);

  const associatedSuppliers = useCallback(async () => {
    if (storeId) {
      const result = await getStoreSuppliers(storeId);
      const filteredSup = suppliersList.filter(supList =>
        result.some(storeSup => storeSup.supplierId === supList.id)
      );
      setStoreSupplierList(filteredSup);
    }
  }, [storeId, suppliersList]);

  useEffect(() => {
    associatedSuppliers();
  }, [associatedSuppliers]);

  const erpSoftwareName =
    formValues && formValues.store && formValues.store.erpSoftwareProviderName;

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.first}>
          <FormSection
            title={
              <>
                <Trans>Membros</Trans>
                <IconButton
                  onClick={openDialog(order.members.length + 1)}
                  color="primary"
                  style={{ float: 'right', padding: '5px' }}
                >
                  <AddIcon />
                </IconButton>
              </>
            }
          >
            <List className={classes.listClass}>
              {order.members.map((person, key) => (
                <ListItem className={classes.listItemClass}>
                  <ListItemText
                    primary={person.name}
                    secondary={memberType.find(member => member.value === person.type).text}
                  />
                  {erpSoftwareName !== 'Grupo Acert' && (
                    <ListItemSecondaryAction className={classes.listActionClass}>
                      <IconButton
                        style={{ padding: '5px' }}
                        color="primary"
                        onClick={openDialog(key)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          const members = formValues.members
                            .map((member, index) => index !== key && member)
                            .filter(Boolean);
                          props.receiveOrder({
                            ...formValues,
                            members
                          });
                        }}
                        style={{ color: '#f44336', padding: '5px' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </FormSection>
        </div>
      </Grid>

      <Grid item xs={12}>
        <FormSection title={<Trans>Data de entrega</Trans>}>
          <DateTimePickerField
            name="orderDueDateTime"
            disabled={erpSoftwareName === 'Grupo Acert'}
            onChangeCallback={moment => {
              notifyLateDate(moment);
            }}
          />
        </FormSection>
      </Grid>
      <Grid item xs={12}>
        {storeSupplierList && (
          <FormSection title={<Trans>Fornecedor</Trans>}>
            <SelectField fullWidth label={<Trans>Fornecedor</Trans>} name="sentToSupplier">
              <MenuItem value="" disabled />

              {storeSupplierList.map(({ id }) => (
                <MenuItem disabled={erpSoftwareName === 'Grupo Acert'} key={id} value={id}>
                  {storeSupplierList.map(({ companyName }) => companyName)}
                </MenuItem>
              ))}
              <Divider />
              <MenuItem disabled={erpSoftwareName === 'Grupo Acert'} value="other">
                Outro
              </MenuItem>
            </SelectField>
          </FormSection>
        )}
      </Grid>

      <Grid item xs={12}>
        <FormSection title={<Trans>Informações adicionais</Trans>}>
          <TextField
            fullWidth
            disabled={erpSoftwareName === 'Grupo Acert'}
            name="notes"
            label={<Trans>Observação</Trans>}
          />
          <TextField
            fullWidth
            disabled={erpSoftwareName === 'Grupo Acert'}
            name="prescription.remark"
            label={<Trans>Iniciais da lente</Trans>}
            validate={[remarkMaxLength]}
          />
          <TextField
            fullWidth
            disabled={erpSoftwareName === 'Grupo Acert'}
            name="controlNumber"
            label={<Trans>Controle</Trans>}
          />
        </FormSection>
      </Grid>
    </>
  );
}
const remarkMaxLength = maxLength(4);

function styles(theme) {
  return {
    first: {
      [theme.breakpoints.up('md')]: {
        marginTop: 50 // não sei porque
      }
    },
    listClass: {
      maxHeight: 200,
      overflowY: 'auto'
    },
    listItemClass: {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3
      }
    },
    listActionClass: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column'
      }
    }
  };
}

export default withStyles(styles)(Information);
