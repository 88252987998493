import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';

import React from 'react';
import { Trans } from '@lingui/macro';
import { getStoreSuppliers } from 'services/stores';
import { Creators as OverlayCreators } from '../overlay';

export const INITIAL_STATE = [];

export function getStoreSupplier(state = INITIAL_STATE, action) {
  return [...state];
}

export function receiveStoreSupplier(state = INITIAL_STATE, action) {
  const { payload } = action;
  return [...payload];
}

export function* watchGetStoreSupplier() {
  yield takeLatest(Types.GET_STORE_SUPPLIER, getStoreSupplierHandler);
}

export function* getStoreSupplierHandler(action) {
  const { payload: storeId } = action;

  try {
    yield put(
      OverlayCreators.showOverlay({
        loadingText: <Trans>Buscando fornecedores...</Trans>
      })
    );
    const supplierList = yield call(getStoreSuppliers, storeId);
    if (!supplierList) {
      throw new Error(`Nenhum fornecedor encontrado para a loja de id: ${storeId}`);
    }

    if (supplierList) {
      yield put(Creators.receiveStoreSupplier(supplierList));
      yield put(OverlayCreators.hideOverlay());
    }
  } catch (err) {
    console.log(err);
    yield put(Creators.receiveStoreSupplier([]));
    yield put(OverlayCreators.hideOverlay());
  }
}

export const { Types, Creators } = createActions({
  getStoreSupplier: ['payload'],
  receiveStoreSupplier: ['payload']
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_STORE_SUPPLIER]: getStoreSupplier,
  [Types.RECEIVE_STORE_SUPPLIER]: receiveStoreSupplier
});
