import React from 'react';
import { Field } from 'redux-form-normalize-on-blur';
import PrescriptionInput from 'components/form/inputs/prescription-input';
import { decimalFormat } from 'utils/formatters/formatters';
import { isMultipleOf, mustBeBetween } from 'components/form/validation';

export const FittingHeightField = ({ step = '0.01', ...props }) => (
  <Field
    {...props}
    step={step}
    component={PrescriptionInput}
    type="number"
    normalizeOnBlur={value => decimalFormat(value)}
    validate={[isMultiple, mustBe]}
  />
);
const isMultiple = isMultipleOf(0.25);
const mustBe = mustBeBetween(10, 40, true);

export default FittingHeightField;
