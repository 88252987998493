import React from 'react';
import MuiButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';

function Button({ children, icon, color = 'primary', variant = 'contained', ...props }) {
  return (
    <MuiButton {...props} color={color} variant={variant}>
      {children}
    </MuiButton>
  );
}

Button.prototypes = {
  icon: PropTypes.node,
  children: PropTypes.node,
  color: PropTypes.string,
  variant: PropTypes.string
};
export default Button;
