import { Trans } from '@lingui/macro';
import React from 'react';
import View from 'components/view/';
import PropTypes from 'prop-types';
import Store from 'propTypes/Store';
import HistoricFilters from './partials/HistoricFilters';
import HistoricLaneTable from './partials/HistoricLaneTable';

export default function HistoricLane(props) {
  const { query, stores } = props;
  const {
    params: { ...filters }
  } = query;

  return (
    <View title={<Trans>Histórico de entradas e saídas por pedido em cada status</Trans>}>
      <HistoricFilters query={query} stores={stores} />
      <HistoricLaneTable filters={filters} />
    </View>
  );
}

HistoricLane.propTypes = {
  query: PropTypes.shape({
    date: PropTypes.string,
    store: PropTypes.arrayOf(PropTypes.string),
    period: PropTypes.string
  }),
  stores: PropTypes.arrayOf(Store)
};
