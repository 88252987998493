import { Trans } from '@lingui/macro';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import Button from 'components/buttons/button';
import AutocompleteField from 'components/form/fields/autocomplete';
import HiddenField from 'components/form/fields/hidden';
import QuantityField from 'components/form/fields/quantity';
import SelectField from 'components/form/fields/select';
import ResourceUnities from 'components/utils/ResourceUnities';
import { getStoreResources, getStoreSupplierResources } from 'services/stores';

import types from 'utils/types';

export default function AddEditProduct({
  onSubmit,
  handleSubmit,
  formValues,
  storeId,
  change,
  order,
  initialValues,
  productKey
}) {
  const [storeResources, setStoreResources] = useState([]);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(false);

  const { type, unitOfMeasure, quantity, selector } = formValues;

  const getResources = async ({ type, storeId, sentToSupplier }) => {
    setLoading(true);

    if (sentToSupplier) {
      const result = await getStoreSupplierResources(sentToSupplier, 'COLOR');
      const filteredResult = result.content.filter(
        item => item.colorType === 'BOTH' || item.colorType === 'COLORING'
      );
      setColors(filteredResult);
    }

    const { content } = await getStoreResources({
      storeId,
      resourceType: type
    });

    setStoreResources(content);
    setLoading(false);
  };

  useEffect(() => {
    if (type) getResources({ type, storeId, sentToSupplier: order.sentToSupplier });
  }, [storeId, type, order.sentToSupplier]);

  const changeFormValues = (unitOfMeasure, sku, description, unitPrice, quantity) => {
    change('unitOfMeasure', unitOfMeasure);
    change('sku', sku);
    change('description', description);
    change('unitPrice', unitPrice);
    change('quantity', quantity);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {order.items.length === 0 ? (
        <SelectField
          onChangeCallback={() => {
            change('unitOfMeasure', null);
            change('sku', null);
            change('description', null);
            change('quantity', 0);
            change('selector', null);
          }}
          fullWidth
          name="type"
          label={<Trans>Tipo do produto ou serviço</Trans>}
        >
          {types &&
            types.map(({ value, text }) => (
              <MenuItem key={value} value={value}>
                {text}
              </MenuItem>
            ))}
        </SelectField>
      ) : (
        <SelectField
          onChangeCallback={() => {
            change('unitOfMeasure', null);
            change('sku', null);
            change('description', null);
            change('quantity', 0);
            change('selector', null);
          }}
          fullWidth
          name="type"
          label={<Trans>Tipo do produto ou serviço</Trans>}
        >
          {types &&
            types
              .filter(
                item =>
                  item.value !== 'FRAME_SHAPE' && item.value !== 'FRAME' && item.value !== 'COLOR'
              )
              .map(({ value, text }) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
        </SelectField>
      )}

      <Tooltip
        placement="top"
        disableFocusListener
        title={ReactDOMServer.renderToString(
          <Trans>Busque por descrição ou código do produto/serviço</Trans>
        )}
      >
        <AutocompleteField
          disabled={loading}
          fullWidth
          isCreatable
          name="selector"
          label={<Trans>Produto ou serviço</Trans>}
          addEdit
          onChangeCallback
          selectionCallback={selectedOption => {
            const selectedResource = storeResources.find(
              resource => resource.sku === selectedOption.value
            );
            if (!selectedResource) changeFormValues('EACH', '', selectedOption.value, 1.0, 1);
            else {
              const { unitOfMeasure, sku, description, unitPrice } = selectedResource;
              changeFormValues(unitOfMeasure, sku, description, unitPrice, 1);
            }
          }}
          options={storeResources.map(({ description, sku }) => ({
            label: description,
            value: sku,
            id: sku
          }))}
          clearCallback={() => {
            if (type === 'COLORING') {
              change('unitOfMeasure', null);
              change('sku', null);
              change('description', null);
            } else {
              change('unitOfMeasure', null);
              change('sku', null);
              change('description', null);
              change('quantity', 0);
            }
          }}
        />
      </Tooltip>

      {type === 'COLORING' && (
        <SelectField fullWidth name="color" label={<Trans>Cor</Trans>}>
          {colors && colors.length === 0 && (
            <MenuItem value="0" disabled>
              <Trans>Nenhuma cor encontrada</Trans>
            </MenuItem>
          )}

          {colors &&
            colors.map(({ sku, description }) => (
              <MenuItem key={sku} value={sku}>
                {description}
              </MenuItem>
            ))}
        </SelectField>
      )}

      <QuantityField
        label={<Trans>Quantidade</Trans>}
        disabled={loading || !selector}
        name="quantity"
        unit={<ResourceUnities quantity={quantity} unitName={unitOfMeasure} />}
      />

      <HiddenField name="unitOfMeasure" />
      <HiddenField name="description" />
      <HiddenField name="sku" />
      <HiddenField name="itemSequence" />
      <HiddenField name="unitPrice" />

      <Button style={{ marginTop: 15 }} disabled={loading || !selector} type="submit">
        <Trans>Salvar</Trans>
      </Button>
    </form>
  );
}
