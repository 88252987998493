import React from 'react';
import { Field } from 'redux-form';
import BaseInput from 'components/form/inputs/base-input';
import { validateEmail } from 'components/form/fields/validation';

function EmailField(props) {
  const validate = props.validate ? [...props.validate, validateEmail] : [validateEmail];

  return <Field {...props} component={BaseInput} validate={validate} type="email" />;
}

export default EmailField;
