export default function(theme) {
  return {
    root: {
      backgroundColor: theme.palette.background.paper
    },
    avatar: {
      backgroundColor: theme.palette.primary.main
    }
  };
}
