import { Trans } from '@lingui/macro';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import Chart from 'react-google-charts';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from 'components/utils/Loading';
import { fetchSuppliers } from 'services/suppliers';
import { getSupplierRequestChart } from 'services/orders';
import { getUserStores } from 'services/stores';
import { createNotification, Creators as NotificationCreators } from 'store/ducks/notifications';

const dateFormat = require('dateformat');

class ChartSuppliers extends Component {
  render() {
    const { classes } = this.props;
    const isOrderReceived = this.state.chartData.length > 1;
    if (this.state.loading) {
      return <Loading />;
    }
    return this.state.loading ? (
      <Loading />
    ) : isOrderReceived ? (
      <Paper className={classes.chartContainer}>
        <Chart
          className={classes.chart}
          chartType="BarChart"
          loader={<Loading />}
          data={this.state.chartData}
          options={{
            title: ReactDOMServer.renderToStaticMarkup(<Trans>Relação fornecedores e lojas</Trans>),
            chartArea: { width: '50%' },
            tooltip: { isHtml: true },
            hAxis: {
              title: ReactDOMServer.renderToStaticMarkup(<Trans>Total de pedidos enviados</Trans>),
              minValue: 0
            }
          }}
          rootProps={{ 'data-testid': '1' }}
        />
      </Paper>
    ) : (
      <Typography component="h1" variant="h6" color="textSecondary">
        <Trans>Relação entre fornecedores e lojas</Trans>
      </Typography>
    );
  }

  state = {
    store: ['all'],
    periodMonth: 1,
    dateTime: null,
    chartData: [],
    checkAllStore: false,
    loading: false,
    storeID: [],
    GetstoreID: [],
    storeData: []
  };

  createCustomHTMLContent(suppliers, store, amount, month) {
    return (
      `${'<div style="padding:5px 5px 5px 5px;">' +
        '<table class="medals_layout">' +
        '<tr>' +
        '<td style="padding-right: 10px;"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 5v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2zm12 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6v-1z"/><path d="M0 0h24v24H0z" fill="none"/></svg></td>' +
        "<td style='padding-right: 10px;'>"}${ReactDOMServer.renderToStaticMarkup(
        <Trans>Fornecedor</Trans>
      )}: <b>${suppliers}</b></td>` +
      `</tr>` +
      `<tr>` +
      `<td><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z"/></svg></td>` +
      `<td style='padding-right: 10px;'>${ReactDOMServer.renderToStaticMarkup(
        <Trans>Loja</Trans>
      )}: <b>${store}</b></td>` +
      `</tr>` +
      `<tr>` +
      `<td><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"/><path d="M0 0h24v24H0z" fill="none"/></svg></td>` +
      `<td style='padding-right: 10px;' >${ReactDOMServer.renderToStaticMarkup(
        <Trans>Quantidade de envios</Trans>
      )}: <b>${amount}</b></td>` +
      `</tr>` +
      `<tr>` +
      `<tr>` +
      `<td><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg></td>` +
      `<td style='padding-right: 10px;' >${ReactDOMServer.renderToStaticMarkup(
        <Trans>Mês do envio</Trans>
      )}: <b>${this.returnMonthSend(month)}</b></td>` +
      `</tr>` +
      `<tr>` +
      `</table>` +
      `</div>`
    );
  }

  returnMonthSend(month) {
    switch (month) {
      case 'JANUARY': {
        return ReactDOMServer.renderToStaticMarkup(<Trans>Janeiro</Trans>);
      }
      case 'FEBRUARY': {
        return ReactDOMServer.renderToStaticMarkup(<Trans>Fevereiro</Trans>);
      }
      case 'MARCH': {
        return ReactDOMServer.renderToStaticMarkup(<Trans>Março</Trans>);
      }
      case 'APRIL': {
        return ReactDOMServer.renderToStaticMarkup(<Trans>Abril</Trans>);
      }
      case 'MAY': {
        return ReactDOMServer.renderToStaticMarkup(<Trans>Maio</Trans>);
      }
      case 'JUNE': {
        return ReactDOMServer.renderToStaticMarkup(<Trans>Junho</Trans>);
      }
      case 'JULY': {
        return ReactDOMServer.renderToStaticMarkup(<Trans>Julho</Trans>);
      }
      case 'AUGUST': {
        return ReactDOMServer.renderToStaticMarkup(<Trans>Agosto</Trans>);
      }
      case 'SEPTEMBER': {
        return ReactDOMServer.renderToStaticMarkup(<Trans>Setembro</Trans>);
      }
      case 'OCTOBER': {
        return ReactDOMServer.renderToStaticMarkup(<Trans>Outubro</Trans>);
      }
      case 'NOVEMBER': {
        return ReactDOMServer.renderToStaticMarkup(<Trans>Novembro</Trans>);
      }
      case 'DECEMBER': {
        return ReactDOMServer.renderToStaticMarkup(<Trans>Dezembro</Trans>);
      }
      default: {
        return ReactDOMServer.renderToStaticMarkup(<Trans>Mês não encontrado</Trans>);
      }
    }
  }

  changeSelectStore = event => {
    this.setState({ store: event.target.value });
    if (event.target.value === ['all']) {
      this.setState({ checkAllStore: false });
      this.componentWillMount();
    } else {
      this.setState({ checkAllStore: true, GetstoreID: event.target.value });
      this.componentWillMount();
    }
  };

  changeSelectPeriodMonth = event => {
    this.setState({ periodMonth: event.target.value });
    this.componentWillMount();
  };

  changeSelectDate = event => {
    if (event.target.value !== null) {
      const minDate = new Date(event.target.value);
      if (minDate.getFullYear() > 2015) {
        this.setState({ dateTime: event.target.value });
        this.componentWillMount();
      } else {
        this.props.addNotification({
          variant: 'error',
          message: <Trans>Por favor, selecione uma data com o ano maior que 2015!</Trans>
        });
      }
    }
  };

  componentDidUpdate(prevProps) {
    const hasDateChanged = prevProps.filters.date !== this.props.filters.date;
    const hasStoreChanged = prevProps.filters.store !== this.props.filters.store;
    const hasPeriodChanged = prevProps.filters.period !== this.props.filters.period;

    if (hasDateChanged || hasStoreChanged || hasPeriodChanged) {
      this.update();
    }
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    this.setState({ loading: true });
    // Recebendo storeId
    const receivingStoreId = [];
    // Recebendo dados da API
    const receivingStoreAPI = [];
    // Populando o select
    const receivingStoreAPIData = [];
    // Recebendo todas as lojas relacionadas ao usuario
    const receivingDataStore = await getUserStores(this.props.user.email);
    // Recebendo todos os fornecedores
    const receivingSuppliers = await fetchSuppliers();
    let now;
    if (this.props.filters.date === null) {
      now = new Date();
    } else {
      now = new Date(this.props.filters.date);
    }

    // Variavel data anterior
    const before = new Date(
      now.getFullYear(),
      now.getMonth() - this.props.filters.period,
      now.getDay()
    );
    // Data atual
    const nowDate = `${dateFormat(now, 'yyyy-mm-dd')}T23:59:59`;
    // Data antes
    const beforeDate = `${dateFormat(before, 'yyyy-mm-dd')}T23:59:59`;

    for (let j = 0; j < receivingDataStore.length; j += 1) {
      receivingStoreId.push(receivingDataStore[j].store.id);
    }

    if (this.props.filters.store[0] === 'all') {
      this.setState({ storeID: receivingStoreId });
    } else {
      this.setState({ storeID: this.props.filters.store });
    }

    const purchasesData = await getSupplierRequestChart(this.state.storeID, beforeDate, nowDate);

    let f = 0;
    do {
      for (let k = 0; k < purchasesData.length; k += 1) {
        if (purchasesData[k].storeId === receivingDataStore[f].store.id) {
          let forn = 0;
          do {
            if (purchasesData[k].supplierId === receivingSuppliers[forn].id) {
              receivingStoreAPI.push([
                ` Fornecedor: ${receivingSuppliers[forn].tradingName}`,
                purchasesData[k].amount,
                this.createCustomHTMLContent(
                  receivingSuppliers[forn].tradingName,
                  receivingDataStore[f].store.tradingName,
                  purchasesData[k].amount,
                  purchasesData[k].month
                )
              ]);
              receivingStoreAPIData.push(receivingDataStore[f].store);
            }
            forn++;
          } while (forn < receivingSuppliers.length);
        }
      }
      f++;
    } while (f < receivingStoreId.length);

    const chartData = [
      [
        'Fornecedor',
        'Pedidos enviados aos fornecedores',
        { type: 'string', role: 'tooltip', p: { html: true } }
      ]
    ];

    for (let i = 0; i < receivingStoreAPI.length; i += 1) {
      chartData.push(receivingStoreAPI[i]);
    }

    if (this.state.storeData.length === 0) {
      this.setState({
        storeData: receivingStoreAPIData
      });
    }
    this.setState({
      chartData,
      loading: false
    });
  }
}

const styles = theme => ({
  chart: {
    minWidth: '66vw',
    minHeight: '66vh'
  },
  chartContainer: {
    padding: theme.spacing.unit * 2,
    width: 'fit-content',
    minWidth: `calc(66vw + ${theme.spacing.unit * 2}px)`,
    minHeight: `calc(66vh + ${theme.spacing.unit * 2}px)`,
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  }
});

function mapStateToProps(state) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {
    addNotification({ message, variant }) {
      dispatch(NotificationCreators.addNotification(createNotification({ message, variant })));
    }
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ChartSuppliers);
