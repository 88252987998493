import React, { useEffect } from 'react';
import FormSection from 'components/form/wrappers/form-section';
import SelectField from 'components/form/fields/select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TextField from 'components/form/fields/text';
import { Trans } from '@lingui/macro';
import { required, maxLength } from 'components/form/fields/validation';
import Button from 'components/buttons/button';

const InsertOrder = ({ classes, stores, handleSubmit, onSubmit, change }) => {
  useEffect(() => {
    if (stores && stores.length === 1) change('storeId', stores[0].store.id);
  }, [change, stores]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={24} className={classes.root}>
        <Grid item xs={12}>
          <FormSection title={<Trans>Informe o número de referência e a loja</Trans>}>
            <TextField
              name="orderNumber"
              fullWidth
              label={<Trans>Código de referência do pedido</Trans>}
              validate={[required, orderNumerMaxLength]}
            />
            <div style={{ height: 10 }}></div>
            <SelectField name="storeId" fullWidth label={<Trans>Loja</Trans>} validate={[required]}>
              {stores &&
                stores.map(store => (
                  <MenuItem key={store.store.id} value={store.store.id}>
                    {store.store.tradingName}
                  </MenuItem>
                ))}
              {!stores.length > 0 && (
                <MenuItem value="disabled" disabled>
                  <Trans>Não há lojas disponíveis</Trans>
                </MenuItem>
              )}
            </SelectField>
          </FormSection>
        </Grid>
      </Grid>
      <div className={classes.button}>
        <Button type="submit">
          <Trans>Prosseguir</Trans>
        </Button>
      </div>
    </form>
  );
};

const orderNumerMaxLength = maxLength(20);

export default InsertOrder;
