import React from 'react';
import View from 'components/view';
import { Trans } from '@lingui/macro';
import AddEditStore from 'forms/store/add-edit/';

function NewStore() {
  return (
    <View size="sm" title={<Trans>Nova Loja</Trans>}>
      <AddEditStore />
    </View>
  );
}
export default NewStore;
