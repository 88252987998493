import React, { Component } from 'react';
import { createNotification, Creators as notificationCreators } from 'store/ducks/notifications/';
import { isEqualTo, required } from 'components/form/fields/validation';

import Button from 'components/buttons/button';
import FormControl from 'components/form/wrappers/form-control';
import { Creators as OverlayCreators } from 'store/ducks/overlay';
import { TextInput } from 'components/form/Input';
import { Trans } from '@lingui/macro';
import { changeStoreStatus } from 'services/stores';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';

class ChangeState extends Component {
  state = {
    confirmEnabled: false
  };

  render = () => {
    const { handleSubmit, valid } = this.props;
    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <FormControl margin="normal" fullWidth>
          <TextInput
            label={<Trans>Digite aqui*</Trans>}
            validate={[required, this.storeNameAreEqual]}
            name="validation"
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <Button color="primary" variant="contained" type="submit" disabled={!valid}>
            <Trans>
              <b>Confirmar</b>
            </Trans>
          </Button>
        </FormControl>
      </form>
    );
  };

  storeNameAreEqual = isEqualTo(this.props.tradingName);

  submit = values => {
    this.props.overlay.show(<Trans>Aguarde, processando...</Trans>);
    changeStoreStatus(
      this.props.storeId,
      this.props.activate === true ? 'ACTIVE' : 'INACTIVE'
    ).then(response => {
      if (response.status === 204) {
        this.props.addNotification({
          variant: 'success',
          message: <Trans>Estado da loja atualizado com sucesso!</Trans>
        });
        this.props.submitCallback();
      } else {
        this.props.addNotification({
          variant: 'error',
          message: (
            <Trans>
              Ocorreu uma falha ao tentar atualizar o estado da loja. Tente novamente mais tarde!
            </Trans>
          )
        });
      }
      this.props.overlay.hide();
    });
  };
}

const styles = theme => ({});

const mapDispatchToProps = dispatch => ({
  addNotification: ({ message, variant }) =>
    dispatch(notificationCreators.addNotification(createNotification({ message, variant }))),
  overlay: {
    show: loadingText => dispatch(OverlayCreators.showOverlay({ loadingText })),
    hide: _ => dispatch(OverlayCreators.hideOverlay())
  }
});

export default reduxForm({
  form: 'changeState'
})(connect(null, mapDispatchToProps)(withStyles(styles)(ChangeState)));
