import { Creators as NotificationCreators, createNotification } from 'store/ducks/notifications';
import { Trans } from '@lingui/macro';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormControl from 'components/form/wrappers/form-control';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, getFormValues, reset } from 'redux-form';
import Fab from '@material-ui/core/Fab';
import TextField from 'components/form/fields/text';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SelectField from 'components/form/fields/select';
import DateTimePickerField from 'components/form/fields/date-time-picker';
import { required, customValidation } from 'components/form/fields/validation';
import Loading from 'components/utils/Loading';
import { sendOrderToSupplier, sendOrderSupplier } from 'services/orders';
import { getUserStores } from 'services/stores';
import { Creators as StoreSupplierCreators } from 'store/ducks/storeSuppliers';
import { Creators as SuppliersCreators } from 'store/ducks/suppliers';
import withNotification from 'store/providers/withNotification';
import Paper from '@material-ui/core/Paper';
import { getOrderById } from 'store/selectors/orders';
import { payloadSupplier } from './utils/orderPayload';

class ChooseSupplier extends Component {
  render() {
    if (!this.props.storeSuppliers) {
      return <Loading />;
    }
    const {
      classes,
      storeSuppliers,
      storeId,
      formValues,
      suppliersList,
      handleSubmit,
      order
    } = this.props;
    const { controlNumber } = formValues;
    const { disabledBack, controlNumberInfo, stores } = this.state;
    const isStores = stores && stores.filter(data => data.store.id === storeId);
    let isStoresAux;
    if (
      controlNumber &&
      controlNumber !== undefined &&
      controlNumber !== '' &&
      controlNumberInfo === ''
    ) {
      this.setState({ controlNumberInfo: controlNumber });
    }
    try {
      if (isStores && isStores.length > 0) {
        isStoresAux = isStores[0].store.enableControlNumber;
      }
    } catch (e) {
      console.log(e);
    }
    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div className={classes.root}>
          <Typography className={classes.titleHead} variant="title" gutterBottom>
            <Trans>Escolha o fornecedor e a data desejada para entrega</Trans>
          </Typography>
          <Typography className={classes.subTitle} variant="subheading" gutterBottom>
            <Trans>
              Selecionando 'Outro' em 'Escolha o fornecedor' o pedido não será transmitido
              automaticamente
            </Trans>
          </Typography>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <Typography className={classes.title} variant="title" gutterBottom>
                  <Trans>Escolha o fornecedor</Trans>
                </Typography>
                <SelectField
                  fullWidth
                  label={<Trans>Fornecedor</Trans>}
                  name="supplierId"
                  onChangeCallback={this.changeSelectSuppliers}
                  validate={[required]}
                  warn={[warningOnOther]}
                  disabled={!!formValues.sentToSupplier}
                >
                  <MenuItem value="" disabled />

                  {storeSuppliers.map(({ supplierId }) => (
                    <MenuItem key={supplierId} value={supplierId} disabled={order.promotionOrder}>
                      {suppliersList
                        .filter(({ id }) => id === supplierId)
                        .map(({ companyName }) => companyName)}
                    </MenuItem>
                  ))}
                  <Divider />
                  <MenuItem value="other" disabled={order.promotionOrder}>
                    Outro
                  </MenuItem>
                </SelectField>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <Typography className={classes.title} variant="title" gutterBottom>
                  <Trans>Data desejada para entrega e controle</Trans>
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <DateTimePickerField name="orderDueDateTime" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="controlNumber"
                      onBlur={this.changeControlNumber}
                      label={<Trans>Controle</Trans>}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
        {controlNumberInfo === '' && isStoresAux && (
          <div className={classes.notifyInfo}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Trans>
                    Está loja obriga informar o <b>número de controle</b>! Para seguir com o envio
                    do pedido, basta informar o <b>"número de controle"</b> no campo{' '}
                    <b>"controle"</b>, esse campo está acima do lado da{' '}
                    <b>"data desejada para entrega"</b>.
                  </Trans>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}

        <div className={classes.actions}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <FormControl>
                <Fab
                  variant="contained"
                  disabled={disabledBack}
                  onClick={this.back}
                  className={classes.button}
                >
                  <NavigateBeforeIcon className={classes.extendedIcon} />
                  <Trans>Voltar</Trans>
                </Fab>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} style={{ textAlign: 'end' }}>
              <FormControl>
                <Fab
                  disabled={controlNumberInfo === '' && isStoresAux}
                  variant="contained"
                  className={classes.button}
                  type="submit"
                >
                  <NavigateNextIcon className={classes.extendedIcon} />
                  {this.state.textButton}
                </Fab>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  }

  state = {
    textButton: 'Próximo',
    disabledBack: false,
    checkedNotSend: false,
    notSendSupplierUrl: false,
    stores: [],
    controlNumberInfo: '',
    notSend: false
  };

  submit = () => {
    if (this.state.notSend) {
      this.notSendSupplier();
    } else if (this.state.notSendSupplierUrl) {
      this.sendSupplierNotUrl();
    } else {
      this.props.nextStep();
    }
  };

  getStores = async () => {
    const { email } = this.props.user;
    const stores = await getUserStores(email);

    this.setState({ stores });
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  changeControlNumber = event => {
    const { change } = this.props;
    change('controlNumber', event.target.value);
    this.setState({ controlNumberInfo: event.target.value });
  };

  changeSelectSuppliers = event => {
    try {
      const { suppliersList } = this.props;
      const supplierUrl = suppliersList.filter(({ id }) => id === event.target.value);
      if (event.target.value === 'other') {
        this.setState({
          textButton: 'Finalizar',
          disabledBack: true,
          checkedNotSend: true,
          notSend: true,
          notSendSupplierUrl: false
        });
      } else if (supplierUrl.length > 0 && supplierUrl[0].webServiceUrl === '') {
        this.setState({
          textButton: 'Finalizar',
          disabledBack: true,
          notSend: false,
          notSendSupplierUrl: true
        });
      } else {
        this.setState({
          textButton: 'Próximo',
          disabledBack: false,
          checkedNotSend: false,
          notSend: false,
          notSendSupplierUrl: false
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentWillMount = async () => {
    await this.getStores();
  };

  componentDidMount() {
    const { storeId } = this.props;
    this.props.fetchSuppliers();
    this.props.getStoreSupplier(storeId);
  }

  notSendSupplier = () => {
    const { addNotification, closeDialog, fetchOrders, order } = this.props;
    sendOrderSupplier(order.orderId, order.storeId, 'ON_SUPPLIER').then(response => {
      switch (response.status) {
        case 204:
          addNotification({
            variant: 'success',
            message: <Trans>Pedido movido com sucesso!</Trans>
          });
          fetchOrders();
          this.props.dispatch(reset('wizard'));
          closeDialog('sendOrder')();
          break;
        case 403:
          addNotification({
            variant: 'error',
            message: (
              <Trans>
                Pedido não pôde ser movido para este status, verifique as permissões do seu usuário!
              </Trans>
            )
          });
          fetchOrders();
          closeDialog('sendOrder')();
          break;
        default:
          addNotification({
            variant: 'error',
            message: (
              <Trans>
                Não foi possível estabelecer conexão com o servidor. Tente novamente mais tarde!
              </Trans>
            )
          });
          fetchOrders();
          closeDialog('sendOrder')();
          break;
      }
    });
  };

  sendSupplierNotUrl = async () => {
    const {
      addNotification,
      closeDialog,
      fetchOrders,
      order,
      formValues,
      prescription
    } = this.props;

    try {
      await sendOrderToSupplier(
        formValues.orderId,
        formValues.storeId,
        payloadSupplier(formValues, prescription)
      ).then(result => {
        if (result.status === 204) {
          addNotification({
            variant: 'success',
            message: <Trans>Pedido enviado com sucesso!</Trans>
          });
          fetchOrders();
          this.props.dispatch(reset('wizard'));
          setTimeout(() => {
            closeDialog('sendOrder')();
          }, 1500);
        } else if (result.status === 400) {
          addNotification({
            variant: 'error',
            message: <Trans>Ocorreu uma falha ao enviar o pedido ao fornecedor!</Trans>
          });
        } else if (result.status === 404) {
          addNotification({
            variant: 'error',
            message: <Trans>Nenhum item foi encontrado para envio ao fornecedor</Trans>
          });
        } else if (result.status === 422) {
          addNotification({
            variant: 'error',
            message: <Trans>Ocorreu uma falha ao enviar o pedido ao fornecedor!</Trans>
          });
        } else if (result.status === 500) {
          addNotification({
            variant: 'error',
            message: (
              <Trans>
                Ocorreu uma falha de comunicação com o servidor. Tente novamente mais tarde!
              </Trans>
            )
          });
        }
        fetchOrders();
      });
    } catch (error) {
      addNotification({
        variant: 'error',
        message: (
          <Trans>
            Ocorreu uma falha de comunicação com o servidor. Tente novamente mais tarde!
          </Trans>
        )
      });
    }
  };
}

const warningOnOther = customValidation(
  `Selecionando 'Outro' o pedido não será
transmitido automaticamente`,
  'other'
);

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    color: theme.palette.text.secondary
  },
  titleHead: {
    color: '#757575'
  },
  subTitle: {
    color: '#757575',
    fontSize: '0.8em',
    marginBottom: theme.spacing.unit * 2
  },
  title: {
    color: '#757575',
    fontSize: '1em',
    marginBottom: theme.spacing.unit * 2
  },
  timePicker: {
    display: 'inline-flex',
    marginBottom: theme.spacing.unit + 12,
    marginRight: theme.spacing.unit + 2
  },
  extendedIcon: {
    marginRight: theme.spacing.unit - 3,
    paddingLeft: theme.spacing.unit - 3
  },
  button: {
    [theme.breakpoints.up('md')]: {
      width: 165
    },
    width: 110,
    borderRadius: 0,
    fontSize: '0.8em',
    backgroundColor: '#047eb4',
    color: '#fff',
    paddingRight: theme.spacing.unit,
    '&:hover': {
      backgroundColor: '#04587D'
    }
  },
  actions: {
    position: 'fixed',
    top: '90%',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: '95%'
    }
  },
  notifyInfo: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: '-8%',
      width: '100%',
      fontSize: '0.6em'
    },
    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  }
});

function mapStateToProps(state, ownProps) {
  const formValues = getFormValues('wizard')(state) || {};
  const order = getOrderById(state, ownProps.order.orderId);

  const stableOder = order || ownProps.order;

  return {
    ...stableOder,
    storeSuppliers: state.storeSuppliers,
    suppliersList: state.suppliers,
    user: state.user,
    formValues
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addNotification({ message, variant }) {
      dispatch(NotificationCreators.addNotification(createNotification({ message, variant })));
    },
    getStoreSupplier(storeId) {
      dispatch(StoreSupplierCreators.getStoreSupplier(storeId));
    },
    fetchSuppliers: _ => dispatch(SuppliersCreators.getSuppliers())
  };
}

export default compose(
  withStyles(styles),
  withNotification,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })
)(ChooseSupplier);
