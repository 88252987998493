import PropTypes from 'prop-types';

export default PropTypes.shape({
  store: PropTypes.shape({
    id: PropTypes.number,
    cnpj: PropTypes.string,
    tradingName: PropTypes.string,
    socialName: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    number: PropTypes.string,
    addressDetails: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    neighborhood: PropTypes.string,
    zipCode: PropTypes.string,
    resourceStore: PropTypes.number,
    storeDetail: PropTypes.shape({
      id: PropTypes.number,
      receiveSendFrames: PropTypes.bool,
      printCertificateship: PropTypes.bool,
      opticsResources: PropTypes.bool,
      status: PropTypes.number
    })
  })
});
