import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import {
  fetchOrder,
  fetchOrders,
  insertReducedOrder,
  patchOrderWithNotification
} from 'services/orders';

import React from 'react';
import { Trans } from '@lingui/macro';
import { Creators as OverlayCreators } from '../overlay';
import { Creators as StoresCreators, Types as StoresTypes } from '../stores';

export const INITIAL_STATE = [];

export function getOrders(state = INITIAL_STATE, action) {
  return [...state];
}

export function getOrdersCopies(state = INITIAL_STATE, action) {
  return [...state];
}

export function getOrder(state = INITIAL_STATE, action) {
  return [...state];
}

export function insertOrder(state = INITIAL_STATE, action) {
  return [...state];
}
export function insertedOrder(state = INITIAL_STATE, action) {
  return [...state];
}

export function changeOrder(state = INITIAL_STATE, action) {
  return [...state];
}

export function receiveOrders(state = INITIAL_STATE, action) {
  const { payload } = action;
  return [...payload];
}

export function receiveOrdersCopies(state = INITIAL_STATE, action) {
  const { payload: copies } = action;
  return [...state, { copies }];
}

export function receiveOrder(state = INITIAL_STATE, action) {
  const { payload: receivedOrder } = action;
  const otherOrders = state.filter(order => order.orderId !== receivedOrder.orderId);

  return [...otherOrders, receivedOrder];
}

export const { Types, Creators } = createActions({
  getOrders: ['payload'],
  getOrdersCopies: ['payload'],
  getOrder: ['payload'],
  changeOrder: ['payload'],
  insertOrder: ['payload'],
  insertedOrder: ['payload'],
  receiveOrders: ['payload'],
  receiveOrder: ['payload'],
  receiveOrdersCopies: ['payload']
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_ORDERS]: getOrders,
  [Types.GET_ORDERS_COPIES]: getOrdersCopies,
  [Types.GET_ORDER]: getOrder,
  [Types.CHANGE_ORDER]: changeOrder,
  [Types.INSERT_ORDER]: insertOrder,
  [Types.INSERTED_ORDER]: insertedOrder,
  [Types.RECEIVE_ORDERS]: receiveOrders,
  [Types.RECEIVE_ORDERS_COPIES]: receiveOrdersCopies,
  [Types.RECEIVE_ORDER]: receiveOrder
});

export function* watchChangeOrder() {
  yield takeEvery(Types.CHANGE_ORDER, changeOrderHandler);
}

export function* watchInsertOrder() {
  yield takeLatest(Types.INSERT_ORDER, insertOrderHandler);
  yield takeLatest(Types.INSERTED_ORDER, getOrderHandler);
}

export function* watchGetOrder() {
  yield takeLatest(Types.GET_ORDER, getOrderHandler);
}

export function* watchGetOrders() {
  yield takeLatest(Types.GET_ORDERS, getStoresForOrders);
  yield takeLatest(StoresTypes.RECEIVE_STORES, getOrdersHanlder);
}

export function* getStoresForOrders(action) {
  const { payload: userEmail } = action;

  yield put(StoresCreators.getStores(userEmail));
}

export function* getOrderHandler(action) {
  const {
    payload: { orderId, storeId }
  } = action;

  yield put(
    OverlayCreators.showOverlay({
      loadingText: <Trans>Atualizando...</Trans>
    })
  );

  const order = yield call(fetchOrder, orderId, storeId);
  if (order) yield put(Creators.receiveOrder(order));

  yield put(OverlayCreators.hideOverlay());
}

export function* changeOrderHandler(action) {
  const {
    payload: { orderId, storeId, data }
  } = action;

  yield put(
    OverlayCreators.showOverlay({
      loadingText: <Trans>Aguarde, solicitando informações</Trans>
    })
  );

  const change = yield call(patchOrderWithNotification, orderId, storeId, data);
  if (change) yield put(Creators.getOrder({ orderId, storeId }));
}

export function* insertOrderHandler(action) {
  const { payload } = action;
  const { storeId } = payload;
  yield put(
    OverlayCreators.showOverlay({
      loadingText: <Trans>Aguarde, gravando pedido...</Trans>
    })
  );
  try {
    const httpResponse = yield call(insertReducedOrder, payload);
    const location = httpResponse.headers.get('Location');
    const orderId = getIdFromLocation(location);
    yield put(Creators.insertedOrder({ storeId, orderId }));
  } catch (error) {
    yield put(OverlayCreators.hideOverlay());
  }
}

const getIdFromLocation = location => {
  const locationArray = location.split('/');
  const idIndex = locationArray.length - 1;
  const id = locationArray[idIndex];
  return id;
};

export function* getOrdersHanlder(action) {
  yield put(Creators.receiveOrders([]));
  yield put(
    OverlayCreators.showOverlay({
      loadingText: <Trans>Buscando...</Trans>
    })
  );
  try {
    const storeIdDefault = localStorage.getItem('storeIdDefault');
    const { payload: userStores } = action;
    let filterStoreIdDefault = null;
    const params = new URLSearchParams(document.location.search.substring(1));
    const storeValue = params.get('store');
    if (storeValue) {
      filterStoreIdDefault = storeValue;
    } else {
      filterStoreIdDefault =
        storeIdDefault || (userStores && userStores.length > 0 ? userStores[0].store.id : null);
    }

    const storeId = filterStoreIdDefault;

    const ordersList = yield Promise.all([
      fetchOrders(storeId, 'ORDERED'),
      fetchOrders(storeId, 'ON_SUPPLIER'),
      fetchOrders(storeId, 'ASSEMBLY_LAB'),
      fetchOrders(storeId, 'AWAITING_STORE'),
      fetchOrders(storeId, 'RECEIVED')
    ])
      .then(orders => orders.map(order => order.content))
      .then(orders => orders.filter(orders => orders && orders.length > 0))
      .then(orders => {
        if (orders && orders.length > 0)
          return orders.reduce((accumulator, order) => accumulator.concat(order));
      });
    if (!ordersList) {
      yield put(OverlayCreators.hideOverlay());
      throw new Error(`Nenhum pedido encontrado para as lojas de id: ${storeId}`);
    }
    yield put(Creators.receiveOrders(ordersList));

    yield put(OverlayCreators.hideOverlay());
  } catch (err) {
    console.log(err);
    yield put(Creators.receiveOrders([]));
  }
}
