import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Dialog from 'components/dialog';
import { Trans } from '@lingui/macro';
import { DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Button from 'components/buttons/button';
import types from 'utils/types';
import { getSupplierResources as fetchResources } from 'services/suppliers';
import {
  getSupplierSku as getIntegrationsByStoreSku,
  sendStoreSupplierIntegration,
  removeStoreSupplierIntegration
} from 'services/stores';
import withOverlay from 'store/providers/withOverlay';
import { Creators as StoreSupplierCreators } from 'store/ducks/storeSuppliers';
import { Creators as SuppliersCreators } from 'store/ducks/suppliers';
import { Creators as NotificationCreators } from 'store/ducks/notifications/';
import IntegrationList from './IntegrationList';

const styles = theme => {
  return {
    header: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      alignItens: 'center',
      justifyContent: 'center'
    },
    col2: {
      margin: '0 0 2% 0',
      [theme.breakpoints.up('sm')]: {
        flex: '0 0 40%',
        margin: '0 2% 0 0'
      }
    },
    col1: {
      flex: '0 0 10%'
    }
  };
};

const IntegrationDialog = ({
  open,
  onClose,
  storeId,
  cnpj,
  defaultSupplier,
  except,
  storeResource: { sku, description, type },
  getStoreSuppliers,
  storeSuppliers,
  fetchSuppliers,
  suppliersList,
  notify,
  classes,
  overlay
}) => {
  const [supplier, setSupplier] = useState(undefined);
  const [supplierResources, setSupplierResources] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [supplierResource, setSupplierResource] = useState(undefined);

  useEffect(() => {
    setIntegrations([]);
  }, []);

  useEffect(() => {
    if (defaultSupplier && suppliersList) {
      const data = suppliersList.find(item => item.id === defaultSupplier);
      if (data) {
        setSupplier({ label: data.companyName, value: data.id });
      }
    }
  }, [defaultSupplier, suppliersList]);

  useEffect(() => {
    getSupplierResources();
  }, [getSupplierResources, supplier]);

  useEffect(() => {
    fetchSuppliers();
    getStoreSuppliers(storeId);
  }, [fetchSuppliers, getStoreSuppliers, overlay, storeId]);

  const clearIntegrations = () => {
    setIntegrations([]);
  };

  const removeIntegration = id => {
    removeStoreSupplierIntegration({
      supplierId: supplier.value,
      storeId,
      id
    })
      .then(res => {
        notify(<Trans>Integração removida com sucesso!</Trans>, 'success');

        setIntegrations([...integrations.filter(integration => integration.id !== id)]);
        return res;
      })
      .catch(() => {
        notify(<Trans>Não foi possível remover a integração. Tente novamente!</Trans>, 'error');
      });
  };

  const getIntegrations = useCallback(async () => {
    if (!supplier) {
      clearIntegrations();
      return;
    }
    const supplierId = supplier.value;

    await getIntegrationsByStoreSku(storeId, supplierId, 'RESOURCE', sku).then(result => {
      const { content } = result;
      setIntegrations(content.filter(({ supplierSku }) => supplierSku !== except) || []);
    });
  }, [except, sku, storeId, supplier]);

  const sendIntegration = async () => {
    const resource = supplierResources.find(item => item.sku === supplierResource.value);
    const hasResourceTypeLens = integrations.find(
      integration => integration.supplierSkuType === resource.type && resource.type === 'LENS'
    );

    if (hasResourceTypeLens) {
      notify(<Trans>Este produto já está integrado com um recurso do tipo LENTE.</Trans>, 'error');
      return;
    }

    const integrationData = {
      integrationType: 'RESOURCE',
      supplierSkuType: resource.type,
      storeSkuType: type,
      supplierSku: resource.sku,
      storeSku: sku,
      conversionFactor: 1
    };
    await sendStoreSupplierIntegration({
      supplierId: supplier.value || defaultSupplier.value,
      storeId,
      integrationData
    }).then(res => {
      if (res.status === 201) {
        getIntegrations();
        notify(<Trans>Integração efetuada com sucesso!</Trans>, 'success');
      } else {
        notify(
          <Trans>
            Não foi possível efetivar a integração. Verifique os dados e tente novamente!
          </Trans>,
          'error'
        );
      }
    });
  };

  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      getIntegrations();
    }, 100);

    return () => window.clearInterval(timeoutID);
  }, [getIntegrations, supplierResources]);

  const doRecursiveFetch = async (supplierId, resourceType, page = 0, allItems = []) => {
    try {
      overlay.show(<Trans>Carregando dados...</Trans>);
      let storage = [];
      if (!cnpj) {
        overlay.hide();

        return storage;
      }
      const { last, content } = await fetchResources({
        supplierId,
        resourceType,
        page,
        storeCnpj: cnpj
      });
      storage = [...(allItems || []), ...(content || [])];
      if (last) {
        overlay.hide();
        return storage;
      }
      return doRecursiveFetch(supplierId, resourceType, page + 1, storage);
      // return last ? storage : doRecursiveFetch(supplierId, resourceType, page + 1, storage);
    } catch (error) {
      console.error(error);
      overlay.hide();
    }
  };

  const clearSupplierResources = () => {
    setSupplierResource(null);
    setSupplierResources([]);
  };

  const getSupplierResources = useCallback(async args => {
    clearSupplierResources();
    if (!supplier) {
      return;
    }
    const selectedTypes = types.filter(
      ({ value }) => value !== 'FRAME' && value !== 'FRAME_SHAPE' && value !== 'BLANK'
    );
    let storage = [];

    selectedTypes.map(async ({ value: resourceType }) => {
      const data = await doRecursiveFetch(supplier.value, resourceType);
      storage = [...storage, ...data];
      setSupplierResources(storage);
    });
  });
  return (
    <Dialog title={`${sku} - ${description}`} open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent style={{ minHeight: 400 }}>
        <div className={classes.header}>
          <Select
            name="suppliers"
            className={classes.col2}
            isLoading={!storeSuppliers}
            isDisabled={!storeSuppliers || defaultSupplier}
            value={supplier}
            isClearable
            placeholder={<Trans>Selecione o fornecedor...</Trans>}
            options={suppliersList
              .filter(({ id }) => storeSuppliers.find(({ supplierId }) => id === supplierId))
              .map(supplier => ({
                label: supplier.companyName,
                value: supplier.id
              }))}
            onChange={async value => {
              setSupplier(value);
            }}
          />
          <Select
            name="supplierResources"
            className={classes.col2}
            value={supplierResource}
            isDisabled={supplierResources.length === 0}
            isClearable
            placeholder={
              supplierResources.length === 0 ? (
                <Trans>Não há recursos disponíveis</Trans>
              ) : (
                <Trans>Selecione o recurso do fornecedor</Trans>
              )
            }
            options={supplierResources
              .filter(({ sku }) => sku !== except)
              .map(({ sku, description }) => ({
                label: `${sku} - ${description}`,
                value: sku
              }))}
            onChange={value => {
              setSupplierResource(value);
            }}
          />
          <Button
            className={classes.col1}
            disabled={!supplier || !supplierResource}
            onClick={() => sendIntegration()}
          >
            <Trans>Adicionar</Trans>
          </Button>
        </div>
        <div className={classes.list}>
          <IntegrationList
            integrations={integrations}
            supplierResources={supplierResources}
            deleteCallback={removeIntegration}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

IntegrationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  storeId: PropTypes.number.isRequired,
  storeResource: PropTypes.shape({
    sku: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  classes: PropTypes.objectOf({}).isRequired,
  getStoreSuppliers: PropTypes.func.isRequired,
  storeSuppliers: PropTypes.objectOf([]).isRequired,
  fetchSuppliers: PropTypes.func.isRequired,
  suppliersList: PropTypes.objectOf([]).isRequired
};

const mapStateToProps = state => {
  return {
    storeSuppliers: state.storeSuppliers,
    suppliersList: state.suppliers
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getStoreSuppliers: storeId => dispatch(StoreSupplierCreators.getStoreSupplier(storeId)),
    fetchSuppliers: () => dispatch(SuppliersCreators.getSuppliers()),
    notify: (message, variant) =>
      dispatch(NotificationCreators.addNotification({ message, variant }))
  };
};

export default compose(
  withOverlay,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(IntegrationDialog);
