import React, { Component } from 'react';
import Dialog from 'components/dialog/';
import DialogContent from '@material-ui/core/DialogContent';
import { Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core/styles';
import PrescriptionErrors from 'components/prescription/PrescriptionErrors';

class PrescriptionErrosDialog extends Component {
  render = () => {
    const { open, onClose, errors } = this.props;
    return (
      <Dialog
        title={<Trans>Erros na validação do pedido</Trans>}
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <PrescriptionErrors errors={errors} />
        </DialogContent>
      </Dialog>
    );
  };
}

const styles = theme => ({
  text: {
    textAlign: 'center'
  }
});

export default withStyles(styles)(PrescriptionErrosDialog);
