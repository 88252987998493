import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectInput } from 'components/form/Input';
import PropTypes from 'prop-types';
import React from 'react';
import { frameShapes } from 'utils/frame/shape';
import { mockframeShape } from './mockFrameShape';

export const SelectShape = ({ data, ...props }) => {
  return (
    <SelectInput {...props}>
      {data && data.length > 0
        ? data.map(({ text, value }) => (
            <MenuItem key={value} value={value}>
              <ListItemIcon style={{ marginRight: '8px', float: 'left' }}>
                {frameShapes
                  .filter(({ id }) => id === value)
                  .map(({ image, id }) => (
                    <img src={image} alt={id} width="25" height="20" />
                  ))}
              </ListItemIcon>
              <ListItemText>{text}</ListItemText>
            </MenuItem>
          ))
        : mockframeShape.map(({ text, value }) => (
            <MenuItem key={value} value={value}>
              <ListItemIcon style={{ marginRight: '8px', float: 'left' }}>
                {frameShapes
                  .filter(({ id }) => id === value)
                  .map(({ image, id }) => (
                    <img src={image} alt={id} width="25" height="20" />
                  ))}
              </ListItemIcon>
              <ListItemText>{text}</ListItemText>
            </MenuItem>
          ))}
    </SelectInput>
  );
};

SelectShape.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired
};

export default SelectShape;
