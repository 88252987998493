import React, { Component } from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import { Trans } from '@lingui/macro';
import BaseOrderDialog from '../BaseOrderDialog';
import SupplierRequestListContainer from './SupplierRequestListContainer';

class SupplierRequestDialog extends Component {
  render() {
    const { order } = this.props;

    const Title = () => (
      <>
        <Trans>Envios ao fornecedor - </Trans> <b>{order && order.orderNumber}</b>
      </>
    );

    return order ? (
      <BaseOrderDialog
        fullWidth
        maxWidth="lg"
        closeCallback={this.close}
        name="supplierRequest"
        title={<Title />}
      >
        <DialogContent>
          <SupplierRequestListContainer orderId={order.orderId} storeId={order.storeId} />
        </DialogContent>
      </BaseOrderDialog>
    ) : null;
  }
}
export default SupplierRequestDialog;
