import React from 'react';
import { OrdersConsumer } from 'views/home/orders/OrdersProvider';
import Information from './Information';

export default ({ order, formValues, suppliersList, ...props }) => {
  return (
    <OrdersConsumer>
      {({ actions, receiveOrder, notifyLateDate }) => (
        <Information
          {...props}
          receiveOrder={receiveOrder}
          order={order}
          formValues={formValues}
          suppliersList={suppliersList}
          actions={actions}
          notifyLateDate={notifyLateDate}
        />
      )}
    </OrdersConsumer>
  );
};
