import AppBar from '@material-ui/core/AppBar';
import Button from 'components/buttons/button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from 'components/dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React from 'react';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';
import { userConfirmation } from 'services/users';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#2a93d5'
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`
  },
  circularProgress: {
    margin: '0 auto',
    display: 'block',
    textAlign: 'center'
  },
  modal: {
    backgroundColor: green[600],
    margin: '0px 0px'
  },
  modalTxt: {
    color: 'white'
  },
  modalAction: {
    backgroundColor: 'white',
    color: 'black',
    margin: '10px',
    '&:hover': {
      backgroundColor: '#A8A8A8',
      color: 'white'
    }
  },
  card: {
    backgroundColor: theme.palette.error.dark
  },
  cardText: {
    color: 'white'
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class UserConfirmation extends React.Component {
  state = {
    open: false,
    loadingConfirmation: true,
    cardError: false,
    cardContentTxt: ''
  };

  goLogin = () => {
    window.location.replace('/login');
  };

  componentDidMount = () => {
    userConfirmation(
      this.props.match.params.userId.replace('userId=', ''),
      this.props.match.params.userToken.replace('userToken=', '')
    ).then(res => {
      switch (res.status) {
        case 200: {
          this.setState({ loadingConfirmation: false });
          this.setState({ open: true });
          const [, urlToRedirect] = this.props.location.search.split('redirectto=');

          if (urlToRedirect) {
            window.location.assign(urlToRedirect);
          }
          break;
        }
        case 403: {
          this.setState({
            cardContentTxt: 'Pedido não autorizado, você não tem permissão para acessar.'
          });
          this.setState({ loadingConfirmation: false });
          this.setState({ cardError: true });
          break;
        }
        case 403: {
          this.setState({
            cardContentTxt: 'Você não tem permissão para acessar.'
          });
          this.setState({ loadingConfirmation: false });
          this.setState({ cardError: true });
          break;
        }
        case 404: {
          this.setState({
            cardContentTxt: 'Sua solicitação tem informações ou estrutura inválidas.'
          });
          this.setState({ loadingConfirmation: false });
          this.setState({ cardError: true });
          break;
        }
        default: {
          this.setState({
            cardContentTxt:
              'Há um problema com o recurso que você está procurando e não pode ser exibido no momento, tente novamente mais tarde.'
          });
          this.setState({ loadingConfirmation: false });
          this.setState({ cardError: true });
          break;
        }
      }
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap>
              Grupo Acert
            </Typography>
          </Toolbar>
        </AppBar>
        <main>
          <div className={classes.heroUnit}>
            <div className={classes.heroContent}>
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                Seja Bem Vindo
              </Typography>
              <Typography variant="h6" align="center" color="textSecondary" paragraph>
                Aguarde alguns minutos enquanto validamos a sua conta.
              </Typography>
              <div className={classes.circularProgress}>
                {loadingConfirmation(this.state.loadingConfirmation)}
              </div>
              <div className={classes.circularProgress}>
                {cardError(this.state.cardError, classes, this.state.cardContentTxt)}
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

function loadingConfirmation(loadingConfirmation) {
  if (loadingConfirmation) {
    return <CircularProgress color="primary" />;
  }
}

function cardError(cardError, classes, cardContentTxt) {
  if (cardError) {
    return (
      <Card>
        <CardActionArea className={classes.card}>
          <CardContent>
            <Typography className={classes.cardText} component="p">
              {cardContentTxt}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

export default withStyles(styles)(UserConfirmation);
