import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { SelectInput } from 'components/form/Input';
import { Trans } from '@lingui/macro';
import FormControl from 'components/form/wrappers/form-control';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { required } from 'components/form/fields/validation';
import Grid from '@material-ui/core/Grid';

class LensLeft extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.title} variant="title" gutterBottom>
          <Trans>Olho Esquerdo</Trans>
        </Typography>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.lensFormControl}>
              <SelectInput
                name="surfacing_left"
                onChange={this.changeLens}
                validate={[required]}
                label={<Trans>Tipo de lente</Trans>}
              >
                <MenuItem value="" disabled />
                {surfacing_left.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </SelectInput>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.lensFormControl}>
              <SelectInput
                name="diopter_left"
                validate={[required]}
                label={<Trans>Dioptria</Trans>}
              >
                <MenuItem value="" disabled />
                {diopter_left.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </SelectInput>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    );
  }

  changeLens = event => {
    if (event.target.value !== undefined) {
      setTimeout(() => {
        this.props.onChangeCallback();
      }, 30);
    }
  };
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  title: {
    color: '#757575',
    fontSize: '1em',
    marginBottom: theme.spacing.unit * 2
  },
  lensFormControl: {
    minWidth: '-webkit-fill-available',
    [theme.breakpoints.up('md')]: {
      minWidth: 200
    }
  }
});

const surfacing_left = [
  { id: 'FINISHED_PROGRESSIVE_LENS', name: <Trans>Multifocal Acabado</Trans> },
  { id: 'FINISHED_BIFOCAL', name: <Trans>Bifocal Acabado</Trans> },
  { id: 'FINISHED_SINGLE_VISION', name: <Trans>Lente Simples</Trans> },
  { id: 'SEMI_FINISHED_PROGRESSIVE_LENS', name: <Trans>Bloco Multifocal</Trans> },
  { id: 'SEMI_FINISHED_BIFOCAL', name: <Trans>Bloco bifocal</Trans> },
  { id: 'SEMI_FINISHED_SINGLE_VISION', name: <Trans>Bloco Visão Simples</Trans> },
  { id: 'SEMI_FINISHED_INTERMEDIATE_VISION', name: <Trans>Visão intermediaria</Trans> },
  { id: 'FREE_FORM', name: <Trans>Free-Form</Trans> }
];

const diopter_left = [
  { id: 'FAR', name: <Trans>Longe</Trans> },
  { id: 'NEAR', name: <Trans>Perto</Trans> }
];

export default withStyles(styles)(LensLeft);
