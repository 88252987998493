import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import MuiDialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import { compose } from 'redux';
import { defaultThemeBase } from 'theme/defaultTheme';
import grey from '@material-ui/core/colors/grey';

class Dialog extends Component {
  render() {
    const { classes, children, closeDialog, dialogs, open, name, title, ...props } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <MuiDialog
          TransitionComponent={this.isFullscreen && Transition}
          fullScreen={this.isFullscreen}
          onClose={this.close}
          open={open}
          {...props}
          ariaLabelledBy="title"
        >
          <Toolbar className={classes.header}>
            <IconButton color="inherit" onClick={this.close} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <DialogTitle id="title">
              <div className={classes.title}>{title}</div>
            </DialogTitle>
          </Toolbar>

          {children}
        </MuiDialog>
      </MuiThemeProvider>
    );
  }

  close = event => {
    const { closeDialog, name, closeCallback, onClose } = this.props;
    if (closeDialog !== undefined) {
      closeDialog(name)();
      closeCallback && closeCallback();
    } else {
      onClose();
    }
  };

  get isFullscreen() {
    const { fullScreen, width } = this.props;
    const isScreenUnderMediumSize = !isWidthUp('md', width);

    return fullScreen || isScreenUnderMediumSize ? true : undefined;
  }
}

const styles = theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  title: {
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      display: 'table-caption'
    }
  }
});

const theme = createMuiTheme({
  ...defaultThemeBase,
  overrides: {
    ...defaultThemeBase.overrides,
    MuiDialogContent: {
      root: {
        backgroundColor: grey[300],
        paddingTop: 24,
        position: 'relative'
      }
    },
    MuiDialogActions: {
      root: {
        backgroundColor: grey[300],
        padding: 24,
        paddingTop: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
      }
    }
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default compose(withStyles(styles), withWidth())(Dialog);
