import { Trans } from '@lingui/macro';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { connect } from 'react-redux';
import { WAIT_FOR_ACTION } from 'redux-wait-for-action';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'components/card';
import Loading from 'components/utils/Loading';
import View from 'components/view/';
import ProfileImage from 'forms/user-profile/profile-image';
import ProfileImageAvatar from 'forms/user-profile/profile-image-avatar';
import UserProfileForm from 'forms/user-profile/UserProfile';
import CardContent from '@material-ui/core/CardContent';
import Button from 'components/buttons/button';
import FormControl from 'components/form/wrappers/form-control';
import { Creators as UserCreators } from 'store/ducks/user';
import { Creators } from 'store/ducks/orders';
import AccessTokenDialog from './AccessTokenDialog';
import ChangePasswordDialog from './ChangePasswordDialog';

class UserProfile extends Component {
  render() {
    const {
      classes,
      user: { imageError, profilePicture, imageUrl, userId },
      setOrders,
      changeImageStatus
    } = this.props;

    const { isAccessTokenDialogOpen, isChangePasswordOpen, loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <View title={<Trans>Meu Perfil</Trans>} size="sm">
        <Card>
          <CardContent>
            <Grid container spacing={24}>
              <Grid alignItems="center" container item md={3} xs={12}>
                <Grid container item justify="center">
                  <ProfileImageAvatar />
                  <Avatar
                    className={classes.avatar}
                    onError={() => {
                      const imageError = true;
                      return changeImageStatus(imageError);
                    }}
                    src={imageError ? undefined : imageUrl}
                  >
                    <AccountCircle className={classes.avatarIcon} />
                  </Avatar>
                  <ProfileImage />
                  <FormControl fullWidth>
                    <Button variant="text" onClick={this.openAccessTokenDialog}>
                      <Trans>Chave de acesso</Trans>
                    </Button>
                  </FormControl>
                  <FormControl fullWidth>
                    <Button color="secondary" onClick={this.openChangePassword} variant="text">
                      <Trans>Mudar senha</Trans>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12} md={9}>
                <Typography component="h2" variant="h6" color="textSecondary" gutterBottom>
                  <Trans>Informações</Trans>
                </Typography>
                <UserProfileForm userData={this.props.user} setOrders={setOrders} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <ChangePasswordDialog
          onClose={this.closeChangePassword}
          open={isChangePasswordOpen}
          userID={this.props.user}
        />
        <AccessTokenDialog
          onClose={this.closeAccessTokenDialog}
          open={isAccessTokenDialogOpen}
          userEmail={this.props.user && this.props.user.email}
        />
      </View>
    );
  }

  state = {
    isAccessTokenDialogOpen: false,
    isChangePasswordOpen: false,
    loading: false
  };

  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  componentWillMount() {
    const {
      getUser,
      user: { userId }
    } = this.props;
    this.setState({ loading: true });
    getUser(userId).then(() => this.setState({ loading: false }));
  }

  // TO DO: refatorar para uma unica função lidar com todos os dialogs..
  closeAccessTokenDialog = event => {
    this.setState({ isAccessTokenDialogOpen: false });
  };

  openAccessTokenDialog = () => {
    this.setState({ isAccessTokenDialogOpen: true });
  };

  closeChangePassword = event => {
    this.setState({ isChangePasswordOpen: false });
  };

  openChangePassword = () => {
    this.setState({ isChangePasswordOpen: true });
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUser(userId) {
      const action = {
        ...UserCreators.getUser({ userId }),
        [WAIT_FOR_ACTION]: 'RECEIVE_USER'
      };

      return dispatch(action);
    },
    setOrders: ordersList => dispatch(Creators.receiveOrders(ordersList))
  };
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
