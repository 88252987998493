export default function styles() {
  return {
    baseStyle: {
      height: 45,
      width: '100%',
      maxWidth: '400px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: '#C6C6C6'
    },
    activeStyle: {
      borderColor: "#0381B8",
    }
  };
}
