import React from 'react';
import MuiCardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';

function CardContent({ children, ...props }) {
  return <MuiCardContent>{children}</MuiCardContent>;
}

CardContent.prototypes = {
  children: PropTypes.node
};

export default CardContent;
