import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import Frame from './Frame';
import Items from './Items';
import Notes from './Notes';
import PrescriptionLeft from './PrescriptionLeft';
import PrescriptionRight from './PrescriptionRight';

class Review extends Component {
  render() {
    const { classes, eyesPrescription, frame, notes, items } = this.props;
    const filteredLeft = eyesPrescription.filter(({ side }) => side === 'LEFT');
    const filteredRight = eyesPrescription.filter(({ side }) => side === 'RIGHT');
    return (
      <>
        <div className={classes.root}>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <PrescriptionRight eyesPrescription={filteredRight} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PrescriptionLeft eyesPrescription={filteredLeft} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ marginBottom: '25px' }}>
                <Frame frame={frame} />
              </div>
              <div style={{ marginBottom: '25px' }}>
                <Notes notes={notes} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Items items={items} />
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

export default withStyles(styles)(Review);
