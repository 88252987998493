import React from 'react';
import { Field } from 'redux-form';
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import Autocomplete from './inputs/autocomplete';
import BaseInput from './inputs/base-input';
import CheckboxInputBase from './inputs/checkbox';
import FileInput from './inputs/file';
import TimePicker from './fields/TimePicker';
import { required, validateCnpj, validateEmail } from './validation';

export const AutocompleteInput = ({
  clearCallback,
  isCreatable,
  label,
  options,
  selectionCallback,
  validate,
  ...restArgs
}) => (
  <Field
    clearCallback={clearCallback}
    component={Autocomplete}
    isCreatable={isCreatable}
    label={label}
    options={options}
    selectionCallback={selectionCallback}
    validate={validate}
    {...restArgs}
  />
);

export const TextInput = props => <Field {...props} component={BaseInput} type="text" />;

export const EmailInput = props => {
  const validate = props.validate ? [...props.validate, validateEmail] : [validateEmail];

  return <Field {...props} component={BaseInput} validate={validate} type="email" />;
};

export const CnpjInput = ({ warn, name, label, ...props }) => {
  const cnpjMask = createTextMask({
    pattern: '99.999.999/9999-99'
  });
  return (
    <Field
      component={BaseInput}
      label={label || 'CNPJ'}
      name={name || 'cnpj'}
      validate={[required, validateCnpj]}
      warn={warn}
      {...cnpjMask}
      {...props}
      type="text"
    />
  );
};

export const ZipCodeInput = ({ name, label, ...props }) => {
  const zipCodeMask = createTextMask({
    pattern: '99999-999'
  });
  return (
    <Field
      component={BaseInput}
      label={label || 'CEP'}
      name={name || 'cep'}
      type="text"
      validate={[required]}
      {...zipCodeMask}
      {...props}
    />
  );
};

export const PhoneNumberInput = (...props) => {
  const phoneMask = inputValue => {
    const withoutDigts = inputValue.replace(/\D/g, '');
    const withDDD = withoutDigts.replace(/^(\d{2})(\d)/g, '($1) $2');
    const masked = withDDD.replace(/(\d)(\d{4})$/, '$1-$2');
    return masked;
  };
  return <Field {...props} normalize={phoneMask} component={BaseInput} type="text" />;
};

export const PasswordInput = props => <Field {...props} component={BaseInput} type="password" />;

export const SelectInput = ({ children, onChangeCallback, ...props }) => (
  <Field {...props} select component={BaseInput} onChangeCallback={onChangeCallback} type="select">
    {children}
  </Field>
);

export const NumberInput = ({ step = '0.25', ...props }) => (
  <Field {...props} step={step} component={BaseInput} type="number" />
);

export const DecimalInput = ({ onFocus, ...props }) => {
  const currencyMask = createNumberMask({
    decimalPlaces: 2,
    spaceAfterSign: true,
    allowNegative: true
  });
  return <Field {...props} {...currencyMask} component={BaseInput} onFocus={onFocus} type="tel" />;
};

export const CheckboxInput = props => (
  <Field {...props} component={CheckboxInputBase} type="checkbox" />
);

export const FileUploadCustom = ({ name, label, onChange, validate, ...props }) => (
  <Field
    {...props}
    component={FileInput}
    complete
    onChange={onChange}
    label={label}
    validate={validate}
    name={name}
    accept="*"
  />
);

export const ImageInput = ({ name, label, onChange }) => (
  <Field component={FileInput} onChange={onChange} label={label} name={name} accept="image/*" />
);

export const TimePickerInput = ({ name, grid }) => (
  <Field component={TimePicker} name={name} grid={grid} />
);

export const HiddenInput = ({ name }) => (
  <Field component={({ name }) => <input name={name} type="hidden" />} name={name} />
);
