import React from 'react';
import { Trans } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import SelectField from 'components/form/fields/select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

function GroupStore({ stores, storeId }) {
  return (
    <>
      <Grid item xs={12} sm={12}>
        <SelectField fullWidth label={<Trans>Loja referência</Trans>} name="billingGroupStore">
          {stores
            .filter(store => store.store.id !== parseInt(storeId))
            .map(store => (
              <MenuItem key={store.store.id} value={store.store.id}>
                {store.store.tradingName}
              </MenuItem>
            ))}
          <Divider />
          <MenuItem value="null">
            <Trans>Não vincular a nenhuma loja</Trans>
          </MenuItem>
        </SelectField>
      </Grid>
    </>
  );
}

export default GroupStore;
