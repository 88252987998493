import { Trans } from '@lingui/macro';
import { IconButton, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IntegrationIcon from '@material-ui/icons/SwapHoriz';
import Delete from '@material-ui/icons/Delete';
import CustomTableCell from 'components/table/CustomTableCell';
import Table from 'components/table/Table';
import React, { useEffect, useState } from 'react';
import { deleteStoreResources, getUserStores } from 'services/stores';
import Dialog from 'components/dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from 'components/buttons/button';
// adasdsd
const StoreResourcesList = ({
  user,
  getResources,
  storeResources,
  storeId,
  classes,
  integrationCallback
}) => {
  const [userPermission, setUserPermission] = useState();
  const [userStores, setUserStores] = useState();
  const [itemInfo, setItemInfo] = useState({
    storeId: undefined,
    type: undefined,
    sku: undefined
  });
  const [open, setOpen] = useState(false);

  const cols = [
    { id: 'translatedType', label: <Trans>Tipo</Trans> },
    { id: 'sku', label: <Trans>SKU</Trans> },
    { id: 'description', label: <Trans>Descrição</Trans> },
    { id: 'Ações', label: <Trans>Ações</Trans> }
  ];
  const adminCols = [...cols, { id: 'remove', label: <Trans>Remover</Trans> }];
  const getStores = async () => {
    try {
      const stores = await getUserStores(user.email);
      setUserStores(stores);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userStores) {
      const isAllowed =
        userStores.find(item => item.store.id === Number(storeId)).role === 'ADMINISTRATOR' ||
        userStores.find(item => item.store.id === Number(storeId)).role === 'ACERT';
      if (isAllowed) {
        setUserPermission(isAllowed);
      } else {
        setUserPermission(false);
      }
    }
  }, [storeId, user, userStores]);

  const removeResource = async () => {
    try {
      await deleteStoreResources(itemInfo.storeId, itemInfo.type, itemInfo.sku);
    } catch (error) {
      console.error(error);
    }
    getResources();
  };
  const handleClose = () => {
    setOpen(false);
  };
  return storeResources.length ? (
    <>
      <Paper className={classes.responsiveTable}>
        <Table cols={userPermission ? adminCols : cols} data={storeResources} paginated>
          {({ translatedType, sku, description, type }) => (
            <>
              <TableRow key={sku}>
                <CustomTableCell>
                  <Typography variant="body1">{translatedType}</Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography variant="body1">{sku}</Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography variant="body1">{description}</Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Tooltip title={<Trans>Integração composta</Trans>}>
                    <IconButton
                      onClick={() => {
                        integrationCallback({ type, sku, description });
                      }}
                    >
                      <IntegrationIcon />
                    </IconButton>
                  </Tooltip>
                </CustomTableCell>
                {userPermission ? (
                  <CustomTableCell>
                    <Tooltip title={<Trans>Deletar</Trans>}>
                      <IconButton
                        onClick={() => {
                          setItemInfo({
                            storeId,
                            type,
                            sku
                          });
                          setOpen(true);
                        }}
                      >
                        <Delete style={{ color: 'red' }} />
                      </IconButton>
                    </Tooltip>
                  </CustomTableCell>
                ) : (
                  <></>
                )}
              </TableRow>
            </>
          )}
        </Table>
      </Paper>
      <Dialog open={open} title="Excluir Recurso" onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            Atenção! O recurso será excluido! Você realmente confirma esta operação?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Trans>Não</Trans>
          </Button>
          <Button
            onClick={() => {
              removeResource();
            }}
            color="primary"
          >
            <Trans>Sim</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <Typography variant="h5">
      <Trans>Não há recursos para esta loja.</Trans>
    </Typography>
  );
};

const styles = theme => ({
  responsiveTable: {
    overflowX: `scroll`,
    overflowY: `hidden `,
    [theme.breakpoints.up('md')]: {
      overflowX: `auto`,
      overflowY: `hidden `
    }
  }
});

export default withStyles(styles)(StoreResourcesList);
