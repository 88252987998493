import React from 'react';
import { Trans } from '@lingui/macro';

const regex = pattern => new RegExp(pattern);
const validate = (pattern, value) => regex(pattern).test(value);

const regexes = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  cnpj: /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/,
  cpf: /^(\d{3}\.?\d{3}\.?\d{3}-?\d{2})$/
};

export const testEmail = value => !validate(regexes.email, value);
export const testCnpj = value => !validate(regexes.cnpj, value) || !isValidCnpj(value);
export const testCpf = value => !validate(regexes.cpf, value) || !isValidCpf(value);

export const validateEmail = value =>
  value && testEmail(value) ? <Trans>Endereço de email inválido</Trans> : undefined;

export const validateCnpj = value =>
  value && testCnpj(value) ? <Trans>CNPJ inválido</Trans> : undefined;

export const validateCpf = value =>
  value && testCpf(value) ? <Trans>CPF inválido</Trans> : undefined;

export const required = value =>
  (value && value.toString().trim()) || typeof value === 'number' ? (
    undefined
  ) : (
    <Trans>Campo obrigatório</Trans>
  );

export const isEqualTo = text => value =>
  value && value.trim() !== text ? `Digite "${text}"` : undefined;

export const passwordMatch = text => value =>
  value && value.trim() !== text ? <Trans>Senhas não coincidem</Trans> : undefined;

export const maxLength = max => value =>
  value && value.trim().length > max
    ? `Este campo deve possuir no máximo ${max} caracteres`
    : undefined;

export const maxLengthAutocomplete = max => value =>
  value && value.value !== null && value.value.trim().length > max
    ? `Este campo deve possuir no máximo ${max} caracteres`
    : undefined;

export const minLength = min => value =>
  value && value.trim().length < min
    ? `Este campo deve possuir no mínimo ${min} caracteres`
    : undefined;

const isValidCnpj = value => {
  let cnpj = value;
  const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  if ((cnpj = cnpj.replace(/[^\d]/g, '')).length !== 14) return false;

  if (/0{14}/.test(cnpj)) return false;

  for (var i = 0, n = 0; i < 12; n += cnpj[i] * b[++i]);
  if (cnpj[12] !== ((n %= 11) < 2 ? 0 : 11 - n).toString()) return false;

  for (i = 0, n = 0; i <= 12; n += cnpj[i] * b[i++]);
  if (cnpj[13] !== ((n %= 11) < 2 ? 0 : 11 - n).toString()) return false;

  return true;
};

const isValidCpf = value => {
  const cpf = value.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;

  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;

  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
};

export const requiredAutocomplete = options => inputValue => {
  const idList = options.map(({ id }) => id);
  const isInputValue = inputValue === undefined || inputValue === null;

  return idList.includes(inputValue) || isInputValue ? (
    <Trans>Selecione uma opção válida</Trans>
  ) : (
    undefined
  );
};

export const maxFileSize = fileSize => file =>
  file.size && file.size < fileSize ? undefined : <Trans>O arquivo deve ter no máximo 6Mb.</Trans>;

export const phoneNumberMinLength = value =>
  value && value.trim().length < 14 ? (
    <Trans>Insira um número de telefone válido.</Trans>
  ) : (
    undefined
  );

export const phoneNumberMaxLength = value =>
  value && value.trim().length > 16 ? (
    <Trans>Insira um número de telefone válido.</Trans>
  ) : (
    undefined
  );

export const customValidation = (message, condition) => value =>
  value === condition ? message : undefined;
