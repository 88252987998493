import React from 'react';
import { Trans } from '@lingui/macro';
import FRAME_SHAPE_01 from 'assets/frameDesign/FRAME_SHAPE_01.jpg';
import FRAME_SHAPE_02 from 'assets/frameDesign/FRAME_SHAPE_02.jpg';
import FRAME_SHAPE_03 from 'assets/frameDesign/FRAME_SHAPE_03.jpg';
import FRAME_SHAPE_04 from 'assets/frameDesign/FRAME_SHAPE_04.jpg';
import FRAME_SHAPE_05 from 'assets/frameDesign/FRAME_SHAPE_05.jpg';
import FRAME_SHAPE_06 from 'assets/frameDesign/FRAME_SHAPE_06.jpg';
import FRAME_SHAPE_07 from 'assets/frameDesign/FRAME_SHAPE_07.jpg';
import FRAME_SHAPE_08 from 'assets/frameDesign/FRAME_SHAPE_08.jpg';

export const mockframeShape = [
  {
    value: 'FRAME_SHAPE_01',
    image: FRAME_SHAPE_01,
    text: <Trans>MODELO 01</Trans>
  },
  {
    value: 'FRAME_SHAPE_02',
    image: FRAME_SHAPE_02,
    text: <Trans>MODELO 02</Trans>
  },
  {
    value: 'FRAME_SHAPE_03',
    image: FRAME_SHAPE_03,
    text: <Trans>MODELO 03</Trans>
  },
  {
    value: 'FRAME_SHAPE_04',
    image: FRAME_SHAPE_04,
    text: <Trans>MODELO 04</Trans>
  },
  {
    value: 'FRAME_SHAPE_05',
    image: FRAME_SHAPE_05,
    text: <Trans>MODELO 05</Trans>
  },
  {
    value: 'FRAME_SHAPE_06',
    image: FRAME_SHAPE_06,
    text: <Trans>MODELO 06</Trans>
  },
  {
    value: 'FRAME_SHAPE_07',
    image: FRAME_SHAPE_07,
    text: <Trans>MODELO 07</Trans>
  },
  {
    value: 'FRAME_SHAPE_08',
    image: FRAME_SHAPE_08,
    text: <Trans>MODELO 08</Trans>
  }
];
