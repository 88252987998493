import React from 'react';
import { Field } from 'redux-form-normalize-on-blur';
import BaseInput from 'components/form/inputs/base-input';
import { decimalFormat } from 'utils/formatters/formatters';
import { mustBeBetween } from 'components/form/validation';

export const VboxField = ({ ...props }) => (
  <Field
    {...props}
    component={BaseInput}
    step="0.01"
    type="number"
    normalizeOnBlur={value => decimalFormat(value)}
    validate={[mustBe]}
  />
);
const mustBe = mustBeBetween(10, 80, true);

export default VboxField;
