import React, { Component } from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';

class Notification extends Component {
  render() {
    const { classes, message, variant, remove } = this.props;

    return (
      <SnackbarContent
        className={classes[variant]}
        message={
          <div className={classes.message}>
            <span className={classes.icon}>{variantIcon[variant]}</span>
            {message}
          </div>
        }
        action={[
          <IconButton aria-label="Close" color="inherit" key="close" onClick={remove}>
            <CloseIcon />
          </IconButton>
        ]}
      />
    );
  }

  componentWillMount() {
    !this.props.persist && setTimeout(this.props.remove, this.props.timeout || 3500);
  }
}

const variantIcon = {
  success: <CheckCircleIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
  info: <InfoIcon />
};

Notification.prototypes = {
  classes: PropTypes.func,
  message: PropTypes.node,
  variant: PropTypes.string,
  remove: PropTypes.func,
  id: PropTypes.string
};

const styles = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  iconVariant: {
    opacity: 0.9
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    opacity: '.75',
    display: 'flex',
    marginRight: theme.spacing.unit
  }
});

export default withStyles(styles)(Notification);
