import React from 'react';
import { Trans } from '@lingui/macro';

function FrameStatusTranslator(frameStatus) {
  const received = <Trans>Armação recebida</Trans>;
  const waiting = <Trans>Aguardando armação</Trans>;

  if (frameStatus === 'RECEIVED') return received;
  return waiting;
}

export default FrameStatusTranslator;
