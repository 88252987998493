import React from 'react';
import { Trans } from '@lingui/macro';

export default [
  {
    value: 'SEMI_FINISHED_PROGRESSIVE_LENS',
    text: <Trans>Bloco multifocal</Trans>
  },
  {
    value: 'FINISHED_PROGRESSIVE_LENS',
    text: <Trans>Multifocal acabado</Trans>
  },
  { value: 'SEMI_FINISHED_BIFOCAL', text: <Trans>Bloco bifocal</Trans> },
  { value: 'FINISHED_BIFOCAL', text: <Trans>Bifocal acabado</Trans> },
  {
    value: 'SEMI_FINISHED_SINGLE_VISION',
    text: <Trans>Bloco visão simples</Trans>
  },
  { value: 'FINISHED_SINGLE_VISION', text: <Trans>Visão simples</Trans> },
  {
    value: 'SEMI_FINISHED_INTERMEDIATE_VISION',
    text: <Trans>Visão intermediária</Trans>
  },
  { value: 'FREE_FORM', text: <Trans>free-form</Trans> }
];
