import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { Creators as authCreators } from 'store/ducks/auth';
import withOverlay from 'store/providers/withOverlay';
import { isUserSigned } from 'store/selectors/auth';
import Access from './Access';

function mapStateToProps(state) {
  return { isUserSigned: isUserSigned(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    login(user, password) {
      dispatch(authCreators.getAuth({ user, password }));
    }
  };
}

export default compose(
  withOverlay,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({ form: 'Access' })
)(Access);
