import React from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import FindInPageIcon from '@material-ui/icons/FindInPageOutlined';
import grey from '@material-ui/core/colors/grey';
import { Trans } from '@lingui/macro';
import tracking from 'assets/images/tracking.png';

export const SupplierStatus = props => (
  <MuiThemeProvider theme={mui}>
    <Tooltip title={<Trans>Status do pedido no fornecedor.</Trans>}>
      <Avatar {...props} style={{ backgroundColor: 'transparent', color: grey[900] }}>
        {/* <FindInPageIcon /> */}
        <img style={{ maxWidth: '100%' }} src={tracking} alt="tracking" />
      </Avatar>
    </Tooltip>
  </MuiThemeProvider>
);

const mui = createMuiTheme({
  overrides: {
    MuiAvatar: {
      root: {
        maxWidth: 25,
        margin: '0!important',
        borderWidth: '!important',
        maxHeight: 25
      }
    }
  }
});
