import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

class MobileDrawer extends Component {
  render() {
    const { classes, open, close, routes, children } = this.props;
    return (
      <SwipeableDrawer anchor="left" open={open} onClose={close}>
        {routes.map(({ icon: Icon, ...route }, index) => (
          <Link
            key={index}
            onClick={close}
            to={`/${route.path.split('/')[1]}`}
            className={classes.link}
          >
            <MenuItem>
              {Icon && <Icon color="primary" />}
              <Typography className={classes.text} variant="body1">
                {route.title}
              </Typography>
            </MenuItem>
          </Link>
        ))}

        {children}
      </SwipeableDrawer>
    );
  }
}

const styles = theme => ({
  link: {
    textDecoration: 'none'
  },
  text: {
    marginLeft: theme.spacing.unit
  }
});

MobileDrawer.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.func,
  close: PropTypes.func,
  routes: PropTypes.array
};

export default withStyles(styles)(MobileDrawer);
