import { Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Creators as SuppliersCreators } from 'store/ducks/suppliers';
import Drawer from 'components/drawer';
import OrderFilters from 'forms/orders/OrderFilters';
import Tooltip from '@material-ui/core/Tooltip';
import withQuery from 'utils/query/withQuery';

function Filters({ ...props }) {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { query, stores, classes } = props;
  const storeIdDefault = localStorage.getItem('storeIdDefault');

  useEffect(() => {
    if (!query.params.sort) query.set({ property: 'sort', value: 'dueDate' });
    if (!query.params.store && storeIdDefault && storeIdDefault !== 'null') {
      query.set({ property: 'store', value: storeIdDefault });
    } else if (
      !query.params.store &&
      stores.length > 0 &&
      (!storeIdDefault || storeIdDefault === 'null')
    ) {
      query.set({ property: 'store', value: stores[0].store.id });
    }
  }, [stores, query, storeIdDefault]);

  return (
    <>
      <Drawer open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
        <Typography variant="h6" color="textSecondary">
          <Trans>Filtros</Trans>
        </Typography>

        <OrderFilters {...props} />
      </Drawer>
      <Grid container alignItems="center" spacing={24} className={classes.filterList}>
        <Grid item>
          <Tooltip title={<Trans>Filtrar pedidos</Trans>}>
            <IconButton color="primary" onClick={() => setDrawerOpen(true)}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        {props.stores.length > 0 && query.params.store && (
          <Grid item>
            <Chip
              color="primary"
              label={
                props.stores.find(userStore => userStore.store.id === +query.params.store).store
                  .tradingName
              }
            />
          </Grid>
        )}

        {query.params.sort && (
          <Grid item>
            <Chip color="primary" label={`Ordenado por ${sortTranslator(query.params.sort)}`} />
          </Grid>
        )}

        {props.suppliersList.length > 0 && query.params.supplier && (
          <Grid item>
            <Chip
              color="primary"
              label={`Enviados ao fornecedor: ${
                props.suppliersList.find(suppliers => suppliers.id === +query.params.supplier)
                  .companyName
              }`}
            />
          </Grid>
        )}

        {query.params.late === 'true' && (
          <Grid item>
            <Chip color="primary" label={<Trans>Somente pedidos atrasados</Trans>} />
          </Grid>
        )}
      </Grid>
    </>
  );
}

function sortTranslator(value) {
  switch (value) {
    case 'codeAsc': {
      return 'Número do pedido (ascendente)';
    }
    case 'codeDesc': {
      return 'Número do pedido (descendente)';
    }
    case 'dueDate': {
      return 'data de entrega';
    }
    case 'storeName': {
      return 'nome da loja';
    }
    default:
      break;
  }
}

const styles = theme => ({
  filterList: {
    marginTop: -theme.spacing.unit * 2,
    [theme.breakpoints.up('md')]: {
      marginTop: -theme.spacing.unit * 4
    }
  }
});

function mapStateToProps(state) {
  return {
    suppliersList: state.suppliers,
    stores: state.stores
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchList: _ => dispatch(SuppliersCreators.getSuppliers())
  };
}

export default compose(
  withQuery,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Filters);
