import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';

function MultiValue({ children, selectProps, isFocused, removeProps, ...props }) {
  return (
    // <Chip
    // tabIndex={-1}
    // label={children}
    // className={classnames(classes.chip, {
    //   [classes.chipFocused]: isFocused
    // })}
    // onDelete={removeProps.onClick}
    // deleteIcon={
    // {...props}
    // />
    // }
    <CancelIcon {...removeProps} />
  );
}

export default MultiValue;
