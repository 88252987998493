import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';
import withNotification from 'store/providers/withNotification';
import withOverlay from 'store/providers/withOverlay';
import AddEditStore from './AddEditStore';

const selector = formValueSelector('updateStore');

function formatZipcode(zipcode) {
  if (zipcode) return zipcode.trim().replace(/\D/g, '');
  return zipcode;
}

function phoneMask(inputValue) {
  const withoutDigits = inputValue.replace(/\D/g, '');
  const dddMask = withoutDigits.replace(/^(\d\d)(\d)/g, '($1) $2');
  const numberMask = dddMask.replace(/(\d{4,5})(\d{4})(\d)?/, '$1-$2');

  return numberMask;
}

function mapStateToProps(state, props) {
  const { storeData } = props;

  const formValues = {
    typeStoreValue: selector(state, 'typeStoreValue')
  };

  let initialValues = {
    plan: {
      planVersion: 'START'
    },
    typeStoreValue: {
      type: 'NO_BACKOFFICE'
    }
  };

  if (storeData) {
    const planValue = storeData.productPlans.find(plan => plan.productVersion === 'ORDER_TRACKING');
    const salesPlanValue = storeData.productPlans.filter(
      plan => plan.productVersion !== 'ORDER_TRACKING'
    );

    initialValues = {
      billingAddress: {
        address: storeData.address,
        addressDetails: storeData.addressDetails,
        city: storeData.city,
        neighborhood: storeData.neighborhood,
        number: storeData.number,
        state: storeData.state,
        zipCode: formatZipcode(storeData.zipCode)
      },
      information: {
        cnpj: storeData.cnpj,
        email: storeData.email,
        phoneNumber: storeData.phoneNumber && phoneMask(storeData.phoneNumber),
        socialName: storeData.socialName,
        tradingName: storeData.tradingName
      },
      resources: { resourceStore: storeData.resourceStore },
      groupStore: { billingGroupStore: storeData.billingGroupStore },
      enableControlNumber: storeData.enableControlNumber,
      plan: planValue
        ? {
            planVersion: planValue.planVersion
          }
        : {
            planVersion: 'START'
          },
      salesPlan: salesPlanValue,
      typeStoreValue: storeData.type
        ? {
            type: storeData.type
          }
        : {
            type: 'NO_BACKOFFICE'
          },
      erpSoftwareProviderName: {
        erpSoftware: {
          label: storeData.erpSoftwareProviderName,
          id: storeData.erpSoftwareProviderName,
          value: storeData.erpSoftwareProviderName
        }
      },
      configuration: {
        ...storeData.storeDetail
      }
    };
  }

  return {
    initialValues,
    formValues
  };
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'updateStore'
  }),
  withNotification,
  withOverlay,
  withRouter
)(AddEditStore);
