import { Creators as NotificationCreators, createNotification } from 'store/ducks/notifications';
import React, { Component } from 'react';
import { isEqualTo, required } from 'components/form/fields/validation';
import Button from 'components/buttons/button';
import FormControl from 'components/form/wrappers/form-control';
import { Creators as OverlayCreators } from 'store/ducks/overlay';
import { TextInput } from 'components/form/Input';
import { Trans } from '@lingui/macro';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { removeAttachOrder } from 'services/orders';

class RemoveAttachFromOrder extends Component {
  render() {
    const { handleSubmit, valid } = this.props;

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <FormControl fullWidth margin="normal">
          <TextInput
            autoFocus
            label={<Trans>Digite aqui*</Trans>}
            name="confirm"
            validate={[required, isEqualToSIM]}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Button color="primary" disabled={!valid} type="submit" variant="contained">
            <Trans>Confirmar</Trans>
          </Button>
        </FormControl>
      </form>
    );
  }

  submit = async values => {
    const {
      overlay,
      submitCallback,
      order: { orderId, storeId },
      attachedFile: { id: attachedFileId }
    } = this.props;
    overlay.show(<Trans>Aguarde, processando...</Trans>);
    const response = await this.removeAttach(orderId, storeId, attachedFileId);
    this.notifyByResponseStatus(response.status);
    overlay.hide();

    submitCallback && submitCallback();
  };

  removeAttach = (orderId, storeId, attachId) => {
    return removeAttachOrder(orderId, storeId, attachId);
  };

  notifyByResponseStatus = status => {
    const { addNotification } = this.props;

    switch (status) {
      case 200:
      case 204:
        addNotification({
          variant: 'success',
          message: <Trans>Anexo removido com sucesso!</Trans>
        });
        break;
      case 403:
        addNotification({
          variant: 'warning',
          message: <Trans>Você não tem permissão para executar essa ação</Trans>
        });
        break;
      default:
        addNotification({
          variant: 'error',
          message: (
            <Trans>
              Não foi possível estabelecer conexão com o servidor. Tente novamente mais tarde!
            </Trans>
          )
        });
        break;
    }
  };
}

const isEqualToSIM = isEqualTo('SIM');

const mapDispatchToProps = dispatch => ({
  addNotification: ({ message, variant }) =>
    dispatch(NotificationCreators.addNotification(createNotification({ message, variant }))),
  overlay: {
    show: loadingText => dispatch(OverlayCreators.showOverlay({ loadingText })),
    hide: _ => dispatch(OverlayCreators.hideOverlay())
  }
});

export default compose(
  reduxForm({ form: 'removeAttch' }),
  connect(null, mapDispatchToProps)
)(RemoveAttachFromOrder);
