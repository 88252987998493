import { connect } from 'react-redux';
import { Creators } from 'store/ducks/notifications';

function withNotification(WrappedComponent) {
  function mapDispatchToProps(dispatch) {
    return {
      notify({ message, variant }) {
        dispatch(Creators.addNotification({ message, variant }));
      }
    };
  }

  return connect(
    null,
    mapDispatchToProps
  )(WrappedComponent);
}

export default withNotification;
