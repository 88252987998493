import { Trans } from '@lingui/macro';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Fab from 'components/fab';
import CustomTableCell from 'components/table/TableCell';
import Loading from 'components/utils/Loading';
import View from 'components/view';
import Tooltip from '@material-ui/core/Tooltip';
import Table from 'components/table/Table';
import { getHistoricalLane, getHistoricalLaneCSV } from 'services/historical';
import { getUserStores } from 'services/stores';
import withNotification from 'store/providers/withNotification';
import { CSVLink } from 'react-csv';

const dateFormat = require('dateformat');

export class HistoricLaneTable extends Component {
  render() {
    const { dataStore, historicalData, laneCSV, loading } = this.state;
    const { classes } = this.props;
    let data = new Date();
    const dia = data.getDate();
    const mes = data.getMonth() + 1;
    const ano = data.getFullYear();
    data = `${dia}/${mes}/${ano}`;

    const withStoreName =
      historicalData.length > 0 &&
      historicalData.map(value => ({
        ...value
      }));

    const csvData = laneCSV;

    if (loading) return <Loading />;
    const cols = [
      { id: 'storeName', label: <Trans>Nome da loja</Trans> },
      { id: 'orderNumber', label: <Trans>Número do pedido</Trans> },
      { id: 'status', label: <Trans>Status</Trans> },
      { id: 'enteredDate', label: <Trans>Data de início</Trans> },
      { id: 'outDate', label: <Trans>Data de saída</Trans> },
      { id: 'username', label: <Trans>E-mail do usuario</Trans> }
    ];
    return (
      <View>
        {withStoreName.length ? (
          <Paper className={classes.responsiveTable}>
            <Table cols={cols} data={withStoreName}>
              {({ orderId, storeId, orderNumber, status, enteredDate, outDate, username }) => (
                <TableRow key={orderId} className={classes.tableRow}>
                  <CustomTableCell>
                    <Typography variant="body1">
                      {
                        dataStore.find(userStore => userStore.store.id === +storeId).store
                          .tradingName
                      }
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Typography variant="body1">
                      {orderNumber !== null ? orderNumber : ' - '}
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Typography variant="body1">{this.translateLane(status)}</Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Typography variant="body1">
                      {enteredDate !== null ? enteredDate : '-'}
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Typography variant="body1">{outDate !== null ? outDate : ' - '}</Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Typography variant="body1">{username}</Typography>
                  </CustomTableCell>
                </TableRow>
              )}
            </Table>
          </Paper>
        ) : (
          <Typography variant="h5">
            <Trans>Nenhuma histórico encontrado</Trans>
          </Typography>
        )}

        <CSVLink filename={`historico-${data}.csv`} data={csvData}>
          <Tooltip title={<Trans>Clique aqui para fazer o download em CSV</Trans>}>
            <Fab>
              <CloudDownloadIcon />
            </Fab>
          </Tooltip>
        </CSVLink>
      </View>
    );
  }

  state = {
    historicalData: [],
    laneCSV: '',
    dataStore: [],
    loading: false,
    store: ['all'],
    periodMonth: 1,
    dateTime: null,
    checkAllStore: false,
    storeID: []
  };

  componentDidMount = async _ => {
    this.update();
  };

  componentDidUpdate(prevProps) {
    const hasLaneChanged = prevProps.filters.chart !== this.props.filters.chart;
    const hasDateChanged = prevProps.filters.date !== this.props.filters.date;
    const hasStoreChanged = prevProps.filters.store !== this.props.filters.store;
    const hasPeriodChanged = prevProps.filters.period !== this.props.filters.period;

    if (hasLaneChanged || hasDateChanged || hasStoreChanged || hasPeriodChanged) {
      this.update();
    }
  }

  translateLane(lane) {
    switch (lane) {
      case 'ORDERED':
        return <Trans>Abertos</Trans>;
      case 'ORDER_RECEIVED':
        return <Trans>Abertos</Trans>;
      case 'ON_SUPPLIER':
        return <Trans>Fornecedor</Trans>;
      case 'ASSEMBLY_LAB':
        return <Trans>Montadora</Trans>;
      case 'AWAITING_STORE':
        return <Trans>Loja</Trans>;
      case 'RECEIVED':
        return <Trans>Recebido</Trans>;
      default:
        return lane;
    }
  }

  async update() {
    this.setState({ loading: true });
    // Recebendo storeId
    const receivingStoreId = [];
    // Recebendo store
    const receivingStore = [];
    // Recebendo todas as lojas relacionadas ao usuario
    const receivingDataStore = await getUserStores(this.props.user.email);

    let now;
    let csv;

    if (this.props.filters.date === null) {
      now = new Date();
    } else {
      now = new Date(this.props.filters.date);
    }

    // Variavel data anterior
    const before = new Date(
      now.getFullYear(),
      now.getMonth() - this.props.filters.period,
      now.getDay()
    );
    // Data atual
    const nowDate = `${dateFormat(now, 'yyyy-mm-dd')}T23:59:59`;
    // Data antes
    const beforeDate = `${dateFormat(before, 'yyyy-mm-dd')}T23:59:59`;

    for (let j = 0; j < receivingDataStore.length; j += 1) {
      receivingStoreId.push(receivingDataStore[j].store.id);
      receivingStore.push({
        id: receivingDataStore[j].store.id,
        tradingName: receivingDataStore[j].store.tradingName
      });
    }

    if (this.props.filters.store === 'all' || this.props.filters.store[0] === 'all') {
      this.setState({ storeID: receivingStoreId });
    } else {
      this.setState({ storeID: this.props.filters.store });
    }

    const historicalData = await getHistoricalLane(this.state.storeID, beforeDate, nowDate);

    const getCsv = await getHistoricalLaneCSV(this.state.storeID, beforeDate, nowDate)
      .then(function(response) {
        return response.text();
      })
      .then(function(data) {
        csv = data;
      });

    this.setState({ historicalData, laneCSV: csv, dataStore: receivingDataStore, loading: false });
  }
}

export const styles = theme => ({
  responsiveTable: {
    overflowX: `scroll`,
    [theme.breakpoints.up('md')]: {
      overflowX: `auto`
    }
  },
  tableRow: {
    cursor: 'pointer'
  }
});

HistoricLaneTable.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return { user: state.user };
}

export default compose(
  connect(mapStateToProps),
  withNotification,
  withStyles(styles)
)(HistoricLaneTable);
