import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { reducer as form } from 'redux-form';
import overlay from './overlay';
import i18n from './i18n';
import suppliers, { watchSuppliers } from './suppliers';
import auth, { watchAuth } from './auth';
import user, { watchUser } from './user';
import notifications from './notifications';
import orders, {
  watchGetOrders,
  watchGetOrder,
  watchChangeOrder,
  watchInsertOrder
} from './orders';
import stores, { watchGetStores } from './stores';
import storeSuppliers, { watchGetStoreSupplier } from './storeSuppliers';

export function* sagas() {
  yield all([
    fork(watchAuth),
    fork(watchUser),
    fork(watchSuppliers),
    fork(watchGetOrders),
    fork(watchGetOrder),
    fork(watchInsertOrder),
    fork(watchChangeOrder),
    fork(watchGetStores),
    fork(watchGetStoreSupplier)
  ]);
}

export default combineReducers({
  i18n,
  auth,
  suppliers,
  user,
  notifications,
  overlay,
  form,
  orders,
  stores,
  storeSuppliers
});
