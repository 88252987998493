import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Creators } from 'store/ducks/suppliers';

function withSuppliers(WrappedComponent) {
  function Hoc({ ...props }) {
    const { getSuppliers, suppliers } = props;

    useEffect(() => {
      if (!suppliers.length) {
        getSuppliers();
      }
    }, [getSuppliers, suppliers]);

    return <WrappedComponent {...props} />;
  }

  function mapStateToProps(state, props) {
    return { suppliers: state.suppliers };
  }

  function mapDispatchToProps(dispatch, props) {
    return bindActionCreators(Creators, dispatch);
  }

  return compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(Hoc);
}

export default withSuppliers;
