export default function styles(theme) {
  return {
    root: {
      overflowX: `scroll`,
      [theme.breakpoints.up('md')]: {
        width: '100%',
        display: 'block',
        textAlign: 'center',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        overflowX: `unset`
      }
    }
  };
}
