import React, { Component } from 'react';
import Dialog from 'components/dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from 'components/buttons/button';
import { Trans } from '@lingui/macro';

class AlertDialog extends Component {
  render() {
    const { title, text, acceptText, declineText, submitCallback, open, handleClose } = this.props;

    return (
      <>
        <Dialog open={open} title={title} onClose={handleClose}>
          <DialogContent>
            <DialogContentText>{text}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {declineText || <Trans>Não</Trans>}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                submitCallback && submitCallback();
              }}
              color="primary"
              autoFocus
            >
              {acceptText || <Trans>Sim</Trans>}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
export default AlertDialog;
