import React, { Component } from 'react';
import ChooseSupplier from 'forms/orders/sendOrder/ChooseSupplier';
import ChooseLens from 'forms/orders/sendOrder/ChooseLens';
import ReviewEditOrder from 'forms/orders/sendOrder/ReviewEditOrder';

class StepperValidation extends Component {
  state = {
    step: 1
  };

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  stepReview = () => {
    const { step } = this.state;
    this.setState({
      step: step - 2
    });
  };

  render() {
    const { step } = this.state;
    const {
      storeId,
      order,
      fetchOrders,
      closeDialog,
      changeOrder,
      getOrderById,
      getStoreById
    } = this.props;

    const shop = getStoreById(storeId);

    if (!order) return null;

    switch (step) {
      case 1:
        return (
          <>
            <ReviewEditOrder
              storeId={storeId}
              order={order}
              getOrderById={getOrderById}
              closeDialog={closeDialog}
              fetchOrders={fetchOrders}
              nextStep={this.nextStep}
              handleChange={this.handleChange}
            />
          </>
        );
      case 2:
        return (
          <>
            <ChooseSupplier
              storeId={storeId}
              order={order}
              closeDialog={closeDialog}
              fetchOrders={fetchOrders}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
            />
          </>
        );
      case 3:
        return (
          <>
            <ChooseLens
              order={order}
              shop={shop}
              closeDialog={closeDialog}
              fetchOrders={fetchOrders}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              stepReview={this.stepReview}
              changeOrder={changeOrder}
              handleChange={this.handleChange}
            />
          </>
        );
      default:
        return 'Sucesso';
    }
  }
}

export default StepperValidation;
