import React from 'react';

export const EyeGlassesIcon = () => (
  <svg x="0px" y="0px" width="35px" height="35px" fill="currentColor" viewBox="0 0 61.864 61.864">
    <path
      d="M43.998,20.008c-4.576,0.328-9.312,2.115-14.479,1.771c-3.854-0.261-7.827-1.496-11.421-1.771
                      c-2.441-0.186-6.161,0-8.043,0.161c-3.557,0.305-8.954,0.891-9.812,3.38c-1.007,2.925,1.398,3.917,1.931,5.147
                      c0.771,1.787,0.685,3.634,1.286,5.146c0.56,1.398,1.289,2.933,2.252,4.186c3.467,4.511,13.371,4.954,17.855,1.287
                      c1.418-1.162,2.895-3.605,3.86-5.791c0.679-1.538,1.441-6.113,3.538-6.113c1.609,0,1.828,1.438,2.253,2.896
                      c1.184,4.059,3.156,7.833,5.951,9.651c4.375,2.85,12.125,2.641,15.604-0.322c2.096-1.783,3.529-4.922,4.345-7.561
                      c0.321-1.047,0.206-2.378,0.645-3.378c0.655-1.501,2.243-1.776,2.092-4.185C61.565,19.965,49.682,19.601,43.998,20.008z
                       M22.282,37.382c-3.14,2.622-9.775,3.034-13.19,0.646c-2.047-1.436-5.787-8.71-3.379-13.192
                      c0.855-1.593,2.395-1.997,4.665-2.253c5.873-0.659,14.79,0.221,16.087,4.344C27.692,30.833,24.208,35.771,22.282,37.382z
                       M56.706,29.821c-0.272,3.131-1.959,7.221-4.184,8.362c-4.875,2.509-11.542,1.283-14.479-2.571
                      c-1.506-1.979-3.467-5.81-2.573-8.688c0.933-3.01,5.355-3.991,9.65-4.344C52.693,21.962,57.374,22.169,56.706,29.821z
                       M51.92,23.71c-0.107-0.234-0.388-0.336-0.622-0.228c-0.233,0.107-0.336,0.389-0.229,0.623l3.006,6.45
                      c0.107,0.234,0.388,0.336,0.62,0.229c0.234-0.109,0.338-0.388,0.229-0.622L51.92,23.71z M50.358,25.271
                      c-0.107-0.234-0.388-0.338-0.622-0.229c-0.232,0.109-0.336,0.388-0.228,0.622l2.067,4.892c0.107,0.234,0.388,0.336,0.622,0.229
                      c0.232-0.109,0.336-0.388,0.227-0.622L50.358,25.271z"
    />
  </svg>
);
