import React from 'react';
import { Field } from 'redux-form-normalize-on-blur';
import PrescriptionInput from 'components/form/inputs/prescription-input';
import { decimalFormat } from 'utils/formatters/formatters';
import { isMultipleOf, mustBeBetween } from 'components/form/validation';

export const CylindricalField = ({ step = '0.25',negativeCylindrical, ...props }) => (
  <Field
    {...props}
    step={step}
    component={PrescriptionInput}
    type="number"
    validate={[isMultiple, mustBe]}
    normalizeOnBlur={value => negativeCylindrical ? decimalFormat(-value): decimalFormat(value)}
  />
);
const isMultiple = isMultipleOf(0.25);
const mustBe = mustBeBetween(-20, 20, true);

export default CylindricalField;