import { Trans } from '@lingui/macro';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import Loading from 'components/utils/Loading';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { getAttachOrder } from 'services/orders';
import withNotification from 'store/providers/withNotification';
import withOverlay from 'store/providers/withOverlay';
import BaseOrderDialog from './BaseOrderDialog';

class AttachOrderNotAddDialog extends Component {
  static propTypes = {
    notify: PropTypes.func.isRequired,
    overlay: PropTypes.shape({
      show: PropTypes.func,
      hide: PropTypes.func
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    order: PropTypes.shape({}), // MUST CHANGE TO DEEPER VALIDATION
    actions: PropTypes.shape({}).isRequired // MUST CHANGE TO DEEPER VALIDATION
  };

  static defaultProps = {
    order: {}
  };

  state = {
    loading: false,
    attachedFiles: []
  };

  getAttachedFiles = initial => async () => {
    if (initial) this.setState({ loading: true });

    const { order } = this.props;
    const { storeId, orderId } = order;
    const attachedFiles = await getAttachOrder(orderId, storeId);

    this.setState({ attachedFiles });

    if (initial) this.setState({ loading: false });
  };

  render() {
    try {
      const { classes, order } = this.props;

      if (!order) return null;

      const { orderNumber } = order;

      const { attachedFiles, loading } = this.state;
      const isThereAttach = attachedFiles.length > 0;
      const frameDesign = attachedFiles.filter(
        item => item.leftRadius !== undefined || item.rightRadius !== undefined
      );

      return (
        <BaseOrderDialog
          maxWidth="sm"
          fullWidth
          name="orderAttachsNotAdd"
          title={
            <>
              <Trans>Anexo do pedido</Trans> <b>{orderNumber}</b>
            </>
          }
          onEnter={this.getAttachedFiles(true)}
        >
          <DialogContent>
            {!loading ? (
              <>
                {isThereAttach ? (
                  <>
                    <Paper className={classes.list}>
                      <List>
                        {attachedFiles.map(attach => (
                          <ListItem>
                            <ListItemText primary={attach.description} />
                            <ListItemSecondaryAction>
                              {frameDesign.map(item => (
                                <>
                                  {item.id === attach.id && (
                                    <Tooltip title={<Trans>visualizar o desenho da armação</Trans>}>
                                      <IconButton edge="end">
                                        <RemoveRedEye
                                          color="primary"
                                          onClick={() => {
                                            this.props.actions('frameDesign', {
                                              order,
                                              frameDesign: item
                                            });
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </>
                              ))}
                              <Tooltip title={<Trans>Realizar download do anexo</Trans>}>
                                <IconButton edge="end" href={attach.url}>
                                  <DownloadIcon color="primary" />
                                </IconButton>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </>
                ) : (
                  <Typography
                    className={classes.notFound}
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                  >
                    <Trans>Nenhum anexo encontrado</Trans>
                  </Typography>
                )}
              </>
            ) : (
              <Loading />
            )}
          </DialogContent>
        </BaseOrderDialog>
      );
    } catch (error) {
      const { notify } = this.props;
      notify({
        variant: 'error',
        message: (
          <Trans>
            Não foi possível estabelecer conexão com o servidor. Tente novamente mais tarde!
          </Trans>
        )
      });
    }
  }
}

const styles = theme => ({
  notFound: {
    marginTop: theme.spacing.unit * 4
  },
  list: {
    marginTop: theme.spacing.unit * 2
  }
});

export default compose(withNotification, withOverlay, withStyles(styles))(AttachOrderNotAddDialog);
