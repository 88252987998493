import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FieldArray } from 'redux-form';
import { Trans } from '@lingui/macro';
import {
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
  Chip
} from '@material-ui/core';
import IntegrationIcon from '@material-ui/icons/SwapHoriz';
import CustomTableCell from 'components/table/TableCell';
import { CheckboxInput } from 'components/form/Input';
import { getSupplierResources } from 'services/suppliers';
import IntegrationDialog from 'views/IntegrationDialog';
import { returnType } from './utils/lensType';
import SupplierInfo from './SupplierInfo';

class ChooseIntegrate extends Component {
  state = {
    supplierResources: [],
    open: false,
    supplierSku: undefined,
    notIntegratedSupplierSku: undefined,
    storeResources: [],
    except: undefined
  };

  render() {
    const {
      classes,
      formValues,
      prescription,
      change,
      order,
      items,
      notify,
      shop,
      additionalItemsCallback,
      additionalItems
    } = this.props;
    const { open, storeResources, except } = this.state;
    return (
      <div className={classes.content}>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell style={{ minWidth: 100 }}>Produto</CustomTableCell>
              <CustomTableCell style={{ maxWidth: 20 }}>
                <Trans>Tipo do item</Trans>
              </CustomTableCell>
              <CustomTableCell style={{ maxWidth: 20 }}>
                <Trans>UN e QTDE</Trans>
              </CustomTableCell>
              <CustomTableCell>
                <Trans>Código do fornecedor</Trans>
              </CustomTableCell>
              <CustomTableCell style={{ maxWidth: 100 }}>
                <Trans>Base</Trans>
              </CustomTableCell>
              <CustomTableCell style={{ maxWidth: 5 }}>
                <Trans>Enviar</Trans>
              </CustomTableCell>
              <CustomTableCell>
                <Trans>Integração</Trans>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray
              name="items"
              component={ItemFields}
              items={items}
              order={order}
              shop={shop}
              formValues={formValues}
              prescription={prescription}
              change={change}
              notify={notify}
              open={open}
              storeResources={storeResources}
              except={except && except.id}
              additionalItems={additionalItems}
              integrationCallback={this.integrationCallback}
              closeCallback={this.handleClickOpen}
              classes={classes}
              additionalItemsCallback={additionalItemsCallback}
            />
          </TableBody>
        </Table>
      </div>
    );
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  integrationCallback = (type, sku, description, except) => {
    this.handleClickOpen();
    this.setState({ storeResources: { type, sku, description }, except });
  };

  handleClickOpen = () => {
    const { change } = this.props;
    this.setState({ open: !this.state.open });
    if (this.state.open) {
      change('checkIntegrations', true);
    }
  };

  doRecursiveFetch = async (supplierId, storeSkuType, storeCnpj, page = 0, allItems = []) => {
    let storage = [];
    const { last, content } = await getSupplierResources({
      supplierId,
      resourceType: storeSkuType,
      storeCnpj,
      page
    });
    storage = [...allItems, ...content];
    return last
      ? storage
      : this.doRecursiveFetch(supplierId, storeSkuType, storeCnpj, page + 1, storage);
  };
}

function ItemFields({
  order,
  items,
  shop,
  formValues,
  prescription,
  change,
  open,
  storeResources,
  except,
  closeCallback,
  integrationCallback,
  additionalItemsCallback
}) {
  const { supplierId } = formValues;
  return (
    order &&
    items.map((item, key) => (
      <TableRow key={item.sku}>
        {items[key] && (
          <Fragment>
            <CustomTableCell component="th" scope="row" style={{ maxWidth: 100 }}>
              {items[key] && `${items[key].description}`}
            </CustomTableCell>
            <CustomTableCell style={{ maxWidth: 100 }}>
              <Chip
                label={returnType(
                  items[key].itemSequence,
                  items[key].sku,
                  items[key].type,
                  items,
                  prescription
                )}
                color="primary"
              />
            </CustomTableCell>
            <CustomTableCell style={{ maxWidth: 20 }}>
              <>
                {items[key] && unit.find(unit => unit.value === items[key].unitOfMeasure).text} -{' '}
                {items[key] && items[key].quantity}
              </>
            </CustomTableCell>
            <SupplierInfo
              itemSku={items[key].sku}
              itemSequenceId={items[key].itemSequence}
              sendField={`items.${key}.send`}
              supplierSkuField={`items.${key}.supplierSku`}
              baseField={`items.${key}.base`}
              baseValue={checkBase(formValues.items[key])}
              quantityField={`items.${key}.quantity`}
              quantityValue={items[key].quantity}
              storeId={order.storeId}
              shop={shop}
              supplierId={supplierId}
              sendValue={checkSend(formValues.items[key])}
              prescription={prescription}
              items={items}
              storeSku={items[key].sku}
              storeSkuType={items[key].type}
              change={change}
              additionalItemsCallback={additionalItemsCallback}
            />
            <CustomTableCell style={{ maxWidth: 5 }}>
              {items[key] && <CheckboxInput name={`items.${key}.send`} />}
            </CustomTableCell>
            <CustomTableCell>
              <Tooltip title={<Trans>Integração composta</Trans>}>
                <IconButton
                  onClick={() => {
                    integrationCallback(
                      items[key].type,
                      items[key].sku,
                      items[key].description,
                      items[key].supplierSku
                    );
                  }}
                >
                  <IntegrationIcon />
                </IconButton>
              </Tooltip>
            </CustomTableCell>
          </Fragment>
        )}
        <IntegrationDialog
          open={open}
          onClose={closeCallback}
          storeResource={storeResources}
          storeId={order.storeId}
          defaultSupplier={supplierId}
          except={except}
        />
      </TableRow>
    ))
  );
}

function checkBase(value) {
  try {
    if (value !== undefined) {
      if (value.base) return value.base;
    } else return undefined;
  } catch (erro) {
    console.log(erro);
  }
}

function checkSend(value) {
  try {
    if (value !== undefined) {
      if (value.send) return value.send;
    } else return undefined;
  } catch (erro) {
    console.log(erro);
  }
}

const unit = [
  { value: 'EACH', text: <Trans>UN</Trans> },
  { value: 'PAIR', text: <Trans>PR</Trans> },
  { value: 'HOUR', text: <Trans>HOUR</Trans> },
  { value: 'DAYS', text: <Trans>DAYS</Trans> }
];

const styles = theme => ({
  content: {
    overflowX: `scroll`,
    [theme.breakpoints.up('md')]: {
      width: '100%',
      display: 'block',
      textAlign: 'center',
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      overflowX: `auto`
    }
  },
  buutonLink: {
    color: '#047eb4',
    backgroundColor: 'transparent',
    fontSize: '0.8em',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    }
  },
  tableFormControl: {
    overflowX: `scroll`,
    [theme.breakpoints.up('md')]: {
      overflowX: `auto`
    }
  }
});

export default withStyles(styles)(ChooseIntegrate);
