import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

const defaultProps = {
  PaperProps: {
    style: {
      padding: 24,
      maxWidth: 300
    }
  }
};

export default function Drawer({ children, ...props }) {
  return <SwipeableDrawer {...props}>{children}</SwipeableDrawer>;
}

Drawer.defaultProps = defaultProps;
