import { emphasize } from '@material-ui/core/styles/colorManipulator';

export default function styles(theme) {
  return {
    root: {
      flexGrow: 1,
      position: 'relative'
    },
    input: {
      display: 'flex',
      backgroundColor: theme.palette.grey[200],
      height: '1.1875em',
      [theme.breakpoints.up('md')]: {
        minWidth: 240
      }
    },
    valueContainer: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      overflow: 'hidden'
    },
    chip: {
      height: 20,
      width: 20,
      backgroundColor: 'red',
      margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08
      )
    },
    noOptionsMessage: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
    },
    singleValue: {
      fontSize: 16,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 200
    },
    valueDisabled: {
      opacity: '.5'
    },
    placeholder: {
      font: 'inherit',
      color: 'rgba(0, 0, 0, 0.87)'
    },

    menu: {
      // position: "absolute",
      // zIndex: 100,
      // top: `100%`,
      // left: 0,
      // right: 0
    }
  };
}
