import React from 'react';
import { Trans } from '@lingui/macro';

export default function(status) {
  switch (status) {
    case 'ORDERED': {
      return 'Abertos';
    }

    case 'ON_SUPPLIER': {
      return 'Em fornecedores';
    }

    case 'ASSEMBLY_LAB': {
      return 'Montagem';
    }

    case 'AWAITING_STORE': {
      return 'Expedição à loja';
    }

    case 'RECEIVED': {
      return 'Concluído';
    }

    case 'ARCHIVED': {
      return 'Arquivado';
    }

    default: {
      throw new Error(`Nenhum status encotrado para ${status}`);
    }
  }
}
