import React from 'react';
import { Trans } from '@lingui/macro';
import { CheckboxInput } from 'components/form/Input';
import FormControl from 'components/form/wrappers/form-control';

function Configuration() {
  return (
    <>
      <FormControl fullWidth>
        <CheckboxInput
          name="receiveSendFrames"
          label={<Trans>Esta loja controla o envio e recebimento de armação</Trans>}
        />
      </FormControl>
      <FormControl fullWidth>
        <CheckboxInput
          name="printCertificateship"
          label={<Trans>Para alguns produtos o certificado será impresso</Trans>}
        />
      </FormControl>
      <FormControl fullWidth>
        <CheckboxInput name="opticsResources" label={<Trans>Utiliza Recursos óticos</Trans>} />
      </FormControl>
    </>
  );
}

export default Configuration;
