import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import { getUserByID } from 'services/users';
import { getUserStores } from 'services/stores';
import { Trans } from '@lingui/macro';
import Loading from 'components/utils/Loading';
import ChooseStore from './ChooseStore';
import styles from './styles';

class ChooseStoreContainer extends Component {
  componentDidMount = async () => {
    await this.getStores();
    if (this.props.token) await this.getUser();
  };

  render() {
    const {
      classes,
      match: {
        params: { page }
      }
    } = this.props;

    const { user, stores } = this.state;

    if (
      (user && user.userDetail && user.userDetail.storeIdDefault) ||
      (stores && stores.length <= 1)
    ) {
      return <Redirect to="/home" />;
    }

    if (!stores || !user) {
      return (
        <div className={classes.wrapperChooseStore}>
          <div className={classes.containerLoading}>
            <Loading />
            <div className={classes.loadingTxt}>
              <Trans>Verificando...</Trans>
            </div>
          </div>
        </div>
      );
    }

    return (
      <ChooseStore
        classes={classes}
        page={page}
        stores={stores}
        submitCallback={this.submitCallback}
      />
    );
  }

  state = {
    user: null,
    stores: null
  };

  getUser = async () => {
    const {
      user: { userId }
    } = this.props;
    const user = await getUserByID(userId);

    if (user && user.userDetail && user.userDetail.storeIdDefault)
      localStorage.setItem('storeIdDefault', user.userDetail.storeIdDefault);

    this.setState({ user });
  };

  getStores = async () => {
    const {
      user: { email }
    } = this.props;
    const stores = await getUserStores(email);

    this.setState({ stores });
  };

  submitCallback = ({ storeIdDefault }) => {
    const { history } = this.props;
    localStorage.setItem('storeIdDefault', storeIdDefault);
    history.push({
      pathname: `/home`
    });
  };
}

function mapStateToProps(state) {
  return { user: state.user, token: state.auth.accessToken };
}

export default connect(mapStateToProps)(withStyles(styles)(ChooseStoreContainer));
