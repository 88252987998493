import { compose } from 'redux';
import withOrders from 'store/providers/withOrders';
import { withStyles } from '@material-ui/core/styles';
import ArchivedOrders from './ArchivedOrders';
import styles from './styles';

export default compose(
  withStyles(styles),
  withOrders({ status: 'ARCHIVED' })
)(ArchivedOrders);
