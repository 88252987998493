import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Trans } from '@lingui/macro';
import parameters from 'utils/dictionaries/individualParameters';
import ReactDOMServer from 'react-dom/server';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import anamneseParameters from 'utils/dictionaries/anamneseParameters';

const sanitizedIndividualParameters = individualParameters => {
  const arrayOfIndividualParameters = Object.entries(individualParameters);
  const sanitizedValues = arrayOfIndividualParameters.map(param => {
    const selectedParameter = parameters.find(parameter =>
      parameter.value.find(val => val === param[0])
    );

    const newParam = {
      id: param[0],
      key: selectedParameter
        ? ReactDOMServer.renderToStaticMarkup(selectedParameter.label)
        : param[0],
      value: param[1]
    };

    return newParam;
  });
  return sanitizedValues;
};

const labels = {
  occupation: 'Ocupação',
  hobby: 'Hobby',
  visualNeed: 'Necessidade visual',
  reading: 'Leitura',
  computer: 'Computador',
  direction: 'Direção',
  tv: 'TV',
  pantoscopicTilt: 'Inclinação Pantoscópica',
  vertexCornea: 'Córnea Vértice'
};

const AnamneseParametersList = ({ data, storeInfo, classes, deleteCallback }) => {
  if (!data) return null;
  const sanitizedValues = sanitizedIndividualParameters(data);
  const hasParameters = sanitizedValues && sanitizedValues.length > 0;
  const primaryText = (key, value) => {
    return (
      <>
        {`${labels[key]}: `}{' '}
        <b>
          {anamneseParameters.find(item => item.value === key).type === 'select'
            ? `${value} Hora${value === '1' ? '' : 's'}`
            : value}
        </b>
      </>
    );
  };

  const erpSoftwareName = storeInfo && storeInfo.store && storeInfo.store.erpSoftwareProviderName;

  return (
    <>
      {hasParameters ? (
        <>
          <Paper className={classes.list}>
            <List>
              {sanitizedValues.map(({ id, key, value }) => (
                <ListItem>
                  <Avatar>{key.substr(0, 2)}</Avatar>
                  <ListItemText primary={primaryText(key, value)} />
                  {erpSoftwareName !== 'Grupo Acert' && (
                    <ListItemSecondaryAction>
                      <Tooltip title={<Trans>Remover</Trans>}>
                        <IconButton
                          edge="end"
                          onClick={() => {
                            delete data[id.toString()];
                            deleteCallback();
                          }}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </Paper>
        </>
      ) : (
        <Typography className={classes.notFound} color="textSecondary" gutterBottom variant="h6">
          <Trans>Não há parâmetros informados para este pedido.</Trans>
        </Typography>
      )}
    </>
  );
};

export default AnamneseParametersList;
