import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { Trans } from '@lingui/macro';

function NoOptionMessage({ ...props }) {
  return (
    <MenuItem disabled style={{ fontSize: '1em' }}>
      <Trans>Nenhuma opção encontrada</Trans>
    </MenuItem>
  );
}

export default NoOptionMessage;
