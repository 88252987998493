export default function styles(theme) {
  return {
    button: {
      width: 'max-content'
    },
    buttonLink: {
      color: theme.palette.common.blue,
      textDecoration: 'none'
    },
    integrationIcon: {
      marginLeft: theme.spacing.unit
    },
    autocomplete: {
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      },
      display: 'block'
    },
    textAdditionalItems: {
      top: -27,
      color: '#047eb4',
      display: 'block',
      position: 'relative',
      fontSize: '0.9em',
      fontWeight: '700',
      width: '100%',
      textAlign: 'end'
    },
    iconButton: {
      cursor: 'default',
      backgroundColor: 'transparent',
      top: -theme.spacing.unit + 6,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    newIntegration: {
      [theme.breakpoints.up('md')]: {
        maxWidth: theme.spacing.unit * 50,
        minWidth: theme.spacing.unit * 50,
        '& >div:nth-child(1)': {
          height: 110
        }
      },
      maxWidth: theme.spacing.unit * 35,
      minWidth: theme.spacing.unit * 35,
      textAlign: 'center'
    },
    baseIntegration: {
      [theme.breakpoints.up('md')]: {
        maxWidth: theme.spacing.unit * 9
      },
      maxWidth: theme.spacing.unit * 13
    }
  };
}
