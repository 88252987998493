import { Trans } from '@lingui/macro';
import React from 'react';
import View from 'components/view';
import IntegrationProvider from './provider';
import IntegrationFilters from './filters';
import IntegrationTable from './table';

function Integration(props) {
  return (
    <View title={<Trans> Integração de produtos com o fornecedor </Trans>} size="sm">
      <IntegrationProvider>
        <IntegrationFilters />
        <IntegrationTable />
      </IntegrationProvider>
    </View>
  );
}

export default Integration;
