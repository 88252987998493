import React from 'react';
import { Field } from 'redux-form-normalize-on-blur';
import BaseInput from 'components/form/inputs/base-input';
import { decimalFormat } from 'utils/formatters/formatters';
import { mustBeBetween } from 'components/form/validation';

export const DistanceBetweenLensesField = ({ ...props }) => (
  <Field
    {...props}
    component={BaseInput}
    type="number"
    step="0.01"
    normalizeOnBlur={value => decimalFormat(value)}
    validate={[mustBe]}
  />
);

const mustBe = mustBeBetween(5, 40, true);

export default DistanceBetweenLensesField;
