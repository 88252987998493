import React from 'react';
import TextField from '@material-ui/core/TextField';

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control({ selectProps, ...props }) {
  return (
    <>
      <TextField
        fullWidth
        margin={selectProps.margin}
        error={selectProps.touched && Boolean(selectProps.error)}
        InputProps={{
          inputComponent,
          inputProps: {
            className: selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            disabled: selectProps.isDisabled,
            ...props.innerProps
          }
        }}
        variant={selectProps.variant}
        {...selectProps.textFieldProps}
        {...selectProps}
      />
    </>
  );
}

export default Control;
