import React from 'react';
import { OrdersConsumer } from 'views/home/orders/OrdersProvider';
import Products from './Products';

export default ({ ...props }) => (
  <OrdersConsumer>
    {({ actions, receiveOrder }) => (
      <Products {...props} receiveOrder={receiveOrder} actions={actions} />
    )}
  </OrdersConsumer>
);
