import React from 'react';
import { Field } from 'redux-form';
import BaseInput from 'components/form/inputs/base-input';
import { phoneNumberMaxLength, phoneNumberMinLength } from '../validation';

function PhoneNumberField(props) {
  const phoneMask = inputValue => {
    const withoutDigits = inputValue.replace(/\D/g, '');
    const dddMask = withoutDigits.replace(/^(\d\d)(\d)/g, '($1) $2');
    const numberMask = dddMask.replace(/(\d{4,5})(\d{4})(\d)?/, '$1-$2');

    return numberMask;
  };

  const validate = props.validate
    ? [...props.validate, phoneNumberMaxLength, phoneNumberMinLength]
    : [phoneNumberMaxLength, phoneNumberMinLength];

  return (
    <Field {...props} validate={validate} normalize={phoneMask} component={BaseInput} type="text" />
  );
}

export default PhoneNumberField;
