import React from 'react';
import { Trans } from '@lingui/macro';

export default [
  { value: 'LENS', text: <Trans>Produto</Trans> },
  { value: 'SERVICE', text: <Trans>Serviço</Trans> },
  { value: 'ASSEMBLY', text: <Trans>Montagem</Trans> },
  { value: 'COLORING', text: <Trans>Coloração</Trans> },
  { value: 'COLOR', text: <Trans>Cor</Trans> },
  { value: 'COATING', text: <Trans>Tratamento</Trans> },
  { value: 'FRAME', text: <Trans>Armação</Trans> },
  { value: 'FRAME_SHAPE', text: <Trans>Desenho de Armação</Trans> },
  { value: 'BLANK', text: <Trans>Outros</Trans> }
];
