import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Creators as UserCreators } from 'store/ducks/user';
import UserProfile from './UserProfile';
import styles from './styles';

function mapStateToProps(state) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {
    changeImageStatus(imageError) {
      dispatch(UserCreators.changeImageStatus(imageError));
    }
  };
}

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserProfile);
