import { Button, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import Loading from 'components/utils/Loading';
import { Trans } from '@lingui/macro';
import { getOrderCopies } from 'services/orders';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import withNotification from 'store/providers/withNotification';
import BaseOrderDialog from './BaseOrderDialog';

class OrderCopiesDialog extends Component {
  render() {
    const { order } = this.props;
    const { copies, loading } = this.state;

    const Title = () => (
      <>
        <Trans>Cópias do pedido </Trans> <b>{order.orderNumber}</b>
      </>
    );

    const Content = () => {
      if (loading) return <Loading />;
      return (
        <>
          {!this.isCopies && (
            <Typography variant="h6" color="textSecondary" gutterBottom>
              <Trans>Nenhuma cópia encontrada para o pedido</Trans> {order.orderNumber}
            </Typography>
          )}
          <>
            {this.isCopies && <List>{copies.map(this.renderCopy)}</List>}
            <Grid container justify="flex-end">
              <Grid item>
                <Button onClick={this.createCopy(order)} color="primary" variant="contained">
                  <Trans>Criar nova cópia</Trans>
                </Button>
              </Grid>
            </Grid>
          </>
        </>
      );
    };

    return order ? (
      <BaseOrderDialog name="orderCopies" title={<Title />} onEnter={this.getCopies}>
        <DialogContent>
          <Content />
        </DialogContent>
      </BaseOrderDialog>
    ) : null;
  }

  renderCopy = (orderCopy, key) => {
    const { classes } = this.props;
    const { replica } = orderCopy;

    return (
      <ListItem button onClick={this.editCopy(replica)} key={replica.orderId}>
        <ListItemAvatar className={classes.avatar}>
          <Avatar>{key + 1}</Avatar>
        </ListItemAvatar>

        <ListItemText primary={`Cópia de ${replica.orderNumber}`} />
      </ListItem>
    );
  };

  createCopy = order => event => this.props.openDialog('copyOrder', order);

  editCopy = order => event => {
    const { openDialog, notify, getOrderById } = this.props;

    getOrderById(order.orderId)
      ? openDialog('updateOrder', order)
      : notify({
          variant: 'warning',
          message: (
            <Trans>
              Pedido não encontrado. Estamos trabalhando para resolver esse problema, tente
              novamente mais tarde.
            </Trans>
          )
        });
  };

  getCopies = async () => {
    const {
      order: { orderId, storeId }
    } = this.props;

    this.setState({ loading: true });

    const copies = await getOrderCopies(orderId, storeId);

    this.setState({ copies, loading: false });
  };

  get isCopies() {
    return this.state.copies.length > 0;
  }

  state = {
    copies: [],
    loading: false
  };

  static defaultProps = {
    order: {}
  };
}

function styles(theme) {
  return {
    avatar: {
      backgroundColor: theme.palette.primary.main
    }
  };
}

export default compose(withNotification, withStyles(styles))(OrderCopiesDialog);
