import { Trans } from '@lingui/macro';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getSupplierResources } from 'services/suppliers';
import {
  getStoreResources,
  getStoreSupplierIntegrations,
  sendStoreSupplierIntegration,
  removeStoreSupplierIntegration
} from 'services/stores';
import { getStoreById } from 'store/selectors/orders';
import withNotification from 'store/providers/withNotification';
import withOverlay from 'store/providers/withOverlay';
import withQuery from 'utils/query/withQuery';

const IntegrationContext = React.createContext();
const SUPPLIER_RESOURCE = 'supplierResource';

export const IntegrationConsumer = IntegrationContext.Consumer;

export function withIntegrations(WrappedComponent) {
  return props => (
    <IntegrationConsumer>
      {({ ...values }) => <WrappedComponent {...values} {...props} />}
    </IntegrationConsumer>
  );
}

export class IntegrationProvider extends Component {
  render() {
    const { supplierResources, storeResources } = this.state;

    const value = {
      query: this.props.query,
      storeResources,
      supplierResources,
      integrations: this.getIntegrationsWithResources(),
      removeIntegration: this.removeIntegration,
      sendIntegration: this.sendIntegration,
      updateResources: this.updateResources,
      getResourceFromSku: this.getResourceFromSku
    };

    return (
      <IntegrationContext.Provider value={value}>{this.props.children}</IntegrationContext.Provider>
    );
  }

  componentDidMount() {
    if (this.areFiltersSet()) this.updateResources();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.didFilterChange(false)(prevProps, prevState) && this.areFiltersSet())
      this.updateResources();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const update =
      this.didFilterChange(true)(nextProps, nextState) ||
      this.didResourcesChange(nextProps, nextState);

    return update;
  }

  areFiltersSet() {
    return Boolean(
      this.props.query.params.storeId &&
        this.props.query.params.supplierId &&
        this.props.query.params.resourceType &&
        (this.props.query.params.resourceType === 'LENS' ? this.props.query.params.lensType : true)
    );
  }

  didResourcesChange = (tempProps, tempState) => {
    const hasSupplierChanged = tempState.supplierResources !== this.state.supplierResources;
    const hasStoreChanged = tempState.storeResources !== this.state.storeResources;
    const hasIntegrationsChanged = tempState.integrations !== this.state.integrations;
    const haveResourcesChanged = hasSupplierChanged || hasStoreChanged || hasIntegrationsChanged;

    return haveResourcesChanged;
  };

  didFilterChange = opitionalFilters => tempProps => {
    const hasTypeChanged =
      tempProps.query.params.resourceType !== this.props.query.params.resourceType;
    const hasLensTypeChanged = tempProps.query.params.lensType !== this.props.query.params.lensType;
    const hasColorTypeChanged =
      tempProps.query.params.colorType !== this.props.query.params.colorType;
    const hasSupplierChanged =
      tempProps.query.params.supplierId !== this.props.query.params.supplierId;
    const hasStoreChanged = tempProps.query.params.storeId !== this.props.query.params.storeId;
    const hasTextChanged = tempProps.query.params.textFilter !== this.props.query.params.textFilter;

    const haveFiltersChanged = !opitionalFilters
      ? hasTypeChanged || hasSupplierChanged || hasStoreChanged || hasLensTypeChanged
      : hasTypeChanged ||
        hasLensTypeChanged ||
        hasColorTypeChanged ||
        hasSupplierChanged ||
        hasStoreChanged ||
        hasTextChanged;

    return haveFiltersChanged;
  };

  getSupplierResources = async () => {
    const { supplierId, resourceType, storeId, colorType } = this.props.query.params;
    const { getStoreById } = this.props;
    const shop = getStoreById(storeId);

    const { content } = await getSupplierResources({
      supplierId,
      resourceType,
      storeCnpj: shop && shop.cnpj
    });

    return content;
  };

  getStoreResources = async () => {
    const { storeId, resourceType } = this.props.query.params;
    const { content } = await getStoreResources({ storeId, resourceType });
    return content;
  };

  getIntegrations = async () => {
    const { overlay } = this.props;
    const { storeId, resourceType, lensType, supplierId } = this.props.query.params;
    overlay.show(
      <Trans>
        Buscando integrações. Esta operação pode levar alguns minutos, por favor aguarde...
      </Trans>
    );
    const integrationType = this.getIntegrationTypeByResourcesType(resourceType);

    const { content } = await getStoreSupplierIntegrations({
      storeId,
      supplierId,
      integrationType,
      resourceType
    });

    overlay.hide();
    return content;
  };

  getResources = async () => {
    const supplierResources = await this.getSupplierResources();
    const storeResources = await this.getStoreResources();
    const integrations = await this.getIntegrations();

    return {
      supplierResources,
      storeResources,
      integrations
    };
  };

  state = {
    supplierResources: [],
    storeResources: [],
    selected: {
      storeResources: [],
      supplierResources: []
    },
    integrations: []
  };

  updateResources = async () => {
    const { overlay } = this.props;

    overlay.show(<Trans>Atualizando integrações...</Trans>);

    const { supplierResources, storeResources, integrations } = await this.getResources();

    this.setState({
      supplierResources,
      storeResources,
      integrations
    });

    overlay.hide();
  };

  getIntegrationsWithResources = () => {
    const { resourceType } = this.props.query.params;
    const { integrations, supplierResources } = this.state;

    const formatedAndFilteredIntegrations =
      integrations &&
      integrations.filter(this.filterIntegrationsByType).map(this.formatIntegration);

    const formatedResouces = supplierResources
      .map(this.formatResourceForIntegration(SUPPLIER_RESOURCE))
      .filter(this.filterResoucerByIntegration(formatedAndFilteredIntegrations));

    const integrationWithResource =
      formatedAndFilteredIntegrations && formatedAndFilteredIntegrations.concat(formatedResouces);

    if (resourceType === 'COLOR') return formatedResouces;

    return integrationWithResource;
  };

  formatResourceForIntegration = resourceName => resource => {
    return {
      [resourceName]: resource
    };
  };

  isResourceIntegrated = resource => integration => {
    return integration.supplierResource.sku === resource.supplierResource.sku;
  };

  filterResoucerByIntegration = integrations => resource => {
    if (!integrations || integrations.length === 0) return true;

    const isIntegrated = integrations.find(this.isResourceIntegrated(resource));

    return Boolean(!isIntegrated);
  };

  filterIntegrationsByType = item => {
    const { resourceType } = this.props.query.params;
    return item.storeSkuType === resourceType;
  };

  getResourceFromSku = (resources, sku) => {
    if (resources !== undefined) return resources.find(resource => resource.sku === sku);
  };

  formatIntegration = integration => {
    const { storeResources, supplierResources } = this.state;
    const { storeSku, supplierSku, conversionFactor, id } = integration;
    const storeResource = this.getResourceFromSku(storeResources, storeSku) || {};
    const supplierResource = this.getResourceFromSku(supplierResources, supplierSku) || {};

    return {
      conversionFactor,
      storeResource,
      supplierResource,
      id,
      integrated: true
    };
  };

  integrationTypes = {
    FRAME: 'FRAME',
    FRAME_SHAPE: 'FRAME_SHAPE',
    RESOURCE: 'RESOURCE'
  };

  getIntegrationTypeByResourcesType = resourcesType => {
    const { FRAME, FRAME_SHAPE, RESOURCE } = this.integrationTypes;

    if (resourcesType === FRAME) return FRAME;
    if (resourcesType === FRAME_SHAPE) return FRAME_SHAPE;
    return RESOURCE;
  };

  sendIntegration = ({ supplierSku, storeSku, conversionFactor }) => {
    const {
      query: {
        params: { supplierId, storeId, resourceType }
      },
      overlay
    } = this.props;
    const integrationType = this.getIntegrationTypeByResourcesType(resourceType);

    overlay.show(<Trans>Enviando integração...</Trans>);

    return sendStoreSupplierIntegration({
      supplierId,
      storeId,
      integrationData: {
        integrationType,
        supplierSkuType: resourceType,
        storeSkuType: resourceType,
        supplierSku,
        storeSku,
        conversionFactor
      }
    }).then(res => {
      overlay.hide();
      return res;
    });
  };

  removeIntegration = id => {
    const {
      query: {
        params: { supplierId, storeId, resourceType }
      },
      overlay
    } = this.props;

    overlay.show(<Trans>Removendo integração...</Trans>);

    return removeStoreSupplierIntegration({
      resourceType,
      supplierId,
      storeId,
      id
    }).then(res => {
      overlay.hide();
      return res;
    });
  };
}

const mapStateToProps = state => ({
  getStoreById: storeId => getStoreById(state, storeId)
});

export default compose(
  withNotification,
  withOverlay,
  withQuery,
  connect(mapStateToProps, null)
)(IntegrationProvider);
