import { HISTORICAL_ENDPOINT } from '../endpoints';
import { fetcher, flatFetcher } from '../fetcher';

export const getOrderHistorical = (orderId, storeId, page) =>
  flatFetcher(`${HISTORICAL_ENDPOINT}/${orderId}/stores/${storeId}?page=${page}`, {
    method: 'GET'
  });

export const getOrderReceived = (storeId, ìnitial_date, final_date) =>
  flatFetcher(
    `${HISTORICAL_ENDPOINT}?storesId=${storeId}&titleKey=ORDER_RECEIVED&initialDate=${ìnitial_date}&finalDate=${final_date}`,
    {
      method: 'GET'
    }
  );

export const getHistoricalLane = (storeId, ìnitial_date, final_date) =>
  flatFetcher(
    `${HISTORICAL_ENDPOINT}/statuses?storesId=${storeId}&titleKeys=ORDERED,ORDER_RECEIVED,ON_SUPPLIER,ASSEMBLY_LAB,AWAITING_STORE,RECEIVED&initialDate=${ìnitial_date}&finalDate=${final_date}`,
    {
      method: 'GET'
    }
  );

export const getHistoricalLaneCSV = (storeId, ìnitial_date, final_date) =>
  fetcher(
    `${HISTORICAL_ENDPOINT}/statuses-csv?storesId=${storeId}&titleKeys=ORDERED,ORDER_RECEIVED,ON_SUPPLIER,ASSEMBLY_LAB,AWAITING_STORE,RECEIVED&initialDate=${ìnitial_date}&finalDate=${final_date}`,
    {
      method: 'GET'
    }
  );
