import { call, put, takeLatest } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import { resendConfirmation } from 'services/users';
import { loginWithNotification, logout as logoutAPI, validateToken } from 'services/security';
import { Creators as UserCreators } from '../user';

export const getToken = () => localStorage.getItem('accessToken');

export const INITIAL_STATE = {
  accessToken: getToken()
};

const getAuth = (state = INITIAL_STATE, action) => ({ ...state });

const validateAuth = (state = INITIAL_STATE, action) => ({ ...state });

const receiveAuth = (state = INITIAL_STATE, { payload: { accessToken } }) => ({
  ...state,
  accessToken
});

export const { Types, Creators } = createActions({
  getAuth: ['payload'],
  receiveAuth: ['payload'],
  validateAuth: ['payload']
});

export const logout = () => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    logoutAPI(token).then(result => {
      if (result.status === 200) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('email');
        localStorage.removeItem('storeIdDefault');
        localStorage.removeItem('storeIdFilter');
        localStorage.removeItem('userId');
        window.location.replace('/login');
      }
    });
  } else window.location.replace('/login');
};

export function* authHandler({ payload: { user, password } }) {
  try {
    const { accessToken } = yield call(loginWithNotification, user, password);
    if (!accessToken) {
      yield call(
        resendConfirmation,
        user,
        password,
        `${process.env.REACT_APP_URL}user-confirmation/userId={userId}/userToken={userToken}`
      );
      throw new Error('No token received');
    }
    yield localStorage.setItem('accessToken', accessToken);

    yield put(Creators.validateAuth({ accessToken }));
  } catch (err) {
    console.error(err);
    yield put(Creators.validateAuth({ accessToken: null }));
  }
}

export function* tokenHandler({ payload: { accessToken } }) {
  try {
    const { userId, email } = yield call(validateToken, accessToken);
    if (!userId) throw new Error('No id received');
    const accessTokenFilter = localStorage.getItem('accessToken');
    if (accessTokenFilter) yield localStorage.removeItem('accessToken');
    yield localStorage.setItem('accessToken', accessToken);
    yield localStorage.setItem('email', email);
    yield localStorage.setItem('userId', userId);

    yield put(UserCreators.receiveUser({ userId, email }));
    yield put(Creators.receiveAuth({ accessToken }));
  } catch (err) {
    yield localStorage.removeItem('accessToken');
    yield localStorage.removeItem('email');
    yield localStorage.removeItem('storeIdDefault');
    yield localStorage.removeItem('userId');

    yield put(UserCreators.receiveUser({ userId: null, email: null }));
  }
}

export function* watchAuth() {
  yield takeLatest(Types.GET_AUTH, authHandler);
  yield takeLatest(Types.VALIDATE_AUTH, tokenHandler);
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_AUTH]: getAuth,
  [Types.RECEIVE_AUTH]: receiveAuth,
  [Types.VALIDATE_AUTH]: validateAuth
});
