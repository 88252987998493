import React from 'react';
import { OrdersConsumer } from './OrdersProvider';

export default WrappedComponent => {
  const hocComponent = ({ ...props }) => (
    <OrdersConsumer>{values => <WrappedComponent {...values} {...props} />}</OrdersConsumer>
  );

  hocComponent.propTypes = {};

  return hocComponent;
};
