import defaultInterceptor from '../interceptor';

const getToken = () => localStorage.getItem('accessToken');

export const defaultHeaders = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  apikey: '1',
  authorization: getToken()
});

export function fetcher(endpoint, options = {}, interceptor = defaultInterceptor) {
  const headers = defaultHeaders();

  return fetch(endpoint, {
    headers,
    ...options
  }).then(interceptor);
}

export const flatFetcher = (endpoint, options = {}) =>
  fetcher(endpoint, options).then(res => res.json());

  export const flatFetcherRest = (endpoint, options = {}) => {
    return fetcher(endpoint, options).then(res => {
      return res.text();
    });
  };