import React, { Component } from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import EditOrderDetail from 'forms/orders/edit-order-detail/';
import { Creators as NotificationCreators } from 'store/ducks/notifications';
import { Trans } from '@lingui/macro';
import { connect } from 'react-redux';
import { editOrder } from 'services/orders';
import { withStyles } from '@material-ui/core/styles';
import { prescriptionTranslator } from 'utils/orders/prescriptionTranslator';
import { formatOrderData } from 'utils/orders/formatOrderData';
import BaseOrderDialog from './BaseOrderDialog';

class UpdateOrderDialog extends Component {
  render() {
    const {
      order,
      getOrderById,
      fetchOrders,
      closeDialog,
      classes,
      getStoreById,
      getStoreResourcesDesign
    } = this.props;

    const { errors } = this.state;

    const store = this.getStore();
    const Title = () => {
      if (order.status !== 'ORDERED')
        return (
          <>
            <Trans>Dados do pedido </Trans> <b>{order.orderNumber}</b> -{' '}
            {store && store.tradingName}
          </>
        );
      return (
        <>
          <Trans>Editar pedido </Trans> <b>{order.orderNumber}</b> - {store && store.tradingName}
        </>
      );
    };

    return order ? (
      <BaseOrderDialog
        fullScreen
        name="updateOrder"
        title={<Title />}
        closeCallback={() => this.setState({ errors: null })}
      >
        <DialogContent className={classes.root}>
          <EditOrderDetail
            orderId={order.orderId}
            fetchOrders={fetchOrders}
            storeId={order.storeId}
            getOrderById={getOrderById}
            closeDialog={closeDialog}
            submitCallback={this.submitCallback}
            getStoreById={getStoreById}
            getStoreResourcesDesign={getStoreResourcesDesign}
            storeInfo={store}
            errors={errors}
          />
        </DialogContent>
      </BaseOrderDialog>
    ) : null;
  }

  getStore = () => {
    const { getStoreById, order } = this.props;
    if (!order) return;
    return getStoreById(order.storeId);
  };

  orderDataIsValid = orderData => {
    const { members, items } = orderData;
    const { notify } = this.props;
    let isValid = true;
    if (members.length === 0) {
      notify(<Trans>O pedido deve possuir ao menos um membro!</Trans>, 'error', true);
      isValid = false;
    }
    if (items.length === 0) {
      notify(<Trans>O pedido deve possuir ao menos um item!</Trans>, 'error', true);
      isValid = false;
    }
    return isValid;
  };

  submitCallback = values => {
    const {
      order: { orderId, storeId },
      closeDialog,
      notify,
      fetchOrders
    } = this.props;

    const orderData = formatOrderData(values);
    if (!this.orderDataIsValid(orderData)) return;
    editOrder(orderId, storeId, orderData).then(res => {
      if (res.status === 202) {
        fetchOrders(orderId);
        this.setState({ errors: null });
        closeDialog('updateOrder');
        notify(<Trans>Pedido atualizado com sucesso!</Trans>, 'success');
      } else if (res.status === 422) {
        res.json().then(res => {
          const prescriptionErros = res.errors;
          const translatedErrors = prescriptionErros.map(error => prescriptionTranslator(error));
          this.setState({ errors: translatedErrors });
        });
      } else if (res.status === 403) {
        notify(<Trans>Você não tem permissão para executar essa ação</Trans>, 'warning', true);
      } else {
        notify(
          <Trans>Não foi possível atualizar seu pedido, tente novamente mais tarde</Trans>,
          'error',
          true
        );
      }
    });
  };

  state = {
    errors: null
  };
}

const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 3,
    overflowY: 'unset'
  }
});

function mapDispatchToProps(dispatch) {
  return {
    notify(message, variant, persist) {
      dispatch(NotificationCreators.addNotification({ message, variant, persist }));
    }
  };
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(UpdateOrderDialog));
