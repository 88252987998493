import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import withHelperText from '../helper-text/withHelperText';
import styles from './styles';
import BaseInput from './BaseInput';

export default compose(
  withStyles(styles),
  withHelperText
)(BaseInput);
