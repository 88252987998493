import { Trans } from '@lingui/macro';
import { Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { isWidthUp } from '@material-ui/core/withWidth';
import Attachment from '@material-ui/icons/Attachment';
import ProductsIcon from '@material-ui/icons/FormatListBulleted';
import InfoIcon from '@material-ui/icons/Info';
import SaveIcon from '@material-ui/icons/Save';
import SupplierRequestIcon from '@material-ui/icons/Send';
import ErrorIcon from '@material-ui/icons/WarningSharp';
import Fab from 'components/fab';
import { ClipboardTextOutline as PrescriptionIcon, Glasses as FrameIcon } from 'mdi-material-ui';
import React, { Component } from 'react';
import { compose } from 'redux';
import { getSupplierRequest, getAttachOrder } from 'services/orders';
import { getStoreResources } from 'services/stores';
import withNotification from 'store/providers/withNotification';
import { OrdersConsumer } from 'views/home/orders/OrdersProvider';
import PrescriptionErrorsDialog from 'views/home/orders/PrescriptionErrorsDialog';
import SupplierRequestList from 'views/home/orders/supplierRequest/SupplierRequestList';
import Frame from '../partials/frame';
import Information from '../partials/information';
import Prescription from '../partials/prescription';
import Products from '../partials/products';
import ContentSection from './ContentSection';

class EditOrderDetail extends Component {
  render() {
    const {
      classes,
      change,
      submitCallback,
      errors,
      handleSubmit,
      suppliersList,
      getOrderById,
      orderId,
      formValues,
      notify,
      actions
    } = this.props;

    const {
      activeContent,
      design,
      supplierRequest,
      storeResources,
      attachedFiles,
      loading
    } = this.state;
    const {
      buttonIcon,
      content,
      sideNav,
      submitFab,
      warningFab,
      removeStep,
      negativeNumber
    } = classes;
    const order = getOrderById(orderId);

    const erpSoftwareName =
      formValues && formValues.store && formValues.store.erpSoftwareProviderName;

    return (
      <>
        <form onSubmit={this.isSendOrder && handleSubmit(submitCallback)}>
          <Paper className={sideNav}>
            <Tooltip title={<Trans>Receita</Trans>}>
              <IconButton onClick={() => this.setActiveContent('prescription')}>
                <PrescriptionIcon className={buttonIcon} />
              </IconButton>
            </Tooltip>

            <Tooltip title={<Trans>Produtos e Serviços</Trans>}>
              <IconButton onClick={() => this.setActiveContent('items')}>
                <ProductsIcon className={buttonIcon} />
              </IconButton>
            </Tooltip>

            <Tooltip title={<Trans>Dados da armação</Trans>}>
              <IconButton onClick={() => this.setActiveContent('frame')}>
                <FrameIcon className={buttonIcon} />
              </IconButton>
            </Tooltip>

            <Tooltip title={<Trans>Adicionar anexo ao pedido</Trans>}>
              <IconButton
                onClick={() => {
                  if (erpSoftwareName === 'Grupo Acert') {
                    actions('orderAttachsNotAdd', { orderId });
                  } else {
                    actions('orderAttachs', { orderId });
                  }
                }}
              >
                <Attachment className={buttonIcon} />
              </IconButton>
            </Tooltip>
            {formValues.status !== 'ORDERED' && (
              <Tooltip title={<Trans>Envios ao fornecedor</Trans>}>
                <IconButton onClick={() => this.setActiveContent('supplierSubmissions')}>
                  <SupplierRequestIcon className={buttonIcon} />
                </IconButton>
              </Tooltip>
            )}

            {!this.isDesktop && (
              <Tooltip title={<Trans>Informações adicionais</Trans>}>
                <IconButton onClick={() => this.setActiveContent('info')}>
                  <InfoIcon className={buttonIcon} />
                </IconButton>
              </Tooltip>
            )}
          </Paper>

          <Grid className={content} container spacing={24}>
            <Grid container item xs={12} md={9} spacing={24} alignContent="flex-start">
              {activeContent === 'prescription' && (
                <ContentSection title={<Trans>Receita</Trans>}>
                  {!loading ? (
                    <div>
                      <Prescription
                        order={order}
                        change={change}
                        storeResources={storeResources}
                        loading={loading}
                        formValues={formValues}
                        removeStep={removeStep}
                        negativeNumber={negativeNumber}
                        notify={notify}
                      />
                    </div>
                  ) : (
                    <div>
                      <CircularProgress />
                    </div>
                  )}
                </ContentSection>
              )}

              {activeContent === 'items' && (
                <ContentSection title={<Trans>Produtos e Serviços</Trans>}>
                  <Products classes={classes} order={order} formValues={formValues} />
                </ContentSection>
              )}

              {activeContent === 'frame' && (
                <ContentSection title={<Trans>Dados da armação</Trans>}>
                  <Frame
                    formValues={formValues}
                    designs={design}
                    change={change}
                    removeStep={removeStep}
                    attachedFiles={attachedFiles}
                    negativeNumber={negativeNumber}
                  />
                </ContentSection>
              )}

              {activeContent === 'supplierSubmissions' && (
                <ContentSection title={<Trans>Envios ao fornecedor</Trans>}>
                  <SupplierRequestList
                    supplierRequest={supplierRequest}
                    suppliersList={suppliersList}
                    rowsPerPage={3}
                  />
                </ContentSection>
              )}

              {!this.isDesktop && activeContent === 'info' && (
                <ContentSection title={<Trans>Informações adicionais</Trans>}>
                  <Grid container spacing={24}>
                    <Information
                      order={order}
                      formValues={formValues}
                      suppliersList={suppliersList}
                      storeId={this.props.initialValues.storeId}
                    />
                  </Grid>
                </ContentSection>
              )}
            </Grid>

            {this.isDesktop && (
              <Grid alignContent="flex-start" container item spacing={24} xs={3}>
                <Information
                  order={order}
                  formValues={formValues}
                  suppliersList={suppliersList}
                  storeId={this.props.initialValues.storeId}
                />
              </Grid>
            )}
          </Grid>
          {this.isSendOrder && erpSoftwareName !== 'Grupo Acert' && (
            <Fab className={submitFab} type="submit">
              <SaveIcon />
            </Fab>
          )}
          {errors && (
            <Tooltip
              title={<Trans>Clique aqui para exibir as críticas de validação do pedido</Trans>}
            >
              <Fab color="secondary" className={warningFab} onClick={this.openPrescriptionDialog}>
                <ErrorIcon />
              </Fab>
            </Tooltip>
          )}
        </form>

        <PrescriptionErrorsDialog
          open={this.state.isOpen}
          onClose={this.closePrescriptionDialog}
          errors={errors}
        />
      </>
    );
  }

  get isDesktop() {
    const { width } = this.props;

    return isWidthUp('md', width);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors !== this.props.errors && this.state.isOpen === false) {
      this.setState({ isOpen: true });
    }
  }

  closePrescriptionDialog = () => {
    this.setState({ isOpen: false });
  };

  openPrescriptionDialog = () => {
    this.setState({ isOpen: true });
  };

  state = {
    tabPrescription: 0,
    tabInformation: 0,
    isOpen: false,
    loading: false,
    supplierRequest: [],
    design: [],
    storeResources: [],
    stores: [],
    attachedFiles: [],
    activeContent: 'prescription'
  };

  setActiveContent = activeContent => this.setState({ activeContent });

  get isSendOrder() {
    const sendOrder =
      this.props.getOrderById(this.props.orderId).status === 'ORDERED' || this.props.copy;

    return sendOrder;
  }

  doRecursiveFetch = async (resourceType, page = 0, allItems = []) => {
    try {
      let storage = [];
      const storeId = this.props.initialValues.storeId;
      const { last, content } = await getStoreResources({
        storeId,
        resourceType,
        page
      });
      storage = [...allItems, ...content];
      return last ? storage : this.doRecursiveFetch(resourceType, page + 1, storage);
    } catch (error) {
      console.log(error);
    }
  };

  getResources = async () => {
    try {
      this.setState({ loading: true });
      const storage = [];
      const data = await this.doRecursiveFetch('LENS');
      storage.push(...data);
      this.setState({ storeResources: [...storage], loading: false });
    } catch (error) {
      console.log(error);
    }
  };

  changeTab = tab => (event, index) => this.setState({ [tab]: index });

  componentWillMount = async () => {
    this.updateOrder();
    this.getSupplierRequestData();
    this.getAttachedFiles();
    this.getDesignResources();
    this.getResources();
  };

  getOrder = () => {
    const { getOrderById, orderId } = this.props;
    return getOrderById(orderId);
  };

  getStore = () => {
    const { getStoreById, storeId } = this.props;
    return getStoreById(storeId);
  };

  updateOrder = () => {
    this.props.fetchOrders(this.props.orderId);
  };

  getAttachedFiles = async _ => {
    const { orderId, storeId, change } = this.props;
    const attachedFiles = await getAttachOrder(orderId, storeId);

    if (
      attachedFiles &&
      attachedFiles.length > 0 &&
      attachedFiles.filter(item => item.leftRadius !== undefined || item.rightRadius !== undefined)
        .length > 0
    ) {
      change('prescription.frame.shape', 'TRACER');
    }

    this.setState({ attachedFiles });
  };

  getDesignResources = () => {
    const { getStoreResourcesDesign, storeId } = this.props;
    getStoreResourcesDesign(storeId).then(contentDesign => {
      if (contentDesign) {
        const newDesignItems = contentDesign.map(({ description, sku }) => ({
          value: sku,
          text: description
        }));

        newDesignItems.push({
          value: 'TRACER',
          text: 'ARQUIVO TRACER'
        });

        this.setState({
          design: [...this.state.design, ...newDesignItems]
        });
      }
    });
  };

  getSupplierRequestData = async _ => {
    const { fetchList, orderId, storeId } = this.props;
    fetchList();
    try {
      const supplierRequestData = await getSupplierRequest(orderId, storeId);
      const sendSuccess =
        supplierRequestData &&
        supplierRequestData.filter(item => item.supplierOrderNumber && item.supplierOrderNumber);
      this.setState({
        supplierRequest: sendSuccess && sendSuccess.length > 0 ? sendSuccess : supplierRequestData
      });
    } catch (error) {
      this.setState({
        supplierRequest: []
      });
    }
  };
}

const ConectOrdersProvider = props => {
  return (
    <OrdersConsumer>
      {({ actions }) => <EditOrderDetail {...props} actions={actions} />}
    </OrdersConsumer>
  );
};

export default compose(withNotification)(ConectOrdersProvider);
