import { createActions, createReducer } from 'reduxsauce';
import { store } from 'App';

export const INITIAL_STATE = {
  loadingText: null,
  visible: false
};

export function showOverlay(state = INITIAL_STATE, action) {
  const {
    payload: { loadingText }
  } = action;

  return {
    ...state,
    visible: true,
    loadingText
  };
}

export function hideOverlay(state = INITIAL_STATE, action) {
  return {
    ...state,
    visible: false,
    loadingText: null
  };
}

export function changeOverlayLoadingText(state = INITIAL_STATE, action) {
  const {
    payload: { loadingText }
  } = action;

  return {
    ...state,
    loadingText
  };
}

export const { Types, Creators } = createActions({
  showOverlay: ['payload'],
  hideOverlay: ['payload'],
  changeOverlayLoadingText: ['payload']
});

export default createReducer(INITIAL_STATE, {
  [Types.SHOW_OVERLAY]: showOverlay,
  [Types.HIDE_OVERLAY]: hideOverlay,
  [Types.CHANGE_OVERLAY_LOADING_TEXT]: changeOverlayLoadingText
});

export const hide = () => store.dispatch(Creators.hideOverlay());
