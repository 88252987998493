import React from 'react';
import { Trans } from '@lingui/macro';

export default ({ role }) => {
  switch (role) {
    case 'ADMINISTRATOR': {
      return <Trans>Administrador</Trans>;
    }

    case 'ACERT': {
      return <Trans>Acert</Trans>;
    }

    case 'ASSEMBLER': {
      return <Trans>Montador</Trans>;
    }

    case 'SALESMAN': {
      return <Trans>Vendedor</Trans>;
    }

    case 'READ_ONLY': {
      return <Trans>Somente leitura</Trans>;
    }
    case 'OPTOMETRIST': {
      return <Trans>Optometrista</Trans>;
    }
    case 'CASHIER': {
      return <Trans>Caixa</Trans>;
    }
    case 'MANAGER': {
      return <Trans>Gerente</Trans>;
    }
    case 'EXTERNAL_SALESMAN': {
      return <Trans>Vendedor Externo</Trans>;
    }

    default: {
      return <Trans>Nenhum papel encontrado.</Trans>;
    }
  }
};
