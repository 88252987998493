import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/buttons/button';
import Dialog from 'components/dialog';
import Fab from 'components/fab';
import Tooltip from '@material-ui/core/Tooltip';
import CustomTableCell from 'components/table/TableCell';
import AddSupplier from 'forms/store/suppliers/AddSupplier';
import Loading from 'components/utils/Loading';
import AddIcon from '@material-ui/icons/Add';
import { removeSupplier, getStoreSuppliers } from 'services/stores';

import Table from 'components/table/Table';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

class StoreSuppliers extends Component {
  render() {
    const { classes, suppliersList, storeId } = this.props;
    const { associatedSuppliers, loading } = this.state;
    if (loading) return <Loading className={classes.loading} />;
    const cols = [
      { label: <Trans>Fornecedor</Trans>, id: 'companyName' },
      { label: <Trans>Código da empresa</Trans>, id: 'supplierId' },
      { label: <Trans>Meu código de cliente</Trans>, id: 'storePurchaseCode' },
      { label: <Trans>CNPJ</Trans>, id: 'cnpj' },
      { label: <Trans>Prêmios</Trans>, id: 'awardsCode' },
      {}
    ];
    const withCompanyName =
      associatedSuppliers.length > 0 &&
      associatedSuppliers.map(supplier => {
        const foundSupplier = suppliersList.find(item => item.id === supplier.supplierId);

        return {
          ...supplier,
          companyName: foundSupplier ? foundSupplier.companyName : '-',
          cnpj: foundSupplier
            ? foundSupplier.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
            : '-'
        };
      });

    return (
      <>
        {withCompanyName.length ? (
          <Paper className={classes.responsiveTable}>
            <Table cols={cols} data={withCompanyName}>
              {({ awardsCode, storePurchaseCode, supplierId, companyName, cnpj }) => (
                <TableRow key={supplierId}>
                  <CustomTableCell>{companyName}</CustomTableCell>
                  <CustomTableCell>{supplierId}</CustomTableCell>
                  <CustomTableCell>{storePurchaseCode}</CustomTableCell>
                  <CustomTableCell>{cnpj}</CustomTableCell>
                  <CustomTableCell>{awardsCode}</CustomTableCell>
                  <CustomTableCell>
                    <Tooltip title={<Trans>Remover fornecedor</Trans>}>
                      <Button
                        onClick={this.remove.bind(this, supplierId)}
                        color="default"
                        variant="default"
                      >
                        <DeleteIcon color="error" />
                      </Button>
                    </Tooltip>
                  </CustomTableCell>
                </TableRow>
              )}
            </Table>
          </Paper>
        ) : (
          <Typography variant="h6" color="textSecondary">
            <Trans>Nenhum fornecedor associado.</Trans>
          </Typography>
        )}
        <Tooltip title={<Trans>Clique aqui para cadastrar um fornecedor</Trans>}>
          <Fab
            onClick={() => {
              this.setState({ dialogOpen: true });
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
        <Dialog
          maxWidth="md"
          fullWidth
          title={<Trans>Associe um fornecedor</Trans>}
          open={this.state.dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
        >
          <DialogContent>
            <AddSupplier
              options={suppliersList.filter(
                supplier =>
                  !associatedSuppliers.map(({ supplierId }) => supplierId).includes(supplier.id)
              )}
              storeId={storeId}
              submitCallback={this.getAssociatedSuppliers.bind(this)}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }

  state = {
    dialogOpen: false,
    associatedSuppliers: [],
    loading: false
  };

  static propTypes = {
    suppliersList: PropTypes.array,
    storeId: PropTypes.string.isRequired
  };

  async componentWillMount() {
    const { fetchList } = this.props;
    fetchList();
    this.setState({ loading: true });
    await this.getAssociatedSuppliers();
    this.setState({ loading: false });
  }

  async remove(supplierId, event) {
    const { storeId, overlay } = this.props;
    overlay.show(<Trans>Removendo fornecedor...</Trans>);
    await removeSupplier(storeId, supplierId);
    await this.getAssociatedSuppliers();
    overlay.hide();
  }

  async getAssociatedSuppliers() {
    const { storeId } = this.props;
    const associatedSuppliers = await getStoreSuppliers(storeId);
    this.setState({ associatedSuppliers, dialogOpen: false });
  }
}

const styles = theme => ({
  responsiveTable: {
    overflowX: `scroll`,
    [theme.breakpoints.up('md')]: {
      overflowX: `auto`
    }
  }
});

export default withStyles(styles)(StoreSuppliers);
