import React from 'react';
import classnames from 'classnames';

function valueContainer(props) {
  return (
    <div
      className={classnames(props.selectProps.classes.valueContainer, {
        [props.selectProps.classes.valueDisabled]: props.selectProps.disabled
      })}
    >
      {props.children}
    </div>
  );
}

export default valueContainer;
