import { Trans } from '@lingui/macro';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Loading from 'components/utils/Loading';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import Button from 'components/buttons/button';
import { getPromotionalOrder, postPromotionalOrder } from 'services/orders';
import withNotification from 'store/providers/withNotification';
import withOverlay from 'store/providers/withOverlay';
import Select from 'react-select';
import StepperValidation from './stepper/steppervalidation/StepperValidation';
import BaseOrderDialog from './BaseOrderDialog';

class OrderPromotionalDialog extends Component {
  static propTypes = {
    notify: PropTypes.func.isRequired,
    overlay: PropTypes.shape({
      show: PropTypes.func,
      hide: PropTypes.func
    }).isRequired,
    classes: PropTypes.shape({}).isRequired,
    order: PropTypes.shape({})
  };

  static defaultProps = {
    order: {}
  };

  state = {
    loading: false,
    promotionSend: false,
    promotional: null,
    showRequiredVoucher: false,
    requiredVoucher: false,
    idPromo: null,
    voucher: null,
    newOrder: undefined,
    promotionalsOrder: []
  };

  getPromotionalOrders = initial => async () => {
    if (initial) this.setState({ loading: true });

    const { notify } = this.props;
    const { order } = this.props;
    const { storeId, orderId } = order;
    const promotionalsOrder = await getPromotionalOrder(orderId, storeId);

    const { promotions } = promotionalsOrder;

    if (promotionalsOrder && !promotionalsOrder.message) {
      this.setState({ loading: false });
    } else {
      notify({
        variant: 'error',
        message: (
          <Trans>
            Não foi possível estabelecer conexão com o servidor. Tente novamente mais tarde!
          </Trans>
        )
      });
      this.setState({ loading: false });
    }

    this.setState({ promotionalsOrder: promotions || promotionalsOrder });
  };

  checkVoucher = requestPromo => {
    const { promotionalsOrder } = this.state;
    const promo = promotionalsOrder.filter(item => item.idPromo === requestPromo.value);

    if (promo && promo.length > 0 && promo[0].requiresNrVoucher === 'S') {
      this.setState({
        showRequiredVoucher: true,
        requiredVoucher: true,
        idPromo: requestPromo.value
      });
    } else {
      this.setState({
        showRequiredVoucher: false,
        requiredVoucher: false,
        idPromo: requestPromo.value
      });
    }

    this.setState({ promotional: requestPromo });
  };

  sendPromotional = () => {
    const { order, fetchOrders } = this.props;
    const { storeId, orderId } = order;
    const { idPromo, voucher } = this.state;
    const payload = { promotionId: idPromo, promotionVoucher: voucher };

    postPromotionalOrder(orderId, storeId, payload).then(response =>
      response.json().then(json => this.setState({ newOrder: json }))
    );
  };

  componentDidUpdate() {
    const { newOrder, promotionSend } = this.state;

    if (newOrder && !promotionSend) {
      this.setState({ promotionSend: true });
    }
  }

  render() {
    const {
      closeDialog,
      changeOrder,
      classes,
      order,
      fetchOrders,
      getOrderById,
      getStoreById
    } = this.props;

    if (!order) return null;

    const { orderNumber } = order;

    const {
      promotionalsOrder,
      newOrder,
      loading,
      promotionSend,
      promotional,
      showRequiredVoucher,
      requiredVoucher
    } = this.state;
    const isPromotionalOrder = promotionalsOrder.length > 0;

    return (
      <BaseOrderDialog
        closeCallback={this.close}
        maxWidth="lg"
        fullScreen={promotionSend}
        fullWidth={promotionSend}
        name="orderPromotional"
        title={
          <>
            <Trans>Pedido promocional</Trans> {!promotionSend && <b>{orderNumber}</b>}
          </>
        }
        onEnter={this.getPromotionalOrders(true)}
      >
        <DialogContent>
          {!loading ? (
            <>
              {!promotionSend ? (
                <>
                  {isPromotionalOrder ? (
                    <>
                      <Select
                        name="promotional"
                        className={classes.col2}
                        isLoading={!promotionalsOrder}
                        isDisabled={!promotionalsOrder}
                        value={promotional}
                        isClearable
                        placeholder={<Trans>Selecione a promoção...</Trans>}
                        options={promotionalsOrder.map(promo => ({
                          label: promo.description,
                          value: promo.idPromo
                        }))}
                        onChange={this.checkVoucher}
                      />
                      {showRequiredVoucher && (
                        <TextField
                          fullWidth
                          autoFocus
                          className={classes.textField}
                          label={<Trans>Voucher</Trans>}
                          onChange={async event => {
                            if (event && event.target.value)
                              this.setState({
                                voucher: event.target.value,
                                requiredVoucher: false
                              });
                          }}
                          variant="outlined"
                          margin="dense"
                        />
                      )}
                      <Button
                        disabled={!promotional || requiredVoucher}
                        onClick={() => {
                          this.sendPromotional();
                        }}
                      >
                        Criar Pedido
                      </Button>
                    </>
                  ) : (
                    <Typography color="textSecondary" gutterBottom variant="h6">
                      <Trans>Nenhum pedido encontrado</Trans>
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  {newOrder && newOrder.storeId && promotionSend && (
                    <StepperValidation
                      storeId={newOrder && newOrder.storeId}
                      order={newOrder}
                      getOrderById={getOrderById}
                      getStoreById={getStoreById}
                      fetchOrders={fetchOrders}
                      changeOrder={changeOrder}
                      closeDialog={closeDialog}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <Loading />
          )}
        </DialogContent>
      </BaseOrderDialog>
    );
  }

  close = event => {
    const { notify, changeOrder } = this.props;
    const { newOrder } = this.state;
    this.setState({
      promotional: null,
      showRequiredVoucher: false,
      voucher: null
    });
    if (newOrder && newOrder.status && newOrder.status === 'ORDERED') {
      changeOrder(newOrder, { orderStatus: 'CANCELED' }).then(response => {
        if (response.status === 'CANCELED') {
          notify({
            variant: 'error',
            message: <Trans>Pedido promocional cancelado!</Trans>
          });
          this.setState({
            promotionSend: false,
            newOrder: undefined
          });
        }
      });
    }
  };
}

const styles = theme => ({
  col2: {
    margin: '0 0 2% 0',
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 40%',
      margin: '0 2% 0 0',
      marginBottom: '15px'
    }
  },
  textField: {
    backgroundColor: '#fff',
    marginBottom: '15px',
    width: '97%',
    '& > div': {
      height: '45px'
    }
  }
});

export default compose(withNotification, withOverlay, withStyles(styles))(OrderPromotionalDialog);
