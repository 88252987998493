import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ItemList from 'components/item-list';

class Items extends Component {
  render() {
    const { classes, items } = this.props;
    return (
      <>
        <Card>
          <CardHeader subheader="Produtos e Serviços" />
          <CardContent>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12}>
                {items.length > 0 ? (
                  <List component="nav" className={classes.root}>
                    {items &&
                      items.map(data => (
                        <ListItem>
                          <ItemList data={data} />
                        </ListItem>
                      ))}
                  </List>
                ) : (
                  <Typography
                    style={{ height: 75 }}
                    className={classes.classTypography}
                    variant="body1"
                    gutterBottom
                  >
                    <b>
                      <Trans>Nenhuma item encontrado</Trans>
                    </b>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  }
}

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      maxHeight: 180
    },
    maxHeight: 300,
    overflowX: 'auto',
    backgroundColor: theme.palette.background.paper
  }
});

export default withStyles(styles)(Items);
