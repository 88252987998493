import { compose } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AnamneseParameters from './AnamneseParameters';
import styles from './styles';

function mapStateToProps(state) {
  const formValues = getFormValues('anamneseParameters')(state) || {};
  return {
    formValues
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null),
  reduxForm({
    form: 'anamneseParameters'
  })
)(AnamneseParameters);
