import { Trans } from '@lingui/macro';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AddEditMembers from 'forms/orders/add-edit-members';
import { connect } from 'react-redux';
import { Creators as OrderCreators } from 'store/ducks/orders';
import { bindActionCreators } from 'redux';
import BaseOrderDialog from './BaseOrderDialog';

class AddEditMembersDialog extends Component {
  render() {
    const { payload } = this.props;
    if (!payload) return null;
    const { order, productKey } = payload;
    const isNewTitle = productKey >= order.members.length;
    const title = isNewTitle ? (
      <>
        <Trans>Adicionar membros ao pedido </Trans> <b>{order.orderNumber}</b>
      </>
    ) : (
      <>
        <Trans>Editar membros ao pedido </Trans> <b>{order.orderNumber}</b>
      </>
    );

    return (
      <BaseOrderDialog name="addEditMembers" title={title}>
        <DialogContent>
          <AddEditMembers onSubmit={this.submitCallback} order={order} productKey={productKey} />
        </DialogContent>
      </BaseOrderDialog>
    );
  }

  submitCallback = res => {
    const {
      receiveOrder,
      payload: { productKey, order }
    } = this.props;

    delete res.selector;

    const isNew = productKey >= order.members.length;

    const members = !isNew
      ? order.members.map((item, index) => {
          if (index === productKey) return res;
          return item;
        })
      : [...order.members, res];

    receiveOrder({ ...order, members });

    this.props.closeDialog('addEditMembers');
  };

  static propTypes = {
    order: PropTypes.object
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(OrderCreators, dispatch);
}

export default connect(
  null,
  mapDispatchToProps
)(AddEditMembersDialog);
