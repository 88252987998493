import React, { useEffect, useState, useCallback } from 'react';
import { Trans } from '@lingui/macro';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import Drawer from 'components/drawer';
import ChartFiltersForm from 'forms/charts/filters';
import PropTypes from 'prop-types';
import MomentDate from 'components/time/Date';
import Store from 'propTypes/Store';

const propTypes = {
  query: PropTypes.shape({
    chart: PropTypes.string,
    date: PropTypes.string,
    store: PropTypes.arrayOf(PropTypes.string),
    period: PropTypes.string
  }),
  stores: PropTypes.arrayOf(Store)
};

function ChartFilters(props) {
  const { query, stores } = props;
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const initialFilters = {
    chart: query.params.chart || 'receivedOrders',
    store: query.params.store || ['all'],
    period: query.params.period || '1',
    date: query.params.date || new Date().toISOString()
  };

  const setFilters = useCallback(filters => {
    Object.keys(filters).forEach(filter => {
      query.set({ property: filter, value: filters[filter] });
    });
  });

  useEffect(() => {
    if (!query.params.date) setFilters(initialFilters);
  }, [initialFilters, query.params.date, setFilters]);

  const findStoreParamById = id => {
    const { store } = query.params;

    const type = typeof store;

    switch (type) {
      case 'object': {
        return store.find(storeParam => id === +storeParam);
      }

      case 'string': {
        return +store === id;
      }

      default:
    }
  };

  const translateChartParam = param => {
    switch (param) {
      case 'receivedOrders':
        return <Trans>Pedidos recebidos</Trans>;
      case 'sentOrders':
        return <Trans>Pedidos enviados ao fornecedor</Trans>;
      case 'ordersPerSupplier':
        return <Trans>Fornecedor x lojas</Trans>;
      default:
    }
  };

  const translatePeriod = param => {
    switch (param) {
      case '1':
        return <Trans>1 Mês</Trans>;
      case '2':
        return <Trans>2 Meses</Trans>;
      case '3':
        return <Trans>3 Meses</Trans>;
      default:
    }
  };

  return (
    <>
      <Drawer open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
        <Typography variant="h6" gutterBottom color="textSecondary">
          <Trans>Filtros</Trans>
        </Typography>
        <ChartFiltersForm initialValues={initialFilters} onSubmit={setFilters} stores={stores} />
      </Drawer>

      <Grid container alignItems="center" spacing={24} style={{ marginBottom: 16 }}>
        <Grid item>
          <Tooltip title={<Trans>Filtrar Indicadores</Trans>}>
            <IconButton color="primary" onClick={() => setDrawerOpen(true)}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        {props.stores.length > 0 &&
          query.params.store &&
          (query.params.store[0] === 'all' ? (
            <Grid item>
              <Chip color="primary" label={<Trans>Todas as lojas</Trans>} />
            </Grid>
          ) : (
            <Grid item>
              <Chip
                color="primary"
                label={props.stores
                  .filter(userStore => findStoreParamById(userStore.store.id))
                  .map(userStore => userStore.store.tradingName)
                  .join(', ')}
              />
            </Grid>
          ))}

        {query.params.chart && (
          <Grid item>
            <Chip color="primary" label={translateChartParam(query.params.chart)} />
          </Grid>
        )}

        {query.params.period && (
          <Grid item>
            <Chip color="primary" label={translatePeriod(query.params.period)} />
          </Grid>
        )}

        {query.params.date && (
          <Grid item>
            <Chip color="primary" label={<MomentDate>{query.params.date}</MomentDate>} />
          </Grid>
        )}
      </Grid>
    </>
  );
}

ChartFilters.propTypes = propTypes;

export default ChartFilters;
