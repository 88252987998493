import React from 'react';
import { Field } from 'redux-form-normalize-on-blur';
import PrescriptionInput from 'components/form/inputs/prescription-input';

export const NumberField = ({ step = '1', ...props }) => (
  <Field
    {...props}
    step={step}
    component={PrescriptionInput}
    type="number"
    normalizeOnBlur={value => parseInt(value)}
  />
);

export default NumberField;
