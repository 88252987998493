import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import React from 'react';

function SelectInput(props) {
  const {
    children,
    classes,
    displayEmpty,
    input,
    label,
    meta,
    fullWidth,
    multiple,
    onChangeCallback,
    options,
    variant,
    ...restProps
  } = props;
  const { onChange, name, value } = input;
  const { touched, error } = meta;

  async function handleChange(event) {
    const {
      target: { value: selected }
    } = event;

    if (multiple) {
      const lastIndex = selected.length - 1;
      const last = selected[lastIndex];
      const [first] = selected;

      let values = [];
      if (selected.length === 0) values = ['all'];
      else if (last === 'all') values = ['all'];
      else if (first === 'all') values = [...selected.slice(1)];
      else values = selected;

      await onChange(values);
      if (onChangeCallback) onChangeCallback(event);
    } else {
      await onChange(selected);
      if (onChangeCallback) onChangeCallback(event);
    }
  }

  return (
    <FormControl displayEmpty={displayEmpty} variant={variant} fullWidth={fullWidth}>
      <InputLabel className={classes.label} htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        className={classes.root}
        error={touched && Boolean(error)}
        displayEmpty={displayEmpty}
        input={<OutlinedInput />}
        id={name}
        multiple={multiple}
        name={name}
        onChange={handleChange}
        value={multiple && value === '' ? [] : value}
      >
        {children}

        {options.map(option => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectInput.propTypes = {
  classes: PropTypes.objectOf({}),
  children: PropTypes.node,
  displayEmpty: PropTypes.bool,
  fullWidth: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  margin: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  multiple: PropTypes.bool,
  onChangeCallback: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
      label: PropTypes.oneOfType(PropTypes.string, PropTypes.node)
    })
  ),
  variant: PropTypes.string
};

SelectInput.defaultProps = {
  displayEmpty: true,
  fullWidth: false,
  multiple: false,
  options: [],
  label: '',
  margin: 'dense',
  classes: {},
  variant: 'outlined',
  onChangeCallback: () => {},
  children: null
};

export default SelectInput;
