import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'components/utils/Loading';
import { getSupplierRequest } from 'services/orders';
import { Creators as SuppliersCreators } from 'store/ducks/suppliers';
import SupplierRequestList from './SupplierRequestList';

class SupplierRequestListContainer extends Component {
  render() {
    const { loading, supplierRequest } = this.state;
    const { suppliersList } = this.props;
    if (loading) {
      return <Loading />;
    }
    return (
      <SupplierRequestList
        supplierRequest={supplierRequest}
        suppliersList={suppliersList}
        rowsPerPage={5}
      />
    );
  }

  componentWillMount = async _ => {
    const { fetchList, orderId, storeId } = this.props;
    this.setState({ loading: true });
    fetchList();
    const supplierRequestData = await getSupplierRequest(orderId, storeId);
    this.setState({ supplierRequest: supplierRequestData, loading: false });
  };
}

function mapStateToProps(state) {
  return { suppliersList: state.suppliers };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchList: _ => dispatch(SuppliersCreators.getSuppliers())
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SupplierRequestListContainer);
