import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import FormSection from 'components/form/wrappers/form-section';
import AutocompleteField from 'components/form/fields/autocomplete';
import TextField from 'components/form/fields/text';
import SelectField from 'components/form/fields/select';
import MenuItem from '@material-ui/core/MenuItem';
import parameters from 'utils/dictionaries/anamneseParameters';
import Grid from '@material-ui/core/Grid';
import Button from 'components/buttons/button';
import PropTypes from 'prop-types';
import { maxLength } from 'components/form/validation';
import NumberField from './components/NumberField';

const hours = [
  { value: '0', label: <Trans>0 Horas</Trans> },
  { value: '1', label: <Trans>1 Hora</Trans> },
  { value: '2', label: <Trans>2 Horas</Trans> },
  { value: '3', label: <Trans>3 Horas</Trans> },
  { value: '4', label: <Trans>4 Horas</Trans> },
  { value: '8', label: <Trans>8 Horas</Trans> }
];
const erpMaxLength = maxLength(30);

const AnamneseParameters = ({ classes, change, handleSubmit, submitCallback, formValues }) => {
  const [selectedType, setSelectedType] = useState('');

  const erpSoftwareName =
    formValues && formValues.store && formValues.store.erpSoftwareProviderName;

  return (
    <form onSubmit={handleSubmit(submitCallback)}>
      <FormSection title={<Trans>Inserir/ Atividade/ Tempo de atividade diária</Trans>}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={5}>
            <AutocompleteField
              name="parameter"
              label={<Trans>Parâmetro</Trans>}
              options={parameters.map(({ value, label, type }) => ({
                label,
                value,
                id: value,
                type
              }))}
              clearCallback={() => {
                setSelectedType('');
                change('value', null);
                change('eye', null);
              }}
              selectionCallback={selectedOption => {
                setSelectedType('');
                change('value', null);
                change('eye', null);
                const { value } = selectedOption;
                const parameter = parameters.find(param => param.value === value);
                setSelectedType(parameter ? parameter.type : undefined);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            {selectedType === 'text' && (
              <TextField
                name="value"
                fullWidth
                label={<Trans>Valor</Trans>}
                validate={[erpMaxLength]}
              />
            )}
            {selectedType === 'select' && (
              <Grid
                item
                xs={12}
                sm={3}
                style={{ maxHeight: '50px', marginTop: '8px', display: 'flex' }}
              >
                <SelectField name="value" fullWidth label={<Trans>Tempo</Trans>}>
                  {hours.map(({ value, label }) => (
                    <MenuItem value={value}>{label}</MenuItem>
                  ))}
                </SelectField>
              </Grid>
            )}
            {selectedType === 'number' && <NumberField label={<Trans>Valor</Trans>} name="value" />}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              className={classes.button}
              disabled={
                !formValues.value || !formValues.parameter || erpSoftwareName === 'Grupo Acert'
              }
              type="submit"
            >
              <Trans>Gravar</Trans>
            </Button>
          </Grid>
        </Grid>
      </FormSection>
    </form>
  );
};

AnamneseParameters.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitCallback: PropTypes.func.isRequired,
  classes: PropTypes.objectOf({}),
  formValues: PropTypes.objectOf({})
};

AnamneseParameters.defaultProps = {
  classes: {},
  formValues: {}
};

export default AnamneseParameters;
