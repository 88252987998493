import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import grey from '@material-ui/core/colors/grey';
import { Trans } from '@lingui/macro';
import eye from 'assets/images/eye.png';

export const OrderData = props => (
  <MuiThemeProvider theme={mui}>
    <Tooltip
      title={
        props.laneId === 'ORDERED' ? <Trans>Editar pedido</Trans> : <Trans>Dados do pedido</Trans>
      }
    >
      <Avatar {...props} style={{ backgroundColor: 'transparent', color: grey[900] }}>
        <img style={{ maxWidth: '100%' }} src={eye} alt="tracking" />
      </Avatar>
    </Tooltip>
  </MuiThemeProvider>
);

const mui = createMuiTheme({
  overrides: {
    MuiAvatar: {
      root: {
        maxWidth: 25,
        margin: '0!important',
        borderWidth: '!important',
        maxHeight: 25
      }
    }
  }
});
