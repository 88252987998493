import Typography from '@material-ui/core/Typography';
import React from 'react';

// TODO: BREADCRUMB...
// Comecei, mas ficou para prox sprint
// import Breadcrumb from "../breadcrumb";

export default ({ title, children, url, size = 'lg' }) => {
  const spacing = size === 'lg' ? 0 : '10vw';

  return (
    <div style={{ margin: `0 ${spacing}` }}>
      <Typography style={{ fontWeight: 700 }} component="h1" variant="h4" gutterBottom>
        {title}
      </Typography>
      {/* <Breadcrumb path={url} /> */}

      {children}
    </div>
  );
};
