import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Trans } from '@lingui/macro';
import parameters from 'utils/dictionaries/individualParameters';
import ReactDOMServer from 'react-dom/server';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';

const indentifyParamEye = (param, selectedParameter) => {
  const eye = param[0].toString().substring(param[0].toString().length - 2);
  let sanitizedEye;
  if (selectedParameter.eye === true) {
    if (eye === '_r') {
      sanitizedEye = ReactDOMServer.renderToStaticMarkup(<Trans>Direito</Trans>);
    } else if (eye === '_l') {
      sanitizedEye = ReactDOMServer.renderToStaticMarkup(<Trans>Esquerdo</Trans>);
    }
  }
  return sanitizedEye;
};
const sanitizedIndividualParameters = individualParameters => {
  const arrayOfIndividualParameters = Object.entries(individualParameters);
  const sanitizedValues = arrayOfIndividualParameters.map(param => {
    const selectedParameter = parameters.find(parameter =>
      parameter.value.find(val => val === param[0])
    );

    const newParam = {
      id: param[0],
      key: selectedParameter
        ? ReactDOMServer.renderToStaticMarkup(selectedParameter.label)
        : param[0],
      value: param[1],
      eye: selectedParameter ? indentifyParamEye(param, selectedParameter) : null
    };

    return newParam;
  });
  return sanitizedValues;
};

const IndividualParametersList = ({ data, storeInfo, classes, deleteCallback }) => {
  if (!data) return null;
  const sanitizedValues = sanitizedIndividualParameters(data);
  const hasParameters = sanitizedValues && sanitizedValues.length > 0;
  const primaryText = (key, value) => {
    return (
      <>
        {`${key}: `} <b>{value}</b>
      </>
    );
  };

  const erpSoftwareName = storeInfo && storeInfo.store && storeInfo.store.erpSoftwareProviderName;

  return (
    <>
      {hasParameters ? (
        <>
          <Paper className={classes.list}>
            <List>
              {sanitizedValues.map(({ id, key, value, eye }) => (
                <ListItem>
                  <Avatar>{key.substr(0, 2)}</Avatar>
                  <ListItemText
                    primary={primaryText(key, value)}
                    secondary={
                      eye
                        ? `${ReactDOMServer.renderToStaticMarkup(<Trans>Olho </Trans>)} ${eye}`
                        : undefined
                    }
                  />
                  {erpSoftwareName !== 'Grupo Acert' && (
                    <ListItemSecondaryAction>
                      <Tooltip title={<Trans>Remover</Trans>}>
                        <IconButton
                          edge="end"
                          onClick={() => {
                            delete data[id.toString()];
                            deleteCallback();
                          }}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </Paper>
        </>
      ) : (
        <Typography className={classes.notFound} color="textSecondary" gutterBottom variant="h6">
          <Trans>Não há parâmetros informados para este pedido.</Trans>
        </Typography>
      )}
    </>
  );
};

export default IndividualParametersList;
