import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { reduxForm, getFormValues, reset } from 'redux-form';
import { Trans } from '@lingui/macro';
import { SelectInput } from 'components/form/Input';
import FormControl from 'components/form/wrappers/form-control';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import last from 'lodash/last';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SendIcon from '@material-ui/icons/Send';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { getOrderById } from 'store/selectors/orders';
import { Creators as NotificationCreators } from 'store/ducks/notifications';
import { Creators as OverlayCreators } from 'store/ducks/overlay';
import { Creators as SuppliersCreators } from 'store/ducks/suppliers';
import { sendOrderToSupplier, getSupplierRequest } from 'services/orders';
import { getSupplierSku, sendStoreSupplierIntegration } from 'services/stores';
import Button from 'components/buttons/button';
import ChooseIntegrate from './ChooseIntegrate';
import ChooseModelIntegrate from './ChooseModelIntegrate';
import ChooseTypeIntegrate from './ChooseTypeIntegrate';
import LensLeft from './lens/LensLeft';
import LensRight from './lens/LensRight';
import { payload } from './utils/orderPayload';
import { setSupplierResourcesDiff, setSupplierResourcesNotDiff } from './utils/lensType';

class ChooseLens extends Component {
  state = {
    selectedLens: false,
    additionalItems: []
  };

  render() {
    const {
      classes,
      handleSubmit,
      formValues: { requiredEye },
      order,
      changeOrder,
      shop
    } = this.props;
    const { items } = this.props.formValues;
    const { additionalItems } = this.state;

    return (
      <form className={classes.root} onSubmit={handleSubmit(this.submit)}>
        <Typography className={classes.titleHead} variant="title" gutterBottom>
          <Trans>Escolha o receituário para envio</Trans>
        </Typography>
        <Typography className={classes.subTitle} variant="subheading" gutterBottom>
          <Trans>
            Escolha também, se irá enviar para 'Ambos os olhos', 'Apenas o olho direito' ou 'Apenas
            o olho esquerdo'
          </Trans>
        </Typography>

        <div className={classes.root}>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={3}>
              <Paper className={classes.paper}>
                <Typography className={classes.title} variant="title" gutterBottom>
                  <Trans>Escolha quais olhos deseja enviar</Trans>
                </Typography>
                <FormControl>
                  <SelectInput label={<Trans>Enviar</Trans>} name="requiredEye">
                    <MenuItem value="BOTH">
                      <Trans>Ambos os olhos</Trans>
                    </MenuItem>
                    <MenuItem value="RIGHT">
                      <Trans>Apenas o olho direito</Trans>
                    </MenuItem>
                    <MenuItem value="LEFT">
                      <Trans>Apenas o olho esquerdo</Trans>
                    </MenuItem>
                  </SelectInput>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Fade in>
                <div className={classes.root}>
                  <Grid container spacing={24}>
                    {requiredEye === 'BOTH' ||
                    requiredEye === 'RIGHT' ||
                    this.state.selectedLens ? (
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <LensRight
                            onChangeCallback={this.onChangeResources.bind(
                              this,
                              this.props,
                              'right'
                            )}
                            change={this.props.change}
                          />
                        </Paper>
                      </Grid>
                    ) : (
                      undefined
                    )}
                    {requiredEye === 'BOTH' || requiredEye === 'LEFT' || this.state.selectedLens ? (
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <LensLeft
                            onChangeCallback={this.onChangeResources.bind(this, this.props, 'left')}
                            change={this.props.change}
                          />
                        </Paper>
                      </Grid>
                    ) : (
                      undefined
                    )}
                  </Grid>
                </div>
              </Fade>
            </Grid>
          </Grid>
        </div>

        {items.length > 0 ? (
          <div>
            <Typography className={classes.titleHead} variant="title" gutterBottom>
              <Trans>Integração do produto com o fornecedor</Trans>
            </Typography>
            <Typography className={classes.subTitle} variant="subheading" gutterBottom>
              <Trans>
                Se o produto já estiver integrado com o fornecedor, você pode permanecer com ele ou
                escolher um novo em 'Código do fornecedor'
              </Trans>
            </Typography>
            <Paper>
              <ChooseIntegrate
                shop={shop}
                order={this.props.order}
                items={this.props.integrationValues.items}
                formValues={this.props.formValues}
                prescription={this.props.prescription}
                change={this.props.change}
                notify={this.props.notify}
                additionalItems={additionalItems}
                additionalItemsCallback={args => {
                  this.setState({ additionalItems: [additionalItems, ...args] });
                }}
                integrationCallback={this.integrationCallback}
              />
            </Paper>
          </div>
        ) : (
          <div />
        )}

        <div className={classes.typeAndShape}>
          <Typography className={classes.titleHead} variant="title" gutterBottom>
            <Trans>Tipo da armação e desenho da armação</Trans>
          </Typography>
          <Typography className={classes.subTitle} variant="subheading" gutterBottom>
            <Trans>
              Se o tipo da lente ou o desenho da armação já estiver integrado, você pode permanecer
              com eles ou escolher um novo
            </Trans>
          </Typography>
          <Grid container spacing={24}>
            {this.props.prescription &&
              this.props.prescription.frame &&
              this.props.prescription.frame.type && (
                <Grid item xs={12} sm={6} className={classes.integrateClass}>
                  <ChooseTypeIntegrate
                    order={this.props.order}
                    type={this.props.prescription.frame.type}
                    formValues={this.props.formValues}
                    change={this.props.change}
                  />
                </Grid>
              )}
            {this.props.prescription && this.props.prescription.frame && (
              <Grid item xs={12} sm={6} className={classes.integrateClass}>
                <ChooseModelIntegrate
                  order={this.props.order}
                  shape={this.props.prescription.frame.shape}
                  formValues={this.props.formValues}
                  change={this.props.change}
                />
              </Grid>
            )}
          </Grid>
        </div>
        <div className={classes.notifyInfo}>
          <Fade
            in={this.state.supplierRemarks}
            className={
              this.state.supplierRemarks ? classes.showSupplierRemarks : classes.hideSupplierRemarks
            }
          >
            <Grid container spacing={16}>
              <Grid item xs={12} md={12}>
                <Typography className={classes.titleHead} variant="title" gutterBottom>
                  Observações do fornecedor
                </Typography>
                <div className={classes.demo}>
                  <List>
                    {this.state.supplierRequestBool ? (
                      <ListItem>
                        {this.state.supplierRequest.notes !== null ? (
                          <ListItemText className={classes.supplierRemarks}>
                            {this.state.supplierRequest.notes}
                          </ListItemText>
                        ) : (
                          <ListItemText>
                            <Trans>Nenhuma observação encontrada</Trans>
                          </ListItemText>
                        )}
                      </ListItem>
                    ) : (
                      <ListItem>
                        <ListItemText>
                          <Trans>Nenhuma observação encontrada</Trans>
                        </ListItemText>
                      </ListItem>
                    )}
                  </List>
                </div>
              </Grid>
              {changeOrder && order && order.promotionOrder && (
                <Grid item xs={12} md={12} className={classes.promo}>
                  <Typography className={classes.titleHead} variant="title" gutterBottom>
                    Deseja editar o pedido e enviar novamente ou cancelar o pedido?
                  </Typography>
                  <div className={classes.promoActions}>
                    <Grid item xs={6} sm={6}>
                      <Button
                        variant="contained"
                        onClick={this.backReview}
                        className={classes.button}
                      >
                        <Trans>Editar o Pedido</Trans>
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Button
                        variant="contained"
                        onClick={this.cancelPromotionOrder}
                        className={classes.button}
                      >
                        <Trans>Cancelar</Trans>
                      </Button>
                    </Grid>
                  </div>
                </Grid>
              )}
            </Grid>
          </Fade>
        </div>

        <div className={classes.actions}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <FormControl>
                <Fab variant="contained" onClick={this.back} className={classes.button}>
                  <NavigateBeforeIcon className={classes.extendedIcon} />
                  <Trans>Voltar</Trans>
                </Fab>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} style={{ textAlign: 'end' }}>
              <FormControl>
                <Fab variant="contained" color="primary" type="submit" className={classes.button}>
                  <SendIcon className={classes.extendedIcon} />
                  <Trans>Enviar</Trans>
                </Fab>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  }

  onChangeResources(props, side) {
    const { formValues } = props;
    if (formValues.diff_sku) {
      setSupplierResourcesDiff(props, side);
    } else {
      setSupplierResourcesNotDiff(props);
    }
  }

  getSupplierNotes = async _ => {
    if (!this.props) return;

    const supplierRequestData = await getSupplierRequest(
      this.props.formValues.orderId,
      this.props.formValues.storeId
    );

    if (supplierRequestData.length > 0 && supplierRequestData.length !== undefined) {
      this.setState({
        supplierRequest: last(supplierRequestData),
        supplierRequestBool: true
      });
    }
  };

  back = e => {
    e.preventDefault();
    this.props.change('resourcesContent', []);
    this.props.prevStep();
  };

  backReview = e => {
    e.preventDefault();
    this.props.change('resourcesContent', []);
    this.props.stepReview();
  };

  cancelPromotionOrder = e => {
    e.preventDefault();
    const { closeDialog, order, changeOrder } = this.props;
    if (order && order.status && order.status === 'ORDERED') {
      changeOrder(order, { orderStatus: 'CANCELED' }).then(response => {
        if (response.status === 'CANCELED') {
          closeDialog('orderPromotional')();
          setTimeout(function() {
            window.location.reload();
          }, 800);
        }
      });
    }
  };

  integrationCallback = ({ storeSku, storeSkuType, supplierResources, notify }) => {
    supplierResources.forEach(async ({ supplierId, ...restArgs }) => {
      const { integrationType, supplierSkuType, supplierSku, conversionFactor } = restArgs;
      const integrationData = {
        integrationType,
        supplierSkuType,
        storeSkuType,
        supplierSku,
        storeSku,
        conversionFactor
      };
      await sendStoreSupplierIntegration({
        supplierId,
        storeId: this.props.formValues.storeId,
        integrationData
      }).then(res => {
        if (res.status === 201) {
          notify({
            variant: 'success',
            message: <Trans>Integração enviada com sucesso!</Trans>
          });
        } else {
          notify({
            variant: 'error',
            message: (
              <Trans>Não foi possível enviar sua integração. Verifique e tente novamente.</Trans>
            )
          });
        }
      });
    });
  };

  submit = async () => {
    const {
      closeDialog,
      notify,
      overlay: { show, hide },
      fetchOrders,
      order,
      changeOrder,
      formValues
    } = this.props;
    const { orderId, storeId } = order;

    const orderValues = {
      ...formValues,
      items: [...formValues.items, ...this.state.additionalItems]
    };

    show(<Trans>Aguarde, estamos processando o envio de seu pedido...</Trans>);
    try {
      await sendOrderToSupplier(
        orderId,
        storeId,
        payload(orderValues, this.props.prescription)
      ).then(result => {
        if (result.status === 204) {
          notify(<Trans>Pedido enviado com sucesso!</Trans>, 'success');
          this.props.dispatch(reset('wizard'));
          if (changeOrder) {
            closeDialog('orderPromotional')();
            setTimeout(function() {
              window.location.reload();
            }, 800);
          } else {
            setTimeout(() => {
              closeDialog('sendOrder')();
            }, 1500);
          }
        } else if (result.status === 400) {
          notify(<Trans>Ocorreu uma falha ao enviar o pedido ao fornecedor!</Trans>, 'error');
        } else if (result.status === 404) {
          notify(<Trans>Nenhum item foi encontrado para envio ao fornecedor</Trans>, 'error');
        } else if (result.status === 422) {
          notify(<Trans>Ocorreu uma falha ao enviar o pedido ao fornecedor!</Trans>, 'error');
          this.getSupplierNotes();
          this.setState({ supplierRemarks: true });
        } else if (result.status === 500) {
          notify(
            <Trans>
              Ocorreu uma falha de comunicação com o servidor. Tente novamente mais tarde!
            </Trans>,
            'error'
          );
          this.setState({ supplierRemarks: true });
        }
        fetchOrders();
      });
    } catch (error) {
      notify(
        <Trans>Ocorreu uma falha de comunicação com o servidor. Tente novamente mais tarde!</Trans>,
        'error'
      );
    }

    hide();
  };

  componentDidMount() {
    try {
      const {
        change,
        prescription: {
          frame: { type, shape }
        }
      } = this.props;
      let frameCode;
      let frameModel;
      this.props.fetchOrders(this.props.order.orderId);

      this.getFrameIntegration(type, 'FRAME').then(content => {
        if (content && content.length > 0) frameCode = content[0].supplierSku;
        if (frameCode) change('frameCode', isNaN(frameCode) === true ? null : frameCode);
      });
      this.getFrameIntegration(shape, 'FRAME_SHAPE').then(content => {
        if (content && content.length > 0) frameModel = content[0].supplierSku;
        if (frameModel) change('frameModel', isNaN(frameModel) === true ? null : frameModel);
      });
    } catch (error) {
      console.log(error);
    }
  }

  getFrameIntegration = async (storeSku, type) => {
    const {
      formValues: { storeId, supplierId }
    } = this.props;
    const { content } = await getSupplierSku(storeId, supplierId, type, storeSku);
    return content;
  };
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  extendedIcon: {
    marginRight: theme.spacing.unit - 3,
    paddingLeft: theme.spacing.unit - 3
  },
  button: {
    [theme.breakpoints.up('md')]: {
      width: 165
    },
    width: 110,
    borderRadius: 0,
    fontSize: '0.8em',
    backgroundColor: '#047eb4',
    color: '#fff',
    paddingRight: theme.spacing.unit,
    '&:hover': {
      backgroundColor: '#04587D'
    }
  },
  actions: {
    position: 'fixed',
    top: '90%',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: '95%'
    }
  },
  titleHead: {
    color: '#757575'
  },
  subTitle: {
    color: '#757575',
    fontSize: '0.8em',
    marginBottom: theme.spacing.unit * 2
  },
  paper: {
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    color: theme.palette.text.secondary
  },
  title: {
    color: '#757575',
    fontSize: '1em',
    marginBottom: theme.spacing.unit * 2
  },
  typeAndShape: {
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    },
    marginTop: theme.spacing.unit * 2
  },
  demo: {
    marginBottom: theme.spacing.unit + 2,
    backgroundColor: '#EFEFEF',
    '& li': {
      textAlign: 'center',
      display: 'inline-block'
    },
    [theme.breakpoints.up('md')]: {
      '& li': {
        textAlign: 'left',
        display: 'flex'
      }
    }
  },
  promo: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center'
  },
  promoActions: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '500px'
  },
  integrateClass: {
    marginTop: 0,
    marginBottom: theme.spacing.unit * 2,
    [theme.breakpoints.up('md')]: {
      marginTop: -theme.spacing.unit * 2
    }
  },
  supplierRemarks: {
    textAlign: 'center'
  },
  showSupplierRemarks: {
    display: 'block'
  },
  hideSupplierRemarks: {
    display: 'none'
  },
  notifyInfo: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: '-1%',
      width: '100%',
      fontSize: '0.6em'
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      position: 'relative',
      marginTop: '-25px',
      marginBottom: 111
    }
  }
});

function mapDispatchToProps(dispatch) {
  return {
    notify(message, variant, persist) {
      dispatch(NotificationCreators.addNotification({ message, variant, persist }));
    },
    fetchList: _ => dispatch(SuppliersCreators.getSuppliers()),
    overlay: {
      show: loadingText => dispatch(OverlayCreators.showOverlay({ loadingText })),
      hide: _ => dispatch(OverlayCreators.hideOverlay())
    }
  };
}

function mapStateToProps(state, ownProps) {
  const formValues = getFormValues('wizard')(state) || {};
  const order = getOrderById(state, ownProps.order.orderId);

  const stableOder = order || ownProps.order;

  return {
    ...stableOder,
    formValues,
    integrationValues: {
      ...stableOder,
      ...formValues
    }
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })
)(ChooseLens);
