import { Trans } from '@lingui/macro';
import Dialog from 'components/dialog';
import DialogContent from '@material-ui/core/DialogContent';
import React, { Component } from 'react';
import { hoursWithoutUTC } from 'utils/date/formatHours';
import { connect } from 'react-redux';
import { WAIT_FOR_ACTION } from 'redux-wait-for-action';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import InsertOrder from 'forms/orders/insert-order';
import { Creators as OrderCreators } from 'store/ducks/orders';
import { compose } from 'redux';
import { getOrderById } from 'store/selectors/orders';
import withOverlay from 'store/providers/withOverlay';
import BaseOrderDialog from '../BaseOrderDialog';

class InsertOrderDialog extends Component {
  render() {
    return (
      <BaseOrderDialog name="insertOrder" title={<Trans>Inserir novo pedido</Trans>}>
        <DialogContent>
          <InsertOrder onSubmit={this.submitCallback} />
        </DialogContent>
      </BaseOrderDialog>
    );
  }

  submitCallback = res => {
    if (!res) return;
    const {
      insert,
      handleDialogs,
      overlay: { show },
      user: { email, name, userDetail }
    } = this.props;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const formatedDateTime = hoursWithoutUTC(tomorrow);
    const payload = {
      ...res,
      orderDueDateTime: formatedDateTime,
      orderDateTime: formatedDateTime,
      members: [
        {
          email,
          identity: userDetail && userDetail.identity,
          name,
          type: 'SALESMAN',
          phoneNumber: userDetail && userDetail.phonenumber
        }
      ],
      prescription: {
        eyesPrescription: [
          {
            itemSequence: 1,
            side: 'LEFT'
          },
          {
            itemSequence: 1,
            side: 'RIGHT'
          }
        ],
        frame: {}
      }
    };
    show(<Trans>Aguarde...</Trans>);
    insert(payload).then(response => {
      const { orderId } = response;
      const { closeDialog } = this.props;
      if (orderId) {
        closeDialog && closeDialog();
        handleDialogs('updateOrder', { orderId: parseInt(orderId) });
      }
    });
  };
}

function mapStateToProps(state, ownProps) {
  return {
    getOrderById(orderId) {
      return getOrderById(state, orderId);
    },
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    insert(order) {
      const action = {
        ...OrderCreators.insertOrder(order),
        [WAIT_FOR_ACTION]: 'RECEIVE_ORDER'
      };
      return dispatch(action);
    }
  };
}

export default compose(
  withWidth(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withOverlay
)(InsertOrderDialog);
