import { applyMiddleware, compose, createStore } from 'redux';

import createReduxWaitForMiddleware from 'redux-wait-for-action';
import createSagaMiddleware from 'redux-saga';
import reducers, { sagas } from './ducks';

const sagaMiddleware = createSagaMiddleware();
const reduxWaitMiddleware = createReduxWaitForMiddleware();

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware),
  applyMiddleware(reduxWaitMiddleware)
);

const configureStore = () => {
  const store = createStore(reducers, enhancer);

  sagaMiddleware.run(sagas);

  return store;
};

export default configureStore;
