import { hoursWithoutUTC } from 'utils/date/formatHours';

function getEyeInformation(eye, formValues, prescription) {
  if (eye === 'LEFT') {
    return {
      base: returnBase(formValues, 'LEFT', prescription),
      diopter: formValues.diopter_left,
      itemSequence: prescription.eyesPrescription.find(item => item.side === 'LEFT').itemSequence,
      surfacing: returnSurfacing(formValues.surfacing_left)
    };
  }
  if (eye === 'RIGHT') {
    return {
      base: returnBase(formValues, 'RIGHT', prescription),
      diopter: formValues.diopter_right,
      itemSequence: prescription.eyesPrescription.find(item => item.side === 'RIGHT').itemSequence,
      surfacing: returnSurfacing(formValues.surfacing_right)
    };
  }
}

function returnSurfacing(surfacing) {
  switch (surfacing) {
    case 'FINISHED_PROGRESSIVE_LENS':
    case 'FINISHED_BIFOCAL':
    case 'FINISHED_SINGLE_VISION': {
      return false;
    }
    case 'SEMI_FINISHED_PROGRESSIVE_LENS':
    case 'SEMI_FINISHED_BIFOCAL':
    case 'SEMI_FINISHED_SINGLE_VISION':
    case 'SEMI_FINISHED_INTERMEDIATE_VISION':
    case 'FREE_FORM': {
      return true;
    }
    default: {
      break;
    }
  }
}

function returnBase(formValues, side, prescription) {
  const item = formValues.items.find(
    item =>
      item.itemSequence ===
      prescription.eyesPrescription.find(item => item.side === side).itemSequence
  );
  return item && item.base;
}

export function payload(formValues, prescription) {
  let left = {};
  let right = {};

  const receivePrescription = prescription || formValues.prescription;

  if (formValues.requiredEye === 'BOTH') {
    left = getEyeInformation('LEFT', formValues, receivePrescription);
    right = getEyeInformation('RIGHT', formValues, receivePrescription);
  } else if (formValues.requiredEye === 'LEFT') {
    left = getEyeInformation('LEFT', formValues, receivePrescription);
  } else {
    right = getEyeInformation('RIGHT', formValues, receivePrescription);
  }

  const payload = {
    expectedDateTime: hoursWithoutUTC(formValues.orderDueDateTime),
    controlNumber:
      formValues.controlNumber !== '' && formValues.controlNumber !== undefined
        ? formValues.controlNumber
        : '',
    items: formValues.items
      .filter(item => item.send === true)
      .filter(
        item =>
          ((item.itemSequence === left.itemSequence || item.itemSequence === right.itemSequence) &&
            item.type === 'LENS') ||
          item.type !== 'LENS'
      )
      .map(({ itemSequence, quantity, type, supplierSku }) => ({
        itemSequence,
        quantity,
        supplierSkuType: type,
        supplierSku: supplierSku && supplierSku.value
      })),
    notes: formValues.notes,
    prescription: {
      frame: {
        color: receivePrescription.frame.color,
        reference: receivePrescription.frame.reference,
        designer: receivePrescription.frame.designer,
        diameter: receivePrescription.frame.assemblyDiameter,
        distanceBetweenLenses: receivePrescription.frame.distanceBetweenLenses,
        dpa: receivePrescription.frame.dpa,
        hbox: receivePrescription.frame.hbox,
        largestDiameter: receivePrescription.frame.largestDiameter,
        sendFrame: receivePrescription.frame.sendFrame,
        changeModel: receivePrescription.frame.changeModel,
        nasal: receivePrescription.frame.nasal,
        temporal: receivePrescription.frame.temporal,
        higher: receivePrescription.frame.higher,
        bottom: receivePrescription.frame.bottom,
        type: receivePrescription.frame.type,
        vbox: receivePrescription.frame.vbox,
        curveTpe: '',
        frameCode: formValues.frameCode,
        model: formValues.frameModel
      },
      left:
        formValues.requiredEye === 'BOTH' || formValues.requiredEye === 'LEFT' ? left : undefined,
      right:
        formValues.requiredEye === 'BOTH' || formValues.requiredEye === 'RIGHT' ? right : undefined
    },
    requiredEye: formValues.requiredEye,
    requiredFrame: formValues.requiredFrame,
    promotionId: formValues.promotionId,
    promotionOrder: formValues.promotionOrder,
    promotionVoucher: formValues.promotionVoucher,
    sentToSupplier: formValues.sentToSupplier,
    sgoOriginId: formValues.sgoOriginId,
    supplierId: formValues.supplierId
  };

  return payload;
}

export function payloadSupplier(formValues, prescription) {
  const payload = {
    expectedDateTime: hoursWithoutUTC(formValues.orderDueDateTime),
    notes: formValues.notes ? formValues.notes : undefined,
    prescription: {
      frame: {
        color: prescription.frame.color,
        reference: prescription.frame.reference,
        designer: prescription.frame.designer,
        diameter: prescription.frame.assemblyDiameter,
        distanceBetweenLenses: prescription.frame.distanceBetweenLenses,
        dpa: prescription.frame.dpa,
        hbox: prescription.frame.hbox,
        largestDiameter: prescription.frame.largestDiameter,
        sendFrame: prescription.frame.sendFrame,
        type: prescription.frame.type,
        vbox: prescription.frame.vbox,
        curveTpe: '',
        frameCode: formValues.frameCode,
        model: formValues.frameModel
      }
    },
    requiredEye: formValues.requiredEye ? formValues.requiredEye : undefined,
    requiredFrame: formValues.requiredFrame ? formValues.requiredFrame : undefined,
    supplierId: formValues.supplierId
  };

  return payload;
}
