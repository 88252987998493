import { Trans } from '@lingui/macro';
import Dialog from 'components/dialog';
import DialogContent from '@material-ui/core/DialogContent';
import React, { Component } from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import ChangePassword from 'forms/user-profile/ChangePassword';

class ChangePasswordDialog extends Component {
  render() {
    const { open, onClose, userID, width } = this.props;
    const isScreenUnderMediumSize = !isWidthUp('md', width);

    return (
      <Dialog
        fullScreen={isScreenUnderMediumSize}
        open={open}
        onClose={onClose}
        title={<Trans>Alteração de senha de usuário</Trans>}
      >
        <DialogContent>
          <ChangePassword userID={userID} submitCallback={onClose} />
        </DialogContent>
      </Dialog>
    );
  }
}

export default withWidth()(ChangePasswordDialog);
