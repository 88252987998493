import { reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AddEditMembers from './AddEditMembers';

const selector = formValueSelector('addEditMembers');

function mapStateToProps(state, ownProps) {
  const members = ownProps.order.members[ownProps.productKey];
  const initialValues = members
    ? {
        ...members
      }
    : {
        identity: ownProps.identity
      };

  return {
    storeId: ownProps.order.storeId,
    initialValues,
    formValues: {
      name: selector(state, 'name'),
      email: selector(state, 'email'),
      phoneNumber: selector(state, 'phoneNumber'),
      type: selector(state, 'type')
    }
  };
}

export default compose(
  connect(mapStateToProps),
  reduxForm({ form: 'addEditMembers' })
)(AddEditMembers);
