import withNotification from 'store/providers/withNotification';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Table from './Table';

function mapStateToProps(state) {
  return { user: state.user };
}

export default compose(
  withNotification,
  connect(mapStateToProps)
)(Table);
