import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const verticalAxis = {
  UP: 'Superior',
  DOWN: 'Inferior'
};
class PrescriptionRight extends Component {
  render() {
    const { classes, eyesPrescription } = this.props;
    return (
      <>
        <Card>
          <CardHeader subheader="Receita - Olho Direito" />
          <CardContent className={classes.root}>
            <Grid container spacing={24}>
              <Grid item xs={6} sm={2}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>Esf.:</Trans>{' '}
                  </b>
                  {eyesPrescription && eyesPrescription[0].spherical}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>Cil.:</Trans>{' '}
                  </b>
                  {eyesPrescription && eyesPrescription[0].cylindrical}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>Eixo:</Trans>{' '}
                  </b>
                  {eyesPrescription && eyesPrescription[0].axis}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>Adição:</Trans>{' '}
                  </b>
                  {eyesPrescription && eyesPrescription[0].addition}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>DNP:</Trans>{' '}
                  </b>
                  {eyesPrescription && eyesPrescription[0].interPupillaryDistance}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>Altura:</Trans>{' '}
                  </b>
                  {eyesPrescription && eyesPrescription[0].fittingHeight}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={24}>
              <Grid item xs={8} sm={3}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>Prisma vertical:</Trans>{' '}
                  </b>
                  {eyesPrescription && eyesPrescription[0].verticalPrismValue}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={3}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>Eixo vertical:</Trans>{' '}
                  </b>
                  {eyesPrescription && verticalAxis[eyesPrescription[0].verticalPrismAxis]}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={3}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>Prisma horizontal:</Trans>{' '}
                  </b>
                  {eyesPrescription && eyesPrescription[0].horizontalPrismValue}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={3}>
                <Typography className={classes.classTypography} variant="body1" gutterBottom>
                  <b>
                    <Trans>Eixo horizontal:</Trans>{' '}
                  </b>
                  {eyesPrescription && eyesPrescription[0].horizontalPrismAxis}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 2
  },
  classTypography: {
    fontSize: '0.8em'
  }
});

export default withStyles(styles)(PrescriptionRight);
